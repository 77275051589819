import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CardContracts from './components/CardContracts';
import CalendarTextOutlineIcon from 'mdi-react/CalendarTextOutlineIcon';

const Dashboard = () => (
  <Container className="contracts">
    <Row>
      <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
        <CalendarTextOutlineIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '13px', marginTop: '-7px' }} />
        <h3 className="page-title">Contratos</h3>
      </Col>
    </Row>
    <Row>
      <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
        <CardContracts />
      </Container>
    </Row>
  </Container>
);

export default Dashboard;
