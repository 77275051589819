import React, { useEffect, useMemo } from 'react';

import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import Select from "react-select";
import { Link, useLocation } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';

import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale("es");
var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months: meses, weekdays: semanas });

import TableBase from './components/TableBase';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

export default function index() {

    
    const location = useLocation();
    const { pushNewItemBreadCrums } = useBreadCrums();

    useEffect(() => {
        pushNewItemBreadCrums('Alertas: Viaje fuera de horario');
    }, [location]);

    const columns = useMemo(() => [
        {
            Header: 'Colaborador',
            accessor: 'colaborador',
            Cell: (cellObj) => (
                <h6 style={{ color: "#4B545A", marginTop: "2.5px", fontSize: "14px", fontWeight: "600" }}>
                    {cellObj.value}
                </h6>
            ),
            Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="Colaborador"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={"Colaboradores"}
                    styles={{
                        option: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isSelected ? "rgb(0,117,255)" : ""
                        })
                    }}
                    onChange={e => {
                        if (e === null) setFilter(undefined)
                        else setFilter(e.value)
                    }}
                    options={[
                        { value: "Laura Yepez", label: "Laura Yepez" },
                        { value: "Sara Ruiz", label: "Sara Ruiz" },
                        { value: "Oscar Erazo", label: "Oscar Erazo" }
                    ]}
                />
            )
        },
        {
            Header: 'Jefe Directo',
            accessor: 'jefeDirecto',
            disableFilters: true
        },
        {
            Header: 'Área / Local',
            accessor: 'area',
            Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="area"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={"Área"}
                    styles={{
                        option: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isSelected ? "rgb(0,117,255)" : ""
                        })
                    }}
                    onChange={e => {
                        if (e === null) setFilter(undefined)
                        else setFilter(e.value)
                    }}
                    options={[
                        { value: "Tecnología", label: "Tecnología" },
                    ]}
                />
            )
        },
        {
            Header: 'Hora de uso irregular',
            accessor: 'horaUsoIrregular',
            disableFilters: true
        },
        {
            Header: 'Inconsistencias KM',
            accessor: 'inconsistencias',
            disableFilters: true
        },
        {
            Header: 'Inconsistencias en salida de  ruta',
            accessor: 'inconsistenciasSalida',
            disableFilters: true
        }
    ],
        []
    );

    const dataGenerator = () => {
        let data = [];
        for (let i = 1; i < 36; i += 1) {
            data.push({
                id: i,
                colaborador: ['Oscar Erazo', 'Laura Yepez', 'Sara Ruiz'][Math.floor((Math.random() * 3))],
                jefeDirecto: ['Ing. Pablo Perez'],
                area: ['Tecnología'],
                horaUsoIrregular: ['14:00'],
                inconsistencias: ["2 KM"],
                inconsistenciasSalida: ["SI"]
            });
        }
        return data;
    }

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px', paddingLeft: "35px" }}>
                    <h3 className="page-title">Alertas: <span className="text-danger">Viaje fuera de horario</span></h3>
                </Col>
            </Row>
            <Row>
                <Container style={{ paddingBottom: '20px', paddingRight: '49px', marginLeft: "0px", width: "90vw" }}>
                    <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
                </Container>
            </Row>
        </Container>
    )
}
