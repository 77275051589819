import { handleActions } from "redux-actions";
import {
  fetchProvidersRequestInvoice,
  fetchProviderInvoice,

  fetchProvidersRequest,
  fetchProvidersSucess,
  fetchProvidersError,
  fetchDriverUserCostSuccess,
  fetchDriverUserKmSuccess,
  fetchDriverUserTravelsSuccess,
  fetchProviderUserCostSuccess,
  fetchProviderUserKmSuccess,
  fetchProviderUserTravelsSuccess,
  fetchProviderTravelsByIdSuccess,
  fetchVehicleByUserId,
  fetchVehicleByUserIdSucess,
  fetchSendEmail,
  fetchSendEmailSucess,
  fetchSendEmailError,
  fetchVehicleByIdSucess,
  fetchCurrentVehicleByUserIdSuccess,
  fetchCurrentVehicleByUserId,
  fetchProviderById,
  fetchProviderByIdSuccess
} from "../actions/ProvidersActions";

const defaultState = {
  provider: null,
  vehicle: null,
  driver: null,
  isFetching: false,
  error: null,
  cost: null,
  km: null,
  travels: null,
  providercost: null,
  providerkm: null,
  providertravels: null,
  providertravelsid: null,
  vehicleByUserId: null,
  isFetchingVehicleByUserId: false,
  isFetchingInvoice: false,
  isSendingEmail: false,
  isSendEmailError: null,
  providerById:null,
  vehicleById: null,
  imgInvoiceUrl:null,
};

export default handleActions(
  {
    [fetchProvidersRequestInvoice](state, { payload }) {
      return {
        ...state,
        imgInvoiceUrl: payload,
        isFetchingInvoice: false,
        error: null,
      };
    },
    [fetchProviderInvoice](state) {
      return {
        ...state,
        isFetchingInvoice: true,
      };
    },
    
    [fetchProvidersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchProvidersSucess](state, { payload }) {
      return {
        ...state,
        vehicle: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProvidersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },

    [fetchDriverUserCostSuccess](state, { payload }) {
      return {
        ...state,
        cost: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchDriverUserKmSuccess](state, { payload }) {
      return {
        ...state,
        km: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchDriverUserTravelsSuccess](state, { payload }) {
      return {
        ...state,
        travels: payload,
        isFetching: false,
        error: null,
      };
    },

    [fetchProviderUserCostSuccess](state, { payload }) {
      return {
        ...state,
        providercost: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProviderUserKmSuccess](state, { payload }) {
      return {
        ...state,
        providerkm: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProviderUserTravelsSuccess](state, { payload }) {
      return {
        ...state,
        providertravels: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProviderTravelsByIdSuccess](state, { payload }) {
      return {
        ...state,
        providertravelsid: payload,
        isFetching: false,
        error: null,
      };
    },


    [fetchProviderById](state) {
      return {
        ...state,
        isFetchingProviderById: true,
      };
    },

    [fetchProviderByIdSuccess](state, { payload }) {
      return {
        ...state,
        providerById: payload,
        isFetchingProviderById: false,
        error: null,
      };
    },


    
    [fetchVehicleByUserId](state) {
      return {
        ...state,
        isFetchingVehicleByUserId: true,
      };
    },
    [fetchVehicleByUserIdSucess](state, { payload }) {
      return {
        ...state,
        vehicleByUserId: payload,
        isFetchingVehicleByUserId: false,
        error: null,
      };
    },


    [fetchCurrentVehicleByUserId](state) {
      return {
        ...state,
        isCurrentFetchingVehicleByUserId: true,
      };
    },

    
    [fetchCurrentVehicleByUserIdSuccess](state, { payload }) {
      return {
        ...state,
        currentVehicleUser: payload,
        isCurrentFetchingVehicleByUserId: false,
        error: null,
      };
    },

    [fetchSendEmail](state, { payload }) {
      return {
        ...state,
        isSendingEmail: true,
        isSendEmailError: null
      };
    },
    [fetchSendEmailSucess](state, { payload }) {
      return {
        ...state,
        isSendingEmail: false,
        isSendEmailError: null
      };
    },
    [fetchSendEmailError](state, { payload }) {
      return {
        ...state,
        isSendingEmail: false,
        isSendEmailError: payload
      };
    },
    [fetchVehicleByIdSucess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        vehicleById: payload
      };
    }
  },

  defaultState
);