import { createAction } from "redux-actions";
import api from "../../utils/api/apiProvider";
import { toast } from "react-toastify";

export const fetchQuestionsRequest = createAction("FETCH_QUESTIONS_REQUEST");
export const fetchQuestionsSuccess = createAction("FETCH_QUESTIONS_SUCCESS");
export const fetchQuestionsError = createAction("FETCH_QUESTIONS_ERROR");
export const fetchQuestionsRolesSucess = createAction(
  "FETCH_QUESTIONS_ROLES_SUCCESS"
);

export const listQuestions = (rolId) => async (dispatch) => {
  try {
    //dispatch(fetchQuestionsRequest());
    const response = await api.questions.getQuestions(rolId);
    const responseRoles = await api.user.getRol();
    dispatch(
      fetchQuestionsRolesSucess(
        responseRoles.data.filter((rol) => rol.id === 5 || rol.id === 2)
      )
    );
    dispatch(fetchQuestionsSuccess(response.data));
  } catch (error) {
    dispatch(fetchQuestionsError({ error }));
    toast.error("Error obteniendo el reporte de las preguntas", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putQuestionById = (id, body, rol) => async (dispatch) => {
  try {
    //dispatch(fetchQuestionsRequest());
    await api.questions.putQuestionsById(id, body);
    toast.success("Pregunta actualizada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listQuestions(rol));
  } catch (error) {
    dispatch(fetchQuestionsError({ error }));
    toast.error("Error al actualizar la pregunta", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postQuestion = (body, rol) => async (dispatch) => {
  try {
    //dispatch(fetchQuestionsRequest());
    await api.questions.postQuestion(body);
    toast.success("Pregunta añadida correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listQuestions(rol))
  } catch (error) {
    dispatch(fetchQuestionsError({ error }));
    toast.error("Error al añadir la pregunta", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
}

export const deleteQuestion = (id, rol) => async (dispatch) => {
  try {
    //dispatch(fetchQuestionsRequest());
    await api.questions.deleteQuestionById(id);
    toast.success("Pregunta eliminada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listQuestions(rol));
  } catch (error) {
    dispatch(fetchQuestionsError({ error }));
    toast.error("Error al eliminar la pregunta", { autoClose: 2500, theme: "colored" });
  }
};
