import { handleActions } from "redux-actions";

import {
  fetchRatesRequest,
  fetchRatesSuccess,
  fetchProvincesSuccess,
  fetchDetailRatesSuccess,
  fetchDetailOnlyRateSuccess,
  fetchRatesError,
} from "../actions/ratesActions";

const defaultState = [];

export default handleActions(
  {
    [fetchRatesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchRatesSuccess](state, { payload }) {
      return {
        ...state,
        ratesData: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchDetailRatesSuccess](state, { payload }) {
      return {
        ...state,
        ratesDetailData: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchDetailOnlyRateSuccess](state, { payload }) {
      return {
        ...state,
        rateDetailOnlyData: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProvincesSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchRatesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
