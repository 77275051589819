import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import CalendarOutlineIcon from 'mdi-react/CalendarOutlineIcon';

import { getUserById, getReviewsByUser } from '../../../redux/actions/usersAction';
import { listDriverCost, listDriverKm, listDriverTravels } from '../../../redux/actions/ProvidersActions';
import moment from 'moment';
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const Dashboard = () => {
  const { driverId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.userById);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const costsLists = useSelector((state) => state.provider.cost);
  const kmLists = useSelector((state) => state.provider.km);
  const travelsLists = useSelector((state) => state.provider.travels);
  const isFetchingData = useSelector((state) => state.provider.isFetching);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const [year, setYear] = useState(new Date());

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte');
  }, [location]);

  useEffect(() => {
    dispatch(getUserById(driverId));
    dispatch(getReviewsByUser(driverId));
    dispatch(listDriverCost(driverId, year));
    dispatch(listDriverKm(driverId, year));
    dispatch(listDriverTravels(driverId, year));
  }, [year]);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="selectYear">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <CalendarOutlineIcon onClick={props.onClick} style={{ height: '20px', marginLeft: '8px', marginTop: '-3px' }} />
      </div>
    );
  });

  const dataGraphics = (graphic) => {
    let data = [];

    if (graphic == 'generalCost') data = costsLists;
    if (graphic == 'generalKm') data = kmLists;
    if (graphic == 'generalTravels') data = travelsLists;

    const resultMapData = Months.map((month) => {
      const index = data.findIndex((monthData) => monthData.Mes === month);
      if (index < 0) return 0;
      else return data[index].Total;
    });

    return {
      labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
      datasets: [
        {
          data: resultMapData,
          backgroundColor: ['rgba(248, 211, 207)', 'rgba(208, 43, 31)', 'rgba(247, 191, 184)', 'rgba(242, 150, 139)', 'rgba(244, 170, 161)', 'rgba(248, 211, 207)', 'rgba(241, 127, 114)', 'rgba(237, 85, 71)', 'rgba(237, 85, 71)', 'rgb(209, 70, 67)', 'rgba(142, 46, 49)', 'rgba(40, 51, 80)'],
          borderWidth: 0,
        },
      ],
    };
  };

  return (
    <Container className="userProfile">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h3 className="page-title">
            Conductor: <span className="text-danger">{user ? `${user.firstName}  ${user.firstLastName}` : ''}</span>
          </h3>
        </div>
        <div className="d-flex">
          <div style={{ marginRight: '15px' }}>
            <DatePicker selected={year} onChange={(date) => setYear(date)} showYearPicker dateFormat="yyyy" className="selectYear" maxDate={new Date()} customInput={<CustomInput />} />
          </div>
          <div className="buttons">
            <Link to={`/drivers/driverGeneralReport/${driverId}`} className="link">
              Ver reporte
            </Link>
          </div>
        </div>
      </div>

      <Row className="m-1">
        <Col md={8} xs={12}>
          {!isFetchingData && costsLists && kmLists && travelsLists ? (
            <>
              <Row>
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Total de <span className="text-danger">gasto</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalCost')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Km <span className="text-danger">promedio</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalKm')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-flex flex-column align-items-center justify-content-center">
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Número de viajes <span className="text-danger">Servicio</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalTravels')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>
        <Col md={4} xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
              Observaciones del <span className="text-danger">Usuario</span>
            </label>
            <div style={{ width: '100%', backgroundColor: 'white', border: '1px solid #C4C4C4', borderRadius: '13px', padding: '10px', height: '450px' }}>
              {!isFetchingReviews &&
                userReviews?.reviews
                  .filter((item) => item.comment !== '')
                  .map((review) => (
                    <div className="mb-4">
                      <div className="d-flex align-items-center">
                        <div className="col-1">
                          <AccountCircleIcon color="#000000" style={{ marginRight: '10px' }} />
                        </div>
                        <div className="col-12">
                          <span className="font-weight-bold"> {`${review.sender.firstName} ${review.sender.firstLastName}`}:</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-1"></div>
                        <div className="col-11">
                          <h5 className="cell">{review.comment}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
