import { get, put, post, deleteRequest } from "../base/index";

export default {
  getProvincesByZone: (idZone) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/provinces/getprovicebyidRegion/${idZone}`
    );
  },


  putProvinceById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/provinces/${id}`, body);
  },

  postProvince: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/provinces`, body);
  },

  deleteProvince: (id) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/provinces/${id}`);
  },
};
