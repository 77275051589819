import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffIcon from 'mdi-react/EyeOffOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { useSelector } from 'react-redux';
import { Spinner, Alert } from 'reactstrap';

const required = value => value ? undefined : 'Required'

const LogInForm = ({ handleSubmit }) => {
  const syncErrors = useSelector((state) => state?.form.log_in_form?.syncErrors);
  const anyTouched = useSelector((state) => state?.form.log_in_form?.anyTouched);
  const isFetching = useSelector(state => state.auth.isFetching);
  const error = useSelector(state => state.auth.error);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <form className="form" onSubmit={handleSubmit} style={{ justifyContent: "center" }}>
      {error?.error?.message && !isFetching &&
        <Alert color="danger" style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '5px 0px', marginBottom: "20px", borderRadius: "12px" }}>
          Error: {error.error.message}
        </Alert>
      }
      <div className="form__form-group">
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="userapp"
            component="input"
            type="text"
            placeholder="Ingresa tu usuario"
            validate={[required]}
          />
        </div>
        {syncErrors?.userapp && anyTouched && <h5 style={{ marginTop: "5px", color: "red", fontWeight: "500" }}>Usuario requerido</h5>}
      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={isPasswordShown ? 'text' : 'password'}
            placeholder="Ingresa tu contraseña"
            validate={[required]}
          />
          {
            isPasswordShown
              ? <button className='form__form-group-button' onClick={() => showPassword()} type="button">
                <EyeIcon />
              </button>
              : <button className='form__form-group-button' onClick={() => showPassword()} type="button">
                <EyeOffIcon />
              </button>
          }
        </div>
        {syncErrors?.password && anyTouched && <h5 style={{ marginTop: "5px", color: "red", fontWeight: "500" }}>Contraseña requerida</h5>}
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
        {!isFetching
          ? <button type="submit" className="btn btn-primary account__btn account__btn--small">Iniciar Sesión</button>
          : <button className="account__btn__loading">
            <Spinner className="spinner__btn__loading" />
          </button>
        }
        <Link className="recoverPassword" to="/password/recover">¿no recuerdas tu contraseña?</Link>
      </div>
    </form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'log_in_form'
})(LogInForm);
