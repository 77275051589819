import { get, put, post, deleteRequest } from '../base/index';

export default {
  getZones: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/regions`);
  },

  putZonesById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/regions/${id}`, body);
  },

  postZone: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/regions`, body);
  },

  deleteZone: (id) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/regions/${id}`);
  },

  getZonesFavorita: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/cities/getzone/favorita`);
  },

  getEstablisments: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/cities/estabishmentsFavorita/favorita`);
  },
};
