import { createAction } from 'redux-actions';
import api from "../../utils/api/apiLocation";
import { toast } from 'react-toastify';

export const fetchLocationRequest = createAction('FETCH_LOCATION_REQUEST');
export const fetchLocationSucess = createAction('FETCH_LOCATION_SUCCESS');
export const fetchLocationError = createAction('FETCH_LOCATION_ERROR');

export const listProvinces = () => async (dispatch) => {
    try {
        dispatch(fetchLocationRequest());
        const response = await api.location.getProvinces();
        dispatch(fetchLocationSucess(response.data.ProvinceBD));
    } catch (error) {
        dispatch(fetchLocationError({ error }));
        toast.error("Error obteniendo las provincias", { autoClose: 2500, theme: "colored" })
        throw error;
    }
}