import { createAction } from "redux-actions";
import api from "../../utils/api/apiProvider";
import { toast } from "react-toastify";

export const fetchParametersRequest = createAction("FETCH_PARAMETERS_REQUEST");
export const fetchParametersSuccess = createAction("FETCH_PARAMETERS_SUCCESS");
export const fetchParametersError = createAction("FETCH_PARAMETERS_ERROR");


export const listParameters = () => async (dispatch) => {
  try {
    dispatch(fetchParametersRequest());
    const response = await api.parameters.getEmergencyNumbers();
    dispatch(fetchParametersSuccess(response.data));
  } catch (error) {
    dispatch(fetchQuestionsError({ error }));
    toast.error("Error al obtener los parámetros", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putParameters = (body) => async (dispatch) => {
  try {
    await api.parameters.putEmergencyNumbers(body);
    toast.success("Parámetros actualizados correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listParameters())
  } catch (error) {
    dispatch(fetchParametersError({ error }));
    toast.error("Error al actualizar los parámetros", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};
