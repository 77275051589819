import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row, Spinner } from 'reactstrap';
import SteeringIcon from 'mdi-react/SteeringIcon';
import Select from "react-select";
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';

import TableBase from './components/TableBase';
import { listUser } from "../../../redux/actions/usersAction";

const Users = () => {
  const dispatch = useDispatch();

  const columns = useMemo(() =>
    [
      {
        Header: 'Placa',
        accessor: 'Placa',
        Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="Placa"
            isClearable={true}
            isSearchable={true}
            placeholder={"Placa"}
            color
            onChange={e => {
              if (e === null) setFilter(undefined)
              else setFilter(e.value)
            }}
            options={[
              { value: "PHP 0765", label: "PHP 0765" },
              { value: "GYE 2347", label: "GYE 2347" },
              { value: "TBU 7865", label: "TBU 7865" }
            ]}
          />
        )
      },
      {
        Header: 'Marcas',
        accessor: 'Marca',
        disableFilters: true
      },
      {
        Header: 'Modelo',
        accessor: 'Modelo',
        disableFilters: true
      },
      {
        Header: "Mantenimiento",
        accessor: "[mantenimentButton]",
        Cell: (cellObj) => (
          <Radio className="radio-table"
            checked={dataGenerator()[cellObj.row.index].Corporative === true}
            onChange={() => handleDataEnabled(cellObj.row.index)}
          />
        ),
        disableFilters: true
      },
      {
        Header: "Corporativo",
        accessor: "[corpButton]",
        Cell: (cellObj) => (
          <Radio className="radio-table"
            checked={dataGenerator()[cellObj.row.index].Security === true}
            onChange={() => handleDataEnabled(cellObj.row.index)}
          />
        ),
        disableFilters: true
      },
      {
        Header: "Habilitado",
        accessor: "[enabledButton]",
        Cell: (cellObj) => (
          <Switch
            checked={dataGenerator()[cellObj.row.index].status === "accepted"}
            onChange={() => handleDataEnabled(cellObj.row.index)}
          />
        ),
        disableFilters: true
      }
    ],
    []
  );

  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Placa: ['PHP 0765', 'GYE 2347', 'TBU 7865'][Math.floor((Math.random() * 3))],
        Marca: ['Chevrolet', 'Hyundai', 'Kia'][Math.floor((Math.random() * 3))],
        Modelo: ['Sedan'][Math.floor((Math.random() * 1))],
        status: ['accepted', 'declined'][Math.floor((Math.random() * 2))],
        Corporative: [true, false][Math.floor((Math.random() * 2))],
        Security: [true, false][Math.floor((Math.random() * 2))]
      });
    }
    return data;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <h3 className="page-title">Autos <span className="text-danger">Inscritos</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
        </Container>
      </Row>
    </Container>
  )
};

export default Users;