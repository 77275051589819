import VehicleApi from "./requests/VehicleApi";
import ProviderApi from "./requests/ProviderApi";
import DriverApi from "./requests/DriverApi";
import HelpAppApi from "./requests/HelpAppApi";
import UsersApi from "./requests/UsersApi";
import FaqApi from "./requests/FaqApi";
import BrandApi from "./requests/BrandApi";
import ParametersApi from "./requests/ParametersApi";
import ColorsApi from "./requests/ColorsApi";
import ModelsApi from "./requests/ModelsApi";
import ZonesApi from "./requests/ZonesApi";
import ProvincesApi from "./requests/ProvincesApi";
import CitiesApi from "./requests/CitiesApi";
import RatesApi from "./requests/RatesApi";


const api = {
    provider: ProviderApi,
    user:UsersApi,
    vehicle: VehicleApi,
    driver: DriverApi,
    brand: BrandApi,
    help:HelpAppApi,
    questions:FaqApi,
    parameters:ParametersApi,
    colors:ColorsApi,
    models:ModelsApi,
    zones:ZonesApi,
    provinces:ProvincesApi,
    cities:CitiesApi,
    rates:RatesApi,
  
};

export default api;