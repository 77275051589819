import { handleActions } from "redux-actions";
import { fetchDashboardRequest, fetchDashboardSucess, fetchDashboardJourneyAlerts, fetchDashboardError, fetchDashboardCollaboratorSucess, fetchDashboardCollaborator, fetchDashboardFormatsSucess, fetchTravelDetailsByIdSucess, fetchtravelsByStatusAndDatesSucess, fetchPassengers, fetchPassengersSucess, fetchDriver, fetchDriverSucess, fetchTopFive } from "../actions/dashboardActions";

const defaultState = {
    data: null,
    dataCollaborator: null,
    dataFormats: null,
    travelDetailsById: null,
    travelsByStatusAndDates: null,
    journeyAlerts: null,
    isFetching: false,
    isFetchingCollaborator: false,
    isFetchingPassengers: false,
    passengers: null,
    isFetchingDriver: false,
    driver: null,
    error: null,
    topFive: null,
}

export default handleActions(
    {
        [fetchDashboardRequest](state) {
            return {
                ...state,
                isFetching: true,
                error: null
            }
        },
        [fetchDashboardSucess](state, { payload }) {
            return {
                ...state,
                data: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchDashboardCollaborator](state) {
            return {
                ...state,
                isFetchingCollaborator: true,
                error: null
            }
        },
        [fetchDashboardCollaboratorSucess](state, { payload }) {
            return {
                ...state,
                dataCollaborator: payload,
                isFetchingCollaborator: false,
                error: null
            }
        },
        [fetchDashboardFormatsSucess](state, { payload }) {
            return {
                ...state,
                dataFormats: payload,
                isFetchingCollaborator: false,
                error: null
            }
        },
        [fetchDashboardJourneyAlerts](state, { payload }) {
            return {
                ...state,
                journeyAlerts: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchTravelDetailsByIdSucess](state, { payload }) {
            return {
                ...state,
                travelDetailsById: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchtravelsByStatusAndDatesSucess](state, { payload }) {
            return {
                ...state,
                travelsByStatusAndDates: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchPassengers](state) {
            return {
                ...state,
                isFetchingPassengers: true,
            }
        },
        [fetchPassengersSucess](state, { payload }) {
            return {
                ...state,
                passengers: payload,
                isFetchingPassengers: false,
                error: null
            }
        },
        [fetchDriver](state) {
            return {
                ...state,
                isFetchingDriver: true,
            }
        },
        [fetchDriverSucess](state, { payload }) {
            return {
                ...state,
                driver: payload,
                isFetchingDriver: false,
                error: null
            }
        },
        [fetchDashboardError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload
            }
        },
        [fetchTopFive](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                topFive: payload
            }
        },
    },
    defaultState,
)