import { createAction } from 'redux-actions';
import api from '../../utils/api/apliClient';
import { toast } from 'react-toastify';

export const fetchUsersRequest = createAction('FETCH_USERS_REQUEST');
export const fetchUsersSucess = createAction('FETCH_USERS_SUCCESS');
export const fetchReviewsByUserRequest = createAction('FETCH_REVIEWS_BY_USER_REQUEST');
export const fetchReviewsByUserSucess = createAction('FETCH_REVIEWS_BY_USER_SUCCESS');
export const fetchPrivilegesByUserRequest = createAction('FETCH_PRIVILEGES_BY_USER_REQUEST');
export const fetchPrivilegesByUserSucess = createAction('FETCH_PRIVILEGES_BY_USER_SUCCESS');
export const fetchRacesByUserRequest = createAction('FETCH_RACES_BY_USER_REQUEST');
export const fetchRacesByUserSucess = createAction('FETCH_RACES_BY_USER_SUCCESS');
export const fetchTravelsByIdCollaboratorRequest = createAction('FETCH_TRAVELS_BY_ID_COLLABORATOR_REQUEST');
export const fetchTravelsByIdCollaboratorSucess = createAction('FETCH_TRAVELS_BY_ID_COLLABORATOR_SUCCESS');
export const fetchUsersRolesSucess = createAction('FETCH_USERS_ROLES_SUCCESS');
export const fetchUserByIdSucess = createAction('FETCH_USER_BY_ID_SUCCESS');
export const fetchUsersError = createAction('FETCH_USERS_ERROR');
export const fetchGetCollaboratorsRequest = createAction('FETCH_GET_COLLABORATORS_REQUEST');
export const fetchGetCollaboratorsSucess = createAction('FETCH_GET_COLLABORATORS_SUCCESS');

export const fetchDriversByProviderSuccess = createAction('FETCH_DRIVERS_PROVIDER_SUCCESS');
export const fetchVehicleByProviderSuccess = createAction('FETCH_VEHICLES_PROVIDER_SUCCESS');

export const listUser =
  (rolId, workAreaCode = 0) =>
  async (dispatch, getState) => {
    const {
      users: { roles },
    } = getState();
    try {
      dispatch(fetchUsersRequest());
      const responseUsers = await api.users.getUsers(rolId, workAreaCode);
      if (roles === null) {
        const responseRoles = await api.users.getRol();
        dispatch(fetchUsersRolesSucess(responseRoles.data.filter((rol) => rol.id === 1 || rol.id === 2 || rol.id === 3)));
      }
      dispatch(fetchUsersSucess(responseUsers.data));
    } catch (error) {
      dispatch(fetchUsersError({ error }));
      toast.error('Error obteniendo los usuarios', { autoClose: 2500, theme: 'colored' });
      throw error;
    }
  };

export const deleteUserByIdList = (id, rol) => async (dispatch) => {
  try {
    await api.users.deletetUserById(id, rol);
    toast.success('Proveedor eliminado', { autoClose: 2500, theme: 'colored' });
    dispatch(listUser(rol));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const closeSessionByIdUser = (id, rol) => async (dispatch) => {
  try {
    await api.auth.closeSessionByIdUser(id);
    toast.success('Usuario cerrado sesión', { autoClose: 2500, theme: 'colored' });
    dispatch(listUser(rol));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando al momento de cerrar sesión, vuelva a intentarlo', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    dispatch(fetchUsersRequest());
    const response = await api.users.getUserById(id);
    dispatch(fetchUserByIdSucess(response.data.usuario));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const putUserByIdList = (id, body, rol) => async (dispatch) => {
  try {
    //dispatch(fetchUsersRequest());
    await api.users.putUserById(id, body);
    toast.success('Usuario actualizado correctamente', { autoClose: 2500, theme: 'colored' });
    dispatch(listUser(rol));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const putUserById = (id, body) => async (dispatch) => {
  try {
    await api.users.putUserById(id, body);
    toast.success('Usuario actualizado correctamente', { autoClose: 2500, theme: 'colored' });
    dispatch(getUserById(id));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const deleteFilesUser = (id, body) => async (dispatch) => {
  try {
    await api.users.deleteFilesUser(id, body);
    toast.success('Archivo borrado', { autoClose: 2500, theme: 'colored' });
    dispatch(getUserById(id));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getDriversByProvider = (id) => async (dispatch) => {
  try {
    dispatch(fetchUsersRequest());
    const response = await api.users.getDriversByProvider(id);
    dispatch(fetchDriversByProviderSuccess(response.data.userDb));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getVehicleByProvider = (id) => async (dispatch) => {
  try {
    dispatch(fetchUsersRequest());
    const response = await api.users.getVehicleByProvider(id);
    dispatch(fetchVehicleByProviderSuccess(response.data.VehicleDb));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getReviewsByUser = (id) => async (dispatch) => {
  try {
    dispatch(fetchReviewsByUserRequest());
    const response = await api.users.getReviewsByUser(id);
    dispatch(fetchReviewsByUserSucess(response.data));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo puntuación del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getPrivilegesByUser = (id) => async (dispatch) => {
  try {
    dispatch(fetchPrivilegesByUserRequest());
    const response = await api.users.getPrivilegesByUser(id);
    dispatch(fetchPrivilegesByUserSucess(response.data));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo privilegios del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const updatePrivilegesByUser = (id, body) => async (dispatch) => {
  try {
    await api.users.updatePrivilegesByUser(id, body);
    toast.success('Usuario actualizado correctamente', { autoClose: 2500, theme: 'colored' });
    dispatch(getPrivilegesByUser(id));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error actualizando el usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getRacesByUser = (idUser, idCollaborator, firstItem, lastItem) => async (dispatch) => {
  try {
    dispatch(fetchRacesByUserRequest());
    // const response = await api.users.getUserRacesById(idUser, idCollaborator, firstItem, lastItem);
    const response = await api.users.getUserRacesOtherById(idUser, idCollaborator, firstItem, lastItem);
    dispatch(fetchRacesByUserSucess({ count: 0, data: response.data, aux: null }));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo las carreras del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const upadteRacesByUser = (idUser, idCollaborator, firstItem, lastItem) => async (dispatch, getState) => {
  const {
    users: { racesByUser },
  } = getState();
  try {
    dispatch(fetchRacesByUserRequest());
    // const { data } = await api.users.getUserRacesById(idUser, idCollaborator, firstItem, lastItem);
    const response = await api.users.getUserRacesOtherById(idUser, idCollaborator, firstItem, lastItem);
    const temp = response.data.length > 0 ? response.data : null;
    if (response.data.length > 0) {
      dispatch(fetchRacesByUserSucess({ count: 0, data: [...racesByUser.data, ...response.data], aux: temp }));
    }
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo las carreras del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getTravelsByIdCollaborator = (idUser, dateI, dateF, format) => async (dispatch) => {
  try {
    dispatch(fetchTravelsByIdCollaboratorRequest());
    // const response = await api.users.getTravelsByIdCollaborator(idUser, dateI, dateF, format);
    const response = await api.users.getHistoryOnlyCollaboratoradmin(idUser, dateI, dateF, format);
    dispatch(fetchTravelsByIdCollaboratorSucess(response.data));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo las carreras del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const getCollaborators = () => async (dispatch) => {
  try {
    dispatch(fetchGetCollaboratorsRequest());
    const [admins, colaborators, superadmins] = await Promise.all([api.dashboard.getUsersByRol(1), api.dashboard.getUsersByRol(3), api.dashboard.getUsersByRol(2)]);
    dispatch(fetchGetCollaboratorsSucess([...admins.data, ...colaborators.data, ...superadmins.data]));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo los colaboradores', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};
