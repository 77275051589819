import { handleActions } from "redux-actions";

import {
  fetchBrandsRequest,
  fetchBrandsSuccess,
  fetchBrandsError,
} from "../actions/brandAction";

const defaultState = [];

export default handleActions(
  {
    [fetchBrandsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchBrandsSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchBrandsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
