import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import TuneIcon from 'mdi-react/TuneIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import PencilPlusIcon from 'mdi-react/PencilPlusIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import Select from "react-select";

const data_time = [
  { image:'/img/casaoficina.png', text: 'Casa - Oficina', status: true },
  { image:'/img/oficinacasa.png', text: 'Oficina - Casa', status: false },
  { image:'/img/tramites.png', text: 'Tramites', status: true }
];

const Dashboard = () => {
  return (
    <Container className="dashboard">
      <Row className="mb-4">
        <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
          <TuneIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '10px', marginTop: '-5px' }} />
          <h3 className="page-title"> Ajuste: <span className="text-danger">Reportes</span></h3>
        </Col>
      </Row>
      <Row className="react-action-top-b-l">
        <div>
          <Button color="danger" style={{ backgroundColor: 'red' }} className="m-0 py-1">Guardar cambios</Button>
        </div>
      </Row>
      <Row className="mt-5 p-0 m-0">
        <Col lg={8} md={12} className="space-line-div py-3">
          <div className="py-2 px-4">
          <h3 className="page-title">Reporte A</h3>
            <Row>
                <AccountIcon style={{ height: '20px', width: '20px', color: "#4B545A", marginRight: '10px', marginTop: '-2px' }} />
                Habilitar <span className="text-danger">pago de CF a invitados</span>
                <Switch className="ml-3"/>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

export default Dashboard;
