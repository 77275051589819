import { TextField } from "@material-ui/core";
import React from "react";
import { Button, Col, Row, FormGroup } from "reactstrap";

export const Form = ({
  title,
  titleRed,
  titleButton,
  placeholderInput,
  value,
  handleChange,
  touched,
  errors,
  handleSubmit
}) => {

  return (
    <Row className="mb-1 mt-3">
      <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
        <h3 className="page-title" style={{ fontSize: "22px" }}>
          {" "}
          {title}: <span className="text-danger">{titleRed} </span>
        </h3>
      </Col>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Col md={12} className="mt-3 mb-2">
          <FormGroup row style={{ alignItems: "center" }}>
            <Col>
              <Row>
                <Col
                  md={11}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ marginRight: 20 }}>Marca:</h4>

                  <TextField
                    fullWidth
                    id="brand"
                    name="brand"
                    variant='outlined'
                    type="text"
                    value={value}
                    onChange={handleChange}
                    error={touched && Boolean(errors)}
                    helperText={touched && errors}
                    placeholder={placeholderInput}
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: "14px" } }}
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} style={{ display: "flex" }}>
          <Button color="danger" type="submit" className="mx-auto py-2">
            {titleButton}
          </Button>
        </Col>
      </form>
    </Row>
  );
};
