import React, { useState, useEffect, useRef } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
const locationIcon = `${process.env.PUBLIC_URL}/img/location.png`;
const carIcon = `${process.env.PUBLIC_URL}/img/carIconTracking.svg`;

export default function MapTracking({ originRoute, destinyRoute, addressDestiny, carPosition }) {
    const [center, setCenter] = useState({ lat: -0.225219, lng: -78.5248 });
    const [originPosition, setOriginPosition] = useState(null);
    const [destinyPosition, setDestinyPosition] = useState(null);
    const [durationTravel, setDurationTravel] = useState("");

    const onIdle = (m) => {
        setCenter(center);
    };

    const contentString =
        '<div>' +
        `<h4 style="text-align: center; font-weight: 500">${durationTravel}</h4>` +
        `<h5>${addressDestiny}</h5>` +
        "</div>";

    return (
        <div style={{ display: 'flex', height: '40vh', width: '100%' }}>
            <Wrapper apiKey={process.env.REACT_APP_MAP_KEY}>
                <Map
                    mapId={'map'}
                    center={center}
                    onIdle={onIdle}
                    zoom={20}
                    style={{ flexGrow: '1', height: '100%' }}
                    setOriginPosition={setOriginPosition}
                    setDestinyPosition={setDestinyPosition}
                    originRoute={originRoute}
                    destinyRoute={destinyRoute}
                    setDurationTravel={setDurationTravel}
                >
                    {originPosition &&
                        <Marker
                            key={'origin'}
                            position={originPosition}
                            icon={{
                                url: locationIcon,
                                scaledSize: new google.maps.Size(30, 30)
                            }} />}
                    {destinyPosition &&
                        <Marker
                            key={'destiny'}
                            position={destinyPosition}
                            icon={{
                                url: 'https://cdn.pixabay.com/photo/2015/08/27/10/30/ban-909970_1280.png',
                                scaledSize: new google.maps.Size(20, 20)
                            }} />}
                    {carPosition && <Marker
                        key={'carIcon'}
                        position={carPosition}
                        icon={{
                            url: carIcon,
                            scaledSize: new google.maps.Size(25, 25)
                        }}
                    />}
                    {originPosition &&
                        <InfoWindow
                            key={'InfoWindow'}
                            visible={true}
                            position={originPosition}
                            content={contentString}
                        />
                    }
                </Map>
            </Wrapper>
        </div>
    )
}

const Map = ({ onIdle, children, style, originRoute, destinyRoute, setOriginPosition, setDestinyPosition, setDurationTravel, ...options }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [response, setResponse] = useState('');

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            if (onIdle) {
                map.addListener('idle', () => onIdle(map));
                if (response === '') {
                    const directionsRenderer = new google.maps.DirectionsRenderer({
                        suppressMarkers: true,
                        polylineOptions: {
                            strokeColor: "#ed3e44"
                        }
                    });
                    const directionsService = new google.maps.DirectionsService()
                        .route({
                            origin: originRoute,
                            destination: destinyRoute,
                            travelMode: google.maps.TravelMode.DRIVING,
                        })
                        .then((response) => {
                            setOriginPosition({
                                lat: response?.routes[0]?.legs[0].start_location.lat(),
                                lng: response?.routes[0]?.legs[0].start_location.lng()
                            })
                            setDestinyPosition({
                                lat: response?.routes[0]?.legs[0].end_location.lat(),
                                lng: response?.routes[0]?.legs[0].end_location.lng()
                            })
                            setDurationTravel(response?.routes[0]?.legs[0]?.duration?.text)
                            setResponse('LOADED');
                            directionsRenderer.setDirections(response);
                        })
                        .catch((e) =>
                            console.log('Directions request failed due to ' + status)
                        );
                    directionsRenderer.setMap(map);
                }
            }
        }
    }, [map, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

const Marker = (options) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) setMarker(new google.maps.Marker());
        return () => { // remove marker from map on unmount
            if (marker) marker.setMap(null);
        };
    }, [marker]);

    useEffect(() => {
        if (marker) marker.setOptions(options);
    }, [marker, options]);

    return null;
};

const InfoWindow = (options) => {
    const [infoWindow, setInfoWindow] = useState();

    useEffect(() => {
        if (!infoWindow) setInfoWindow(new google.maps.InfoWindow());
        return () => { // remove info Window from map on unmount
            if (infoWindow) infoWindow.setMap(null);
        };
    }, [infoWindow]);

    useEffect(() => {
        if (infoWindow) infoWindow.setOptions(options);
    }, [infoWindow, options]);

    return null;
}