import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Input,
} from "reactstrap";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  listProviderCost,
  listProviderKm,
  listProviderTravels,
} from "../../../redux/actions/ProvidersActions";
import CalendarOutlineIcon from "mdi-react/CalendarOutlineIcon";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ProviderById = ({ match }) => {
  const { params: { providerId } } = match;
  const dispatch = useDispatch();
  const isFetchingData = useSelector((state) => state.provider.isFetching);
  const costsLists = useSelector((state) => state.provider.providercost);
  const kmLists = useSelector((state) => state.provider.providerkm);
  const travelsLists = useSelector((state) => state.provider.providertravels);
  const [year, setYear] = useState(new Date())

  
  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
      pushNewItemBreadCrums('Reporte');
  }, [location]);

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className='selectYear'>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <CalendarOutlineIcon onClick={props.onClick} style={{ height: "20px", marginLeft: "8px", marginTop: "-3px" }} />
      </div>
    );
  });




  useEffect(() => {
    dispatch(listProviderCost(providerId, year));
    dispatch(listProviderKm(providerId, year));
    dispatch(listProviderTravels(providerId, year));
  }, [year]);

  const dataGraphics = (graphic) => {
    let data = [];

    if (graphic == "generalCost") data = costsLists;
    if (graphic == "generalKm") data = kmLists;
    if (graphic == "generalTravels") data = travelsLists;

    const resultMapData = Months.map(month => {
      const index = data.findIndex(monthData => monthData.Mes === month)
      if (index < 0) return 0;
      else return data[index].Total;
    })

    return ({
      labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
      datasets: [
        {
          data: resultMapData,
          backgroundColor: ['rgba(248, 211, 207)', 'rgba(208, 43, 31)', 'rgba(247, 191, 184)', 'rgba(242, 150, 139)', 'rgba(244, 170, 161)', 'rgba(248, 211, 207)', 'rgba(241, 127, 114)', 'rgba(237, 85, 71)', 'rgba(237, 85, 71)', 'rgb(209, 70, 67)', 'rgba(142, 46, 49)', 'rgba(40, 51, 80)'],
          borderWidth: 0,
        }
      ]
    })
  }



  return (
    <Container className="userProfile">
      {!isFetchingData && costsLists && kmLists && travelsLists ?
        <>
          <Col md={12}>
            <h3 className="page-title" style={{ fontSize: "25px", marginTop: "5px", marginBottom: "5px", marginLeft: "-15px" }}> Proveedor: <span className="text-danger">Usuario </span></h3>
            <div style={{ display: "flex", position: "absolute", top: "0px", right: "1.8vw", flexDirection: "row", justifyContent: "flex-end", zIndex: "1" }}>
              <div style={{ marginRight: "15px" }}>
                <DatePicker
                  selected={year}
                  onChange={date => setYear(date)}
                  showYearPicker
                  dateFormat="yyyy"
                  className='selectYear'
                  maxDate={new Date()}
                  customInput={<CustomInput />}
                />
              </div>
              <div className="buttons">
                <Link to={`/providers/generalReport/${providerId}`} className="link">Ver reporte</Link>
              </div>
            </div>
            <div className="row" style={{ width: "100%", marginTop: "20px" }}>
              <div style={{ width: "47%", marginRight: "3%" }}>
                <label style={{ fontWeight: 600, fontSize: "16px" }} htmlFor="zona-calor" className="label-head-info">Total de <span className="text-danger">gasto</span></label>
                <div style={{ backgroundColor: "white", padding: "10px 5px 5px 10px", marginBottom: "30px" }}>
                  <Bar
                    style={{ height: "200px" }}
                    data={dataGraphics("generalCost")}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false
                          }
                        }
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ width: "47%", marginRight: "3%" }}>
                <label style={{ fontWeight: 600, fontSize: "16px" }} htmlFor="zona-calor" className="label-head-info">Km <span className="text-danger">promedio</span></label>
                <div style={{ backgroundColor: "white", padding: "10px 5px 5px 10px", marginBottom: "30px" }}>
                  <Bar
                    style={{ height: "200px" }}
                    data={dataGraphics("generalKm")}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false
                        }
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false
                          }
                        }
                      },
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{ width: "100%", marginBottom: "10px", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "47%", marginRight: "3%" }}>
                  <label style={{ fontWeight: 600, fontSize: "16px" }} htmlFor="zona-calor" className="label-head-info">Número de viajes <span className="text-danger">Servicio</span></label>
                  <div style={{ backgroundColor: "white", padding: "10px 5px 5px 10px", marginBottom: "30px" }}>
                    <Bar
                      style={{ height: "200px" }}
                      data={dataGraphics("generalTravels")}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false
                          }
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false
                            }
                          }
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </>
        : <Spinner className="table-fetch-spinner" style={{ marginLeft: "50%" }} />
      }
    </Container>
  )
};

export default ProviderById;
