import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import api from '../../utils/api/apiProvider';

export const fetchZonesRequest = createAction('FETCH_ZONES_REQUEST');
export const fetchZonesSuccess = createAction('FETCH_ZONES_SUCCESS');
export const fetchEstablisments = createAction('FETCH_ESTABLISMENTS');
export const fetchZonesError = createAction('FETCH_ZONES_ERROR');

export const fetchZonesFavorita = createAction('FETCH_ZONES_FAVORITA');
export const getProvincesFavorita = createAction('GET_PROVINCES_FAVORITA');
export const getCitiesFavorita = createAction('GET_CITIES_FAVORITA');
export const fetchEstablishmentsFavorita = createAction('FETCH_ESTABLISHMENTS_FAVORITA');

export const listZones = () => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    const response = await api.zones.getZones();
    dispatch(fetchZonesSuccess(response.data.RegionBD));
  } catch (error) {
    dispatch(fetchZonesSuccess({ error }));
    toast.error('Error obteniendo el reporte de las zonas', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const putZoneById = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    await api.zones.putZonesById(id, body);
    toast.success('Zona actualizada correctamente', {
      autoClose: 2500,
      theme: 'colored',
    });
    dispatch(listZones());
  } catch (error) {
    dispatch(fetchZonesError({ error }));
    toast.error('Error al actualizar la zona', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const postZone = (body) => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    await api.zones.postZone(body);
    toast.success('Zona añadida correctamente', {
      autoClose: 2500,
      theme: 'colored',
    });
    dispatch(listZones());
  } catch (error) {
    dispatch(fetchZonesError({ error }));
    toast.error('Error al añadir la zona', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const deleteZone = (id) => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    await api.zones.deleteZone(id);
    toast.success('Zona eliminada correctamente', {
      autoClose: 2500,
      theme: 'colored',
    });
    dispatch(listZones());
  } catch (error) {
    dispatch(fetchZonesError({ error }));
    toast.error('Error al eliminar la zona', {
      autoClose: 2500,
      theme: 'colored',
    });
  }
};

export const ZonesFavorita = () => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    const response = await api.zones.getZonesFavorita();
    dispatch(fetchZonesFavorita(response.data));
  } catch (error) {
    dispatch(fetchZonesError({ error }));
    toast.error('Error obteniendo las zonas', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const ListPrivicesByZone = (provinces) => async (dispatch) => {
  dispatch(getProvincesFavorita(provinces));
};

export const ListCities = (cities) => async (dispatch) => {
  dispatch(getCitiesFavorita(cities));
};

export const listEstablisments = () => async (dispatch) => {
  try {
    dispatch(fetchZonesRequest());
    const response = await api.zones.getEstablisments();
    dispatch(fetchEstablishmentsFavorita(response.data));
  } catch (error) {
    dispatch(fetchZonesError({ error }));
    toast.error('Error obteniendo los establecimientos', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};
