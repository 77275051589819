import React, { useState, useEffect, useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import TrashIcon from 'mdi-react/TrashIcon';
import EditIcon from 'mdi-react/EditIcon';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TableBase from './components/TableBase';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { deleteModel, listModelsByBrand, postModel, putModelById } from '../../../redux/actions/modelsAction';
import { Form } from './components/Form';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const validationSchema = yup.object({
  model: yup.string('Ingrese el Modelo').required('El modelo es requerido'),
  idBrand: yup.number().required('La marca es requerida'),
});

const index = () => {
  const dispatch = useDispatch();
  const [idBrand, setIdBrand] = useState(0);
  const [brandOptions, setBrandOptions] = useState([]);
  const modelList = useSelector((state) => state.models.data);
  const isFetchingModelList = useSelector((state) => state.models.isFetching);
  const brandList = useSelector((state) => state.models.dataBrand);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Modelos', 1);
  }, [location]);

  const formUpdate = useFormik({
    initialValues: {
      model: '',
      idBrand: null,
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(putModelById(values.id, { name: values.model, idMark: values.idBrand }, idBrand));
      formUpdate.resetForm();
    },
  });

  const formSave = useFormik({
    initialValues: {
      model: '',
      idBrand: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postModel(
          {
            name: values.model,
            idMark: values.idBrand,
          },
          idBrand
        )
      );
      formSave.resetForm();
    },
  });

  const brandsOptions = () =>
    brandList.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));

  const valueBrand = () =>
    brandList.map((brandValue) => {
      if (brandValue.id === idBrand)
        return {
          value: brandValue.id,
          label: brandValue.name,
        };
    });

  const columns = useMemo(
    () => [
      {
        Header: 'Modelo',
        accessor: 'model',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.name}</span>,
        disableFilters: true,
      },
      {
        Header: ' ',
        accessor: ' ',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Acciones',
        accessor: 'actions',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateModel(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteModel(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [modelList]
  );

  const handleDeleteModel = (idModel) => {
    dispatch(deleteModel(idModel, idBrand));
  };

  const handleUpdateModel = (objectModel) => {
    formUpdate.setValues({
      model: objectModel.name,
      idBrand: objectModel.idMark,
      id: objectModel.id,
    });
  };

  useEffect(() => {
    dispatch(listModelsByBrand(idBrand));
  }, [idBrand]);

  useEffect(() => {
    if (!brandList) return;
    setBrandOptions(brandsOptions());
  }, [brandList]);

  return (
    <Container className="dashboard container">
      <Row>
        <Col lg={6} md={12}>
          <TitleSettings title="Modelos ingresados" />
          {!isFetchingModelList && modelList ? (
            <>
              <Select className="basic-single" classNamePrefix="selectDataUsers" name="marcas" placeholder={'Marcas'} onChange={(e) => setIdBrand(e.value)} options={brandOptions} value={valueBrand()} />

              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: modelList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>

        <Col lg={6} md={12}>
          <Row>
            <Col lg={12}>
              <Form
                title="Autorización"
                titleRed="Ingreso de vehículos Modelo"
                titleButton="Guardar Modelo"
                placeholderInput="Nombre del modelo"
                value={formSave.values.model}
                valueId={formSave.values.idBrand}
                handleChange={formSave.handleChange}
                touched={formSave.touched.model}
                errors={formSave.errors.model}
                errorSelect={formSave.errors.idBrand}
                touchedSelect={formSave.touched.idBrand}
                handleSubmit={formSave.handleSubmit}
                optionsSelect={brandOptions}
                changeSelect={(id) => {
                  formSave.setValues({
                    model: formSave.values.model,
                    idBrand: id,
                  });
                }}
              />
            </Col>

            {formSave.errors.brandId && formSave.touched.brandId && <div className="input-feedback">{formSave.errors.brandId}</div>}

            <Col lg={12} style={{ marginTop: '10px' }}>
              <Form
                title="Editar"
                titleRed="Modelo Ingresado"
                titleButton="Actualizar Modelo"
                placeholderInput="Escoger un modelo de la tabla"
                value={formUpdate.values.model}
                valueId={formUpdate.values.idBrand}
                handleChange={formUpdate.handleChange}
                touched={formUpdate.touched.model}
                errors={formUpdate.errors.model}
                errorSelect={formUpdate.errors.idBrand}
                touchedSelect={formUpdate.touched.idBrand}
                handleSubmit={formUpdate.handleSubmit}
                optionsSelect={brandOptions}
                changeSelect={(id) => {
                  formUpdate.setValues({
                    model: formUpdate.values.model,
                    idBrand: id,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
