import { handleActions } from "redux-actions";
import { fetchLocationRequest, fetchLocationSucess, fetchLocationError } from "../actions/locationAction";

const defaultState = {
    data: null,
    isFetching: false,
    error: null,
}

export default handleActions(
    {
        [fetchLocationRequest](state) {
            return {
                ...state,
                isFetching: true,
                error: null
            }
        },
        [fetchLocationSucess](state, { payload }) {
            return {
                ...state,
                data: payload,
                isFetching: false,
                error: null
            }
        },
        [fetchLocationError](state, { payload }) {
            return {
                ...state,
                isFetching: false,
                error: payload
            }
        },
    },
    defaultState,
)