import { createAction } from "redux-actions";
import { toast } from "react-toastify";
import api from "../../utils/api/apiProvider";

export const fetchModelsRequest = createAction("FETCH_MODELS_REQUEST");
export const fetchModelsSuccess = createAction("FETCH_MODELS_SUCCESS");
export const fetchBrandsSuccess = createAction("FETCH_BRANDS_SUCCESS");
export const fetchModelsError = createAction("FETCH_MODELS_ERROR");

export const listModelsByBrand = (idBrand) => async (dispatch) => {
  try {
    dispatch(fetchModelsRequest());
    const response = await api.models.getModelsByBrand(idBrand);
    const responseBrands = await api.brand.getBrands();
    dispatch(fetchBrandsSuccess(responseBrands.data))
    dispatch(fetchModelsSuccess(response.data));
  } catch (error) {
    dispatch(fetchModelsSuccess({ error }));
    toast.error("Error obteniendo el reporte de los modelos", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putModelById = (id, body, idBrand) => async (dispatch) => {
  try {
    dispatch(fetchModelsRequest());
    await api.models.putModelById(id, body);
    toast.success("Modelo actualizado correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listModelsByBrand(idBrand));
  } catch (error) {
    dispatch(fetchModelsError({ error }));
    toast.error("Error al actualizar el modelo", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postModel = (body, idBrand) => async (dispatch) => {
  try {
    dispatch(fetchModelsRequest());
    await api.models.postModel(body);
    toast.success("Modelo añadido correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listModelsByBrand(idBrand));
  } catch (error) {
    dispatch(fetchModelsError({ error }));
    toast.error("Error al añadir el modelo", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};


export const deleteModel = (idModel, idBrand) => async (dispatch) =>{
    try {
        dispatch(fetchModelsRequest());
        await api.models.deleteModelById(idModel);
        toast.success("Modelo eliminado correctamente", {
          autoClose: 2500,
          theme: "colored",
        });
        dispatch(listModelsByBrand(idBrand));
    } catch (error) {
        dispatch(fetchModelsError({ error }));
        toast.error("Error al eliminar el modelo", {
          autoClose: 2500,
          theme: "colored",
        });
    }
}