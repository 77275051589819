import { Switch, TextareaAutosize, TextField } from '@material-ui/core';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, Spinner, Card, CardBody, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { deleteQuestion, listQuestions, postQuestion, putQuestionById } from '../../../redux/actions/FaqAction';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import TableBase from './components/TableBase';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const validationSchema = yup.object({
  question: yup.string('Ingrese la pregunta').min(7, 'La pregunta debe tener un minimo de 7 caracteres').required('La pregunta es requerida*'),
  answer: yup.string('Ingrese la respuesta').min(7, 'La respuesta debe tener un minimo de 7 caracteres').required('La respuesta es requerida*'),
});

const index = () => {
  const [rol, setRol] = useState(2);
  const [updateForm, setUpdateForm] = useState('new');
  const dispatch = useDispatch();
  const questionList = useSelector((state) => state.questions.data);
  const usersRoles = useSelector((state) => state.questions.roles);
  const isFetchingQuestionList = useSelector((state) => state.questions.isFetching);
  const [editQuestion, setEditQuestion] = useState({
    question: '',
    answer: '',
  });

  const form = useFormik({
    initialValues: {
      question: '',
      answer: '',
      id: null,
      action: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (updateForm === 'new') {
        const newQuestion = {
          roleId: rol,
          question: values.question,
          answer: values.answer,
        };
        dispatch(postQuestion(newQuestion, rol));
        form.resetForm();
      } else {
        if (editQuestion.question !== values.question || editQuestion.answer !== values.answer) {
          dispatch(
            putQuestionById(
              values.id,
              {
                question: values.question,
                answer: values.answer,
              },
              rol
            )
          );
          setUpdateForm('new');
          form.resetForm();
        }
      }
    },
  });

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Preguntas y Respuestas', 1);
  }, [location]);

  const columns = useMemo(
    () => [
      {
        Header: 'Preguntas',
        accessor: 'questions',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.question}</span>,
        disableFilters: true,
      },

      {
        Header: 'Respuestas',
        accessor: 'answers',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.answer}</span>,
        disableFilters: true,
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: (cellObj) => (
          <div>
            <Switch checked={cellObj.row.original.status} onChange={() => handleQuestionEnabled(cellObj.row.original.id, !cellObj.row.original.status)} />
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: 'Acciones',
        accessor: 'actions',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateQuestion(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteQuestion(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [questionList]
  );

  const handleQuestionEnabled = (questionId, status) => {
    dispatch(
      putQuestionById(
        questionId,
        {
          status,
        },
        rol
      )
    );
  };

  const handleDeleteQuestion = (id) => {
    dispatch(deleteQuestion(id, rol));
  };

  const handleUpdateQuestion = (objectQuestion) => {
    setUpdateForm('update');
    setEditQuestion({
      question: objectQuestion.question,
      answer: objectQuestion.answer,
    });
    form.setValues({
      question: objectQuestion.question,
      answer: objectQuestion.answer,
      id: objectQuestion.id,
    });
  };

  const userRolesOptions = () =>
    usersRoles.map((rol) => ({
      value: rol.id,
      label: rol.id === 2 ? 'Colaborador' : 'Conductor',
    }));

  const userRole = () =>
    usersRoles.map((rolValue) => {
      if (rolValue.id === rol)
        return {
          value: rolValue.id,
          label: rolValue.id === 2 ? 'Colaborador' : 'Conductor',
        };
    });

  useEffect(() => {
    dispatch(listQuestions(rol));
  }, [rol]);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={6} md={8}>
          <TitleSettings title="Preguntas y Respuestas" />
        </Col>
        <Col lg={3} md={4}>
          {!isFetchingQuestionList && questionList && <Select className="basic-single" classNamePrefix="selectDataUsers" name="Roles de usuario" placeholder={'Roles de usuario'} onChange={(e) => setRol(e.value)} options={userRolesOptions()} value={userRole()} />}
        </Col>
      </Row>

      <Row className="mt-4 p-0 m-0">
        <Col lg={8} md={12}>
          {!isFetchingQuestionList && questionList ? (
            <>
              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: questionList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>
        <Col lg={4} md={12}>
          <Container>
            {updateForm === 'new' && (
              <Card>
                <CardBody style={{ borderRadius: '13px', border: '1px solid #C4C4C4' }}>
                  <form onSubmit={form.handleSubmit}>
                    <FormGroup row>
                      <Col sm={12}>
                        <TextField fullWidth variant="outlined" id="question" name="question" type="text" value={form.values.question} onChange={form.handleChange} className="inputForm" inputProps={{ style: { fontSize: '14px' } }} error={form.touched.question && Boolean(form.touched.question) && form.errors?.question} helperText={form.touched.question && form.errors.question} placeholder="Escribir Pregunta" />
                      </Col>
                      <Col sm={12} className="mt-4">
                        <TextField id="answer" name="answer" variant="outlined" type="textarea" value={form.values.answer} onChange={form.handleChange} className="inputForm" style={{ width: '100%' }} inputProps={{ style: { fontSize: '14px' } }} error={form.touched.answer && Boolean(form.touched.answer) && form.errors?.answer} helperText={form.touched.answer && form.errors.answer} multiline rows={6} placeholder="Escribir Respuesta" />
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Button style={{ color: 'white', borderRadius: '8.8px' }} color="danger" type="submit" className="mx-auto py-2">
                          Agregar Pregunta
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            )}

            {updateForm === 'update' && (
              <Card>
                <CardBody>
                  <form onSubmit={form.handleSubmit}>
                    <FormGroup row>
                      <Col sm={12}>
                        <TextField fullWidth variant="outlined" id="question" name="question" type="text" value={form.values.question} onChange={form.handleChange} className="inputForm" inputProps={{ style: { fontSize: '14px' } }} error={form.touched.question && Boolean(form.touched.question) && form.errors?.question} helperText={form.touched.question && form.errors.question} placeholder="Escribir Pregunta" />
                      </Col>

                      <Col sm={12} className="mt-4">
                        <TextField id="answer" name="answer" variant="outlined" type="textarea" value={form.values.answer} onChange={form.handleChange} className="inputForm" inputProps={{ style: { fontSize: '14px' } }} error={form.touched.answer && Boolean(form.touched.answer) && form.errors?.answer} helperText={form.touched.answer && form.errors.answer} multiline rows={6} style={{ width: '100%' }} placeholder="Escribir Respuesta" />
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Button style={{ color: 'white', borderRadius: '8.8px' }} color="danger" type="submit" className="mx-auto py-2">
                          Actualizar Pregunta
                        </Button>
                      </Col>
                    </Row>
                  </form>
                  <Col
                    sm={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      className="mx-auto py-2"
                      style={{ width: '190px', color: 'white', borderRadius: '8.8px', marginLeft: 'auto' }}
                      color="danger"
                      onClick={() => {
                        setUpdateForm('new');
                        form.resetForm();
                      }}
                    >
                      Nueva Pregunta
                    </Button>
                  </Col>
                </CardBody>
              </Card>
            )}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
