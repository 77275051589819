import React, { useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import { Link, useLocation } from "react-router-dom";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const dataGraphics = {
  labels: [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ],
  datasets: [
    {
      label: "Data 2019",
      data: [100, 300, 120, 120, 120, 120, 150, 200, 220, 300, 400, 500],
      backgroundColor: [
        "rgba(255, 192, 192)",
        "rgba(255, 43, 43)",
        "rgba(255, 155, 155)",
        "rgba(255, 135, 125)",
        "rgba(255, 115, 115)",
        "rgba(255, 95, 95)",
        "rgba(255, 75, 75)",
        "rgba(255, 55, 55)",
        "rgba(255, 35, 35)",
        "rgb(175, 20, 20)",
        "rgba(90, 8, 8)",
        "rgba(25, 25, 70)",
      ],
      borderWidth: 0,
    },
    {
      label: "Data 2020",
      data: [80, 150, 100, 90, 100, 90, 170, 180, 200, 270, 355, 400],
      backgroundColor: [
        "rgba(5, 192, 192)",
        "rgba(5, 43, 43)",
        "rgba(5, 155, 155)",
        "rgba(5, 135, 125)",
        "rgba(5, 115, 115)",
        "rgba(5, 95, 95)",
        "rgba(5, 75, 75)",
        "rgba(5, 55, 55)",
        "rgba(5, 35, 35)",
        "rgb(5, 20, 20)",
        "rgba(5, 8, 8)",
        "rgba(5, 25, 70)",
      ],
      borderWidth: 0,
    },
  ],
};

export default function index() {
  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte General');
  }, [location]);
  return (
    <Container className="dashboard">
      <Col md={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3
            className="page-title"
            style={{ fontSize: "25px", marginTop: "15px" }}
          >
            {" "}
            <ClockOutlineIcon
              style={{ color: "#D8394C", marginLeft: "-10px" }}
            ></ClockOutlineIcon>{" "}
            Reporte <span className="text-danger">General</span>
          </h3>
          <div className="row" style={{ justifyContent: "flex-end" }}>
            <div style={{ marginRight: "15px" }}>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Fecha"
                isClearable={true}
                isSearchable={true}
                placeholder={"Fecha"}
                color
                options={[]}
              />
            </div>
            <div style={{ marginRight: "15px" }}>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Tipo de Viaje"
                isClearable={true}
                isSearchable={true}
                placeholder="Tipo de Viaje"
                color
                options={[]}
              />
            </div>
            <div style={{ marginRight: "15px" }}>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Area"
                isClearable={true}
                isSearchable={true}
                placeholder="Área"
                color
                options={[]}
              />
            </div>
            <div style={{ marginRight: "15px" }}>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Ciudades"
                isClearable={true}
                isSearchable={true}
                placeholder="Ciudades"
                color
                options={[]}
              />
            </div>
            <div style={{ marginRight: "15px" }}>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Traslado"
                isClearable={true}
                isSearchable={true}
                placeholder="Traslado"
                color
                options={[]}
              />
            </div>
            <div>
              <Select
                className="select__control"
                classNamePrefix="select"
                name="Nombre"
                isClearable={true}
                isSearchable={true}
                placeholder="Nombre"
                color
                options={[]}
              />
            </div>
          </div>
        </div>
        <div className="col-11">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <h3 className="text-danger" style={{ fontWeight: 600 }}>
              Viaje fuera de horario{" "}
              <EyeOutlineIcon color="#D8394C"></EyeOutlineIcon>
            </h3>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div style={{ width: "47%", marginRight: "5%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ marginBottom: "0px" }}
                  htmlFor="zona-calor"
                  className="label-head-info"
                >
                  Total de <span className="text-danger">gasto</span>
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      marginRight: "10px",
                    }}
                  >
                    Ver informe{" "}
                    <EyeOutlineIcon color="#7C7C7C"></EyeOutlineIcon>
                  </h3>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#3DBE00",
                      marginRight: "5px",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    XLM <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#EC1C24",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    PDF <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                </div>
              </div>
              <div style={{ height: "250px" }}>
                <Bar
                  data={dataGraphics}
                  width={100}
                  height={50}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: { beginAtZero: true },
                        },
                      ],
                      xAxes: {
                        grid: { offset: true },
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ marginBottom: "0px", fontSize: "12px" }}
                  htmlFor="zona-calor"
                  className="label-head-info"
                >
                  Número de viajes <span className="text-danger">Servicio</span>
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      marginRight: "10px",
                    }}
                  >
                    Ver informe{" "}
                    <EyeOutlineIcon color="#7C7C7C"></EyeOutlineIcon>
                  </h3>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#3DBE00",
                      marginRight: "5px",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    XLM <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#EC1C24",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    PDF <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                </div>
              </div>
              <div style={{ height: "250px" }}>
                <Bar
                  data={dataGraphics}
                  width={100}
                  height={50}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: { beginAtZero: true },
                        },
                      ],
                      xAxes: {
                        grid: { offset: true },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ width: "100%", marginTop: "30px", marginBottom: "30px" }}
          >
            <div style={{ width: "47%", marginRight: "5%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ marginBottom: "0px" }}
                  htmlFor="zona-calor"
                  className="label-head-info"
                >
                  Km <span className="text-danger">promedio</span>
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      marginRight: "10px",
                    }}
                  >
                    Ver informe{" "}
                    <EyeOutlineIcon color="#7C7C7C"></EyeOutlineIcon>
                  </h3>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#3DBE00",
                      marginRight: "5px",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    XLM <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#EC1C24",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    PDF <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                </div>
              </div>
              <div style={{ height: "250px" }}>
                <Bar
                  data={dataGraphics}
                  width={100}
                  height={50}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: { beginAtZero: true },
                        },
                      ],
                      xAxes: {
                        grid: { offset: true },
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div style={{ width: "48%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
              >
                <label
                  style={{ marginBottom: "0px" }}
                  htmlFor="zona-calor"
                  className="label-head-info"
                >
                  Tiempo de <span className="text-danger">Translado</span>
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h3
                    style={{
                      fontWeight: 300,
                      fontSize: "16px",
                      marginRight: "10px",
                    }}
                  >
                    Ver informe{" "}
                    <EyeOutlineIcon color="#7C7C7C"></EyeOutlineIcon>
                  </h3>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#3DBE00",
                      marginRight: "5px",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    XLM <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                  <button
                    className="dashboardButton"
                    style={{
                      backgroundColor: "#EC1C24",
                      fontSize: "12px",
                      padding: "0 10px",
                    }}
                  >
                    PDF <DownloadIcon style={{ width: "15px" }}></DownloadIcon>
                  </button>
                </div>
              </div>
              <div style={{ height: "250px" }}>
                <Bar
                  data={dataGraphics}
                  width={100}
                  height={50}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [
                        {
                          ticks: { beginAtZero: true },
                        },
                      ],
                      xAxes: {
                        grid: { offset: true },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Container>
  );
}
