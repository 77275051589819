import UsersApi from "./requests/UsersApi";
import DashboardApi from "./requests/DashboardApi";
import AuthApi from "./requests/AuthApi";
import NotifcationsApi from "./requests/NotifcationsApi";

const api = {
    users: UsersApi,
    dashboard: DashboardApi,
    notification:NotifcationsApi,
    auth: AuthApi
};

export default api;