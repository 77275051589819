import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const ResetPassword = () => {
  return (
    <div className="account account--not-photo">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center"
        }}
      >
        <img src={Logo} alt="avatar" style={{ marginBottom: '5px', height: '189px', width: '314px', marginTop: "60px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <h6 style={{ fontSize: "18px", fontWeight: 600, marginBottom: "10px", color: "#fff" }}>En caso de no contar con una contraseña</h6>
          <h6 style={{ fontSize: "18px", fontWeight: 300, color: "#fff" }}>Por favor comunicarce con el departamento de tecnologia</h6>
          <NavLink
            to="/"
            style={{
              backgroundColor: "#E8272C",
              boxShadow: "-12px -12px 20px rgba(57, 57, 57, 0.8)",
              color: "#fff",
              fontSize: "18px",
              fontWeight: 600,
              padding: "7px 70px",
              borderRadius: "12px",
              marginTop: "90px"
            }}
          >
            Iniciar Sesión
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
