import React, { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../scss/app.scss";
import Router from "./Router";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import { LoadScript } from "@react-google-maps/api";
import { socket, SocketContext } from "../../context/socket";

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
 

const App = () => {
  
  return (
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
        <BrowserRouter>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_MAP_KEY}
            loadingElement={<div />}
            libraries={["visualization"]}
          >
            <ScrollToTop>
              <Fragment>
                <Router />
              </Fragment>
            </ScrollToTop>
          </LoadScript>
        </BrowserRouter>
      </Provider>
    </SocketContext.Provider>
  );
};

export default App;
