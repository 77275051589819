import { get, put, deleteRequest } from '../base/index';

export default {
  getJourneyAlerts: (workAreaCode) => {
    return get(`${process.env.REACT_APP_URL_BASE}/travels/${workAreaCode}`);
  },

  getGeneralCostByYearAndStatus: (year, startDate, endDate, status) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generalcost/${year}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generalcost/${startDate}/${endDate}/${status}`);
  },
  getGeneralKmByYearAndStatus: (year, startDate, endDate, status) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generalkm/${year}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generalkm/${startDate}/${endDate}/${status}`);
  },
  getGeneralTravelsByYearAndStatus: (year, startDate, endDate, status) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generaltravels/${year}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generaltravels/${startDate}/${endDate}/${status}`);
  },
  getAllAdressByStartEndDateAnStatus: (startDate, endDate, status) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/address/${startDate}/${endDate}/${status}`);
  },

  getGeneralCostByYearAndStatusWorkArea: (year, startDate, endDate, status, workArea) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generalcostWorkArea/${year}/${status}/${workArea}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generalcostWorkArea/${startDate}/${endDate}/${status}/${workArea}`);
  },
  getGeneralKmByYearAndStatusWorkArea: (year, startDate, endDate, status, workArea) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generalkmWorkArea/${year}/${status}/${workArea}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generalkmWorkArea/${startDate}/${endDate}/${status}/${workArea}`);
  },
  getGeneralTravelsByYearAndStatusWorkArea: (year, startDate, endDate, status, workArea) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/generaltravelsWorkArea/${year}/${status}/${workArea}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/generaltravelsWorkArea/${startDate}/${endDate}/${status}/${workArea}`);
  },
  getAllAdressByStartEndDateAnStatusWorkArea: (startDate, endDate, status, workArea) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/addressWorArea/${startDate}/${endDate}/${status}/${workArea}`);
  },

  getGeneralCostByYearCollaboratorAndStatus: (year, startDate, endDate, status, idCollaborator) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratorCost/${year}/${idCollaborator}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratorCost/${startDate}/${endDate}/${idCollaborator}/${status}`);
  },
  getGeneralKmByYearCollaboratorAndStatus: (year, startDate, endDate, status, idCollaborator) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratorkm/${year}/${idCollaborator}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratorkm/${startDate}/${endDate}/${idCollaborator}/${status}`);
  },
  getGeneralTravelsByYearCollaboratorAndStatus: (year, startDate, endDate, status, idCollaborator) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratortravels/${year}/${idCollaborator}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/collaboratortravels/${startDate}/${endDate}/${idCollaborator}/${status}`);
  },
  getUsersByRol: (rolId, workAreaCode = 0) => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/byrole/${rolId}/${workAreaCode}`);
  },
  putTravelStatus: (travelId, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/travels/${travelId}`, body);
  },
  getGeneralCostByYearFormatsAndStatus: (year, startDate, endDate, status, format) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/formatscost/${year}/${format}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/formatscost/${startDate}/${endDate}/${format}/${status}`);
  },
  getGeneralKmByYearFormatsAndStatus: (year, startDate, endDate, status, format) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/formatskm/${year}/${format}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/formatskm/${startDate}/${endDate}/${format}/${status}`);
  },
  getGeneralTravelsByYearFormatsAndStatus: (year, startDate, endDate, status, format) => {
    // return get(`${process.env.REACT_APP_URL_BASE}/reports/formatstravels/${year}/${format}/${status}`);
    return get(`${process.env.REACT_APP_URL_BASE}/reports/formatstravels/${startDate}/${endDate}/${format}/${status}`);
  },
  getTravelDetailsById: (travelId) => {
    return get(`${process.env.REACT_APP_URL_BASE}/travels/${travelId}`);
  },
  getTravelsByStatus: (status) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/travelsbystatus/${status}`);
  },
  getTravelsByDates: (initialDate, endDate) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/travelsbydates/${initialDate}/${endDate}`);
  },
  deleteTravelById: (idTravel) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/travels/permanent/${idTravel}`);
  },
  getTopFive: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/topFive`);
  },
  getTopFiveWorkArea: (startDate, endDate, workArea) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/topFive/${startDate}/${endDate}/${workArea}`);
  },
};
