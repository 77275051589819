import { createAction } from 'redux-actions';
import api from '../../utils/api/apliClient';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';

export const fetchAuthRequest = createAction('FETCH_AUTH_REQUEST');
export const stopFetchLog = createAction('STOP_FETCH_LOGIN');
export const fetchLoginSuccess = createAction('FETCH_LOGIN_SUCCESS');
export const fetchRenovationToken = createAction('FETCH_RENOVATION_TOKEN');
export const fetchRenovationTokenSuccess = createAction('FETCH_RENOVATION_TOKEN_SUCCESS');
export const fetchRenovationTokenError = createAction('FETCH_RENOVATION_TOKEN_ERROR');
export const fetchAuthError = createAction('FETCH_AUTH_ERROR');
export const fetchlogOut = createAction('LOGOUT');
export const fetchUserPrivilegesByIdSucess = createAction('FETCH_USER_PRIVILEGES_BY_ID_SUCCESS');

const secreytKey = `${process.env.REACT_APP_SECRET_KEY}`;
export const logIn = (body) => async (dispatch) => {
  localStorage.setItem('x-token', secreytKey);
  try {
    dispatch(fetchAuthRequest());
    const response = await api.auth.login(body);
    if (
      //TODO: Aqui se hizo el cambio de userCollaboratorDB a user que se cambio en el bknd
      (response.data?.user?.roleId === 1 || response.data?.user?.roleId === 3) &&
      response.data?.user?.status === 'accepted'
    ) {
      localStorage.setItem('token', response.data.token);
      dispatch(fetchLoginSuccess(response.data.user));
      const id = response.data.user.id;
      const resPrivileges = await api.users.getPrivilegesByUser(id);
      dispatch(fetchUserPrivilegesByIdSucess(resPrivileges.data));
    } else {
      const errorMessage = { message: 'Usuario sin permisos' };
      dispatch(fetchAuthError({ error: errorMessage }));
    }
  } catch (error) {
    dispatch(fetchAuthError({ error }));
    throw error;
  }
};

const getPrivilegesByUser = (id) => async (dispatch) => {
  try {
    dispatch(fetchPrivilegesByUserRequest());
    const response = await api.users.getPrivilegesByUser(id);
    dispatch(fetchPrivilegesByUserSucess(response.data));
  } catch (error) {
    dispatch(fetchUsersError({ error }));
    toast.error('Error obteniendo privilegios del usuario', { autoClose: 2500, theme: 'colored' });
    throw error;
  }
};

export const stopFetchLogin = () => async (dispatch) => {
  dispatch(stopFetchLog());
};

export const logOut = () => async (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('x-token');
  dispatch(fetchlogOut());
};

export const tokenRenovation = (token) => async (dispatch) => {
  try {
    dispatch(fetchRenovationToken());
    const response = await api.auth.tokenRenovation({
      headers: { 'x-token': `${token}` },
    });
    if ((response.data?.user?.roleId === 1 || response.data?.user?.roleId === 3) && response.data?.user?.status === 'accepted') {
      localStorage.setItem('token', response.data.token);
      dispatch(fetchLoginSuccess(response.data.user));
      const id = response.data.user.id;
      const resPrivileges = await api.users.getPrivilegesByUser(id);
      dispatch(fetchUserPrivilegesByIdSucess(resPrivileges.data));
    } else {
      const errorMessage = { message: 'Usuario sin permisos' };
      dispatch(fetchAuthError({ error: errorMessage }));
    }
  } catch (error) {
    dispatch(fetchRenovationTokenError({ error }));
    localStorage.removeItem('token');
    localStorage.removeItem('x-token');
    throw error;
  }
};
