import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'reactstrap';
import SteeringIcon from 'mdi-react/SteeringIcon';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import { listProvinces } from '../../redux/actions/locationAction';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableBase from './components/TableBase';
import { listUser, putUserByIdList, getDriversByProvider } from '../../redux/actions/usersAction';
import PlusThickIcon from 'mdi-react/PlusThickIcon';
import { useBreadCrums } from '../../hooks/useBreadCrums';
import { DataTableCustom } from '../../shared/components/DataTableCustom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';

const colsDrivers = [
  {
    field: 'first',
    header: '',
    style: { maxWidth: '60px' },
  },
  {
    field: 'driver',
    header: 'Conductor',
    filter: true,
    sortable: true,
    style: { textTransform: 'uppercase' },
  },
  {
    field: 'provider',
    header: 'Proveedor',
    sortable: true,
    filter: true,
    style: { textTransform: 'uppercase' },
  },
  {
    field: 'zone',
    header: 'Zona',
    style: { maxWidth: '100px' },
    sortable: true,
    filter: true,
  },
  {
    field: 'sector',
    header: 'Sector',
    style: { maxWidth: '100px' },
    sortable: true,
    filter: true,
  },
  {
    field: 'direction',
    header: 'Dirección de domicilio',
    sortable: true,
  },
  {
    field: 'habilitado',
    header: 'Habilitado',
    style: { maxWidth: '90px', justifyContent: 'center' },
  },
  {
    field: 'actions',
    header: 'Acciones',
    style: { maxWidth: '90px', justifyContent: 'center' },
  },
];

const colsDriversbyProvider = [
  {
    field: 'first',
    header: '',
    style: { maxWidth: '60px' },
  },
  {
    field: 'driver',
    header: 'Conductor',
    filter: true,
    sortable: true,
    style: { textTransform: 'uppercase' },
  },
  {
    field: 'zone',
    header: 'Zona',
    style: { maxWidth: '100px' },
    sortable: true,
    filter: true,
  },
  {
    field: 'sector',
    header: 'Sector',
    style: { maxWidth: '100px' },
    sortable: true,
    filter: true,
  },
  {
    field: 'direction',
    header: 'Dirección de domicilio',
    sortable: true,
  },
  {
    field: 'habilitado',
    header: 'Habilitado',
    style: { maxWidth: '90px', justifyContent: 'center' },
  },
  {
    field: 'actions',
    header: 'Acciones',
    style: { maxWidth: '90px', justifyContent: 'center' },
  },
];

const Drivers = () => {
  const dispatch = useDispatch();
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);
  const usersLists = useSelector((state) => state.users.data);
  const listDrivers = useSelector((state) => state.users.driverByProvider);
  const provincesLists = useSelector((state) => state.location.data);
  const userAuth = useSelector((state) => state.auth.dataUser);

  const [listUsers, setlistUsers] = useState([]);
  const [lisDriverByProvider, setlisDriverByProvider] = useState([]);

  const [rol, setRol] = useState(5);
  const [driverSelected, setdriverSelected] = useState(null);
  const { driverId } = useParams();

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  const mapperTable = (typeRender) => {
    if (typeRender === 1) {
      if (usersLists) {
        if (usersLists.length > 0) {
          var result = [];
          usersLists.map((item, key) => {
            result.push({
              first: (
                <>
                  <Tooltip title="Mirar perfil" placement="top" arrow>
                    <Link to={`/drivers/driverProfile/${item.id}/${item.idBoss}`}>
                      <EyeOutlineIcon color="#818181" style={{ marginRight: '10px', cursor: 'pointer' }} />
                    </Link>
                  </Tooltip>
                  <PlusThickIcon
                    color="#818181"
                    style={{ marginRight: '10px', height: '17px', cursor: 'pointer' }}
                    // onClick={() => {
                    //   setdriverSelected((oldElement) => (oldElement === cellObj.cell.row.id ? null : cellObj.cell.row.id));
                    // }}
                  />
                </>
              ),
              driver: `${item.firstName} ${item.firstLastName}`,
              provider: item.user ? `${item.user.firstName} ${item.user.firstLastName} ${item.user.secondLastName}` : '',
              zone: item.city ? `${item.city.province.nameProvince}` : '',
              sector: item.sector ? `${item.sector.nameSector}` : '',
              direction: `${item.mainStreet}, ${item.secondStreet} ${item.homeNumber}`,
              habilitado: (
                <div>
                  <Switch disabled={userAuth.roleId !== 3} checked={item.status === 'accepted'} onChange={() => handleUserEnabled(item)} />
                </div>
              ),
              actions: (
                <>
                  <Tooltip title="Mirar estadísticas" placement="top" arrow>
                    <Link className="table__action__icon" to={`/drivers/driver/${item.id}`}>
                      <ChartBarIcon color="#00E0FF" />
                    </Link>
                  </Tooltip>
                </>
              ),
            });
          });
          setlistUsers(result);
        } else {
          setlistUsers([]);
        }
      }
    } else {
      if (listDrivers) {
        if (listDrivers.length > 0) {
          var result = [];
          listDrivers.map((item, key) => {
            result.push({
              first: (
                <Tooltip title="Mirar perfil" placement="top" arrow>
                  <Link to={`/drivers/driverProfile/${item.id}/${item.idBoss}`}>
                    <EyeOutlineIcon
                      color="#818181"
                      style={{ marginRight: '10px', height: '17px', cursor: 'pointer' }}
                      // onClick={() => {
                      //   setdriverSelected((oldElement) => (oldElement === cellObj.cell.row.id ? null : cellObj.cell.row.id));
                      // }}
                    />
                  </Link>
                </Tooltip>
              ),
              driver: `${item.firstName} ${item.firstLastName}`,
              zone: item.city ? `${item.city.province.nameProvince}` : '',
              sector: item.sector ? `${item.sector.nameSector}` : '',
              direction: `${item.mainStreet}, ${item.secondStreet} ${item.homeNumber}`,
              habilitado: (
                <div>
                  <Switch disabled={userAuth.roleId !== 3} checked={item.status === 'accepted'} onChange={() => handleUserEnabled(item)} />
                </div>
              ),
              actions: (
                <>
                  <Tooltip title="Mirar estadísticas" placement="top" arrow>
                    <Link className="table__action__icon" to={`/drivers/driver/${item.id}`}>
                      <ChartBarIcon color="#00E0FF" />
                    </Link>
                  </Tooltip>
                </>
              ),
            });
          });
          setlisDriverByProvider(result);
        } else {
          setlisDriverByProvider([]);
        }
      }
    }
  };

  const getInfoProveedor = (user) => {
    return user ? (
      <>
        <span style={{ textTransform: 'uppercase' }}>{user.firstName}</span> <span style={{ textTransform: 'uppercase' }}>{user.firstLastName}</span> <span style={{ textTransform: 'uppercase' }}>{user.secondLastName}</span>
      </>
    ) : (
      <></>
    );
  };

  const handleUserEnabled = (user) => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserByIdList(user.id, body, rol));
  };

  useEffect(() => {
    if (driverId === 'none') {
      dispatch(listUser(rol));
      if (!isFetchingusersLists) mapperTable(1);
    }
    if (provincesLists === null) dispatch(listProvinces());
  }, [rol]);

  useEffect(() => {
    if (driverId && driverId !== 'none') {
      dispatch(getDriversByProvider(driverId));
      if (!isFetchingusersLists) mapperTable(2);
    }
  }, [driverId]);

  useEffect(() => {
    if (driverId === 'none') {
      pushNewItemBreadCrums('Conductores', 1);
    } else {
      pushNewItemBreadCrums('Conductores');
    }
  }, [location]);

  useEffect(() => {
    if (!isFetchingusersLists) mapperTable(1);
  }, [usersLists]);

  useEffect(() => {
    if (!isFetchingusersLists) mapperTable(2);
  }, [listDrivers]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: 'flex', flexDirection: 'row', paddingTop: '21px' }}>
          <SteeringIcon
            style={{
              height: '40px',
              width: '50px',
              color: '#4B545A',
              marginRight: '13px',
              marginTop: '-7px',
            }}
          />
          <h3 className="page-title">
            {/*  Proveedor  */}Conductores{/* <span className="text-danger">Conductores</span> */}
          </h3>
        </Col>
      </Row>
      <Row>
        <Container
          style={{
            paddingLeft: '20px',
            paddingBottom: '20px',
            paddingRight: '20px',
          }}
        >
          {!isFetchingusersLists ? (
            usersLists && driverId === 'none' ? (
              <DataTableCustom
                //
                data={listUsers}
                columns={colsDrivers}
                filtered
                scrollHeight="500px"
              />
            ) : (
              listDrivers && (
                // <TableBase
                //   reactTableData={{
                //     tableHeaderData: columnsDriversByProvider,
                //     tableRowsData: listDrivers,
                //   }}
                //   multiTable={true}
                //   multiTableRowSelected={driverSelected}
                // />
                <DataTableCustom
                  //
                  data={lisDriverByProvider}
                  columns={colsDriversbyProvider}
                  filtered
                  scrollHeight="500px"
                />
              )
            )
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default Drivers;
