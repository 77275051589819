import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { useSelector } from 'react-redux';

const SidebarContent = ({ onClick, changeToDark, changeToLight, sidebarCollapse }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  const dataUser = useSelector((state) => state.auth.dataUser);

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Escritorio" icon="chart-bars" route="/dashboard" onClick={handleHideSidebar} />
        {/* <SidebarLink title="Autorizaciones" icon="checkmark-circle" route="/authorizations" onClick={handleHideSidebar} /> */}
        <SidebarLink title="Usuarios" icon="user" route="/users" onClick={handleHideSidebar} />
        <SidebarLink title="Proveedor" icon="users" route="/providers" onClick={handleHideSidebar} />
        <SidebarLink title="Conductor" icon="car" route="/drivers/none" onClick={handleHideSidebar} />
        {/*  <SidebarLink title="Contratos" icon="file-add" route="/contracts" onClick={handleHideSidebar} /> */}
        {dataUser.roleId === 3 && (
          <SidebarCategory title="Ajustes" icon="cog" sidebarCollapse={sidebarCollapse} pathBase="settings">
            {/*  <SidebarLink title="Destinos" route="/settings/routes" onClick={handleHideSidebar} /> */}
            {/*   <SidebarLink title="Traslados" route="/settings/transfers" onClick={handleHideSidebar} /> */}

            {/*       <SidebarLink title="Cobertura" route="/settings/coverage" onClick={handleHideSidebar} /> */}
            <SidebarLink title="FAQ" route="/settings/faq" onClick={handleHideSidebar} />
            <SidebarLink title="Ajustes de Reportar Problemas" route="/settings/helpApp" onClick={handleHideSidebar} />
            <SidebarLink title="Marcas" route="/settings/brand" onClick={handleHideSidebar} />
            <SidebarLink title="Colores" route="/settings/colors" onClick={handleHideSidebar} />
            <SidebarLink title="Modelos" route="/settings/models" onClick={handleHideSidebar} />
            {/*           <SidebarLink title="Provincias" route="/settings/provinces" onClick={handleHideSidebar} /> */}
            <SidebarLink title="Zonas" route="/settings/zones" onClick={handleHideSidebar} />
            <SidebarLink title="Tarifas" route="/settings/rates" onClick={handleHideSidebar} />

            {/*          <SidebarLink title="Invitados" route="/settings/guests" onClick={handleHideSidebar} /> */}
            {/*          <SidebarLink title="Reportes" route="/settings/reports" onClick={handleHideSidebar} /> */}
            {/*  <SidebarLink title="Ayuda menú" route="/settings/helpMenu" onClick={handleHideSidebar} /> */}
          </SidebarCategory>
        )}

        {/* rutas de ejemplo */}
        {/* <SidebarCategory title="Conductores" icon="users" sidebarCollapse={sidebarCollapse} pathBase="drivers">
          <SidebarLink title="Proveedor" route="/drivers1/providers" onClick={handleHideSidebar} />
          <SidebarLink title="Conductores" route="/drivers1/drivers" onClick={handleHideSidebar} />
          <SidebarLink title="Vehículos" route="/drivers1/vehicles" onClick={handleHideSidebar} />
          <SidebarLink title="Destinos Frecuentes" route="/drivers1/frequent-destinations" onClick={handleHideSidebar} />
          <SidebarLink title="Conductores Frecuentes" route="/drivers1/frequent-rivers" onClick={handleHideSidebar} />
          <SidebarLink title="Conductores Afiliados" route="/drivers1/affiliate-drivers" onClick={handleHideSidebar} />
          <SidebarLink title="Autos Inscritos" route="/drivers1/registered-cars" onClick={handleHideSidebar} />
          <SidebarLink title="Pasajeros Frecuentes" route="/drivers1/frequent-passengers" onClick={handleHideSidebar} />

          <SidebarLink title="Conductores Usuario" route="/subroutes/user_drivers" onClick={handleHideSidebar} />
          <SidebarLink title="Vehiculos Usuario" route="/subroutes/vehicles_user" onClick={handleHideSidebar} />
          <SidebarLink title="Conductores Datos cifras" route="/subroutes/drivers_facts_figures" onClick={handleHideSidebar} />
          <SidebarLink title="Conductor Datos personales" route="/subroutes/driver_personal_data" onClick={handleHideSidebar} />
          <SidebarLink title="Propietario Usuario" route="/subroutes/owner_user" onClick={handleHideSidebar} />
        </SidebarCategory> */}
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
