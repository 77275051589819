import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReactTableBase from '../../../../shared/components/table/ReactTableBase';

const ResizableReactTable = ({ reactTableData, setSelectedRows, deleteVisible, deleteUsers }) => {
  const tableConfig = {
    isEditable: false,
    isSelectable: false,
    isFiltering: true,
    isResizable: false,
    isSortable: true,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [16, 20, 30, 40],
    placeholder: 'Buscar por Identificador...',
    setSelectedRows
  };

  return (
    <ReactTableBase
      key={'common'}
      columns={reactTableData.tableHeaderData}
      data={reactTableData.tableRowsData}
      tableConfig={tableConfig}
    />
  );
};

ResizableReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default ResizableReactTable;
