import React, { useEffect, useMemo } from 'react';
import { Col, Row, Spinner, Container, FormGroup, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import TrashIcon from 'mdi-react/TrashIcon';
import TableBase from './components/TableBase';
import EditIcon from 'mdi-react/EditIcon';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { deleteColor, listColors, postColor, putColorById } from '../../../redux/actions/colorsActions';
import { Form } from './components/Form';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
import { TextField } from '@material-ui/core';

const validationSchema = yup.object({
  color: yup.string('Ingrese el nombre del color').required('El nombre del color es requerido'),
  hexadecimal: yup.string('Seleccione un color').required('El color es requerido'),
});

const index = () => {
  const dispatch = useDispatch();
  const colorList = useSelector((state) => state.colors.data);
  const isFetchingColorList = useSelector((state) => state.colors.isFetching);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Colores', 1);
  }, [location]);

  const formSave = useFormik({
    initialValues: {
      color: '',
      hexadecimal: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postColor({
          name: values.color,
          hexadecimal: values.hexadecimal,
        })
      );
      formSave.resetForm();
    },
  });

  const formUpdate = useFormik({
    initialValues: {
      color: '',
      id: null,
      hexadecimal: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        putColorById(values.id, {
          name: values.color,
          hexadecimal: values.hexadecimal,
        })
      );
      formUpdate.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'color',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.name}</span>,
        disableFilters: true,
      },
      {
        Header: 'Color',
        accessor: 'hexadecimal',
        Cell: (cellObj) => <>{cellObj.cell.row.original.hexadecimal ? <div style={{ border: '1px solid black', width: 25, height: 25, backgroundColor: cellObj.cell.row.original.hexadecimal }}></div> : <></>}</>,
        disableFilters: true,
      },
      {
        Header: ' ',
        accessor: ' ',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Acciones',
        accessor: 'actions',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateColor(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteColor(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [colorList]
  );

  const handleDeleteColor = (id) => {
    dispatch(deleteColor(id));
  };

  const handleUpdateColor = (objectColor) => {
    formUpdate.setValues({ id: objectColor.id, color: objectColor.name, hexadecimal: objectColor.hexadecimal });
  };

  useEffect(() => {
    dispatch(listColors());
  }, []);

  return (
    <Container className="dashboard container">
      <Row>
        <Col lg={6} md={12}>
          <TitleSettings title="Colores ingresados" />
          {!isFetchingColorList && colorList ? (
            <>
              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: colorList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>
        <Col lg={6} md={12}>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h3 className="page-title" style={{ fontSize: '22px' }}>
                Autorización: <span className="text-danger">Ingreso de vehículos Colores </span>
              </h3>
            </div>
            <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} onSubmit={formSave.handleSubmit}>
              <div className="d-flex align-items-center my-2" style={{ width: '100%' }}>
                <Col xs={4} className="my-2">
                  <h4>Color:</h4>
                </Col>
                <Col xs={8} className="my-2">
                  <TextField
                    //
                    fullWidth
                    id="color"
                    name="color"
                    variant="outlined"
                    type="text"
                    value={formSave.values.color}
                    onChange={formSave.handleChange}
                    error={formSave.touched.color && Boolean(formSave.errors.color)}
                    helperText={formSave.touched.color && formSave.errors.color}
                    placeholder="Ingrese el color"
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: '14px' } }}
                  />
                </Col>
              </div>

              <div className="d-flex align-items-center my-2" style={{ width: '100%' }}>
                <Col xs={4} className="my-2">
                  <h4>Seleccione un color:</h4>
                </Col>
                <Col xs={8} className="my-2">
                  <TextField
                    //
                    fullWidth
                    id="hexadecimal"
                    name="hexadecimal"
                    variant="outlined"
                    type="color"
                    value={formSave.values.hexadecimal}
                    onChange={formSave.handleChange}
                    error={formSave.touched.hexadecimal && Boolean(formSave.errors.hexadecimal)}
                    helperText={formSave.touched.hexadecimal && formSave.errors.hexadecimal}
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: '14px' } }}
                  />
                </Col>
              </div>

              <div style={{ width: 250 }}>
                <Button color="danger" type="submit" className="py-2 my-2">
                  Ingresar Color
                </Button>
              </div>
            </form>
          </Row>
          <Row>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h3 className="page-title" style={{ fontSize: '22px' }}>
                Editar: <span className="text-danger">Color Ingresado </span>
              </h3>
            </div>
            <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} onSubmit={formUpdate.handleSubmit}>
              <div className="d-flex align-items-center my-2" style={{ width: '100%' }}>
                <Col xs={4} className="my-2">
                  <h4>Color:</h4>
                </Col>
                <Col xs={8} className="my-2">
                  <TextField
                    //
                    fullWidth
                    id="color"
                    name="color"
                    variant="outlined"
                    type="text"
                    value={formUpdate.values.color}
                    onChange={formUpdate.handleChange}
                    error={formUpdate.touched.color && Boolean(formUpdate.errors.color)}
                    helperText={formUpdate.touched.color && formUpdate.errors.color}
                    placeholder="Escoger un color de la tabla"
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: '14px' } }}
                  />
                </Col>
              </div>
              <div className="d-flex align-items-center" style={{ width: '100%' }}>
                <Col xs={4} className="my-2">
                  <h4>Seleccione un color:</h4>
                </Col>
                <Col xs={8} className="my-2">
                  <TextField
                    //
                    fullWidth
                    id="color"
                    name="hexadecimal"
                    variant="outlined"
                    type="color"
                    value={formUpdate.values.hexadecimal}
                    onChange={formUpdate.handleChange}
                    error={formUpdate.touched.hexadecimal && Boolean(formUpdate.errors.hexadecimal)}
                    helperText={formUpdate.touched.hexadecimal && formUpdate.errors.hexadecimal}
                    placeholder="Escoger un color de la tabla"
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: '14px' } }}
                  />
                </Col>
              </div>
              <div style={{ width: 250 }}>
                <Button color="danger" type="submit" className=" py-2 my-2">
                  Actualizar Color
                </Button>
              </div>
            </form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
