import React from 'react';
import { Col, Container, Row, Table, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import Select from "react-select";
import StarIcon from 'mdi-react/StarIcon';
import SteeringIcon from 'mdi-react/SteeringIcon';

import Checkbox from '@material-ui/core/Checkbox';
import EyeIcon from 'mdi-react/EyeIcon';

const user_img = `${process.env.PUBLIC_URL}/img/user_img.png`;

const data = [
  { name:'Cédula del propietario', status: true, status_cops: true},
  { name:'Plantilla de servicio básico ', status: false, status_cops: true},
  { name:'Póliza del seguro', status: false, status_cops: true},
  { name:'Matrícula del Vehículo', status: false, status_cops: true}
];

const Dashboard = () => {
  return (
    <Container className="dashboard">
      <Row className="mb-4">
        <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
          <SteeringIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '13px', marginTop: '-7px' }} />
          <h3 className="page-title">Propietario: <span className="text-danger">Óscar Erazo</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          <Row className="react-action-top rt-6 rr-4">
              <Select
                className="basic-single-user"
                name="Codigo_provedor"
                isClearable={true}
                isSearchable={true}
                placeholder={"Provincia"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
              <Select
                className="basic-single-user ml-3"
                name="Codigo_provedor"
                isClearable={true}
                isSearchable={true}
                placeholder={"Placa"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <Row>
                <Col lg={6}>
                  <label htmlFor="zona-calor" className="fw-bold mb-3">Datos del <span className="text-danger">Propietario</span></label>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="primer_nombre" name="primer_nombre" defaultValue="Oscar" placeholder="Ingrese su primer nombre" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="segundo_nombre" name="segundo_nombre" defaultValue="Alberto" placeholder="Ingrese su segundo nombre" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="primer_apellido" name="primer_apellido" defaultValue="Erazo" placeholder="Ingrese su primer apellido" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="segundo_apellido" name="segundo_apellido" defaultValue="Suarez" placeholder="Ingrese su segundo apellido" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="email" name="email" defaultValue="marcos@hotmail.com" placeholder="Ingrese su email" type="email"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="celular" name="celular" defaultValue="09 823467465" placeholder="Ingrese su celular" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="pais" name="pais" defaultValue="Ecuador" placeholder="Ingrese su pais" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="provincia" name="provincia" defaultValue="Pichincha" placeholder="Ingrese su provincia" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="ciudad" name="ciudad" defaultValue="Quito" placeholder="Ingrese su ciudad" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="dirección" name="dirección" defaultValue="Av. 12 de Octubre" placeholder="Ingrese su dirección" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="calle" name="calle" defaultValue="La niña" placeholder="Ingrese su calle principal" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="casa" name="casa" defaultValue="Oe3-23" placeholder="Ingrese su número de casa" type="text"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <label htmlFor="zona-calor" className="fw-bold mb-3">Datos del <span className="text-danger">Vehículo</span></label>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="modelo" name="modelo" defaultValue="Modelo Sedan" placeholder="Ingrese el modelo" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="marca" name="marca" defaultValue="Chevrolet" placeholder="Ingrese la marca" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="color" name="color" defaultValue="Blanco" placeholder="Ingrese el color" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="year" name="year" defaultValue="2012" placeholder="Ingrese el año del carro" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="placas" name="placas" defaultValue="XXX-1234" placeholder="Ingrese las placas" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="numero" name="numero" defaultValue="3" placeholder="Ingrese el número" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="seguro" name="seguro" defaultValue="Seguros Equinoccial" placeholder="Ingrese el seguro" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Input id="telefono" name="telefono" defaultValue="023462" placeholder="Ingrese el telefono" type="text"/>
                      </FormGroup>
                    </Col>
                    <Col lg={12}>
                      <label htmlFor="seguro" style={{ fontSize: "14px", marginLeft: "15px" }}>Tiene seguro de vehículo:</label>
                      <Checkbox className="color-red"></Checkbox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-5 mt-4">
            <Col lg={12}>
              <div className="table react-table">
                <label htmlFor="zona-calor" className="fw-bold mb-3">Validación del <span className="text-danger">Vehículo</span></label>
                <Table className="table-user title-red table-border-bottom">
                  <thead>
                    <tr>
                      <th>Documento</th>
                      <th colspan="2">Mantenimiento</th>
                      <th colspan="2">Corporativo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((item) => (
                        <tr>
                          <td className="lh-1"><EyeIcon color="#00E0FF" className="mr-2"/> { item.name }</td>
                          <td>
                            <Select
                              className={"basic-single-user w-15 p-0 min-select " + (item.status ? "single-circle-success" : "single-circle-warning")}
                              name="Codigo_provedor"
                              isClearable={true}
                              isSearchable={true}
                              placeholder={'Seleccione un estado'}
                              color
                              defaultValue={ item.status ? { value: "Activo", label: "Activo"} : { value: "En revisión", label: "En revisión" } }
                              options={[
                                { value: "Activo", label: "Activo"},
                                { value: "En revisión", label: "En revisión" },
                              ]}
                            />
                          </td>
                          <td><Input placeholder="Ingrese sus observaciones"></Input></td>
                          <td>
                            <Select
                              className={"basic-single-user w-15 p-0 min-select " + (item.status_cops ? "single-circle-success" : "single-circle-warning")}
                              name="Codigo_provedor"
                              isClearable={true}
                              isSearchable={true}
                              placeholder={'Seleccione un estado'}
                              color
                              defaultValue={ item.status_cops ? { value: "Activo", label: "Activo"} : { value: "En revisión", label: "En revisión" } }
                              options={[
                                { value: "Activo", label: "Activo"},
                                { value: "En revisión", label: "En revisión" },
                              ]}
                            />
                          </td>
                          <td><Input placeholder="Ingrese sus observaciones"></Input></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
};

export default Dashboard;
