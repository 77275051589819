import { handleActions } from "redux-actions";

import {
  fetchZonesRequest,
  fetchZonesSuccess,
  fetchZonesError,
} from "../actions/zoneAction";

const defaultState = [];

export default handleActions(
  {
    [fetchZonesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchZonesSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchZonesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
