import { handleActions } from "redux-actions";
import {
  fetchQuestionsRequest,
  fetchQuestionsSuccess,
  fetchQuestionsError,
  fetchQuestionsRolesSucess,
} from "../actions/FaqAction";

const defaultState = [];
export default handleActions(
  {
    [fetchQuestionsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchQuestionsSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchQuestionsRolesSucess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchQuestionsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
