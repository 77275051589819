import React, { useEffect, useState, useCallback, useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Spinner, Input, Row } from 'reactstrap';
import { Accordion, AccordionSummary, AccordionDetails, Tooltip, Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select';
import PlayIcon from 'mdi-react/PlayIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import CalendarOutlineIcon from 'mdi-react/CalendarOutlineIcon';
import { Link, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { getDataDashboard, getDataCollaboratorDashboard, getDataFormatsDashboard, getDataDashboardWorArea } from '../../redux/actions/dashboardActions';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import socketio from 'socket.io-client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';

import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { ListCities, listEstablisments, ListPrivicesByZone, ZonesFavorita } from '../../redux/actions/zoneAction';
import { useBreadCrums } from '../../hooks/useBreadCrums';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SelectOption } from '../../shared/components/SelectOptions';
import './styles.css';
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'total'];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.dataUser);
  const dataDashboard = useSelector((state) => state.dashboard.data);
  const dataCollaborator = useSelector((state) => state.dashboard.dataCollaborator);
  const dataFormats = useSelector((state) => state.dashboard.dataFormats);
  const topFive = useSelector((state) => state.dashboard.topFive);
  const isFetchingDataDashboard = useSelector((state) => state.dashboard.isFetching);
  const isFetchingDataDashboardCollaborator = useSelector((state) => state.dashboard.isFetchingCollaborator);

  const zones = useSelector((state) => state.zonesFavorita.zones);
  const cities = useSelector((state) => state.zonesFavorita.cities);
  const establishments = useSelector((state) => state.zonesFavorita.establishments);

  const [establish, setEstablish] = useState([]);

  const [year, setYear] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [collaboratorSelected, setCollaboratorSelected] = useState({ value: null, label: '' });
  const [formatSelected, setFormatSelected] = useState({ value: null, label: '' });
  const [newAlert, setNewAlert] = useState([]);
  const [topFiveCollaboratorsAccordion, setTopFiveCollaboratorsAccordion] = useState(true);
  const [topFiveDriversAccordion, setTopFiveDriversAccordion] = useState(true);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Escritorio', 1);
  }, [location]);

  useEffect(() => {
    if (endDate >= startDate) {
      dispatch(getDataDashboardWorArea(moment(year).format('YYYY'), 'completed', `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, userAuth.workAreaCode));
      // dispatch(getDataDashboard(moment(year).format('YYYY'), 'completed', `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`));
    } else {
      toast.success('La fecha de inicio debe ser menor a la de fecha fin', {
        autoClose: 3500,
        theme: 'dark',
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (collaboratorSelected.value) dispatch(getDataCollaboratorDashboard(moment(year).format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', collaboratorSelected.value));
  }, [collaboratorSelected]);

  useEffect(() => {
    if (formatSelected.value) dispatch(getDataFormatsDashboard(moment(year).format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', formatSelected.value));
  }, [formatSelected]);

  const handleNewAlert = useCallback((res) => {
    setNewAlert((prevArray) => [...res.data, ...prevArray]);
  }, []);

  useEffect(() => {
    const socket = socketio.connect(process.env.REACT_APP_URL_SOCKET);
    socket.on('emit_order_admin', handleNewAlert);
    return () => {
      socket.emit('desconectar');
    };
  }, []);

  useEffect(() => {
    dispatch(ZonesFavorita());
    dispatch(listEstablisments());
  }, []);

  const dataGraphics = (graphic) => {
    let data;

    if (collaboratorSelected.value) {
      if (dataCollaborator !== null) data = dataCollaborator[graphic];
      else data = dataDashboard[graphic];
    } else if (formatSelected.value) {
      if (dataFormats !== null) data = dataFormats[graphic];
      else data = dataDashboard[graphic];
    } else {
      data = dataDashboard[graphic];
    }

    const resultMapData = Months.map((month) => {
      const index = data.findIndex((monthData) => monthData.Mes === month);
      if (index < 0) return 0;
      else return data[index].Total;
    });

    return {
      labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
      datasets: [
        {
          data: resultMapData,
          backgroundColor: ['rgba(248, 211, 207)', 'rgba(208, 43, 31)', 'rgba(247, 191, 184)', 'rgba(242, 150, 139)', 'rgba(244, 170, 161)', 'rgba(248, 211, 207)', 'rgba(241, 127, 114)', 'rgba(237, 85, 71)', 'rgba(237, 85, 71)', 'rgb(209, 70, 67)', 'rgba(142, 46, 49)', 'rgba(40, 51, 80)'],
          borderWidth: 0,
        },
      ],
    };
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="selectYear">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <CalendarOutlineIcon onClick={props.onClick} style={{ height: '20px', marginLeft: '8px', marginTop: '-3px' }} />
      </div>
    );
  });

  const exportToExcel = async () => {
    const result = dataToExport();


    const Heading = [['Mes', 'kilometros', 'viajes', 'costo']];
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const ws = XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, Heading);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Reporte General.xlsx');
  };

  const generatePDF = () => {
    html2canvas(document.querySelector('#tableToExport'), {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById('tableToExport').style.display = 'block';
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 3, 3);
      pdf.save('Reporte General.pdf');
    });
  };

  const dataToExport = () => {
    let dataCost;
    let dataKm;
    let dataTravels;

    let totalCost = 0;
    let totalKm = 0;
    let totalTravels = 0;

    const MonthsEs = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', 'Total'];

    const dataDashboardFunction = () => {
      dataCost = dataDashboard['generalCost'];
      dataKm = dataDashboard['generalKm'];
      dataTravels = dataDashboard['generalTravels'];
    };

    if (collaboratorSelected) {
      if (dataCollaborator !== null) {
        dataCost = dataCollaborator['generalCost'];
        dataKm = dataCollaborator['generalKm'];
        dataTravels = dataCollaborator['generalTravels'];
      } else dataDashboardFunction();
    } else if (formatSelected) {
      if (dataFormats !== null) {
        dataCost = dataFormats['generalCost'];
        dataKm = dataFormats['generalKm'];
        dataTravels = dataFormats['generalTravels'];
      } else dataDashboardFunction();
    } else dataDashboardFunction();

    const dataResult = Months.map((month, monthIndex) => {
      let monthBalance = {
        Mes: MonthsEs[monthIndex],
        kilometros: 0,
        viajes: 0,
        costo: 0,
      };
      const indexKm = dataKm.findIndex((kmMonth) => kmMonth.Mes === month);
      const indexTravels = dataTravels.findIndex((travelsMonth) => travelsMonth.Mes === month);
      const indexCost = dataCost.findIndex((costMonth) => costMonth.Mes === month);

      if (indexKm > -1) monthBalance.kilometros = dataKm[indexKm].Total;
      if (indexTravels > -1) monthBalance.viajes = dataTravels[indexTravels].Total;
      if (indexCost > -1) monthBalance.costo = dataCost[indexCost].Total;

      return monthBalance;
    });

    dataResult.map((item) => {
      totalCost += item.costo;
      totalKm += item.kilometros;
      totalTravels += item.viajes;
    });

    dataResult[dataResult.length - 1].costo = totalCost;
    dataResult[dataResult.length - 1].viajes = totalTravels;
    dataResult[dataResult.length - 1].kilometros = totalKm;

    return dataResult;
  };

  const zonesOptions = () => {
    return zones.map((item) => ({ value: item.code, label: item.name, cities: item.cities }));
  };

  const selectZone = (e) => {
    if (e) {
      dispatch(ListPrivicesByZone(e ? e.cities : []));
      dispatch(ListCities(e ? e.cities : []));
    }
  };

  const citiesOptions = () => {
    return cities.map((item) => ({ value: item.code, label: item.name }));
  };

  const selectCity = (e) => {
    if (e) {
      const array = establishments.filter((item) => item.city.name === e.label);
      setEstablish([...array.map((item) => ({ value: item.workAreaCode, label: `${item.establishmentName} - ${item.workAreaCode} - ${item.workAreaName} ` }))]);
    }
  };

  const establishmentsOptions = () => {
    establishments.map((item) => ({ value: item.establishmentCode, label: item.establishmentAreaName }));
  };

  const selectEstablishment = (e) => {
    if (e) {
      dispatch(getDataDashboardWorArea(moment(year).format('YYYY'), 'completed', `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, e.value));
    }
  };

  return (
    <Container className="dashboard" id="dashboard">
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ width: '100%' }}>
            <KeyboardDatePicker
              // style={{ width: 200 }}
              className="input-dashboard"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Fecha desde"
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
                setYear(date);
              }}
              maxDate={new Date()}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              // style={{ width: 200 }}
              className="input-dashboard"
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Fecha hasta"
              value={endDate}
              maxDate={new Date()}
              onChange={(date) => {
                setEndDate(date);
                setYear(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <SelectOption
              onClick={() => setFormatSelected({ value: null, label: '' })}
              styles={{ minWidth: 200 }}
              disabled={collaboratorSelected.value !== null}
              inputLabel="Tipo de vehículos"
              option={formatSelected.value}
              options={[
                { value: 'favorita', label: 'Favorita' },
                { value: 'external', label: 'Externo' },
                { value: 'personal', label: 'Personal' },
              ]}
              handleChange={(e) => {
                if (e === null) setFormatSelected({ value: null, label: '' });
                else {
                  setCollaboratorSelected({ value: null, label: '' });
                  setFormatSelected({ value: e.target.value, label: e.label });
                }
              }}
            />
            <SelectOption
              onClick={() => setCollaboratorSelected({ value: null, label: '' })}
              styles={{ minWidth: 200 }}
              disabled={formatSelected.value !== null}
              inputLabel="Colaborador"
              option={collaboratorSelected.value}
              options={dataDashboard && dataDashboard.users.filter((user) => user.workAreaCode === userAuth.workAreaCode).map((user) => ({ value: user.id, label: `${user.firstName} ${user.firstLastName} - ${user.workAreaCode}` }))}
              handleChange={(e) => {
                if (e === null) setCollaboratorSelected({ value: null, label: '' });
                else {
                  setFormatSelected({ value: null, label: '' });
                  setCollaboratorSelected({ value: e.target.value, label: e.label });
                }
              }}
            />
            <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
              <label style={{ fontWeight: 600, fontSize: '18px', paddingTop: '5px' }} htmlFor="zona-calor" className="text-danger">
                Reporte General:{' '}
              </label>
              <div className="my-2">
                <button
                  //
                  disabled={isFetchingDataDashboard}
                  className="dashboardButton"
                  onClick={exportToExcel}
                  style={{ backgroundColor: '#3DBE00', marginRight: '15px', marginLeft: '15px', height: 40 }}
                >
                  XLSX <DownloadIcon></DownloadIcon>
                </button>
                <button
                  //
                  disabled={isFetchingDataDashboard}
                  className="dashboardButton"
                  onClick={generatePDF}
                  style={{ backgroundColor: '#EC1C24', height: 40 }}
                >
                  PDF <DownloadIcon></DownloadIcon>
                </button>
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ width: '100%' }}>
          <Autocomplete
            disabled={isFetchingDataDashboard}
            id="zones"
            noOptionsText={'No se encuentran zonas'}
            className="input-dashboard my-2"
            options={zonesOptions()}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => selectZone(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zonas"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            disabled={isFetchingDataDashboard}
            id="cities"
            noOptionsText={'No se encuentran ciudades'}
            className="input-dashboard my-2"
            options={citiesOptions()}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => selectCity(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ciudades"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            disabled={isFetchingDataDashboard}
            id="establisment"
            noOptionsText={'No se encuentran areas de trabajo'}
            className="input-dashboard my-2"
            options={establish}
            autoHighlight
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => selectEstablishment(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Area de trabajo"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
      </div>
      {!isFetchingDataDashboard && dataDashboard ? (
        <div className="row" style={{ marginTop: '30px' }}>
          <div className="col-md-5 my-2" style={{ paddingLeft: 0 }}>
            {!isFetchingDataDashboardCollaborator ? (
              <>
                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Total de <span className="text-danger">gasto</span>
                  </label>
                </div>
                <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px' }}>
                  <Bar
                    style={{ height: '250px' }}
                    data={dataGraphics('generalCost')}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="mt-4 label-head-info">
                    Km <span className="text-danger">promedio</span>
                  </label>
                </div>
                <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px' }}>
                  <Bar
                    style={{ height: '250px' }}
                    data={dataGraphics('generalKm')}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="mt-4 label-head-info">
                    Número de viajes <span className="text-danger">Servicio</span>
                  </label>
                </div>
                <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                  <Bar
                    style={{ height: '250px' }}
                    data={dataGraphics('generalTravels')}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </>
            ) : (
              <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
            )}
          </div>
          <div className="col-md-4 my-2">
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
              <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className=" label-head-info">
                Mapa de{' '}
                <span className="text-danger" style={{ marginTop: '0 !important' }}>
                  zona de calor
                </span>
              </label>
              <Link style={{ color: '#4B545A', fontWeight: '300', fontSize: '12px' }} to="/dashboard/HeatZoneMaps">
                Ver Todas <EyeOutlineIcon color="#646777"></EyeOutlineIcon>
              </Link>
            </div>
            <GoogleMap
              id="map_dashboardHome"
              center={{ lat: -0.225219, lng: -78.5248 }}
              zoom={7}
              mapContainerStyle={{ height: '90%', width: '100%', minHeight: '500px' }}
              options={{
                streetViewControl: false,
                zoomControl: false,
                mapTypeControl: false,
                minZoom: 2,
              }}
            >
              {dataDashboard &&
                dataDashboard.addressList.map((point, index) => (
                  <Marker
                    key={`point-${index}-${point.latOrigin}`}
                    position={{
                      lat: Number(point.latOrigin),
                      lng: Number(point.lngOrigin),
                    }}
                    icon={{
                      url: 'https://img.icons8.com/emoji/256/red-circle-emoji.png',
                      scaledSize: new google.maps.Size(20, 20),
                    }}
                  />
                  //  <Circle
                  //   key={`point-${index}`}
                  //   center={{
                  //     lat: Number(point.latOrigin),
                  //     lng: Number(point.lngOrigin),
                  //   }}
                  //   radius={120}
                  //   options={options}
                  // />
                ))}
            </GoogleMap>
          </div>
          <div className="col-md-3 my-2" style={{ paddingRight: 0 }}>
            <Accordion className="accordion">
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader" style={{ backgroundColor: '#4B545A' }}>
                <PlayIcon className="playIcon" color="#BFBFBF" />
                <div className="numberAlerts">{dataDashboard.JourneyAlerts.length + newAlert.length}</div>
                <div className="nameAccordion">
                  <h6 style={{ color: 'white', fontWeight: '600', fontSize: '14px' }}>Viajes</h6>
                  <Link style={{ color: 'white', fontWeight: '300', fontSize: '12px' }} to="/dashboard/JourneyAlerts">
                    Ver Todas <EyeOutlineIcon color="white"></EyeOutlineIcon>
                  </Link>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {[...newAlert, ...dataDashboard.JourneyAlerts].slice(0, 10).map((alert, index) => (
                  <div className="rowArowAccordionAlertBody" key={`alert-${index}`} style={{ alignItems: 'center' }}>
                    <AccountCircleIcon className="icon" style={{ overflow: 'visible', width: '10%', marginRight: '5px' }}></AccountCircleIcon>
                    <h6 style={{ width: '45%' }}>
                      {alert?.collaborator.firstName} {alert?.collaborator.firstLastName}
                    </h6>
                    <h6 style={{ width: '45%' }}>{alert?.addresOrigin}</h6>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion" expanded={topFiveCollaboratorsAccordion} onChange={() => setTopFiveCollaboratorsAccordion(!topFiveCollaboratorsAccordion)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader" style={{ backgroundColor: '#4B545A' }}>
                <PlayIcon className="playIcon" color="#BFBFBF" />
                {/* <div className="numberAlerts">{dataDashboard.JourneyAlerts.length + newAlert.length}</div> */}
                <div className="nameAccordion">
                  <h6 style={{ color: 'white', fontWeight: '600', fontSize: '14px' }}>Top 5 Colaboradores</h6>
                  <Link style={{ color: 'white', fontWeight: '300', fontSize: '12px' }} to="/dashboard/JourneyAlerts">
                    Ver Todas <EyeOutlineIcon color="white"></EyeOutlineIcon>
                  </Link>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {topFive &&
                  topFive.ListTopFiveCollaborators.map((alert, index) => (
                    <div className="rowArowAccordionAlertBody2" key={`collaborator-${index}`}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <AccountCircleIcon className="icon" style={{ overflow: 'visible', width: '10%', marginRight: '5px' }}></AccountCircleIcon>
                        <h6>
                          {alert?.firstName} {alert?.firstLastName}
                        </h6>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>Viajes: {alert?.total}</h6>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>Gastos: ${alert?.gastos}</h6>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>km: {alert?.km}</h6>
                      </div>
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordion" expanded={topFiveDriversAccordion} onChange={() => setTopFiveDriversAccordion(!topFiveDriversAccordion)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader" style={{ backgroundColor: '#4B545A' }}>
                <PlayIcon className="playIcon" color="#BFBFBF" />
                {/* <div className="numberAlerts">{ListTopFiveDrivers.length}</div> */}
                <div className="nameAccordion">
                  <h6 style={{ color: 'white', fontWeight: '600', fontSize: '14px' }}>Top 5 Conductores</h6>
                  <Link style={{ color: 'white', fontWeight: '300', fontSize: '12px' }} to="/dashboard/JourneyAlerts">
                    Ver Todas <EyeOutlineIcon color="white"></EyeOutlineIcon>
                  </Link>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {topFive &&
                  topFive.ListTopFiveDrivers.map((alert, index) => (
                    <div className="rowArowAccordionAlertBody2" key={`driver-${index}`}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                        <AccountCircleIcon className="icon" style={{ overflow: 'visible', width: '10%', marginRight: '5px' }}></AccountCircleIcon>
                        <h6>
                          {alert?.firstName} {alert?.firstLastName}
                        </h6>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>Viajes: {alert?.total}</h6>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>Gastos: ${alert?.gastos}</h6>
                        <h6 style={{ fontSize: 12, fontWeight: 300 }}>km: {alert?.km}</h6>
                      </div>
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ) : (
        <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
      )}

      {/* </Col> */}
      {!isFetchingDataDashboard && dataDashboard && (
        <table style={{ borderLeft: '1px solid #f2f2f2', borderRight: 'none', borderBottom: 'none', display: 'none' }} id="tableToExport">
          <tbody>
            <tr style={{ backgroundColor: '#303e47', borderBottom: 'none' }}>
              <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Mes</th>
              <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Kilometros</th>
              <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Viajes</th>
              <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Costo</th>
            </tr>
            {dataToExport().map((item, index) => (
              <tr key={`row-${index}`} style={{ borderRight: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}>
                <td style={{ padding: '5px 15px 5px 5px' }}>{item.Mes}</td>
                <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{item.kilometros}</td>
                <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{item.viajes}</td>
                <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{item.costo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Container>
  );
};

export default Dashboard;
