import { createAction } from "redux-actions";
import api from "../../utils/api/apiProvider";
import { toast } from "react-toastify";

export const fetchProblemsRequest = createAction("FETCH_PROBLEMS_REQUEST");
export const fetchProblemsSuccess = createAction("FETCH_PROBLEMS_SUCCESS");
export const fetchProblemsError = createAction("FETCH_PROBLEMS_ERROR");
export const fetchProblemsRolesSucess = createAction('FETCH_PROBLEMS_ROLES_SUCCESS');

export const listProblems = (rolId) => async (dispatch) => {
  try {
    //dispatch(fetchProblemsRequest());
    const response = await api.help.getProblems(rolId);
    const responseRoles = await api.user.getRol();
    dispatch(fetchProblemsRolesSucess(responseRoles.data.filter(rol => (rol.id === 5 || rol.id === 2))))
    dispatch(fetchProblemsSuccess(response.data));
  } catch (error) {
    dispatch(fetchProblemsError({ error }));
    toast.error("Error obteniendo el reporte de los problemas", { autoClose: 2500, theme: "colored", });
    throw error;
  }
};

export const putProblemById = (id, body, rol) => async (dispatch) => {
  try {
    //dispatch(fetchProblemsRequest());
    await api.help.putProblemById(id, body);
    toast.success("Problema actualizado correctamente", { autoClose: 2500, theme: "colored" });
    dispatch(listProblems(rol));
  } catch (error) {
    dispatch(fetchProblemsError({ error }));
    toast.error("Error al actualizar el problema", { autoClose: 2500, theme: "colored" });
    throw error;
  }
}

export const postNewProblem = (body, rol) => async (dispatch) => {
  try {
    //dispatch(fetchProblemsRequest());
    await api.help.postNewProblem(body);
    toast.success("Problema añadido correctamente", { autoClose: 2500, theme: "colored" });
    dispatch(listProblems(rol));
  } catch (error) {
    dispatch(fetchProblemsError({ error }));
    toast.error
  }
}


export const deleteProblem = (id, rol) => async (dispatch) => {
  try {
    //dispatch(fetchProblemsRequest());
    await api.help.deleteProblemById(id);
    toast.success("Problema eliminado correctamente", { autoClose: 2500, theme: "colored" });
    dispatch(listProblems(rol));
  } catch (error) {
    dispatch(fetchProblemsError({ error }));
    toast.error("Error al eliminar el problema", { autoClose: 2500, theme: "colored" });
  }
}
