import React from 'react';
import LogInForm from './components/LogInForm';
import { useDispatch } from 'react-redux';
import { logIn } from '../../../redux/actions/authActions';

const Logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const LogIn = () => {
  const dispatch = useDispatch();

  const handleSubmit = (props) => {
    dispatch(logIn(props))
  }

  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <img src={Logo} alt="avatar" style={{ marginBottom: '50px', height: '150px', width: '' }} />
          <LogInForm onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  )
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
