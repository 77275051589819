import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table, Input, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import StarIcon from 'mdi-react/StarIcon';
import SteeringIcon from 'mdi-react/SteeringIcon';
import moment from 'moment';
import { templateEmail } from '../../../shared/templates/email';
import EyeIcon from 'mdi-react/EyeIcon';
import TrashIcon from 'mdi-react/TrashIcon';

import { getUserById, putUserById, getReviewsByUser, deleteFilesUser } from '../../../redux/actions/usersAction';
import { sendEmail } from '../../../redux/actions/ProvidersActions';
import { number, string } from 'prop-types';

const Dashboard = ({ match }) => {
  const {
    params: { driverId },
  } = match;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.userById);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const isSendingEmail = useSelector((state) => state.provider.isSendingEmail);
  const isSendEmailError = useSelector((state) => state.provider.isSendEmailError);
  const userAuth = useSelector((state) => state.auth.dataUser);
  const [observations, setObservations] = useState('');

  useEffect(() => {
    dispatch(getUserById(driverId));
    dispatch(getReviewsByUser(driverId));
  }, []);

  const handleUserEnabled = () => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserById(user.id, body));
  };

  const sendNotification = () => {
    dispatch(
      sendEmail({
        from: 'Movilitix',
        to: user?.user?.email,
        subject: 'Nuevas observaciones',
        text: observations,
        html: templateEmail('Nuevas observaciones', observations),
      })
    );
  };

  const handleDeleteFile = (idUser, url, type) => {
    let body = {};
    switch (type) {
      case 1:
        body = {
          userImgUrl: url,
        };
        dispatch(deleteFilesUser(idUser, body));
        return true;
      case 2:
        body = {
          urlLicence: url,
        };
        dispatch(deleteFilesUser(idUser, body));
        return true;
      case 3:
        body = {
          basicServiceUrl: url,
        };
        dispatch(deleteFilesUser(idUser, body));
        return true;
      case 4:
        body = {
          urlAtn: url,
        };
        dispatch(deleteFilesUser(idUser, body));
        return true;
      case 5:
        body = {
          dniImgUrl: url,
        };
        dispatch(deleteFilesUser(idUser, body));
        return true;

      default:
        return true;
    }
  };

  useEffect(() => {
    if (!isSendingEmail) {
      if (isSendEmailError === null) setObservations('');
    }
  }, [isSendingEmail]);

  return (
    <Container className="userProfile">
      <Row className="d-flex justify-content-between flex-wrap mb-4">
        <div className="d-flex my-2">
          <SteeringIcon style={{ height: '40px', width: '50px', color: '#4B545A', marginRight: '13px', marginTop: '-7px' }} />
          <h3 className="page-title">Conductor</h3>
        </div>
        <div className="d-flex mx-4 my-2">
          <h5 style={{ marginRight: '10px', fontWeight: 600, color: '#4B545A', fontSize: '15px' }}>Habilitado</h5> <Switch disabled={userAuth.roleId !== 3} className="switch-user-data mr-3" checked={user?.status === 'accepted'} onChange={() => handleUserEnabled()} />
        </div>
      </Row>

      {!isFetchinguser && user ? (
        <>
          <Row className="contentUser">
            <div>
              <div className="leftColumn">
                <img src={user?.userImgUrl} alt="avatar" style={{ minWidth: '100px' }} />
                <div className="separator"></div>
                <div>
                  <div className="profileData">
                    <h6 className="company">Corporación Favorita</h6>
                    <h6 className="code">Código: {driverId}</h6>
                    <h6 className="name">
                      {user?.firstName} {user?.firstLastName}
                    </h6>
                    <h6 className="company">
                      Puntuación: {isFetchingReviews ? '' : userReviews?.average}
                      <StarIcon style={{ height: '16px', marginTop: '-3px', marginLeft: '-3px' }} color="#FFCE31" />
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '2%', maxWidth: '50px' }}></div>
          </Row>

          <Row>
            <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
              <Row className="mb-5">
                <Col lg={6} md={12} xs={12}>
                  <div className="table react-table">
                    <h3 className="page-title" style={{ fontSize: '18px', marginTop: '40px', marginBottom: '0px' }}>
                      Datos del <span className="text-danger">Conductor</span>
                    </h3>
                    <Table className="table-user title-red table-border-bottom">
                      <thead>
                        <tr>
                          <th>Documento</th>
                          <th>Fecha de subida</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="lh-1">
                            {user?.status !== 'accepted' && (
                              <button disabled={user?.userImgUrl && user?.userImgUrl.length > 4 ? false : true} className="table__action__icon" onClick={() => handleDeleteFile(driverId, user?.userImgUrl, 1)}>
                                <TrashIcon color={user?.userImgUrl && user?.userImgUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} />
                              </button>
                            )}
                            <a href={user?.userImgUrl && user?.userImgUrl.length > 4 && user?.userImgUrl} target="_blank">
                              <EyeIcon color={user?.userImgUrl && user?.userImgUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} className="mr-2" />
                            </a>
                            Fotografía{' '}
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            {user?.status !== 'accepted' && (
                              <button disabled={user?.urlLicence && user?.urlLicence.length > 4 ? false : true} className="table__action__icon" onClick={() => handleDeleteFile(driverId, user?.urlLicence, 2)}>
                                <TrashIcon color={user?.urlLicence && user?.urlLicence.length > 4 ? '#00E0FF' : '#C1C1C1'} />
                              </button>
                            )}
                            <a href={user?.urlLicence && user?.urlLicence.length > 4 && user?.urlLicence} target="_blank">
                              <EyeIcon color={user?.urlLicence && user?.urlLicence.length > 4 ? '#00E0FF' : '#C1C1C1'} className="mr-2" />
                            </a>
                            Licencia
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            {user?.status !== 'accepted' && (
                              <button disabled={user?.basicServiceUrl && user?.basicServiceUrl.length > 4 ? false : true} className="table__action__icon" onClick={() => handleDeleteFile(driverId, user?.basicServiceUrl, 3)}>
                                <TrashIcon color={user?.basicServiceUrl && user?.basicServiceUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} />
                              </button>
                            )}
                            <a href={user?.basicServiceUrl && user?.basicServiceUrl.length > 4 && user?.basicServiceUrl} target="_blank">
                              <EyeIcon color={user?.basicServiceUrl && user?.basicServiceUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} className="mr-2" />
                            </a>
                            Planilla de servicio básico
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            {user?.status !== 'accepted' && (
                              <button disabled={user?.urlAtn && user?.urlAtn.length > 4 ? false : true} className="table__action__icon" onClick={() => handleDeleteFile(driverId, user?.urlAtn, 4)}>
                                <TrashIcon color={user?.urlAtn && user?.urlAtn.length > 4 ? '#00E0FF' : '#C1C1C1'} />
                              </button>
                            )}
                            <a href={user?.urlAtn && user?.urlAtn.length > 4 && user?.urlAtn} target="_blank">
                              <EyeIcon color={user?.urlAtn && user?.urlAtn.length > 4 ? '#00E0FF' : '#C1C1C1'} className="mr-2" />
                            </a>
                            ANT
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            {user?.status !== 'accepted' && (
                              <button disabled={user?.dniImgUrl && user?.dniImgUrl.length > 4 ? false : true} className="table__action__icon" onClick={() => handleDeleteFile(driverId, user?.dniImgUrl, 5)}>
                                <TrashIcon color={user?.dniImgUrl && user?.dniImgUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} />
                              </button>
                            )}
                            <a href={user?.dniImgUrl && user?.dniImgUrl.length > 4 && user?.dniImgUrl} target="_blank">
                              <EyeIcon color={user?.dniImgUrl && user?.dniImgUrl.length > 4 ? '#00E0FF' : '#C1C1C1'} className="mr-2" />
                            </a>
                            Cédula
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <h3 style={{ fontSize: '18px', marginTop: '80px', marginBottom: '0px', fontWeight: 600 }} className="text-danger">
                    Observaciones
                  </h3>
                  <Row>
                    <Col lg={8}>
                      <Input type="textarea" style={{ height: '180px', fontSize: '14px', marginTop: '15px', backgroundColor: '#D6D8DB', color: '#5B5B5B' }} placeholder="Ingrese sus observaciones" id="Observations" value={observations} onChange={(e) => setObservations(e.target.value)} disabled={isSendingEmail} />
                    </Col>
                    <Col lg={3}>
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: '#EC1C24',
                          borderRadius: '9px',
                          marginRight: '20px',
                          fontWeight: 600,
                          fontSize: '15px',
                          color: 'white',
                          padding: '10px 20px',
                          marginTop: '70px',
                        }}
                        disabled={observations === '' || isSendingEmail}
                        onClick={sendNotification}
                      >
                        {isSendingEmail ? <Spinner color="white" className="table-fetch-spinner" style={{ margin: '2px 30px' }} /> : 'ENVIAR Notificación'}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </>
      ) : (
        <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
      )}
    </Container>
  );
};

export default Dashboard;
