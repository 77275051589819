import React, { useEffect, useMemo } from "react";
import EditIcon from "mdi-react/EditIcon";
import TrashIcon from "mdi-react/TrashIcon";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { deleteCity, listCities, postCity, putCityById } from "../../../redux/actions/citiesActions";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import TableBase from "./components/TableBase";
import { Form } from "./components/Form";
import { useFormik } from "formik";
import * as yup from "yup";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const validationSchema = yup.object({
  city: yup
    .string("Ingrese la Ciudad")
    .required("La ciudad es requerida"),
});


const index = () => {
  const { id, province } = useParams();
  const dispatch = useDispatch();
  const citiesList = useSelector((state) => state.cities.data);
  const isFetchingList = useSelector((state) => state.cities.isFetching);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Ciudades');
  }, [location]);

  const formSave = useFormik({
    initialValues: {
      city: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postCity(
          {
            nameCity: values.city,
            idProvince: id,
          },
          id
        )
      );
      formSave.resetForm();
    },
  });

  const formUpdate = useFormik({
    initialValues: {
      city: "",
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        putCityById(
          values.id,
          {
            nameCity: values.city,
            idProvince: id,
          },
          id
        )
      );
      formUpdate.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Ciudades",
        accessor: "cities",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.nameCity}</span>,
        disableFilters: true,
      },
      {
        Header: " ",
        accessor: " ",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (cellObj) => (
          <>
            <button
              className="table__action__icon"
              onClick={() => handleUpdateCity(cellObj.cell.row.original)}
            >
              <EditIcon color="#00E0FF" />
            </button>
            <button
              className="table__action__icon"
              onClick={() => handleDeleteCity(cellObj.cell.row.original.id)}
            >
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [citiesList]
  );

  const handleUpdateCity = (objectCity) => {
    formUpdate.setValues({
      city: objectCity.nameCity,
      id: objectCity.id,
    });
  };

  const handleDeleteCity = (idCity) => {
    dispatch(deleteCity(idCity, id));
  };


  useEffect(() => {
    dispatch(listCities(id));
  }, [id]);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={7} md={12}>
          <TitleSettings title={`Ciudades - ${province}`} />
          {!isFetchingList && citiesList ? (
            <TableBase
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: citiesList,
              }}
            />
          ) : (
            <Spinner
              className="table-fetch-spinner"
              style={{ marginLeft: "50%" }}
            />
          )}
        </Col>

        <Col lg={5} md={12}>
          <Row>
            <Col lg={12}>
              <Form
                title="Agregar"
                titleRed="Nueva Ciudad "
                titleButton="Agregar Ciudad"
                placeholderInput="Nombre de Ciudad"
                value={formSave.values.city}
                handleChange={formSave.handleChange}
                touched={formSave.touched.city}
                errors={formSave.errors.city}
                handleSubmit={formSave.handleSubmit}
              ></Form>
            </Col>
            <Col lg={12} style={{ marginTop: "20px" }}>
              <Form
                title="Editar"
                titleRed="Ciudad Ingresada"
                titleButton="Actualizar Ciudad"
                placeholderInput="Escoger una ciudad de la tabla"
                value={formUpdate.values.city}
                handleChange={formUpdate.handleChange}
                touched={formUpdate.touched.city}
                errors={formUpdate.errors.city}
                handleSubmit={formUpdate.handleSubmit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
