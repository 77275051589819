import { get, put, post, deleteRequest } from "../base/index";

export default {
  getQuestions: (roleId) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/questions/getquestionbyrol/${roleId}`
    );
  },
  putQuestionsById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/questions/${id}`, body);
  },
  postQuestion: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/questions`, body);
  },
  deleteQuestionById: (id) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/questions/${id}`);
  },
};
