import { handleActions } from "redux-actions";

import {
  fetchCitiesRequest,
  fetchCitiesSuccess,
  fetchCitiesError,
} from "../actions/citiesActions";

const defaultState = [];

export default handleActions(
  {
    [fetchCitiesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCitiesSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchCitiesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
