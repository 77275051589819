import { createAction } from "redux-actions";
import { toast } from "react-toastify";
import api from "../../utils/api/apiProvider";

export const fetchCitiesRequest = createAction("FETCH_CITIES_REQUEST");
export const fetchCitiesSuccess = createAction("FETCH_CITIES_SUCCESS");
export const fetchCitiesError = createAction("FETCH_CITIES_ERROR");

export const listCities = (idProvince) => async (dispatch) => {
  try {
    dispatch(fetchCitiesSuccess());
    const response = await api.cities.getCitiesByProvinceId(idProvince);
    dispatch(fetchCitiesSuccess(response.data.Cities));
  } catch (error) {
    dispatch(fetchCitiesError({ error }));
    toast.error("Error obteniendo el reporte de las ciudades", {
      autoClose: 2500,
      theme: "colored",
    });

    throw error;
  }
};

export const putCityById = (id, body, idProvince) => async (dispatch) => {
  try {
    dispatch(fetchCitiesRequest());
    await api.cities.putCityById(id, body);
    toast.success("Ciudad actualizada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listCities(idProvince));
  } catch (error) {
    dispatch(fetchCitiesError({ error }));
    toast.error("Error al actualizar la Ciudad", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postCity = (body, idProvince) => async (dispatch) => {
  try {
    dispatch(fetchCitiesRequest());
    await api.cities.postCity(body);
    toast.success("Ciudad añadida correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listCities(idProvince));
  } catch (error) {
    dispatch(fetchCitiesError({ error }));
    error.response.data.msg === 'City ya existente'?
    toast.error("La Ciudad ya existe", {
      autoClose: 2500,
      theme: "colored",
    }):  toast.error("Error al añadir la ciudad", {
      autoClose: 2500,
      theme: "colored",
    })
    throw error;
  }
};

export const deleteCity = (id, idProvince) => async (dispatch) => {
  try {
    dispatch(fetchCitiesRequest());
    await api.cities.deleteCity(id);
    toast.success("Ciudad eliminada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listCities(idProvince));
  } catch (error) {
    dispatch(fetchCitiesError({ error }));
    toast.error("Error al eliminar la ciudad", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};
