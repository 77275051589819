import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { deleteBrand, listBrands, postBrand, putBrandById } from '../../../redux/actions/brandAction';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { Form } from './components/Form';
import TableBase from './components/TableBase';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const validationSchema = yup.object({
  brand: yup.string('Ingrese la marca').required('La marca es requerida'),
});

const index = () => {
  const dispatch = useDispatch();
  const brandList = useSelector((state) => state.brands.data);
  const isFetchingBrandList = useSelector((state) => state.brands.isFetching);
  const [editBrand, setEditBrand] = useState('');

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Marcas', 1);
  }, [location]);

  const formSave = useFormik({
    initialValues: {
      brand: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postBrand({
          name: values.brand,
        })
      );
      formSave.resetForm();
    },
  });

  const formUpdate = useFormik({
    initialValues: {
      brand: '',
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editBrand !== values.brand) {
        dispatch(
          putBrandById(values.id, {
            name: values.brand,
          })
        );
        formUpdate.resetForm();
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Marca',
        accessor: 'Brand',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.name}</span>,
        disableFilters: true,
      },
      {
        Header: ' ',
        accessor: ' ',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Acciones',
        accessor: 'actions',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateBrand(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteBrand(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [brandList]
  );

  const handleDeleteBrand = (id) => {
    dispatch(deleteBrand(id));
  };

  const handleUpdateBrand = (objectBrand) => {
    setEditBrand(objectBrand.name);
    formUpdate.setValues({ id: objectBrand.id, brand: objectBrand.name });
  };

  useEffect(() => {
    dispatch(listBrands());
  }, []);

  return (
    <Container className="dashboard">
      <TitleSettings title="Marcas ingresadas" />
      <Row>
        <Col lg={5} md={12} className="mr-5">
          {!isFetchingBrandList && brandList ? (
            <>
              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: brandList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>
        <Col lg={6} md={12}>
          <Row>
            <Col lg={12}>
              <Form title="Autorización" titleRed="Ingreso de vehículos Marcas" titleButton="Guardar Marca" placeholderInput="Escribir Marca" value={formSave.values.brand} handleChange={formSave.handleChange} touched={formSave.touched.brand} errors={formSave.errors.brand} handleSubmit={formSave.handleSubmit} />
            </Col>
            <Col lg={12}>
              <Form title="Editar" titleRed="Marca Ingresada" titleButton="Actualizar Marca" placeholderInput="Escoger una marca de la tabla" value={formUpdate.values.brand} handleChange={formUpdate.handleChange} touched={formUpdate.touched.brand} errors={formUpdate.errors.brand} handleSubmit={formUpdate.handleSubmit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
