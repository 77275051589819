import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Button, Col, Row, Spinner, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import {
  listParameters,
  putParameters,
} from "../../../redux/actions/ParametersActions";
import { useLocation } from "react-router-dom";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const validationSchema = yup.object({
  flux: yup
    .number()
    .typeError("Este campo solo acepta numeros")
    .required("El numero flux es requerido"),
  cf: yup
    .number()
    .typeError("Este campo solo acepta numeros")
    .required("El numero CF es requerido"),
  emergency: yup
    .number()
    .typeError("Este campo solo acepta numeros")
    .required("El numero de emergencia es requerido"),
});

const index = () => {
  const parameters = useSelector((state) => state.parameters.data);
  const isFetchingParameters = useSelector(
    (state) => state.parameters.isFetching
  );

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Parámetros');
  }, [location]);

  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      flux: "",
      cf: "",
      emergency: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      

      const updateParameters = {
        Flux: `${values.flux}`,
        CF: `${values.cf}`,
        Emergency: `${values.emergency}`,
      };
      dispatch(putParameters(updateParameters));
    },
  });

  useEffect(() => {
    dispatch(listParameters());
  }, []);

  useEffect(() => {
    if (parameters) {
      form.setValues({
        flux: parameters.Flux,
        cf: parameters.CF,
        emergency: parameters.Emergency,
      });
    }
  }, [parameters]);

  return (
    <Container className="dashboard container">
      <TitleSettings title="Parámetros" />
      <h4 className="ml-2" style={{ fontWeight: "bold", fontSize: 20 }}>
        Ingrese números de teléfono para Ayuda app
      </h4>
      {!isFetchingParameters && parameters ? (
        <Row style={{ flexDirection: "column", paddingLeft: "20px" }}>
          <form onSubmit={form.handleSubmit}>
            <Col sm={12} lg={6} className="mt-4">
              <TextField
                fullWidth
                placeholder="Ingrese número de ayuda Flux"
                id="flux"
                name="flux"
                variant="outlined"
                type="text"
                className="inputFormOutCard"
                inputProps={{ style: { fontSize: "14px" } }}
                value={form.values.flux}
                onChange={form.handleChange}
                error={form.touched.flux && Boolean(form.errors.flux)}
                helperText={form.touched.flux && form.errors.flux}
              ></TextField>
            </Col>
            <Col sm={12} lg={6} className="mt-4">
              <TextField
                fullWidth
                placeholder="Ingrese número de llamada CF"
                id="cf"
                name="cf"
                variant="outlined"
                type="text"
                className="inputFormOutCard"
                inputProps={{ style: { fontSize: "14px" } }}
                value={form.values.cf}
                onChange={form.handleChange}
                error={form.touched.cf && Boolean(form.errors.cf)}
                helperText={form.touched.cf && form.errors.cf}
              ></TextField>
            </Col>

            <Col sm={12} lg={6} className="mt-4">
              <div className="TextField-without-border-radius">
                <TextField
                  fullWidth
                  placeholder="Ingrese número de llamada emergencia"
                  id="emergency"
                  name="emergency"
                  className="inputFormOutCard"
                  inputProps={{ style: { fontSize: "14px" } }}
                  variant="outlined"
                  type="text"
                  value={form.values.emergency}
                  onChange={form.handleChange}
                  error={
                    form.touched.emergency && Boolean(form.errors.emergency)
                  }
                  helperText={form.touched.emergency && form.errors.emergency}
                ></TextField>
              </div>
            </Col>

            <Col
              className="mt-4"
              sm={12}
              lg={6}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{""}</div>
              <Button color="danger" className=" py-2" type="submit">
                Actualizar
              </Button>
            </Col>
          </form>
        </Row>
      ) : (
        <Spinner
          className="table-fetch-spinner"
          style={{ marginLeft: "20%", marginTop: 40 }}
        />
      )}
    </Container>
  );
};

export default index;
