import React, { useMemo } from "react";
import { useEffect } from "react";
import EditIcon from "mdi-react/EditIcon";
import TrashIcon from "mdi-react/TrashIcon";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  listProvinces,
  deleteProvince,
  postProvince,
  putProvinceById,
} from "../../../redux/actions/provincesActions";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import TableBase from "./components/TableBase";
import { Form } from "./components/Form";
import Select from "react-select";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const validationSchema = yup.object({
  province: yup
    .string("Ingrese la provincia")
    .required("La provincia es requerida"),
});

const index = () => {
  const { id, zone } = useParams();
  const dispatch = useDispatch();
  const provinceList = useSelector((state) => state.provinces.data);
  const zoneList = useSelector((state) => state.zones.data);
  const isFetchingList = useSelector((state) => state.provinces.isFetching);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Provincias');
  }, [location]);

  const listZonesSelected = () => {
    return isFetchingList && zoneList.map((zone) => {
      return {
        value: zone.id,
        label: zone.nameRegion,
      };
    });
  };

  const formSave = useFormik({
    initialValues: {
      province: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postProvince(
          {
            nameProvince: values.province,
            idRegion: id,
          },
          id
        )
      );
      formSave.resetForm();
    },
  });

  const formUpdate = useFormik({
    initialValues: {
      province: "",
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        putProvinceById(
          values.id,
          {
            nameProvince: values.province,
            idRegion: id,
          },
          id
        )
      );
      formUpdate.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Provincias",
        accessor: "province",
        Cell: (cellObj) => (
          <span>{cellObj.cell.row.original.nameProvince}</span>
        ),
        disableFilters: true,
      },
      {
        Header: " ",
        accessor: " ",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Zona",
        accessor: "rate",
        Cell: (cellObj) => (
          <div style={{ width: 150, marginRight: 30 }}>
            <Select
              className="select__control"
              classNamePrefix="selectTable"
              name="Tarifa"
              placeholder={"Escoger zona"}
              color
              onChange={(e) => {
                dispatch(
                  putProvinceById(
                    cellObj.cell.row.original.id,
                    {
                      idRegion: e.value,
                    },
                    id
                  )
                );
              }}
              styles={{
                control: (styles, { isDisabled }) => ({
                  ...styles,
                  height: 30,
                  minHeight: 30,
                  backgroundColor: isDisabled
                    ? "#c7c7c7 !important"
                    : "#D6D8DB",
                  color: isDisabled ? "#e9e9ea !important" : "#D6D8DB",
                  cursor: isDisabled ? "not-allowed !important" : "auto",
                }),
              }}
              menuPosition={"fixed"}
              value={isFetchingList && listZonesSelected().filter(
                (zone) => zone.value === parseInt(id)
              )}
              options={listZonesSelected()}
            />
          </div>
        ),
        disableFilters: true,
      },

      {
        Header: "",
        accessor: "admin",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellObj) => (
          <Link
            to={`/settings/zones/cities/${cellObj.cell.row.original.id}/${cellObj.cell.row.original.nameProvince}`}
          >
            <Button
              color="danger"
              style={{
                marginBottom: "0px",
                padding: "5px 20px",
                marginRight: 30,
              }}
            >
              Administrar
            </Button>
          </Link>
        ),
      },

      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (cellObj) => (
          <>
            <button
              className="table__action__icon"
              onClick={() => handleUpdateProvince(cellObj.cell.row.original)}
            >
              <EditIcon color="#00E0FF" />
            </button>
            <button
              className="table__action__icon"
              onClick={() => handleDeleteProvince(cellObj.cell.row.original.id)}
            >
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [provinceList]
  );

  const handleDeleteProvince = (idProvince) => {
    dispatch(deleteProvince(idProvince, id));
  };

  const handleUpdateProvince = (objectProvince) => {
    formUpdate.setValues({
      province: objectProvince.nameProvince,
      id: objectProvince.id,
    });
  };

  useEffect(() => {
    dispatch(listProvinces(id));
  }, [id]);
  /* 
  useEffect(() => {
    dispatch(listZones());
  }, []); */

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={7} md={12}>
          <TitleSettings title={`Provincias - ${zone}`} />
          {!isFetchingList && provinceList ? (
            <TableBase
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: provinceList,
              }}
            />
          ) : (
            <Spinner
              className="table-fetch-spinner"
              style={{ marginLeft: "50%" }}
            />
          )}
        </Col>

        <Col lg={5} md={12}>
          <Row>
            <Col lg={12}>
              <Form
                title="Agregar"
                titleRed="Nueva Provincia "
                titleButton="Agregar Provincia"
                placeholderInput="Nombre de Provincia"
                value={formSave.values.province}
                handleChange={formSave.handleChange}
                touched={formSave.touched.province}
                errors={formSave.errors.province}
                handleSubmit={formSave.handleSubmit}
              ></Form>
            </Col>
            <Col lg={12} style={{ marginTop: "20px" }}>
              <Form
                title="Editar"
                titleRed="Provincia Ingresada"
                titleButton="Actualizar Provincia"
                placeholderInput="Escoger una provincia de la tabla"
                value={formUpdate.values.province}
                handleChange={formUpdate.handleChange}
                touched={formUpdate.touched.province}
                errors={formUpdate.errors.province}
                handleSubmit={formUpdate.handleSubmit}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
