import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import { FilterMatchMode } from 'primereact/api';
import { PaginatorTemplateOptions } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';

// interface ColumnTable {
//     field: string;
//     header: string;
//     sortable?: boolean;
//     filter?: boolean;
// }

// interface Props {
//     data: any[];
//     columns: ColumnTable[];
//     emptyMessage?: string;
//     selectabled?: boolean;
//     filtered?: boolean;
//     multiSelectColumns?: boolean;
// }

export const DataTableCustom = ({ data, columns, emptyMessage, filtered, selectabled, multiSelectColumns, actionsHeaders, scrollHeight }) => {
  // PAGINATION

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [globalFilterValue1, setGlobalFilterValue1] = useState('');
  const [globalFilterFields, setGlobalFilterFields] = useState([]);

  const onCustomPage1 = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    setCurrentPage(event.page + 1);
  };

  const template2 = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Registros por página:{' '}
          </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };
  // COLTOGGLE

  const [selectedColumns, setSelectedColumns] = useState(columns);

  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
    setSelectedColumns(orderedSelectedColumns);
  };

  const columnComponents = selectedColumns.map((col) => {
    return (
      <Column
        //
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        filter={filtered ? col.filter : false}
        filterPlaceholder={`Buscar por ${col.header}`}
        style={{ ...col.style }}
      />
    );
  });

  // SELECTION MULTI SELECT ROW
  const [selectedRow, setSelectedRow] = useState(null);

  // FILTER

  const [filters, setFilters] = useState();

  const renderHeader1 = () => {
    return (
      <div className={actionsHeaders ? "d-flex justify-content-between": "d-flex justify-content-end"}>
        {actionsHeaders}
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar" />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue1(value);
  };

  const header1 = renderHeader1();

  useEffect(() => {
    const obj = new Object();
    obj['global']= { value: null, matchMode: FilterMatchMode.CONTAINS };
    const globalTmp = [];

    columns
      .filter((col) => col.filter)
      .map((col) => {
        const prop = col.field;
        globalTmp.push(prop);
        obj[prop] = { value: null, matchMode: FilterMatchMode.CONTAINS };
      });
    setFilters(obj);
    setGlobalFilterFields(globalTmp);
  }, []);

  return (
    <div style={{backgroundColor: 'white', padding:15, borderRadius:8}}>
      {filtered ? (
        <DataTable
          //
          value={data}
          responsiveLayout="scroll"
          paginator
          paginatorTemplate={template2}
          first={first}
          rows={rows}
          onPage={onCustomPage1}
          paginatorClassName="justify-content-end"
          stripedRows
        //   selectionMode="checkbox"
        //   selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey={columns[0].field}
          emptyMessage={emptyMessage ? emptyMessage : 'No existe registros'}
          filters={filters}
          filterDisplay="menu"
          header={header1}
          scrollable
          scrollHeight={scrollHeight}
          globalFilterFields={globalFilterFields}
        >
          {/* {selectabled && (
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}>
              <input type="checkbox"></input>
            </Column>
          )} */}
          {columnComponents}
        </DataTable>
      ) : (
        <DataTable
          //
          value={data}
          responsiveLayout="scroll"
          paginator
          paginatorTemplate={template2}
          first={first}
          rows={rows}
          onPage={onCustomPage1}
          paginatorClassName="justify-content-end"
          stripedRows
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey={columns[0].field}
          emptyMessage={emptyMessage ? emptyMessage : 'No existe registros'}
          scrollable
          scrollHeight={scrollHeight}
          header={header1}
        >
          {columnComponents}
        </DataTable>
      )}
    </div>
  );
};
