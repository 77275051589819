import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reducer as reduxFormReducer } from "redux-form";
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  authReducer,
  userReducer,
  dashboardReducer,
  locationReducer,
  ProviderReducer,
  helpAppReducer,
  FaqReducer,
  ParametersReducer,
  colorsReducer,
  modelsReducer,
  provincesReducer,
  citiesReducer,
  ratesReducer, 
  brandReducer, 
  zoneReducer,
  zonesFavoritaReducer,
  breadcrumsReducer
} from "../../redux/reducers/index";


const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  users: userReducer,
  problems: helpAppReducer,
  questions: FaqReducer,
  dashboard: dashboardReducer,
  location: locationReducer,
  provider: ProviderReducer,
  colors: colorsReducer,
  models: modelsReducer,
  zones: zoneReducer,
  provinces: provincesReducer,
  cities: citiesReducer,
  rates:ratesReducer, 
  parameters:ParametersReducer,
  brands:brandReducer,
  zonesFavorita:zonesFavoritaReducer,
  breadcrums:breadcrumsReducer,
});

const composeEnhancers = process.env.NODE_ENV === "development" ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
