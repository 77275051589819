import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import * as yup from "yup";
import { useFormik } from "formik";
import { TextField } from "@material-ui/core";
import {
  detailRate,
  postDetailRate,
  putDetailRateById,
} from "../../../redux/actions/ratesActions";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object({
  baseRate: yup
    .number()
    .required("El valor de tarifa base es requerido.")
    .test(
      'Is positive?', 
      'ERROR: El numero debe ser mayor o igual a 0', 
      (value) => value >= 0
    ),
  valueKm: yup
    .number()
    .required("El valor por kilometro es requerido.")
    .test(
      'Is positive?', 
      'ERROR: El numero debe ser mayor o igual a 0', 
      (value) => value >= 0
    ),
  valueMin: yup
    .number()
    .required("El valor por minuto es requerido.")
    .test(
      'Is positive?', 
      'ERROR: El numero debe ser mayor o igual a 0', 
      (value) => value >= 0
    ),
  applyFrom: yup.number().required("Aplica desde es requerido.")
  .test(
    'Is positive?', 
    'ERROR: El numero debe ser mayor o igual a 0', 
    (value) => value >= 0
  ),
  fromDate: yup.number().required("Este campo es requerido.")
  .test(
    'Is positive?', 
    'ERROR: El numero debe ser mayor o igual a 0', 
    (value) => value >= 0
  ),
  untilDate: yup.number().positive().required("Este campo es requerido.")
  .test(
    'Is positive?', 
    'ERROR: El numero debe ser mayor o igual a 0', 
    (value) => value >= 0
  ),
  extra: yup
    .number()
    .required("El valor extra de la tarifa es requerido.")
    .test(
      'Is positive?', 
      'ERROR: El numero debe ser mayor o igual a 0', 
      (value) => value >= 0
    ),
});

const index = () => {
  const { idRate, rate, idDetailRate } = useParams();

  const detailRateOnly = useSelector((state) => state.rates.rateDetailOnlyData);
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      baseRate: "",
      valueKm: "",
      valueMin: "",
      extra: "",
      applyFrom: "",
      fromDate: "",
      untilDate: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (idDetailRate === "none") {
        dispatch(
          postDetailRate({
            idRate: idRate,
            baseRate: values.baseRate,
            valueKm: values.valueKm,
            valueMin: values.valueMin,
            applyFrom: values.applyFrom,
            fromDate: values.fromDate,
            untilDate: values.untilDate,
            extra: values.extra,
          })
        );
        form.resetForm();
      } else {
        if (values.baseRate !== detailRateOnly.baseRate || values.valueKm !== detailRateOnly.valueKm || values.valueMin !== detailRateOnly.valueMin || values.applyFrom !== detailRateOnly.applyFrom || values.fromDate !== detailRateOnly.fromDate || values.untilDate !== detailRateOnly.untilDate || values.extra !== detailRateOnly.extra) {
          dispatch(
            putDetailRateById(idDetailRate, {
              idRate: idRate,
              baseRate: values.baseRate,
              valueKm: values.valueKm,
              valueMin: values.valueMin,
              applyFrom: values.applyFrom,
              fromDate: values.fromDate,
              untilDate: values.untilDate,
              extra: values.extra,
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    if (idDetailRate) {
      dispatch(detailRate(idDetailRate));
    }
  }, [idDetailRate]);

  useEffect(() => {
    if (detailRateOnly) {
      form.setValues({
        baseRate: detailRateOnly.baseRate,
        valueKm: detailRateOnly.valueKm,
        valueMin: detailRateOnly.valueMin,
        extra: detailRateOnly.extra,
        applyFrom: detailRateOnly.applyFrom,
        fromDate: detailRateOnly.fromDate,
        untilDate: detailRateOnly.untilDate,
      });
    }
  }, [detailRateOnly]);

  useEffect(() => {
    form.setValues({
      baseRate: "",
      valueKm: "",
      valueMin: "",
      extra: "",
      applyFrom: "",
      fromDate: "",
      untilDate: "",
    });
  }, []);

  return (
    <Container className="dashboard">
      {idDetailRate === "none" ? (
        <TitleSettings title={`Nuevo detalle de ${rate}`} />
      ) : (
        <TitleSettings title={`Actualizar detalle de ${rate}`} />
      )}

      <Row style={{ flexDirection: "column" }}>
        <form onSubmit={form.handleSubmit}>
          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 75,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Tarifa Base:
            </p>
            <TextField
              fullWidth
              id="baseRate"
              name="baseRate"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 300 }}
              className="inputFormOutCard"
              value={form.values.baseRate}
              onChange={form.handleChange}
              error={form.touched.baseRate && Boolean(form.errors.baseRate)}
              helperText={form.touched.baseRate && form.errors.baseRate}
            ></TextField>
          </Col>

          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 60,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Valor por KM:
            </p>
            <TextField
              fullWidth
              id="valueKm"
              name="valueKm"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 300 }}
              className="inputFormOutCard"
              value={form.values.valueKm}
              onChange={form.handleChange}
              error={form.touched.valueKm && Boolean(form.errors.valueKm)}
              helperText={form.touched.valueKm && form.errors.valueKm}
            ></TextField>
          </Col>

          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 22,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Valor por minuto:
            </p>
            <TextField
              fullWidth
              id="valueMin"
              name="valueMin"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 300 }}
              className="inputFormOutCard"
              value={form.values.valueMin}
              onChange={form.handleChange}
              error={form.touched.valueMin && Boolean(form.errors.valueMin)}
              helperText={form.touched.valueMin && form.errors.valueMin}
            ></TextField>
          </Col>

          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 128,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Extra:
            </p>
            <TextField
              fullWidth
              id="extra"
              name="extra"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 300 }}
              className="inputFormOutCard"
              value={form.values.extra}
              onChange={form.handleChange}
              error={form.touched.extra && Boolean(form.errors.extra)}
              helperText={form.touched.extra && form.errors.extra}
            ></TextField>
          </Col>

          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 52,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Aplica desde:
            </p>
            <TextField
              fullWidth
              id="applyFrom"
              name="applyFrom"
              variant="outlined"
              type="number"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 300 }}
              className="inputFormOutCard"
              value={form.values.applyFrom}
              onChange={form.handleChange}
              error={form.touched.applyFrom && Boolean(form.errors.applyFrom)}
              helperText={form.touched.applyFrom && form.errors.applyFrom}
            ></TextField>
          </Col>

          <Col
            sm={12}
            lg={6}
            className="mt-3"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: 48,
                color: "#4B545A",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Rango desde:
            </p>
            <TextField
              fullWidth
              id="fromDate"
              name="fromDate"
              variant="outlined"
              type="number"
              placeholder="Desde"
              InputProps={{ inputProps: { min: 0, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 130 }}
              className="inputFormOutCard"
              value={form.values.fromDate}
              onChange={form.handleChange}
              error={form.touched.fromDate && Boolean(form.errors.fromDate)}
              helperText={form.touched.fromDate && form.errors.fromDate}
            ></TextField>

            <TextField
              fullWidth
              id="untilDate"
              name="untilDate"
              variant="outlined"
              type="number"
              placeholder="Hasta"
              InputProps={{ inputProps: { min: parseFloat(form.values.fromDate) + 1, step: "0.01" }, style: { fontSize: "14px" } }}
              style={{ width: 130, marginLeft: 40 }}
              className="inputFormOutCard"
              value={form.values.untilDate}
              onChange={form.handleChange}
              error={form.touched.untilDate && Boolean(form.errors.untilDate)}
              helperText={form.touched.untilDate && form.errors.untilDate}
            ></TextField>
          </Col>

          <Col className="mt-5" sm={12} lg={6}>
            <div>{""}</div>
            <Button
              style={{ marginLeft: "310px" }}
              color="danger"
              className=" py-2"
              type="submit"
            >
              {idDetailRate === "none"
                ? "Guardar y Enviar"
                : "Actualizar y Enviar"}
            </Button>
          </Col>
        </form>
      </Row>
    </Container>
  );
};

export default index;
