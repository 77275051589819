import TuneIcon from "mdi-react/TuneIcon";
import React from "react";
import { Col, Row } from "reactstrap";

export const TitleSettings = ({title}) => {
  return (
    <Row className="mb-4">
      <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
        <TuneIcon
          style={{
            height: "40px",
            width: "50px",
            color: "#4B545A",
            marginRight: "10px",
            marginTop: "-5px",
          }}
        />
        <h3 className="page-title">
          {" "}
          Ajuste: <span className="text-danger">{title} </span>
        </h3>
      </Col>
    </Row>
  );
};
