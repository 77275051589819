import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button, Spinner, Card, CardBody, FormGroup } from 'reactstrap';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import Select from 'react-select';
import TableBase from './components/TableBase';
import { Radio, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { deleteProblem, listProblems, postNewProblem, putProblemById } from '../../../redux/actions/helpAppActions';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { Link, useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const validationSchema = yup.object({
  problem: yup.string('Ingrese el problema').min(7, 'El problema debe tener un minimo de 7 caracteres').required('El problema es requerido'),
});

const Dashboard = () => {
  const dispatch = useDispatch();
  const problemList = useSelector((state) => state.problems.data);
  const usersRoles = useSelector((state) => state.problems.roles);
  const [updateForm, setUpdateForm] = useState('new');
  const [rol, setRol] = useState(5);
  const [editQuestion, setEditQuestion] = useState('');
  const isFetchingProblemList = useSelector((state) => state.problems.isFetching);

  const formUpdate = useFormik({
    initialValues: {
      problem: '',
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editQuestion !== values.problem) {
        dispatch(putProblemById(values.id, { problem: values.problem }, rol));
        setUpdateForm('new');
        formUpdate.resetForm();
      }
    },
  });

  const formSave = useFormik({
    initialValues: {
      problem: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const newProblem = {
        Flux: false,
        problem: values.problem,
        CF: false,
        roleId: rol,
        Emergency: false,
      };
      dispatch(postNewProblem(newProblem, rol));
      formSave.resetForm();
    },
  });

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reportar problemas', 1);
  }, [location]);

  const columns = useMemo(
    () => [
      {
        Header: 'Título',
        accessor: (data) => `${data.id}`,
        Cell: (cellObj) => <span>{cellObj.cell.row.original.problem}</span>,
        disableFilters: true,
      },
      {
        Header: 'Envio FLUX',
        accessor: 'Envio',
        disableFilters: true,
        Cell: (cellObj) => <Radio className="radio-table" checked={cellObj.cell.row.original.Flux} onClick={() => handleDataRow(cellObj.cell.row.original.id, !cellObj.cell.row.original.Flux, 'Flux')} />,
      },
      {
        Header: 'Llamada CF',
        accessor: 'Llamada',
        disableFilters: true,
        Cell: (cellObj) => <Radio className="radio-table" checked={cellObj.cell.row.original.CF} onClick={() => handleDataRow(cellObj.cell.row.original.id, !cellObj.cell.row.original.CF, 'CF')} />,
      },
      {
        Header: '911',
        accessor: '911',
        disableFilters: true,
        Cell: (cellObj) => <Radio className="radio-table" checked={cellObj.cell.row.original.Emergency} onClick={() => handleDataRow(cellObj.cell.row.original.id, !cellObj.cell.row.original.Emergency, 'Emergency')} />,
      },
      {
        Header: 'Acciones',
        accessor: '[editButton]',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateDataProblems(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteProblem(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [problemList]
  );

  const userRolesOptions = () =>
    usersRoles.map((rol) => ({
      value: rol.id,
      label: rol.id === 2 ? 'Colaborador' : 'Conductor',
    }));

  const handleDataRow = (id_data, status, field) => {
    dispatch(
      putProblemById(
        id_data,
        {
          [field]: status,
        },
        rol
      )
    );
  };

  const handleDeleteProblem = (id) => {
    dispatch(deleteProblem(id, rol));
  };

  const handleUpdateDataProblems = (problemObject) => {
    setUpdateForm('update');
    setEditQuestion(problemObject.problem);
    formUpdate.setValues({
      problem: problemObject.problem,
      id: problemObject.id,
    });
  };

  const userRole = () =>
    usersRoles.map((rolValue) => {
      if (rolValue.id === rol)
        return {
          value: rolValue.id,
          label: rolValue.id === 2 ? 'Colaborador' : 'Conductor',
        };
    });

  useEffect(() => {
    dispatch(listProblems(rol));
  }, [rol]);

  return (
    <Container className="dashboard pb-5">
      <Row>
        <Col lg={6} md={8}>
          <TitleSettings title="Reportar Problemas" />
        </Col>
        <Col lg={3} md={4}>
          {!isFetchingProblemList && problemList && <Select className="basic-single" classNamePrefix="selectDataUsers" name="Roles de usuario" placeholder={'Roles de usuario'} onChange={(e) => setRol(e.value)} options={userRolesOptions()} value={userRole()} menuPosition={'fixed'} />}
        </Col>
      </Row>

      <Row className="mt-4 p-0 m-0">
        <Col lg={8} md={12}>
          {!isFetchingProblemList && problemList ? (
            <>
              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: problemList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>

        <Col lg={4} md={12} style={{ marginTop: '50px' }}>
          <Container>
            <Row>
              {updateForm === 'new' && (
                <Col lg={12}>
                  <Card>
                    <CardBody style={{ borderRadius: '13px', border: '1px solid #C4C4C4' }}>
                      <form onSubmit={formSave.handleSubmit} name="f1">
                        <FormGroup row>
                          <Col sm={12}>
                            <TextField fullWidth id="problem" name="problem" variant="outlined" type="text" placeholder="Escribir Problema" value={formSave.values.problem} onChange={formSave.handleChange} error={formSave.touched.problem && Boolean(formSave.errors.problem)} helperText={formSave.touched.problem && formSave.errors.problem} className="inputForm" inputProps={{ style: { fontSize: '14px' } }} />
                          </Col>
                        </FormGroup>
                        <Row>
                          <Col
                            sm={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button color="danger" className="mx-auto py-2" type="submit">
                              Guardar Problema
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {updateForm === 'update' && (
                <Col>
                  <Card>
                    <CardBody>
                      <form onSubmit={formUpdate.handleSubmit} name="f2">
                        <FormGroup row>
                          <Col sm={12}>
                            <TextField fullWidth id="problem" name="problem" variant="outlined" type="text" placeholder="Escoja un problema de la tabla" value={formUpdate.values.problem} onChange={formUpdate.handleChange} inputProps={{ style: { fontSize: '14px' } }} className="inputForm" error={formUpdate.touched.problem && Boolean(formUpdate.errors.problem)} helperText={formUpdate.touched.problem && formUpdate.errors.problem} />
                          </Col>
                        </FormGroup>

                        <Row>
                          <Col
                            sm={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button color="danger" className="mx-auto py-2" type="submit">
                              Actualizar Problema
                            </Button>
                          </Col>
                        </Row>
                      </form>
                      <Col
                        sm={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <Button color="danger" onClick={() => setUpdateForm('new')} className="mx-auto py-2" style={{ width: '190px' }}>
                          Nuevo problema
                        </Button>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <div>
          <Link to="/settings/parameters">
            <Button color="danger" className=" ml-4 py-2">
              Parámetros
            </Button>
          </Link>
        </div>
      </Row>
    </Container>
  );
};

export default Dashboard;
