import { createAction } from "redux-actions";
import { toast } from "react-toastify";
import api from "../../utils/api/apiProvider";

export const fetchColorsRequest = createAction("FETCH_COLORS_REQUEST");
export const fetchColorsSuccess = createAction("FETCH_COLORS_SUCCESS");
export const fetchColorsError = createAction("FETCH_COLORS_ERROR");

export const listColors = () => async (dispatch) => {
  try {
    dispatch(fetchColorsRequest());
    const response = await api.colors.getColors();
    dispatch(fetchColorsSuccess(response.data.filter(color=> color.status === 1)));
  } catch (error) {
    dispatch(fetchColorsSuccess({ error }));
    toast.error("Error obteniendo el reporte de los colores", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putColorById = (id, body) => async (dispatch) => {
  try {
    dispatch(fetchColorsRequest());
    await api.colors.putColorById(id, body);
    toast.success("Color actualizado correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listColors());
  } catch (error) {
    dispatch(fetchColorsError({ error }));
    toast.error("Error al actualizar el color", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postColor = (body) => async (dispatch) => {
  try {
    dispatch(fetchColorsRequest());
    await api.colors.postColor(body);
    toast.success("Color añadido correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listColors());
  } catch (error) {
    dispatch(fetchColorsError({ error }));
    toast.error("Error al añadir el color", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};


export const deleteColor= (id) => async (dispatch) => {
    try {
      dispatch(fetchColorsRequest());
      await api.colors.deleteColorById(id);
      toast.success("Color eliminado correctamente", {
        autoClose: 2500,
        theme: "colored",
      });
      dispatch(listColors());
    } catch (error) {
      dispatch(fetchColorsError({ error }));
      toast.error("Error al eliminar el color", {
        autoClose: 2500,
        theme: "colored",
      });
    }
  };
