import React from 'react';
import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Dialog = ({ title, description, open, setOpen, acceptClick }) => {
  const handleDelete = () => {
    setOpen(!open);
    acceptClick();
  };
  return (
    <div>
      <Modal isOpen={open} toggle={open}>
        <ModalHeader toggle={() => setOpen(!open)}>{title}</ModalHeader>
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            Aceptar
          </Button>{' '}
          <Button color="secondary" onClick={() => setOpen(!open)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Dialog;
