import { handleActions } from 'redux-actions';
import {
    fetchAuthError,
    fetchAuthRequest,
    fetchLoginSuccess,
    fetchlogOut,
    fetchRenovationTokenError,
    fetchRenovationToken,
    fetchRenovationTokenSuccess,
    fetchUserPrivilegesByIdSucess,
    stopFetchLog
} from '../actions/authActions';

const defaultState = {
    isAuth: false,
    isFetching: true,
    isFetchingToken: true,
    dataUser: null,
    privileges: null,
    error: null,
};

export default handleActions(
    {
        [fetchAuthRequest](state) {
            return {
                ...state,
                isFetching: true,
            };
        },
        [stopFetchLog](state) {
            return {
                ...state,
                isFetching: false,
                isFetchingToken: false,
            };
        },
        [fetchLoginSuccess](state, { payload }) {
            return {
                isAuth: true,
                isFetching: false,
                isFetchingToken: false,
                dataUser: payload,
                error: null,
            };
        },
        [fetchUserPrivilegesByIdSucess](state, { payload }) {
            return {
                ...state,
                privileges: payload
            };
        },
        [fetchlogOut]() {
            return {
                isAuth: false,
                isFetching: false,
                error: null,
            }
        },
        [fetchAuthError](state, { payload }) {
            return {
                isAuth: false,
                isFetching: false,
                error: payload,
            };
        },
        [fetchRenovationToken](state) {
            return {
                ...state,
                isFetchingToken: true,
            };
        },
        [fetchRenovationTokenSuccess](state, { payload }) {
            return {
                isAuth: true,
                isFetching: false,
                isFetchingToken: false,
                dataUser: payload,
                error: null,
            }
        },
        [fetchRenovationTokenError](state) {
            return {
                ...state,
                isAuth: false,
                isFetching: false,
                isFetchingToken: false
            };
        }
    },
    defaultState,
);