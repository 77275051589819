import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Spinner } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import CurrencyUsdCircleOutlineIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import CarIcon from 'mdi-react/CarHatchbackIcon';
import Switch from '@material-ui/core/Switch';
import StarIcon from 'mdi-react/StarIcon';
import MapMarkerDistanceIcon from 'mdi-react/MapMarkerDistanceIcon';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { listDriverCost, listDriverKm, listDriverTravels, getVehicleByUserId, getCurrentVehicleByUserId, getProviderById } from '../../../redux/actions/ProvidersActions';
import { getUserById, putUserById, getReviewsByUser } from '../../../redux/actions/usersAction';
import moment from 'moment';
import SteeringIcon from 'mdi-react/SteeringIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const DriversFactsFigures = ({ match }) => {
  const {
    params: { driverId, idBoss },
  } = match;

  const dispatch = useDispatch();

  const user = useSelector((state) => {
    if (state.users.userById === null) return null;
    return {
      ...state.users.userById,
      city: state.users.userById.city !== null && state.users.userById.city.nameCity,
    };
  });

  const provider = useSelector((state) => {
    return state.provider.providerById === null
      ? null
      : {
          ...state.provider.providerById,
          city: state.provider.providerById.city !== null && state.provider.providerById.city.nameCity,
        };
  });

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  const isFetchingProvider = useSelector((state) => state.provider.isFetchingProviderById);
  const isFetchinguser = useSelector((state) => state.users.isFetching);

  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const costsLists = useSelector((state) => state.provider.cost);
  const kmLists = useSelector((state) => state.provider.km);
  const travelsLists = useSelector((state) => state.provider.travels);
  const isFetchingVehicleByUserId = useSelector((state) => state.provider.isFetchingVehicleByUserId);
  const isCurrentFetchingVehicleByUserId = useSelector((state) => state.provider.isCurrentFetchingVehicleByUserId);
  const vehicleByUserId = useSelector((state) => state.provider.vehicleByUserId);

  const currentVehicleByUserId = useSelector((state) => state.provider.currentVehicleUser);

  const userAuth = useSelector((state) => state.auth.dataUser);

  useEffect(() => {
    dispatch(getUserById(driverId));
    dispatch(getProviderById(idBoss));
    dispatch(getReviewsByUser(driverId));
    dispatch(getVehicleByUserId(driverId));
    dispatch(getCurrentVehicleByUserId(driverId));
    dispatch(listDriverCost(driverId, moment().format('YYYY')));
    dispatch(listDriverKm(driverId, moment().format('YYYY')));
    dispatch(listDriverTravels(driverId, moment().format('YYYY')));
  }, []);

  const handleUserEnabled = () => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserById(user.id, body));
  };

  const calculateNumbers = (param) => {
    let data = [];

    if (param == 'generalCost') data = costsLists;
    if (param == 'generalKm') data = kmLists;
    if (param == 'generalTravels') data = travelsLists;

    let sum = 0;
    data.map((month) => (sum = sum + month.Total));
    if (param !== 'generalTravels') sum = sum.toFixed(2);
    return sum;
  };

  useEffect(() => {
    isFetchinguser && pushNewItemBreadCrums(`Conductor`);
  }, [isFetchinguser, location]);

  return (
    <Container className="userProfile">
      <Row>
        <Col md={12} className="title">
          <SteeringIcon
            style={{
              height: '40px',
              width: '50px',
              color: '#4B545A',
              marginRight: '13px',
              marginTop: '-7px',
              marginLeft: '-15px',
            }}
          />
          <h3 className="page-title">Conductor</h3>
        </Col>
      </Row>
      <Row className="contentUser">
        {isFetchinguser ? (
          <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
        ) : (
          <>
            <Col lg={4} md={12} xs={12}>
              <div className="leftColumn">
                <img
                  onError={(e) => {
                    e.currentTarget.src = `${process.env.PUBLIC_URL}/img/avatar.png`;
                  }}
                  src={user?.userImgUrl}
                  alt="test"
                  style={{ minWidth: '100px' }}
                />
                <div className="separator"></div>
                <div>
                  <div className="profileData">
                    <h6 className="company">Corporación Favorita</h6>
                    <h6 className="code">Código: {driverId}</h6>
                    <h6 className="name">
                      {user?.firstName} {user?.firstLastName}
                    </h6>
                    <h6 className="company">
                      Puntuación: {isFetchingReviews ? '' : userReviews?.average}
                      <StarIcon
                        style={{
                          height: '16px',
                          marginTop: '-3px',
                          marginLeft: '-3px',
                        }}
                        color="#FFCE31"
                      />
                    </h6>
                  </div>
                  <div className="buttons" style={{ marginTop: '15px' }}>
                    <Link to={`/drivers/driverGeneralReport/${driverId}`} className="link">
                      Ver reporte
                    </Link>
                    <Link to={`/drivers/driverPersonalData/${driverId}`} className="link">
                      Ver perfil
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dataUser">
                <div className="header">
                  <h3
                    style={{
                      fontSize: '14px',
                      color: '#1A0C12',
                      fontWeight: 600,
                    }}
                  >
                    Datos del <span className="text-danger">Conductor</span>
                  </h3>
                </div>
                <div className="item">
                  <div className="cellHeader">
                    <div className="cell">
                      <h6>Correo Electrónico: </h6>
                      <h6 style={{ paddingBottom: '2px' }}>{user?.email}</h6>
                    </div>
                    <div className="cell">
                      <h6>Teléfono: </h6>
                      <h6>{user?.phoneNumber}</h6>
                    </div>
                    {!isFetchingProvider && provider && (
                      <div className="cell" style={{ alignItems: 'center' }}>
                        <h6>Proveedor: </h6>
                        <NavLink to={`/providerPersonalData/${idBoss}`}>
                          <h6>{`${provider.firstName} ${provider.firstLastName}`}</h6>
                        </NavLink>
                        <NavLink to={`/providerPersonalData/${idBoss}`}>
                          {/* <IconButton> */}
                          <EyeOutlineIcon color="black" />
                          {/* </IconButton> */}
                        </NavLink>
                      </div>
                    )}

                    <div className="cell">
                      <h6>Ciudad: </h6>
                      <h6>{user?.city}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8} md={12} xs={12}>
              <div className="rightColumn">
                <div className="numbers my-2">
                  <h3 style={{ fontSize: '14px', color: '#1A0C12', fontWeight: 600 }}>
                    {' '}
                    Cifras <span className="text-danger">{moment().format('YYYY')}</span>
                  </h3>

                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="number mx-2 my-2">
                      <h6 className="numbersHeader">
                        <MapMarkerOutlineIcon className="iconHeader"></MapMarkerOutlineIcon> Traslados
                      </h6>
                      <h6>{travelsLists && travelsLists !== null ? calculateNumbers('generalTravels') : ''} Viajes</h6>
                    </div>
                    <div className="number mx-2 my-2">
                      <h6 className="numbersHeader">
                        <MapMarkerDistanceIcon className="iconHeader"></MapMarkerDistanceIcon> Kilometros
                      </h6>
                      <h6>{kmLists && kmLists !== null ? calculateNumbers('generalKm') : ''} km</h6>
                    </div>
                    <div className="number mx-2 my-2">
                      <h6 className="numbersHeader">
                        <CurrencyUsdCircleOutlineIcon className="iconHeader"></CurrencyUsdCircleOutlineIcon> Costos
                      </h6>
                      <h6>$ {costsLists && costsLists !== null ? calculateNumbers('generalCost') : ''}</h6>
                    </div>
                    <div className="number mx-2 my-2">
                      <h6 className="numbersHeader" style={{ marginBottom: '5px' }}>
                        <AccountOutlineIcon className="iconHeader"></AccountOutlineIcon>
                        Habilitado
                      </h6>
                      <Switch disabled={userAuth.roleId !== 3} checked={user?.status === 'accepted'} onChange={() => handleUserEnabled()} />
                    </div>
                  </div>
                </div>
                <div className="observations">
                  <h3
                    style={{
                      fontSize: '14px',
                      color: '#1A0C12',
                      fontWeight: 600,
                      padding: '0 15px',
                    }}
                  >
                    {' '}
                    Vehículos{' '}
                  </h3>
                  <div className="bodyReport">
                    <div className="header">
                      <h6 style={{ width: '10%' }}></h6>
                      <h6 style={{ width: '25%' }}>Modelo</h6>
                      <h6 style={{ width: '20%' }}>Placa</h6>
                      <h6 style={{ width: '25%' }}>Color</h6>
                      <h6 style={{ width: '20%' }}>Tipo</h6>
                    </div>
                    {!isFetchingVehicleByUserId &&
                      vehicleByUserId &&
                      vehicleByUserId.map(({ vehicleProvider }) => (
                        <div className="body">
                          <h6 style={{ width: '10%' }}>
                            <CarIcon color="#D8394C" />
                          </h6>
                          <h6 style={{ width: '25%' }}>{vehicleProvider.model.name}</h6>
                          <h6 style={{ width: '20%' }}>{vehicleProvider.carPlate}</h6>
                          <h6 style={{ width: '25%' }}>{vehicleProvider.color.name}</h6>
                          <h6 style={{ width: '10%' }}>Liviano</h6>
                        </div>
                      ))}
                  </div>

                  <h3
                    style={{
                      fontSize: '14px',
                      color: '#1A0C12',
                      fontWeight: 600,
                      padding: '0 15px',
                      marginTop: '10px',
                    }}
                  >
                    {' '}
                    Vehículo en uso{' '}
                  </h3>
                  <div className="bodyReport">
                    <div className="header">
                      <h6 style={{ width: '10%' }}></h6>
                      <h6 style={{ width: '25%' }}>Modelo</h6>
                      <h6 style={{ width: '20%' }}>Placa</h6>
                      <h6 style={{ width: '25%' }}>Color</h6>
                      <h6 style={{ width: '20%' }}>Tipo</h6>
                    </div>
                    {!isCurrentFetchingVehicleByUserId && currentVehicleByUserId && (
                      <div className="body">
                        <h6 style={{ width: '10%' }}>
                          <CarIcon color="#D8394C" />
                        </h6>
                        <h6 style={{ width: '25%' }}>{currentVehicleByUserId.model.name}</h6>
                        <h6 style={{ width: '20%' }}>{currentVehicleByUserId.carPlate}</h6>
                        <h6 style={{ width: '25%' }}>{currentVehicleByUserId.color}</h6>
                        <h6 style={{ width: '10%' }}>Liviano</h6>
                      </div>
                    )}
                  </div>
                </div>
                <div className="observations">
                  <h3
                    style={{
                      fontSize: '14px',
                      color: '#1A0C12',
                      fontWeight: 600,
                      padding: '0 15px',
                      marginBottom: '10px',
                    }}
                  >
                    Novedades del <span className="text-danger">Conductor</span>
                  </h3>
                  <div>
                    {!isFetchingReviews &&
                      userReviews?.reviews
                        .filter((item) => item.comment !== '')
                        .map((review) => (
                          <div className="cell">
                            <h6 className="col-4">
                              {/* <span><img src={review.sender.userImgUrl}/></span> */}
                              <AccountCircleIcon color="#000000" style={{ marginRight: '10px' }} />
                              <span className="font-weight-bold"> {`${review.sender.firstName} ${review.sender.firstLastName}`}:</span>
                            </h6>
                            <h5 className="col-8">{review.comment} </h5>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default DriversFactsFigures;
