import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import { useBreadCrums } from '../../../hooks/useBreadCrums';


const headerReport = { nombre: "José Espinoza C.", empresa: "Corporación Favorita", ruc: "1711234569001", proveedor: "0001234234", fecha: "Del 01 de agosto al 02 de septiembre del 2021" }
const reportBody = [
    {
        conductor: "José Espinoza C.",
        routes: [
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 },
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 },
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 }
        ]
    },
    {
        conductor: "José Espinoza C.",
        routes: [
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 },
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 },
            { km: 23, tiempo: 120, ciudad: "Quito", origen: "Supemaxi 12 de Octubre", destino: "Megamaxi 6 de Diciembre", valor: 22.50 }
        ]
    }
]

export default function index() {
    const location = useLocation();
    const { pushNewItemBreadCrums } = useBreadCrums();

    useEffect(() => {
        pushNewItemBreadCrums('Reporte: general');
      }, [location]);

    return (
        <Container className="dashboard">
            <Col md={12} className="generalReportTable">
                <h3 className="page-title" style={{ marginLeft: "-25px" }}>Reporte: <span className="text-danger">general</span></h3>
                <div className="contentReport">
                    <div className="headerReport">
                        <div className="cell">
                            <h6>Nombre de Gestor: </h6>
                            <h6>{headerReport.nombre}</h6>
                        </div>
                        <div className="cell">
                            <h6>Empresa: </h6>
                            <h6>{headerReport.empresa}</h6>
                        </div>
                        <div className="cell">
                            <h6>R.U.C.: </h6>
                            <h6>{headerReport.ruc}</h6>
                        </div>
                        <div className="cell">
                            <h6>Número de Proveedor: </h6>
                            <h6>{headerReport.proveedor}</h6>
                        </div>
                        <div className="cell">
                            <h6>Rango de Fecha: </h6>
                            <h6>{headerReport.fecha}</h6>
                        </div>
                    </div>
                    <div className="bodyReport">
                        <div className="Header">
                            <h6 style={{ width: "20%" }}>Conductor</h6>
                            <h6 style={{ width: "5%" }}>KM</h6>
                            <h6 style={{ width: "5%" }}>Tiempo</h6>
                            <h6 style={{ width: "15%" }}>Ciudad</h6>
                            <h6 style={{ width: "22.5%" }}>Origen</h6>
                            <h6 style={{ width: "22.5%" }}>Destino</h6>
                            <h6 style={{ width: "10%" }}>Valor</h6>
                        </div>
                        {
                            reportBody.map(driver =>
                                <div>
                                    <div className="driverCell">
                                        <h6>{driver.conductor}</h6>
                                    </div>
                                    {
                                        driver.routes.map(route =>
                                            <div className="cell">
                                                <h6 style={{ width: "20%" }}></h6>
                                                <h6 style={{ width: "5%" }}>{route.km}</h6>
                                                <h6 style={{ width: "5%" }}>{route.tiempo}</h6>
                                                <h6 style={{ width: "15%" }}>{route.ciudad}</h6>
                                                <h6 style={{ width: "22.5%" }}>{route.origen}</h6>
                                                <h6 style={{ width: "22.5%" }}>{route.destino}</h6>
                                                <h6 style={{ width: "10%" }}>$ {route.valor}</h6>
                                            </div>
                                        )
                                    }
                                    <div className="cellTotal">
                                        <h6>TOTAL: </h6>
                                        <h6>$67.50</h6>
                                    </div>
                                </div>
                            )
                        }
                        <div className="total">
                            <div className="itemValue">
                                <h6>Subtotal: </h6>
                                <h6>$67.50</h6>
                            </div>
                            <div className="itemValue">
                                <h6>(-) Comisión APP: </h6>
                                <h6>$67.50</h6>
                            </div>
                            <div className="itemValue">
                                <h6>TOTAL: </h6>
                                <h6>$67.50</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </Col>
        </Container >
    )
};