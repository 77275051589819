import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SocketContext } from '../../../context/socket';
import { Col, Container, Row, Spinner } from 'reactstrap';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import CarHatchbackIcon from 'mdi-react/CarHatchbackIcon';
import StarIcon from 'mdi-react/StarIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getTravelDetailsById, updateStatusTravelAlert } from '../../../redux/actions/dashboardActions';
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import MapTracking from './components/MapTracking';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
const locationIcon = `${process.env.PUBLIC_URL}/img/location.png`;

const statusList = [
  { value: 'pending', label: 'Pendiente' },
  { value: 'accepted', label: 'Aceptado' },
  { value: 'reject', label: 'Rechazado' },
  { value: 'completed', label: 'Completado' },
  { value: 'onway', label: 'En camino' },
  { value: 'traveling', label: 'Viajando' },
];

export default function index({ match }) {
  const {
    params: { travelId },
  } = match;
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const travel = useSelector((state) => state.dashboard.travelDetailsById);
  const isFetchingTravel = useSelector((state) => state.dashboard.isFetching);
  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const passengers = useSelector((state) => state.dashboard.passengers);
  const isFetchingPassengers = useSelector((state) => state.dashboard.isFetchingPassengers);
  const driver = useSelector((state) => state.dashboard.driver);
  const isFetchingDriver = useSelector((state) => state.dashboard.isFetchingDriver);
  const [response, setResponse] = useState(null);
  const [loadedMap, setLoadedMap] = useState(false);
  const [travelStatus, setTravelStatus] = useState(null);
  const [carPosition, setCarPosition] = useState(null);
  const count = useRef(0);


  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Detalle del viaje');
  }, [location]);

  useEffect(() => {
    dispatch(getTravelDetailsById(travelId));
  }, []);

  const handlePositionCar = useCallback((res) => {
    setCarPosition({
      lat: Number(res?.latitud),
      lng: Number(res?.longitud),
    });
  }, []);

  useEffect(() => {
    if (travel?.travel?.idDriver && (travel?.travel?.status === 'traveling' || travel?.travel?.status === 'onway') && travel?.travel?.idDriver !== null) socket.on(`listen_tracking_${travel?.travel?.id}`, handlePositionCar);
  }, [travel]);

  useEffect(() => {
    if (!isFetchingTravel && travel) setTravelStatus(statusList.filter((item) => item.value === travel?.travel?.status));
    else setTravelStatus(null);
  }, [travel]);

  const directionsCallback = (response) => {
    if (response !== null && count.current < 2) {
      if (response.status === 'OK') {
        count.current += 1;
        setResponse(response);
      } else {
        count.current = 0;
      }
    }
    if (response !== null) {
      if (response.status === 'OK') setResponse(response);
      else console.log('response: ', response);
    }
  };

  const eliminaDuplicadas = (arr) => {
    const personasMap = arr.map((persona) => {
      return [persona.user.id, persona.user];
    });

    return [...new Map(personasMap).values()];
  };

  useEffect(() => {
    if (!loadedMap) {
      setTimeout(() => {
        setLoadedMap(true);
      }, 1000);
    }
  }, [response]);

  return (
    <Container className="dashboard">
      <div className="mx-4">
        {isFetchingTravel && !travel ? (
          <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
        ) : (
          <div className="JourneyAlertDetails">
            <h3 className="page-title" style={{ marginLeft: '-25px' }}>
              Alertas de <span className="text-danger">viaje</span>
            </h3>
            <Select
              className="basic-single"
              classNamePrefix="selectJourneyAlert"
              name="status"
              placeholder={'Estado'}
              value={travelStatus}
              isDisabled={travel?.travel?.status === 'traveling' || travel?.travel?.status === 'onway' ? true : false}
              options={
                travel?.travel?.status === 'traveling' || travel?.travel?.status === 'onway'
                  ? [
                      { value: 'onway', label: 'En camino' },
                      { value: 'traveling', label: 'Viajando' },
                    ]
                  : [
                      { value: 'pending', label: 'Pendiente' },
                      { value: 'accepted', label: 'Aceptado' },
                      { value: 'reject', label: 'Rechazado' },
                    ]
              }
              onChange={(e) => {
                setTravelStatus(statusList.filter((item) => item.value === e.value));
                socket.emit('notificar', { id: travelId, status: e.value });
                dispatch(updateStatusTravelAlert(travelId, { status: e.value }));
                if (e.value === 'reject') history.push('/dashboard/JourneyAlerts');
              }}
              menuPosition={'fixed'}
            />
            {travel?.travel?.status === 'traveling' || travel?.travel?.status === 'onway' ? (
              <>
                <div className="profileUserAlert d-flex flex-wrap ">
                  <img src={userReviews?.user?.userImgUrl} alt="avatar" />
                  <div style={{ backgroundColor: '#969696', width: '2px', height: '145px' }}></div>
                  <div className="profileData">
                    <h6 className="company">Corporación Favorita</h6>
                    <h6 className="code">Código: {travel?.travel?.id}</h6>
                    <h6 className="name">
                      {travel?.travel?.firstName} {travel?.travel?.firstLastName}
                    </h6>
                    <h6 className="normal">PENDIENTE cargo</h6>
                    <h6 className="company">
                      Puntuación: {isFetchingReviews ? '' : userReviews.average}
                      <StarIcon style={{ height: '17px', marginTop: '-5px' }} color="#FFCE31" />
                    </h6>
                  </div>
                  <div style={{ backgroundColor: '#969696', width: '2px', height: '145px' }}></div>
                  <div className="lastRoutes">
                    <h6 className="text-danger" style={{ fontSize: '16px', fontWeight: 600, marginBottom: '5px' }}>
                      Pasajeros
                    </h6>
                    <div className="listItems">
                      {!isFetchingPassengers &&
                        passengers &&
                        eliminaDuplicadas(passengers).map((user, index) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={`alert-${index}`}>
                              <AccountCircleIcon style={{ height: '25px', width: '25px' }} />
                              <h6 style={{ marginLeft: '8px', paddingTop: '8px' }}>{`${user.firstName} ${user.firstLastName}`} </h6>
                              {/* <h6 style={{ marginLeft: "8px", paddingTop: "8px" }}>{user?.invited === null ? `${user.firstName} ${user.firstLastName}` : ""} </h6> */}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap my-3">
                  <div className="descriptionAlert">
                    <div>
                      <h3 className="text-danger" style={{ fontWeight: 600, marginLeft: '-25px' }}>
                        <ClockOutlineIcon style={{ marginRight: '5px', height: '30px', width: '30px' }}></ClockOutlineIcon>Movilización {moment(travel?.travel?.createdAt, 'HH:mm').isBetween(moment('04:01', 'HH:mm'), moment('21:59', 'HH:mm')) ? '' : 'fuera de horario'}
                      </h3>
                      <div className="dataDescriptionAlert">
                        <h5>
                          <CarHatchbackIcon className="carICon"></CarHatchbackIcon> Auto Favorita
                        </h5>
                        <h6>{moment(travel?.travel?.updatedAt).format('HH:MM')} en punto de destino</h6>
                        <h6 style={{ marginBottom: '15px' }}>
                          <strong>Precio:</strong> ${travel?.travel?.price}
                        </h6>
                        <h6>
                          <strong>Punto de partida:</strong> {travel?.travel?.addresOrigin}
                        </h6>
                        <h6>
                          <strong>Punto de llegada:</strong> {travel?.travel?.addressDestiny}
                        </h6>
                        <h6>
                          <strong>Conductor:</strong> {isFetchingDriver ? '' : `${driver.firstName} ${driver.firstLastName}`}
                        </h6>
                      </div>
                    </div>
                  </div>
                  {travel && travel?.travel && loadedMap ? (
                    <MapTracking originRoute={new google.maps.LatLng(Number(travel?.travel?.latOrigin), Number(travel?.travel?.lngOrigin))} destinyRoute={new google.maps.LatLng(Number(travel?.travel?.latDestiny), Number(travel?.travel?.lngDestiny))} addressDestiny={travel?.travel?.addressDestiny} carPosition={carPosition} />
                  ) : (
                    <div style={{ display: 'flex', height: '40vh', width: '40vw', flexDirection: 'column' }}>
                      <Spinner className="table-fetch-spinner" style={{ marginLeft: '47%', height: '30px', width: '30px' }} />
                      <h5 style={{ textAlign: 'center', marginTop: '7px' }}>Cargando mapa</h5>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="profileUserAlert">
                  <div className="descriptionAlert">
                    <div className="dataDescriptionAlert">
                      <div className="my-2">
                        <h5>
                          <CarHatchbackIcon className="carICon"></CarHatchbackIcon> Auto Favorita
                        </h5>
                      </div>
                      <h6 style={{ marginBottom: '15px' }}>{moment(travel?.travel?.updatedAt).format('HH:MM')} en punto de destino</h6>
                      <h6>
                        <strong>Punto de partida:</strong> {travel?.travel?.addresOrigin}
                      </h6>
                      <h6>
                        <strong>Punto de llegada:</strong> {travel?.travel?.addressDestiny}
                      </h6>
                      <h6>
                        <strong>Precio:</strong> ${travel?.travel?.price}
                      </h6>
                    </div>
                  </div>
                  <div style={{ backgroundColor: '#969696', width: '2px', height: '145px', marginRight: '30px', marginLeft: '10px' }}></div>
                  <div className="lastRoutes">
                    <h6 className="text-danger" style={{ fontSize: '16px', fontWeight: 600, marginBottom: '5px' }}>
                      Pasajeros
                    </h6>
                    <div className="listItems">
                      {!isFetchingPassengers &&
                        passengers &&
                        eliminaDuplicadas(passengers).map((user, index) => {
                          return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={`alert-${index}`}>
                              <AccountCircleIcon style={{ height: '25px', width: '25px' }} />
                              {/* <h6 style={{ marginLeft: '8px', paddingTop: '8px' }}>{user?.invited === null ? `${user?.user?.firstName} ${user?.user?.firstLastName}` : user?.invited} </h6> */}
                              <h6 style={{ marginLeft: '8px', paddingTop: '8px' }}>{`${user?.firstName} ${user?.firstLastName}`} </h6>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <GoogleMap
                    id="map_alertDetail"
                    mapContainerStyle={{ height: '40vh', width: '100%' }}
                    center={{ lat: -0.225219, lng: -78.5248 }}
                    zoom={7}
                    options={{
                      streetViewControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      minZoom: 2,
                    }}
                  >
                    {travel && travel?.travel && (
                      <>
                        <Marker
                          position={{
                            lat: Number(travel?.travel?.latOrigin) - 0.0002,
                            lng: Number(travel?.travel?.lngOrigin) + 0.0001,
                          }}
                          icon={{
                            url: locationIcon,
                            scaledSize: new google.maps.Size(30, 30),
                          }}
                        />
                        <InfoWindow
                          visible={true}
                          position={{
                            lat: Number(travel?.travel?.latDestiny) + 0.001,
                            lng: Number(travel?.travel?.lngDestiny),
                          }}
                        >
                          <div>
                            {loadedMap ? (
                              <>
                                <h4 style={{ textAlign: 'center', fontWeight: 500 }}>{response?.routes[0].legs[0].duration.text}</h4>
                                <h5>{travel?.travel?.addressDestiny}</h5>
                              </>
                            ) : (
                              <>
                                <Spinner className="table-fetch-spinner" style={{ marginLeft: '47%', height: '20px', width: '20px' }} />
                                <h5 style={{ textAlign: 'center', marginTop: '7px' }}>Cargando</h5>
                              </>
                            )}
                          </div>
                        </InfoWindow>
                        <Marker
                          position={{
                            lat: Number(travel?.travel?.latDestiny),
                            lng: Number(travel?.travel?.lngDestiny),
                          }}
                          icon={{
                            url: 'https://cdn.pixabay.com/photo/2015/08/27/10/30/ban-909970_1280.png',
                            scaledSize: new google.maps.Size(20, 20),
                          }}
                        />

                        <DirectionsService
                          options={{
                            origin: new google.maps.LatLng(Number(travel?.travel?.latOrigin), Number(travel?.travel?.lngOrigin)),
                            destination: new google.maps.LatLng(Number(travel?.travel?.latDestiny), Number(travel?.travel?.lngDestiny)),
                            travelMode: 'DRIVING',
                          }}
                          callback={directionsCallback}
                        />

                        {loadedMap && (
                          <DirectionsRenderer
                            options={{
                              directions: response,
                              suppressMarkers: true,
                              polylineOptions: {
                                strokeColor: '#ed3e44',
                              },
                            }}
                          />
                        )}
                      </>
                    )}
                  </GoogleMap>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
