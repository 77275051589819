import React from 'react';
import { Col, Container, Row, Button, Card, CardBody, Table, Input, FormGroup } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import Select from "react-select";
import Radio from '@material-ui/core/Radio';
import TuneIcon from 'mdi-react/TuneIcon';
//import Map from '../../../Map';
import TrashIcon from 'mdi-react/TrashIcon';

const data_cobertura = [
  { value: 'Pichincha', label: 'Pichincha', status: true, viaje_programado: true, viaje_inmediato: false },
  { value: 'Guayas', label: 'Guayas', status: true, viaje_programado: true, viaje_inmediato: false },
  { value: 'Manabí', label: 'Manabí', tatus: true, viaje_programado: true, viaje_inmediato: false },
  { value: 'Azuay', label: 'Azuay', status: true, viaje_programado: true, viaje_inmediato: false }
];

const Dashboard = () => {
  return (
    <Container className="dashboard">
      <Row className="mb-4">
        <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
          <TuneIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '10px', marginTop: '-5px' }} />
          <h3 className="page-title"> Ajuste: <span className="text-danger">Coberturas</span></h3>
        </Col>
      </Row>
      <Row className="react-action-top-b-l">
        <div>
          <Button className="m-0 py-1 mr-2 btn-simple"> eliminar <TrashIcon style={{ marginTop: '-1px' }} /></Button>
          <Button color="danger" style={{ backgroundColor: 'red' }} className="m-0 py-1">Guardar y Enviar</Button>
        </div>
      </Row>
      <Row className="mt-4 p-0 m-0">
        <Col lg={6} md={12}>
          <Card>
            <CardBody className="body-map">
              {/* <Map data={[]} style={{ position: 'relative' }} center={{ lat: -0.225219, lng: -78.5248 }} /> */}
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} md={12}>
          <Card>
            <CardBody>
              <label htmlFor="zona-calor" className="label-head-info">Provincias <span className="text-danger">con cobertura</span></label>
              <Table className="table-user title-red table-border-bottom text-center">
                <thead>
                  <tr>
                    <th>Provincia</th>
                    <th>Habilitado</th>
                    <th>Viaje programado</th>
                    <th>Viaje inmediato</th>
                  </tr>
                </thead>
                <tbody>
                  {data_cobertura.map((rq) => (
                    <tr>
                      <td className="lh-1">{rq.label}</td>
                      <td>
                        <Switch checked={rq.value} />
                      </td>
                      <td>
                        <Radio className="m-0 p-0" checked={rq.viaje_programado} />
                      </td>
                      <td>
                        <Radio className="m-0 p-0" checked={rq.viaje_inmediato} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <FormGroup>
                <Row>
                  <Col lg={6} md={12}>
                    <Select
                      className="basic-single-user input-none w-100"
                      isClearable={true}
                      isSearchable={true}
                      placeholder={"Provincias"}
                      color
                      options={data_cobertura}
                    />
                  </Col>
                  <Col lg={6} md={12}>
                    <Input id="provincia" name="provincia" placeholder="Añadir nueva provincia" type="text" />
                  </Col>
                </Row>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default Dashboard;
