import { get, put } from '../base/index';

export default {
    getvehicleProviders: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/${id}`);
    },

    putVehicleStatus: (id, body) => {
        return put(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/updateVehicle/${id}`, body);
    },
    getVehicleByUserId: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/getVehicleByUserId/${id}`);
    },
    getVehicleById: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/getVehicleById/${id}`);
    },
    getCurrentVehicleByIdDriver:(id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/getCurrentVehicleByIdDriver/${id}`);
    }
}