import { get, put } from "../base/index";

export default {
  getEmergencyNumbers: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/parameters/emergencyNumbers`);
  },
  putEmergencyNumbers: (body) => {
    return put(
      `${process.env.REACT_APP_URL_BASE}/parameters/emergencyNumbers`,
      body
    );
  },
};
