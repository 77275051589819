import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { Avatar, Badge, ClickAwayListener, Divider, Fab, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import BellIcon from 'mdi-react/BellIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import NotificationClearAllIcon from 'mdi-react/NotificationClearAllIcon';

const Topbar = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
  // const { getNotifications, putNotificationToViewed, deleteAllNotifications, deleteNotificationById } = useNotifications();
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [countNotification, setCountNotification] = useState(4);
  const [notificationsList, setNotificationsList] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const anchorRef = useRef(null);

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenNotifications = () => {
    setIsOpenNotifications((prev) => !prev);
    // setCountNotification(countNotification + 1);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpenNotifications(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpenNotifications(false);
    }
  }

  const handleOpenCloseSidebar = () => {
    changeMobileSidebarVisibility();
    changeSidebarVisibility();
  };

  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left d-flex align-items-center">
          <Link className="topbar__logo" to="/dashboard" />
          <div className='toggle_sideBar'>
            <IconButton size="small" onClick={handleOpenCloseSidebar} style={{ height: 'auto', width: 'auto' }}>
              <MenuIcon color="#deecee" />
            </IconButton>
          </div>
        </div>
        <div className="topbar__right">
          {/* <IconButton size="small" onClick={handleOpenNotifications}>
            {countNotification > 0 ? (
              <Badge badgeContent={countNotification} color="primary">
                <BellIcon color="#deecee" />
              </Badge>
            ) : (
              <BellIcon color="#deecee" />
            )}
          </IconButton> */}
          <Popper open={isOpenNotifications} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={handleClose}>Profilesdfgsdfgsdfgsdfgsdfgsdfg</MenuItem>
                      <MenuItem onClick={handleClose}>My accounsdfgsdfgsdfgsdfgsdfgt</MenuItem>
                      <MenuItem onClick={handleClose}>Logoutsdfgsdfgsdfgsdfgsdfgsdfgd</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
                {/* <Fab color="primary" aria-label="clear-all" sx={{ position: 'sticky', bottom: 15, marginLeft: '24rem' }} className="" onClick={()=>console.log('clic')}>
                  <NotificationClearAllIcon />
                </Fab> */}
              </Grow>
            )}
          </Popper>
          {/* <Fade in={isOpenNotifications}>
            
              
          </Fade> */}
          <TopbarProfile />
        </div>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;
