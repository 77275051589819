import React, { useState, useEffect } from 'react';
import { Col, Container, Input, Spinner } from 'reactstrap';
import DownloadIcon from 'mdi-react/DownloadIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { getUserById, getTravelsByIdCollaborator } from '../../../redux/actions/usersAction';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Select from 'react-select';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import { Link, useLocation } from 'react-router-dom';
import { listEstablisments } from '../../../redux/actions/zoneAction';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
import { translateStatus } from '../../../utils/util';

export default function index({ match }) {
  const {
    params: { userId },
  } = match;
  const dispatch = useDispatch();
  const [initialDate, setInitialDate] = useState(`${moment().subtract(1, 'month').format('YYYY-MM-DD')}`);
  const [endDate, setEndDate] = useState(`${moment().format('YYYY-MM-DD')}`);
  const user = useSelector((state) => state.users.userById);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const TravelsByIdCollaborator = useSelector((state) => state.users.TravelsByIdCollaborator);
  const isFetchingTravelsByIdCollaborator = useSelector((state) => state.users.isFetchingTravelsByIdCollaborator);
  const establishments = useSelector((state) => state.zonesFavorita.establishments);

  const [totalCost, setTotalCost] = useState('');
  const [formatSelected, setFormatSelected] = useState({ value: 'favorita', label: 'Favorita' });
  const rowsPagePdf = 20;

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte general');
  }, [location]);

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getTravelsByIdCollaborator(userId, initialDate, endDate, formatSelected.value));
    dispatch(listEstablisments());
  }, []);

  const travelsByDates = () => {
    if (endDate !== '' && initialDate !== '' && !isFetchingTravelsByIdCollaborator) dispatch(getTravelsByIdCollaborator(userId, initialDate, endDate, formatSelected.value));
  };

  const totalCostCalc = () => {
    let sum = 0;
    if (!isFetchingTravelsByIdCollaborator && TravelsByIdCollaborator) {
      TravelsByIdCollaborator.filter((item) => item.travel.status === 'completed').map((route) => (sum = sum + Number(route.price)));
    }
    return parseFloat(sum.toFixed(2));
  };

  const translateTypeRequest = (typeRequest) => {
    switch (typeRequest) {
      case 'now':
        return 'Ahora';
      case 'schedule':
        return 'Agendado';
      default:
        return typeRequest;
    }
  };

  const translateTypeTravel = (typeRequest) => {
    switch (typeRequest) {
      case 'home':
        return 'Transporte a domicillo';
      case 'steps':
        return 'Gestiones varias';
      case 'outoff':
        return 'Transporte fuera de la ciudad';
      default:
        return typeRequest;
    }
  };

  const getWorkArea = (workArea) => {
    const workAreaFind = establishments.find((item) => item.workAreaCode.toString() === workArea);
    return `${workAreaFind.establishmentName} - ${workAreaFind.workAreaCode} - ${workAreaFind.workAreaName}`;
  };

  const exportToExcel = () => {
    if (!isFetchingTravelsByIdCollaborator && TravelsByIdCollaborator) {
      const filterData = TravelsByIdCollaborator.map((item) => ({
        conductor: item.travel.user ? `${item.travel.user.firstName} ${item.travel.user.firstLastName}` : '',
        workArea: getWorkArea(user.workAreaCode),
        fecha: item.travel.date,
        colaborador: user ? `${user.firstName} ${user.secondName} ${user.firstLastName} ${user.secondLastName}` : '',
        typeRequest: translateTypeRequest(item.travel.typeRequest),
        typeTravel: translateTypeTravel(item.travel.typeTravel),
        typeCar: item.travel.typeVehicle.toUpperCase(),
        addresOrigin: item.travel.addresOrigin,
        addressDestiny: item.travel.addressDestiny,
        addressDestiny: item.travel.addressDestiny,
        state: translateStatus(item.travel.status),
        price: item.price ? parseFloat(item.price) : item.price,
      }));
      filterData.push({
        conductor: '',
        workArea: '',
        fecha: '',
        colaborador: '',
        typeRequest: '',
        typeTravel: '',
        typeCar: '',
        addresOrigin: '',
        addressDestiny: '',
        state: 'Total',
        price: totalCostCalc(),
      });

      const Heading = [['Conductor', 'Area de trabajo', 'Fecha', 'Colaborador', 'Tipo de solicitud', 'Tipo de viaje', 'Tipo de vehiculo', 'Origen', 'Destino', 'Estado', 'Valor']];
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const ws = XLSX.utils.json_to_sheet(filterData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.sheet_add_aoa(ws, Heading);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'Reporte de usuario.xlsx');
    }
  };

  const generatePDF = () => {
    if (!isFetchingTravelsByIdCollaborator && TravelsByIdCollaborator) {
      const pdf = new jsPDF('l', 'mm', [297, 210]);
      for (let i = 0; i < Math.ceil(TravelsByIdCollaborator.length / rowsPagePdf); i++) {
        html2canvas(document.querySelector(`#tableToExport${i}`), {
          onclone: function (clonedDoc) {
            clonedDoc.getElementById(`tableToExport${i}`).style.display = 'block';
          },
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 3, 3);

          if (i + 1 === Math.ceil(TravelsByIdCollaborator.length / rowsPagePdf)) pdf.save('Reporte de usuario.pdf');
          else pdf.addPage();
        });
      }
    }
  };

  return (
    <Container className="dashboard">
      <Col md={12} className="generalReportTable">
        <div className="d-flex justify-content-between align-items-center flex-wrap my-3">
          <h3 className="page-title" style={{ marginLeft: '-25px' }}>
            Reporte: <span className="text-danger">general</span>
          </h3>
          <div className="d-flex justify-content-between align-items-center flex-wrap my-2">
            <div style={{ marginRight: '10px' }}>
              <Select
                className="select__control my-2"
                classNamePrefix="select"
                name="Formatos"
                isClearable={false}
                isSearchable={false}
                placeholder={'Formatos'}
                value={formatSelected}
                onChange={(e) => {
                  if (e === null) setFormatSelected(null);
                  else {
                    setFormatSelected({ value: e.value, label: e.label });
                  }
                }}
                options={[
                  { value: 'favorita', label: 'Favorita' },
                  { value: 'external', label: 'Externo' },
                  { value: 'personal', label: 'Personal' },
                ]}
                styles={{
                  control: (styles, { isDisabled }) => {
                    return {
                      ...styles,
                      backgroundColor: isDisabled ? '#e9e9ea !important' : '#D6D8DB',
                      cursor: isDisabled ? 'not-allowed !important' : 'auto',
                      minWidth: '120px',
                      marginTop: '-1px',
                    };
                  },
                }}
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <Input
                className="select__control my-2"
                id="selectorDate"
                name="Seleccionar Fecha"
                placeholder="Seleccione Fecha"
                type="date"
                style={{ width: '170px' }}
                onChange={(e) => {
                  setInitialDate(e.target.value);
                }}
                value={initialDate}
                max={moment().format('YYYY-MM-DD')}
              />
            </div>
            <div style={{ marginRight: '10px' }}>
              <Input
                className="select__control my-2"
                id="selectorDate"
                name="Seleccionar Fecha"
                placeholder="Seleccione Fecha"
                type="date"
                style={{ width: '170px' }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                value={endDate}
                max={moment().format('YYYY-MM-DD')}
              />
            </div>
            <button
              style={{
                border: 'none',
                backgroundColor: '#EC1C24',
                height: '38px',
                borderRadius: '9px',
                marginRight: '20px',
              }}
              onClick={travelsByDates}
            >
              <MagnifyIcon color="white" />
            </button>
          </div>
          <div>
            <button
              className="dashboardButton"
              onClick={exportToExcel}
              style={{
                backgroundColor: '#3DBE00',
                marginRight: '5px',
                fontSize: '12px',
                height: '42px',
              }}
            >
              XLSX <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
            <button
              className="dashboardButton"
              onClick={generatePDF}
              style={{
                backgroundColor: '#EC1C24',
                fontSize: '12px',
                height: '42px',
              }}
            >
              PDF <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
          </div>
        </div>
        <div className="contentReport">
          <div className="headerReport">
            <div className="cell">
              <h6>Nombre de Colaborador: </h6>
              <h6>{!isFetchinguser && user ? `${user.firstName} ${user.secondName} ${user.firstLastName} ${user.secondLastName}` : ''}</h6>
            </div>
            <div className="cell">
              <h6>Rango de fecha: </h6>
              <h6>
                Del {moment(initialDate).format('DD MMMM YYYY')} al {moment(endDate).format('DD MMMM YYYY')}
              </h6>
            </div>
          </div>
          <div className="bodyReport">
            <div className="Header">
              <h6 style={{ width: '5%' }}>Detalle</h6>
              <h6 style={{ width: '25%' }}>Tipo de solicitud</h6>
              <h6 style={{ width: '25%' }}>Tipo de viaje</h6>
              <h6 style={{ width: '25%' }}>Origen</h6>
              <h6 style={{ width: '25%' }}>Destino</h6>
              <h6 style={{ width: '25%' }}>Estado</h6>
              <h6 style={{ width: '25%' }}>Valor</h6>
            </div>
            {!isFetchingTravelsByIdCollaborator && TravelsByIdCollaborator ? (
              TravelsByIdCollaborator.map((route, index) => (
                <div
                  key={`row${index}`}
                  className="cell"
                  style={{
                    backgroundColor: index % 2 === 0 ? 'transparent' : '#E5E5E5',
                  }}
                >
                  <h6 style={{ width: '5%' }}>
                    <button className="table__action__icon" title="Ver viaje">
                      <Link to={`/users/UserGeneralReport/JourneyDetails/${route.travel.id}`}>
                        <EyeOutlineIcon color="#7C7C7C" />
                      </Link>
                    </button>
                  </h6>
                  <h6 style={{ width: '25%' }}>{translateTypeRequest(route.travel.typeRequest)}</h6>
                  <h6 style={{ width: '25%' }}>{translateTypeTravel(route.travel.typeTravel)}</h6>
                  <h6 style={{ width: '25%' }}>{route.travel.addresOrigin}</h6>
                  <h6 style={{ width: '25%' }}>{route.travel.addressDestiny}</h6>
                  <h6 style={{ width: '25%' }}>{translateStatus(route.travel.status)}</h6>
                  <h6 style={{ width: '25%' }}>$ {route.price}</h6>
                </div>
              ))
            ) : (
              <Spinner className="table-fetch-spinner" style={{ marginLeft: '45%', marginTop: '30px' }} />
            )}
            {!isFetchingTravelsByIdCollaborator && TravelsByIdCollaborator && (
              <div className="total">
                <div className="itemValue">
                  <h6>TOTAL: </h6>
                  <h6>${totalCostCalc()}</h6>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isFetchingTravelsByIdCollaborator &&
          TravelsByIdCollaborator &&
          TravelsByIdCollaborator.map((item, index) => {
            if (index < Math.ceil(TravelsByIdCollaborator.length / rowsPagePdf))
              return (
                <table key={index} style={{ borderLeft: '1px solid #f2f2f2', borderRight: 'none', borderBottom: 'none', display: 'none', width: '1100px', maxWidth: '1100px', minWidth: '1100px' }} id={`tableToExport${index}`}>
                  <tbody>
                    <tr style={{ backgroundColor: '#303e47', borderBottom: 'none' }}>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>Tipo de solicitud</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>Tipo de viaje</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>Origen</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>Destino</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>Valor</th>
                    </tr>
                    {TravelsByIdCollaborator.slice(index * rowsPagePdf, (index + 1) * rowsPagePdf).map((item, index) => (
                      <tr key={`row-${index}`} style={{ borderRight: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}>
                        <td style={{ padding: '5px 15px 5px 5px', width: '110px' }}>{translateTypeRequest(item.travel.typeRequest)}</td>
                        <td style={{ padding: '5px 15px 5px 5px', width: '110px' }}>{translateTypeTravel(item.travel.typeTravel)}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>{item.travel.addresOrigin}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>{item.travel.addressDestiny}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>{item.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
          })}
      </Col>
    </Container>
  );
}
