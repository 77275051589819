import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row, Spinner } from 'reactstrap';
import TuneIcon from 'mdi-react/TuneIcon';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableBase from './components/TableBase';

const Drivers = () => {
  const dispatch = useDispatch();
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);

  const columns = useMemo(() =>
    [
      {
        Header: 'Código',
        accessor: 'Codigo',
        disableFilters: true
      },
      {
        Header: 'Destino',
        accessor: 'Destino',
        disableFilters: true
      },
      {
        Header: 'Dirección',
        accessor: 'Direccion',
        disableFilters: true
      },
      {
        Header: 'Coordenadas',
        accessor: 'Coordenadas',
        disableFilters: true
      },
      {
        Header: 'Formato',
        accessor: 'Formato',
        disableFilters: true
      },
      {
        Header: "Acciones",
        accessor: "[editButton]",
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleDataCareers(cellObj.row.index)}>
              <CarIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDataReport(cellObj.row.index)}>
              <ChartBarIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true
      }
    ],
    []
  );

  const filterOptions = (filterAttr) => {
    let arrayToReturn = [];
    let filterColumnData = [];
    dataGenerator().map(value => { if (value[filterAttr] !== null) filterColumnData.push(value[filterAttr]) })

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    filterColumnData.filter(onlyUnique).map((value) => arrayToReturn.push({ value, label: value }))

    return arrayToReturn;
  }
  const handleDataCareers = (index) => {
    alert(`Carreras del usuario ${dataGenerator()[index].Jefe}`)
  };
  const handleDataReport = (index) => {
    alert(`Reporte del usuario ${dataGenerator()[index].Jefe}`)
  };
  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Codigo: ['NRU0012'][Math.floor((Math.random() * 1))],
        Destino: ['Megamaxi 6 de diciembre'][Math.floor((Math.random() * 1))],
        Direccion: ['Av. 6 de diciembre'][Math.floor((Math.random() * 1))],
        Coordenadas: ['-005477; -7832489324'][Math.floor((Math.random() * 1))],
        Formato: ['Supermaxi'][Math.floor((Math.random() * 1))]
      });
    }
    return data;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <TuneIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '10px', marginTop: '-5px' }} />
          <h3 className="page-title">Ajuste: <span className="text-danger">Destinos</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          {!isFetchingusersLists && dataGenerator()
            ? <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
            : <Spinner className="table-fetch-spinner" style={{ marginLeft: "50%" }} />
          }
        </Container>
      </Row>
    </Container>
  )
};

export default Drivers;