import { createAction } from "redux-actions";
import { toast } from "react-toastify";
import api from "../../utils/api/apiProvider";

export const fetchRatesRequest = createAction("FETCH_RATES_REQUEST");
export const fetchProvincesSuccess = createAction("FETCH_PROVINCES_SUCCESS");
export const fetchRatesSuccess = createAction("FETCH_RATES_SUCCESS");
export const fetchDetailRatesSuccess = createAction(
  "FETCH_DETAILRATES_SUCCESS"
);
export const fetchDetailOnlyRateSuccess = createAction(
  "FETCH_DETAILONLYRATES_SUCCESS"
);
export const fetchRatesError = createAction("FETCH_RATES_ERROR");

export const listProvincesandRates = () => async (dispatch) => {
  try {
    dispatch(fetchRatesRequest());
    const response = await api.rates.getAllRates();
    const responseProvinces = await api.rates.getAllProvince();
    dispatch(fetchRatesSuccess(response.data));
    dispatch(fetchProvincesSuccess(responseProvinces.data.ProvinceBD));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error obteniendo el reporte de las provincias", {
      autoClose: 2500,
      theme: "colored",
    });

    throw error;
  }
};

export const listRates = () => async (dispatch) => {
  try {
    //dispatch(fetchRatesRequest());
    const response = await api.rates.getAllRates();
    dispatch(fetchRatesSuccess(response.data));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error obteniendo el reporte de las tarifas", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const detailRate = (id) => async (dispatch) => {
  try {
    dispatch(fetchRatesRequest());
    const response = await api.rates.getDetailRateById(id);
    dispatch(fetchDetailOnlyRateSuccess(response.data));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error obteniendo el detalle de la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const listDetailRateById = (rateId) => async (dispatch) => {
  try {
    dispatch(fetchRatesRequest());
    const response = await api.rates.getDetailRateByRateId(rateId);
    dispatch(fetchDetailRatesSuccess(response.data));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error obteniendo el detalle de las tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const deleteRate = (idRate) => async (dispatch) => {
  try {
    const response = await api.rates.getDetailRateByRateId(idRate);
    if (response.data.length === 0) {
      dispatch(fetchRatesRequest());
      await api.rates.deleteRate(idRate);
      dispatch(listRates());
      toast.success("Tarifa eliminada correctamente", {
        autoClose: 2500,
        theme: "colored",
      });
    } else {
      toast.error("No puede eliminar la tarifa si tiene detalles dentro", {
        autoClose: 2500,
        theme: "colored",
      });
    }
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error al eliminar la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const deleteDetailRate = (idDetailRate, rateId) => async (dispatch) => {
  try {
    dispatch(fetchRatesRequest());
    await api.rates.deleteDetailRate(idDetailRate);
    toast.success("Detalle de la tarifa eliminada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listDetailRateById(rateId));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error al eliminar el detalle de la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postRate = (body) => async (dispatch) => {
  try {
    //dispatch(fetchRatesRequest());
    await api.rates.postNewRate(body);
    dispatch(listRates());
    toast.success("Tarifa agregada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error al agregar nueva tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postDetailRate = (body) => async (dispatch) => {
  try {
    dispatch(fetchRatesRequest());
    await api.rates.postDetailRate(body);
    toast.success("Detalle de tarifa agregada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error al agregar nuevo detalle de la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putProvinceById = (id, body) => async (dispatch) => {
  try {
    await api.rates.putProvinceById(id, body);
    toast.success("Tarifa cambiada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listProvincesandRates());
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error cambiando la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });

    throw error;
  }
};

export const putDetailRateById = (id, body) => async (dispatch) => {
  try {
    await api.rates.putDetailRate(id, body);
    toast.success("Detalle de la tarifa actualizada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(detailRate(id));
  } catch (error) {
    dispatch(fetchRatesError({ error }));
    toast.error("Error cambiando la tarifa", {
      autoClose: 2500,
      theme: "colored",
    });

    throw error;
  }
};
