import { get, post } from '../base/index';
import moment from 'moment';

export default {
    getDriverExample: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/${id}`);
    },

    getDriverCost: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/drivercost/${moment(year).format("YYYY")}/${id}/completed`);
    },
    getDriverKm: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/driverkm/${moment(year).format("YYYY")}/${id}/completed`);
    },
    getDriverTravels: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/drivertravels/${moment(year).format("YYYY")}/${id}/completed`);
    },
    sendEmail: (body) => {
        return post(`${process.env.REACT_APP_URL_BASE}/email`, body);
    }
}