import { post, getCustomParams, get } from '../base/index';

export default {
  login: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/auth/collaborator`, body);
  },
  tokenRenovation: (params) => {
    return getCustomParams(`${process.env.REACT_APP_URL_BASE}/auth`, params);
  },
  closeSessionByIdUser: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/auth/closeSessionByIdUser/${id}`);
  },
};
