import React, { useEffect, useState, useRef } from 'react';
import { Col, Container, Spinner, Input, Row } from 'reactstrap';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import CarHatchbackIcon from 'mdi-react/CarHatchbackIcon';
import { getRacesByUser, getUserById, getCollaborators, upadteRacesByUser } from '../../../redux/actions/usersAction';
import { getTravelDetailsByIdHistorial } from '../../../redux/actions/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Marker, DirectionsService, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
const locationIcon = `${process.env.PUBLIC_URL}/img/location.png`;

export default function index({ match }) {
  const {
    params: { userId },
  } = match;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.userById);
  const racesByUser = useSelector((state) => state.users.racesByUser);
  const isFetchingRacesByUser = useSelector((state) => state.users.isFetchingRacesByUser);
  const collaborators = useSelector((state) => state.users.collaborators);
  const isFetchingCollaborators = useSelector((state) => state.users.isFetchingCollaborators);
  const travel = useSelector((state) => state.dashboard.travelDetailsById);
  const isFetchingTravel = useSelector((state) => state.dashboard.isFetching);
  const [collaboratorSelected, setCollaboratorSelected] = useState(null);
  const [mapVisible, setMapVisible] = useState(false);
  const [coordenates, setCoordenates] = useState(null);
  const [response, setResponse] = useState(null);
  const [loadedMap, setLoadedMap] = useState(false);
  const [loadMoreRaces, setloadMoreRaces] = useState(false);
  const [markers, setMarkers] = useState([]);
  let racesPagination = useRef(1);
  let count = useRef(0);
  let scrollRef = useRef();

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Carreras');
  }, [location]);

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getRacesByUser(userId, 'favorita', 0, 5));
    dispatch(getCollaborators());
  }, []);

  useEffect(() => {
    setloadMoreRaces(false);
  }, [racesByUser]);

  useEffect(() => {
    if (travel) {
      setCoordenates({
        latDestiny: travel.travel.latDestiny,
        latOrigin: travel.travel.latOrigin,
        lngDestiny: travel.travel.lngDestiny,
        lngOrigin: travel.travel.lngOrigin,
        addressDestiny: travel.travel.addressDestiny,
      });
      setMapVisible(true);
      setLoadedMap(false);
    }
  }, [travel]);

  useEffect(() => {
    setMapVisible(false);
  }, []);

  useEffect(() => {
    if (!isFetchingCollaborators && collaborators && user) {
      const userSel = collaborators.filter((userSelected) => userSelected.id === user.id)[0];
      setCollaboratorSelected({
        value: userSel.id,
        label: `${userSel.firstName} ${userSel.firstLastName} - ${userSel.memberCode}`,
      });
    }
  }, [user, collaborators]);

  const changeCollaborator = (e) => {
    if (collaboratorSelected) {
      dispatch(getUserById(e.value));
      dispatch(getRacesByUser(e.value, 'idCollaborator', 0, 5));
    }
    setloadMoreRaces(false);
    racesPagination.current = 1;
    setMapVisible(false);
  };

  useEffect(() => {
    if (!loadedMap) {
      setTimeout(() => {
        setLoadedMap(true);
      }, 1000);
    }
  }, [response]);

  const directionsCallback = (response) => {
    if (response !== null && count.current < 2) {
      if (response.status === 'OK') {
        count.current += 1;
        setResponse(response);
        let tempMarkers = [];
        response?.routes[0]?.legs.map((route, index) => {
          if (index === 0) {
            tempMarkers.push({
              lat: Number(route.start_location.lat()),
              lng: Number(route.start_location.lng()),
            });
            tempMarkers.push({
              lat: Number(route.end_location.lat()),
              lng: Number(route.end_location.lng()),
            });
          } else {
            tempMarkers.push({
              lat: Number(route.end_location.lat()),
              lng: Number(route.end_location.lng()),
            });
          }
        });
        setMarkers(tempMarkers);
      } else {
        count.current = 0;
      }
    }
    if (response !== null) {
      if (response.status === 'OK') {
        setResponse(response);
        let tempMarkers = [];
        response?.routes[0]?.legs.map((route, index) => {
          if (index === 0) {
            tempMarkers.push({
              lat: Number(route.start_location.lat()),
              lng: Number(route.start_location.lng()),
            });
            tempMarkers.push({
              lat: Number(route.end_location.lat()),
              lng: Number(route.end_location.lng()),
            });
          } else {
            tempMarkers.push({
              lat: Number(route.end_location.lat()),
              lng: Number(route.end_location.lng()),
            });
          }
        });
        setMarkers(tempMarkers);
      } else console.log('response: ', response);
    }
  };

  const mapVisibility = (race) => {
    dispatch(getTravelDetailsByIdHistorial(race.travel.id));
  };

  const scrollHandle = () => {
    if (
      scrollRef.current &&
      !isFetchingRacesByUser
      // &&
      // racesPagination.current < Math.ceil(racesByUser?.count / 5)
    ) {
      if (scrollRef.current.scrollTop + scrollRef.current.clientHeight > scrollRef.current.scrollHeight - 15) {
        setloadMoreRaces(true);
        dispatch(upadteRacesByUser(collaboratorSelected.value, 'favorita', racesPagination.current * 5, 5));
        racesPagination.current += 1;

        if (racesByUser.aux && racesByUser.aux.length < 5) {
          setloadMoreRaces(false);
        }
      }
    }
  };

  return (
    <Container className="dashboard">
      <Col md={12}>
        {!isFetchingCollaborators && collaborators ? (
          <>
            <div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
              <h3 className="page-title" style={{ fontSize: '25px', marginTop: '15px', marginBottom: '5px' }}>
                {' '}
                Carreras del{' '}
                <span className="text-danger">
                  Usuario {user?.firstName} {user?.firstLastName}
                </span>
              </h3>
              <div className="d-flex my-2">
                <div style={{ marginRight: '15px', minWidth: '200px' }}>
                  <Select
                    className="select__control"
                    classNamePrefix="select"
                    name="Colaborador"
                    isSearchable={true}
                    placeholder={'Colaboradores'}
                    isDisabled={isFetchingRacesByUser}
                    options={collaborators.map((user) => ({
                      value: user.id,
                      label: `${user.firstName} ${user.firstLastName} - ${user.memberCode}`,
                    }))}
                    value={collaboratorSelected}
                    onChange={(e) => {
                      if (e === null) setCollaboratorSelected(null);
                      else changeCollaborator(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <Row>
              <Col md={6} xs={12}>
                <div
                  ref={scrollRef}
                  onScroll={() => scrollHandle()}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '20px',
                    margin: '15px 10px 0px 20px',
                    height: '70vh',
                    overflowY: 'auto',
                  }}
                >
                  {racesByUser?.data?.map((race, index) => (
                    <div>
                      <div style={{ display: 'flex' }}>
                        <CarHatchbackIcon
                          className="carICon"
                          color="#999999"
                          style={{
                            height: '35px',
                            width: '35px',
                            marginRight: '10px',
                          }}
                        ></CarHatchbackIcon>
                        <div>
                          <h5
                            style={{
                              color: '#1A0C12',
                              fontWeight: 600,
                              fontSize: '20px',
                              margin: '7px 0',
                            }}
                          >
                            Favorita
                            <EyeOutlineIcon style={{ marginLeft: '20px', cursor: 'pointer' }} color="#7C7C7C" onClick={() => mapVisibility(race)}></EyeOutlineIcon>
                          </h5>
                          <h6 style={{ marginBottom: '20px' }}>{race.travel.hour} en punto de destino</h6>
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <h6 style={{ marginBottom: '7px' }}>Punto de partida:</h6>
                          <h6 style={{ marginBottom: '7px' }}>Punto de llegada:</h6>
                          <h6 style={{ marginBottom: '7px' }}>Conductor:</h6>
                          <h6 style={{ marginBottom: '7px' }}>Costo:</h6>
                          <h6 style={{ marginBottom: '7px' }}>Fecha:</h6>
                          <h6 style={{ marginBottom: '7px' }}>Hora:</h6>
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                          <h6 style={{ marginBottom: '7px' }}>{race.travel.addresOrigin}</h6>
                          <h6 style={{ marginBottom: '7px' }}>{race.travel.addressDestiny}</h6>
                          <h6 style={{ marginBottom: '7px' }}>{`${race.travel.driver.firstName} ${race.travel.driver.firstLastName}`}</h6>
                          <h6 style={{ marginBottom: '7px' }}>${race.price}</h6>
                          <h6 style={{ marginBottom: '7px' }}> {race.travel.date}</h6>
                          <h6 style={{ marginBottom: '7px' }}> {race.travel.hour} </h6>
                        </div>
                      </div>
                    </div>
                  ))}
                  {loadMoreRaces && (
                    <div style={{ height: '150px', paddingBottom: '20px' }}>
                      <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div style={{ height: 'auto', paddingLeft: 0 }}>
                  {mapVisible && coordenates !== null && (
                    <GoogleMap
                      id="map_raceTravel"
                      mapContainerStyle={{ height: '70vh', width: '100%' }}
                      center={{ lat: -0.225219, lng: -78.5248 }}
                      zoom={7}
                      options={{
                        streetViewControl: false,
                        zoomControl: false,
                        mapTypeControl: false,
                        minZoom: 2,
                      }}
                    >
                      <InfoWindow
                        visible={true}
                        position={{
                          lat: Number(coordenates.latDestiny),
                          lng: Number(coordenates.lngDestiny),
                        }}
                      >
                        <div>
                          {loadedMap ? (
                            <>
                              <h4 style={{ textAlign: 'center', fontWeight: 500 }}>{response?.routes[0]?.legs.length > 1 ? `${Math.round(response?.routes[0]?.legs.reduce((a, b) => (a += b.duration.value), 0) / 60)} min` : response?.routes[0]?.legs[0]?.duration?.text}</h4>
                              <h5>{coordenates.addressDestiny}</h5>
                            </>
                          ) : (
                            <>
                              <Spinner
                                className="table-fetch-spinner"
                                style={{
                                  marginLeft: '47%',
                                  height: '20px',
                                  width: '20px',
                                }}
                              />
                              <h5 style={{ textAlign: 'center', marginTop: '7px' }}>Cargando</h5>
                            </>
                          )}
                        </div>
                      </InfoWindow>

                      {markers.map((waypoint, index) => (
                        <Marker
                          position={{
                            lat: waypoint.lat,
                            lng: waypoint.lng,
                          }}
                          icon={{
                            url: index === 0 ? locationIcon : 'https://cdn.pixabay.com/photo/2015/08/27/10/30/ban-909970_1280.png',
                            scaledSize: index === 0 ? new google.maps.Size(30, 30) : new google.maps.Size(20, 20),
                          }}
                        />
                      ))}

                      <DirectionsService
                        options={{
                          origin: new google.maps.LatLng(Number(coordenates.latOrigin), Number(coordenates.lngOrigin)),
                          destination: new google.maps.LatLng(Number(coordenates.latDestiny), Number(coordenates.lngDestiny)),
                          waypoints: travel.travel.detailTravels
                            .filter((waypoint, index, { length }) => index < length - 1)
                            .map((waypoint) => ({
                              location: new google.maps.LatLng(Number(waypoint.lat), Number(waypoint.lng)),
                              stopover: true,
                            })),
                          travelMode: 'DRIVING',
                        }}
                        callback={directionsCallback}
                      />

                      {loadedMap && (
                        <DirectionsRenderer
                          options={{
                            directions: response,
                            suppressMarkers: true,
                            polylineOptions: {
                              strokeColor: '#ed3e44',
                            },
                          }}
                        />
                      )}
                    </GoogleMap>
                  )}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
        )}
      </Col>
    </Container>
  );
}
