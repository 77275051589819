import { handleActions } from 'redux-actions';
import { addBreads, getBreadCrums, pushBreadCrums } from '../actions/breadcrumsActions';

const defaultState = {
  paths: [],
};

export default handleActions(
  {
    [getBreadCrums](state) {
      return {
        ...state,
      };
    },
    [pushBreadCrums](state, { payload }) {
      return {
        ...state,
        paths: [...state.paths, payload],
      };
    },
    [addBreads](state, { payload }) {
      return {
        ...state,
        paths: [...payload],
      };
    },
  },
  defaultState
);
