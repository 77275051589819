import {  handleActions } from "redux-actions";

const defaultState = [];

import {
    fetchModelsRequest,
    fetchModelsSuccess,
    fetchBrandsSuccess,
    fetchModelsError,
  } from "../actions/modelsAction";

export default handleActions(
    {
      [fetchModelsRequest](state) {
        return {
          ...state,
          isFetching: true,
          error: null,
        };
      },
      [fetchModelsSuccess](state, { payload }) {
        return {
          ...state,
          data: payload,
          isFetching: false,
          error: null,
        };
      },
      [fetchBrandsSuccess](state, { payload }) {
        return {
          ...state,
          dataBrand: payload,
          isFetching: false,
          error: null,
        };
      },
      [fetchModelsError](state, { payload }) {
        return {
          ...state,
          isFetching: false,
          error: payload,
        };
      },
    },
    defaultState
  );