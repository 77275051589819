import { get, put, post, deleteRequest } from "../base/index";

export default {
  getAllProvince: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/provinces`);
  },

  getAllRates: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/rates/allrate`);
  },

  getDetailRateByRateId: (rateId) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/rates/detailratebyid/${rateId}`
    );
  },

  getDetailRateById: (rateId) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/rates/getdetailratebyid/${rateId}`
    );
  },

  postNewRate: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/rates/create/newrate`, body);
  },

  postDetailRate: (body) => {
    return post(
      `${process.env.REACT_APP_URL_BASE}/rates/create/newdetailrate`,
      body
    );
  },

  deleteRate: (idRate) => {
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/rates/create/newrate/${idRate}`
    );
  },

  deleteDetailRate: (idDetailRate) => {
    
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/rates/create/newdetailrate/${idDetailRate}`
    );
  },

  putProvinceById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/provinces/${id}`, body);
  },

  putDetailRate: (id, body) => {
    return put(
      `${process.env.REACT_APP_URL_BASE}/rates/create/newdetailrate/${id}`,
      body
    );
  },
};
