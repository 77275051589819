import {  handleActions } from "redux-actions";

const defaultState = [];

import {
    fetchColorsRequest,
    fetchColorsSuccess,
    fetchColorsError,
  } from "../actions/colorsActions";

export default handleActions(
    {
      [fetchColorsRequest](state) {
        return {
          ...state,
          isFetching: true,
          error: null,
        };
      },
      [fetchColorsSuccess](state, { payload }) {
        return {
          ...state,
          data: payload,
          isFetching: false,
          error: null,
        };
      },
      [fetchColorsError](state, { payload }) {
        return {
          ...state,
          isFetching: false,
          error: payload,
        };
      },
    },
    defaultState
  );