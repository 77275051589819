import React from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import CheckIcon from 'mdi-react/CheckBoldIcon';
import CloseThickIcon from 'mdi-react/CloseThickIcon';

const Authorizations = () => {

  const dataAuthorizedRequests = [
    {
      name: "Carlos Morillo",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Raúl Chavez",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Mario Carrasco",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Carlos Morillo",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Raúl Chavez",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Mario Carrasco",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    }
  ]

  const dataAuthorizationRequested = [
    {
      name: "Juan Espinoza",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Carlos Morillo",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Mario Carrasco",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    },
    {
      name: "Raúl Chavez",
      date: "28/10/2021",
      Route: "Supermaxi CCI - Megamaxi 6 de Diciembre"
    }
  ]

  return (
    <Container className="authorizations">
      <Col md={5} className="col__left">
        <h3 className="page-title">Autorización: <span className="text-danger">Crear</span></h3>
        <Form>
          <FormGroup row>
            <Label for="fecha" sm={2}>
              Título:
            </Label>
            <Col sm={10}>
              <Input id="title" name="title" placeholder="Nombre de la Autorización" type="text" className="AuthorizationsInput" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fecha" sm={2}>
              Asunto:
            </Label>
            <Col sm={10}>
              <Input id="title" name="title" placeholder="Descripción de la Solicitud" type="text" className="AuthorizationsInput" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fecha" sm={2}>
              Código:
            </Label>
            <Col sm={6} style={{ paddingRight: '0' }} >
              <Input id="title" name="title" placeholder="Código" type="number" className="AuthorizationsInput" />
            </Col>
            <Col sm={4} s>
              <Input id="title" name="title" placeholder="Generar Código" type="number" className="AuthorizationsInput" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fecha" sm={2}>
              Fecha:
            </Label>
            <Col sm={10}>
              <Input id="title" name="title" placeholder="Nombre de la Autorización" type="date" className="AuthorizationsInput" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="fecha" sm={2}>
              Hora:
            </Label>
            <Col sm={10}>
              <Input id="title" name="title" placeholder="Seleccionar la hora" type="time" className="AuthorizationsInput" />
            </Col>
          </FormGroup>
        </Form>
        <Row className="authorizationRequested">
          <h3 className="page-title">Autorizar <span className="text-danger">Solicitudes</span></h3>
          <div className="authorizationsContainer">
            {
              dataAuthorizationRequested.map((item) => (
                <div className="authorizationContainer">
                  <div className="authorizationText">
                    <h6>{item.name} {item.date}</h6>
                    <h6>Ruta: {item.Route}</h6>
                  </div>
                  <div className="authorizationButtons">
                    <button>ACEPTAR <CheckIcon color="#fff" style={{ marginLeft: "4px", height: "20px", width: "20px" }} /></button>
                    <button>RECHAZAR <CloseThickIcon color="#fff" style={{ marginLeft: "5px", height: "17px", width: "24px" }} /></button>
                  </div>
                </div>
              ))
            }
          </div>
        </Row>
      </Col>
      <Col md={7} className="col__right ">
        <Row className="authorizedRequests">
          <h3 className="page-title">Solicitudes <span className="text-danger">Autorizadas</span></h3>
          <div className="authorizationsContainer">
            {
              dataAuthorizedRequests.map((item) => (
                <div className="authorizationContainer">
                  <div className="authorizationText">
                    <h6>{item.name} {item.date}</h6>
                    <h6>Ruta: {item.Route}</h6>
                  </div>
                </div>
              ))
            }
          </div>
        </Row>
      </Col>
    </Container>
  )
};

export default Authorizations;
