import { createAction } from "redux-actions";
import api from "../../utils/api/apiProvider";
import { toast } from "react-toastify";

export const fetchBrandsRequest = createAction("FETCH_BRANDS_REQUEST");
export const fetchBrandsSuccess = createAction("FETCH_BRANDS_SUCCESS");
export const fetchBrandsError = createAction("FETCH_BRANDS_ERROR");

export const listBrands = () => async (dispatch) => {
  try {
    //dispatch(fetchBrandsRequest());
    const response = await api.brand.getBrands();
    dispatch(fetchBrandsSuccess(response.data));
  } catch (error) {
    dispatch(fetchBrandsSuccess({ error }));
    toast.error("Error obteniendo el reporte de las marcas", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const putBrandById = (id, body) => async (dispatch) => {
  try {
    //dispatch(fetchBrandsRequest());
    await api.brand.putBrandById(id, body);
    toast.success("Marca actualizada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listBrands());
  } catch (error) {
    dispatch(fetchBrandsError({ error }));
    toast.error("Error al actualizar la marca", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postBrand = (body) => async (dispatch) => {
  try {
    //dispatch(fetchBrandsRequest());
    await api.brand.postBrand(body);
    toast.success("Marca añadida correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listBrands());
  } catch (error) {
    dispatch(fetchBrandsError({ error }));
    toast.error("Error al añadir la marca", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const deleteBrand = (id) => async (dispatch) => {
  try {
    //dispatch(fetchBrandsRequest());
    await api.brand.deleteBrandById(id);
    toast.success("Marca eliminada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listBrands());
  } catch (error) {
    dispatch(fetchBrandsError({ error }));
    toast.error("Error al eliminar la marca", {
      autoClose: 2500,
      theme: "colored",
    });
  }
};
