import { get, put, post, deleteRequest } from "../base/index";

export default {
  getProblems: (roleId) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/parameters/reportproblems/rol/${roleId}`
    );
  },
  putProblemById: (id, body) => {
    return put(
      `${process.env.REACT_APP_URL_BASE}/parameters/reportproblems/${id}`,
      body
    );
  },
  postNewProblem: (body) => {
    return post(
      `${process.env.REACT_APP_URL_BASE}/parameters/reportproblems`,
      body
    );
  },
  deleteProblemById: (id) => {
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/parameters/reportproblems/${id}`
    );
  },
};
