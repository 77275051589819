import React, { useEffect, useMemo, useState, useContext, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from '../../../context/socket';
import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';

import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
moment.locale('es');
var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months: meses, weekdays: semanas });

import TableBase from './components/TableBase';
import { getJourneyAlerts, updateStatusTravel } from '../../../redux/actions/dashboardActions';

import socketio from 'socket.io-client';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

export default function index() {
  const dispatch = useDispatch();
  const journeyAlertsList = useSelector((state) => state.dashboard.journeyAlerts);
  const isFetchingJourneyAlertsList = useSelector((state) => state.dashboard.isFetching);
  const socket = useRef(socketio.connect(process.env.REACT_APP_URL_SOCKET));
  const statusList = [
    { value: 'pending', label: 'Pendiente' },
    { value: 'accepted', label: 'Aceptado' },
    { value: 'completed', label: 'Completado' },
    { value: 'onway', label: 'En camino' },
    { value: 'traveling', label: 'Viajando' },
  ];
  const [scheduleFilter, setScheduleFilter] = useState({
    value: 'horario',
    label: 'Horario',
  });
  const [statusFilter, setStatusFilter] = useState({
    value: 'pending',
    label: 'Pendiente',
  });
  const [colaboratorFilter, setColaboratorFilter] = useState(null);
  const [newAlert, setNewAlert] = useState([]);
  const [dateFilter, setDateFilter] = useState(null);
  const [typeRequestFilter, setTypeRequestFilter] = useState(null);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Alertas de viaje');
  }, [location]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Asunto',
        accessor: 'Asunto',
        disableFilters: true,
        Cell: (cellObj) => (
          <h6 style={{ color: '#646777', marginTop: '2.5px', fontSize: '13px' }}>
            <AccountCircleIcon color="#000000" style={{ marginRight: '10px' }} />
            Fuera de fecha asignada
          </h6>
        ),
      },
      {
        Header: 'Colaborador',
        accessor: 'firstName',
        Cell: (cellObj) => (
          <h6
            style={{
              color: '#4B545A',
              marginTop: '2.5px',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            <span style={{ textTransform: 'uppercase' }}>{cellObj.cell.row.original.collaborator.firstName}</span> <span style={{ textTransform: 'uppercase' }}>{cellObj.cell.row.original.collaborator.firstLastName}</span>
          </h6>
        ),
        disableFilters: true,
      },
      {
        Header: 'Fecha',
        accessor: (row) => moment(row.createdAt).format('YYYY-MM-DD'),
        disableFilters: true,
      },
      {
        Header: 'Destino',
        accessor: 'addressDestiny',
        disableFilters: true,
      },
      {
        Header: 'Detalles',
        accessor: '[actionButton]',
        Cell: (cellObj) => (
          <div style={{ marginLeft: '25px' }}>
            <button className="table__action__icon">
              <Link to={`/dashboard/JourneyAlertDetails/${cellObj.cell.row.original.id}`}>
                <EyeOutlineIcon color="#7C7C7C" />
              </Link>
            </button>
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Estado de viaje',
        accessor: 'status',
        disableSortBy: true,
        Cell: (cellObj) => (
          <Select
            className="select__control"
            classNamePrefix="selectTable"
            name="Estado"
            placeholder={'Estado'}
            color
            isDisabled={cellObj.cell.row.original.status === 'completed' || cellObj.cell.row.original.status === 'traveling' || cellObj.cell.row.original.status === 'onway'}
            styles={{
              control: (styles, { isDisabled }) => ({
                ...styles,
                height: 30,
                minHeight: 30,
                backgroundColor: isDisabled ? '#c7c7c7 !important' : '#D6D8DB',
                color: isDisabled ? '#e9e9ea !important' : '#D6D8DB',
                cursor: isDisabled ? 'not-allowed !important' : 'auto',
              }),
            }}
            menuPosition={'fixed'}
            value={statusList.filter((item) => item.value === cellObj.cell.row.original.status)}
            onChange={(e) => {
              socket.current.emit('notificar', {
                id: cellObj.cell.row.original.id,
                status: e.value,
              });

              dispatch(
                updateStatusTravel(cellObj.cell.row.original.id, {
                  status: e.value,
                  email: cellObj.cell.row.original.email,
                })
              );
              const newDataAlert = newAlert.filter((alert) => alert.id !== cellObj.cell.row.original.id);
              setNewAlert(newDataAlert);
            }}
            options={[
              { value: 'pending', label: 'Pendiente' },
              { value: 'accepted', label: 'Aceptado' },
              { value: 'reject', label: 'Rechazado' },
            ]}
          />
        ),
        disableFilters: true,
      },
    ];
  }, [journeyAlertsList, newAlert]);

  const filterOptions = (filterAttr, filterAttr2) => {
    let arrayToReturn = [];
    let filterColumnData = [];
    [...newAlert, ...journeyAlertsList].map((value) => {
      if (value[filterAttr] !== null && value[filterAttr2] !== null) filterColumnData.push(`${value[filterAttr]} ${value[filterAttr2]}`);
    });

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    filterColumnData.filter(onlyUnique).map((value) => arrayToReturn.push({ value, label: value }));

    return arrayToReturn;
  };

  const scheduleFilterData = () => {
    let dataReturn = [];

    if (scheduleFilter === null) dataReturn = [...newAlert, ...journeyAlertsList];
    else {
      if (scheduleFilter.value === 'horario') dataReturn = [...newAlert, ...journeyAlertsList].filter((alert) => moment(alert.createdAt, 'HH:mm').isBetween(moment('04:01', 'HH:mm'), moment('21:59', 'HH:mm')));
      else dataReturn = [...newAlert, ...journeyAlertsList].filter((alert) => moment(alert.createdAt, 'HH:mm').isBetween(moment('22:00', 'HH:mm'), moment('04:00', 'HH:mm')));
    }

    if (statusFilter !== null) dataReturn = dataReturn.filter((data) => data.status === statusFilter.value);
    if (colaboratorFilter !== null) {
      const indexSpace = colaboratorFilter.value.indexOf(' ');
      dataReturn = dataReturn.filter((data) => data.firstName === colaboratorFilter.value.slice(0, indexSpace) && data.firstLastName === colaboratorFilter.value.slice(indexSpace + 1));
    }
    if (dateFilter !== null) dataReturn = dataReturn.filter((data) => moment(data.createdAt).format('YYYY-MM-DD') === dateFilter);
    if (typeRequestFilter !== null) {
      if (typeRequestFilter.value === 'now') {
        dataReturn = dataReturn.filter((data) => data.typeRequest === 'now');
      } else {
        dataReturn = dataReturn.filter((data) => data.typeRequest === 'schedule');
      }
    }

    return dataReturn;
  };

  const handleNewAlert = useCallback((res) => {
    setNewAlert((prevArray) => [...res.data, ...prevArray]);
  }, []);

  useEffect(() => {
    socket.current = socketio.connect(process.env.REACT_APP_URL_SOCKET);
    dispatch(getJourneyAlerts());
    socket.current.on('emit_order_admin', handleNewAlert);
    return () => {
      socket.current.emit('desconectar');
    };
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: 'flex', flexDirection: 'row', paddingTop: '21px' }}>
          <h3 className="page-title">
            Alertas de <span className="text-danger">viaje</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Container
          style={{
            paddingLeft: '38px',
            paddingBottom: '20px',
            paddingRight: '30px',
            marginLeft: '0px',
            width: '95vw',
          }}
        >
          {!isFetchingJourneyAlertsList && journeyAlertsList ? (
            <>
              <div className="d-flex flex-wrap">
                <Select
                  className="basic-single"
                  classNamePrefix="selectDataJourneyAlerts4"
                  name="typeRequest"
                  isClearable={true}
                  placeholder={'Tipo de solicitud'}
                  options={[
                    { value: 'now', label: 'Ahora' },
                    { value: 'schedule', label: 'Agendada' },
                  ]}
                  menuPosition={'fixed'}
                  onChange={(e) => {
                    if (e === null) setTypeRequestFilter(null);
                    else setTypeRequestFilter(e);
                  }}
                />

                <Select
                  className="basic-single"
                  classNamePrefix="selectDataJourneyAlerts"
                  name="horario"
                  isClearable={true}
                  placeholder={'Tipo de alerta'}
                  options={[
                    { value: 'horario', label: 'Horario' },
                    { value: 'fuera de horario', label: 'Fuera de Horario' },
                  ]}
                  defaultValue={{ value: 'horario', label: 'Horario' }}
                  menuPosition={'fixed'}
                  onChange={(e) => {
                    if (e === null) setScheduleFilter(null);
                    else setScheduleFilter(e);
                  }}
                />
                <Select
                  className="basic-single"
                  classNamePrefix="selectDataJourneyAlerts2"
                  name="Estado"
                  isClearable={true}
                  isSearchable={false}
                  placeholder={'Estado'}
                  options={statusList}
                  defaultValue={{ value: 'pending', label: 'Pendiente' }}
                  menuPosition={'fixed'}
                  onChange={(e) => {
                    if (e === null) setStatusFilter(null);
                    else setStatusFilter({ value: e.value, label: e.label });
                  }}
                />
                <Select
                  className="basic-single"
                  classNamePrefix="selectDataJourneyAlerts3"
                  name="Colaborador"
                  isClearable={true}
                  isSearchable={true}
                  placeholder={'Colaborador'}
                  options={filterOptions('firstName', 'firstLastName')}
                  menuPosition={'fixed'}
                  value={colaboratorFilter}
                  onChange={(e) => {
                    if (e === null) setColaboratorFilter(null);
                    else setColaboratorFilter({ value: e.value, label: e.label });
                  }}
                />
                <Input
                  id="selectorDate"
                  name="date"
                  placeholder="Seleccione Fecha"
                  type="date"
                  onChange={(e) => {
                    if (e.target.value === '') setDateFilter(null);
                    else setDateFilter(moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                  }}
                  max={moment().format('YYYY-MM-DD')}
                  style={{
                    width: '170px',
                    margin: 10,
                    backgroundColor: '#D6D8DB',
                    borderRadius: '8.9px',
                    boxShadow: '7.34286px 7.34286px 17.6229px rgb(166 180 200 / 70%)',
                    border: 'none',
                  }}
                />
              </div>
              <div style={{ marginTop: '50px' }}>
                <TableBase
                  reactTableData={{
                    tableHeaderData: columns,
                    tableRowsData: scheduleFilterData(),
                  }}
                />
              </div>
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Container>
      </Row>
    </Container>
  );
}
