import { Input } from 'reactstrap';
import EyeIcon from 'mdi-react/MagnifyIcon';

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter, Header } }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", backgroundColor: "#D6D8DB", boxShadow: "7.34286px 7.34286px 17.6229px rgb(166 180 200 / 70%)", borderRadius: "8.8171px", border: "none", paddingRight: "4px" }}>
            <Input
                className="material-form__field"
                placeholder={`${Header}`}
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                style={{
                    width: '95%',
                    backgroundColor: "#D6D8DB",
                    border: "none",
                    color: "#383838",
                    outline: "none",
                    boxShadow: "none",
                    borderTopLeftRadius: "8.8171px",
                    borderBottomLeftRadius: "8.8171px"
                }}
            />
            <EyeIcon style={{ paddingRight: "4px" }} />
        </div>
    );
}

export default DefaultColumnFilter;