import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from 'reactstrap';
import Select from "react-select";
import Radio from '@material-ui/core/Radio';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import Switch from '@material-ui/core/Switch';

import TableBase from './components/TableBase';
import { getDriversByProvider, putUserByIdList } from "../../../redux/actions/usersAction";
import { listProvinces } from "../../../redux/actions/locationAction";

const Affiliate = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const listDrivers = useSelector((state) => state.users.driverByProvider);
  const provincesLists = useSelector((state) => state.location.data);
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);
  const [rol, setRol] = useState(5);

  const columns = useMemo(() =>
    [
      {
        Header: 'Conductor',
        accessor: (data) => (`${data.firstName} ${data.firstLastName} ${data.secondLastName}`),
      },
      {
        Header: 'Tipo de Conductor',
        accessor: 'status',
        disableFilters: true
      },
      {
        Header: 'Cabeza de transporte',
        accessor: (data) => (`${data.firstName} ${data.firstLastName} ${data.secondLastName}`),
      },
      {
        Header: 'Provincia',
        accessor: 'Provincia',
        Cell: (cellObj) => (
          <>
            {printProvince(listDrivers[cellObj.row.index].cityId)}
          </>
        ),
        disableFilters: true
      },
      {
        Header: 'Servicio Promedio',
        accessor: 'phoneNumber',
        disableFilters: true
      },
      {
        Header: 'Ticket Promedio',
        accessor: 'dni',
        disableFilters: true
      },
      {
        Header: () => (
          <div style={{ marginLeft: "50px" }}>
            Habilitado
          </div>
        ),
        accessor: "[enabledButton]",
        Cell: (cellObj) => (
          <div style={{ textAlign: "center" }}>
            <Switch
              checked={cellObj.row.original.status === "accepted"}
              onChange={() => handleUserEnabled(cellObj.row.original)}
            />
          </div>
        ),
        disableFilters: true
      },
      {
        Header: "Acciones",
        accessor: "[editButton]",
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleDataReport(cellObj.row.index)}>
              <ChartBarIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true
      },
    ],
    [listDrivers]
  );

  const handleUserEnabled = (user) => {
    let body = {};
    if (user.status === "accepted") body = { status: "pending" };
    else body = { status: "accepted" }
    dispatch(putUserByIdList(user.id, body, rol))
  }

  const printProvince = (id) => {
    if (id) {
      const province = provincesLists.find(el => el.id === id);
      if (province) {
        return province.nameProvince;
      } else {
        return "Sin provincia añadida";
      }
    }
    return "Sin Ubicación";
  }

  useEffect(() => {
    dispatch(getDriversByProvider(id));
  }, [id]);

  useEffect(() => {
    if (provincesLists === null) dispatch(listProvinces());
  }, [])

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <h3 className="page-title">Conductores <span className="text-danger">Afiliados</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          {!isFetchingusersLists && listDrivers
            ? <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: listDrivers }} />
            : <Spinner className="table-fetch-spinner" style={{ marginLeft: "50%" }} />
          }
        </Container>
      </Row>
    </Container>
  )
};

export default Affiliate;