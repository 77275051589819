import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon  from 'mdi-react/CloseIcon';


export const SelectOption = ({ styles, disabled, inputLabel, option, options, handleChange, onClick }) => {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      //
      className='input-dashboard '
      disabled={disabled}
      style={{ minWidth: 300, ...styles, marginTop: 8}}
      id="outlined-select-currency"
      select
      label={inputLabel}
      value={option}
      onChange={handleChange}
      variant="outlined"
      InputProps={{
        endAdornment: onClick && option ? (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={onClick} onMouseDown={handleMouseDownPassword}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
      }}
    >
      {options &&
        options.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
    </TextField>
  );
};
