import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import { deleteZone, listZones, postZone, putZoneById } from '../../../redux/actions/zoneAction';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { Form } from './components/Form';
import TableBase from './components/TableBase';
import { Link, useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const validationSchema = yup.object({
  zone: yup.string('Ingrese la zona').required('La zona es requerida'),
});

const index = () => {
  const dispatch = useDispatch();
  /* const zonesList = useSelector() */
  const zoneList = useSelector((state) => state.zones.data);
  const isFetchingZoneList = useSelector((state) => state.zones.isFetching);
  const formSave = useFormik({
    initialValues: {
      zone: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postZone({
          nameRegion: values.zone,
          idCountry: 2,
        })
      );
      formSave.resetForm();
    },
  });

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Zonas', 1);
  }, [location]);

  const formUpdate = useFormik({
    initialValues: {
      zone: '',
      id: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        putZoneById(values.id, {
          nameRegion: values.zone,
          idCountry: 2,
        })
      );
      formUpdate.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Zonas',
        accessor: 'zone',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.nameRegion}</span>,
        disableFilters: true,
      },
      {
        Header: ' ',
        accessor: ' ',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'admin',
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellObj) => (
          <Link to={`/settings/zones/provinces/${cellObj.cell.row.original.id}/${cellObj.cell.row.original.nameRegion}`}>
            <Button color="danger" style={{ marginBottom: '0px', padding: '5px 20px' }}>
              Administrar
            </Button>
          </Link>
        ),
      },

      {
        Header: 'Acciones',
        accessor: 'actions',
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleUpdateZone(cellObj.cell.row.original)}>
              <EditIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDeleteZone(cellObj.cell.row.original.id)}>
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [zoneList]
  );

  const handleDeleteZone = (id) => {
    dispatch(deleteZone(id));
  };

  const handleUpdateZone = (objectZone) => {
    formUpdate.setValues({ zone: objectZone.nameRegion, id: objectZone.id });
  };

  useEffect(() => {
    dispatch(listZones());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={7} md={12}>
          <TitleSettings title="Zonas" />
          {!isFetchingZoneList && zoneList ? (
            <>
              <TableBase
                reactTableData={{
                  tableHeaderData: columns,
                  tableRowsData: zoneList,
                }}
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>

        <Col lg={5} md={12}>
          <Row>
            <Col lg={12}>
              <Form title="Agregar" titleRed="Nueva Zona" titleButton="Agregar Zona" placeholderInput="Nombre de la zona" value={formSave.values.zone} handleChange={formSave.handleChange} touched={formSave.touched.zone} errors={formSave.errors.zone} handleSubmit={formSave.handleSubmit}></Form>
            </Col>
            <Col lg={12} style={{ marginTop: '20px' }}>
              <Form title="Editar" titleRed="Zona Ingresada" titleButton="Actualizar Zona" placeholderInput="Escoger una zona de la tabla" value={formUpdate.values.zone} handleChange={formUpdate.handleChange} touched={formUpdate.touched.zone} errors={formUpdate.errors.zone} handleSubmit={formUpdate.handleSubmit} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
