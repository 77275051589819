import { createAction } from "redux-actions";
import { toast } from "react-toastify";
import api from "../../utils/api/apiProvider";

export const fetchProvincesRequest = createAction("FETCH_PROVINCES_REQUEST");
export const fetchProvincesSuccess = createAction("FETCH_PROVINCES_SUCCESS");
export const fetchProvincesError = createAction("FETCH_PROVINCES_ERROR");

export const listProvinces = (idZone) => async (dispatch) => {
  try {
    dispatch(fetchProvincesSuccess());
    const response = await api.provinces.getProvincesByZone(idZone);
    dispatch(fetchProvincesSuccess(response.data.ProvinceBD));
  } catch (error) {
    dispatch(fetchProvincesError({ error }));
    toast.error("Error obteniendo el reporte de las provincias", {
      autoClose: 2500,
      theme: "colored",
    });

    throw error;
  }
};

export const putProvinceById = (id, body, idZone) => async (dispatch) => {
  try {
    dispatch(fetchProvincesRequest());
    await api.provinces.putProvinceById(id, body);
    toast.success("Provincia actualizada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listProvinces(idZone));
  } catch (error) {
    dispatch(fetchProvincesError({ error }));
    toast.error("Error al actualizar la provincia", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const postProvince = (body, idZone) => async (dispatch) => {
  try {
    dispatch(fetchProvincesRequest());
    await api.provinces.postProvince(body);
    toast.success("Provincia añadida correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listProvinces(idZone));
  } catch (error) {
    dispatch(fetchProvincesError({ error }));
    toast.error("Error al añadir la provincia", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};

export const deleteProvince = (id, idZone) => async (dispatch) => {
  try {
    dispatch(fetchProvincesRequest());
    await api.provinces.deleteProvince(id);
    toast.success("Provincia eliminada correctamente", {
      autoClose: 2500,
      theme: "colored",
    });
    dispatch(listProvinces(idZone));
  } catch (error) {
    dispatch(fetchProvincesError({ error }));
    toast.error("Error al eliminar la provincia", {
      autoClose: 2500,
      theme: "colored",
    });
    throw error;
  }
};
