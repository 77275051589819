import { handleActions } from "redux-actions";
import {
  fetchParametersRequest,
  fetchParametersSuccess,
  fetchParametersError,
} from "../actions/ParametersActions";

const defaultState = [];
export default handleActions(
  {
    [fetchParametersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchParametersSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchParametersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
