import { createAction } from 'redux-actions';

export const getBreadCrums = createAction('GET_BREAD_CRUMS');
export const pushBreadCrums = createAction('PUSH_BREAD_CRUMS');
export const addBreads = createAction('ADD_BREAD_CRUMS');

export const getBreadcrums = () => async (dispatch) => {
  dispatch(getBreadCrums());
};

export const pushBreadcrums = (breadcrum) => async (dispatch) => {
  dispatch(pushBreadCrums(breadcrum));
};
export const addBreadCrums = (breadcrums) => async (dispatch) => {
  dispatch(addBreads(breadcrums));
};
