import { handleActions } from 'redux-actions';

import { fetchZonesFavorita, fetchZonesError, getCitiesFavorita, fetchEstablishmentsFavorita, fetchZonesRequest, getProvincesFavorita } from '../actions/zoneAction';

const defaultState = {
  zones: [],
  provinces: [],
  cities: [],
  establishments: [],
  isFetching: false,
  error: '',
};

export default handleActions(
  {
    [fetchZonesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchZonesFavorita](state, { payload }) {
      return {
        ...state,
        zones: payload,
        isFetching: false,
        error: null,
      };
    },
    [getProvincesFavorita](state, { payload }) {
      return {
        ...state,
        provinces: payload,
        isFetching: false,
        error: null,
      };
    },
    [getCitiesFavorita](state, { payload }) {
      return {
        ...state,
        cities: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchEstablishmentsFavorita](state, { payload }) {
      return {
        ...state,
        establishments: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchZonesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
