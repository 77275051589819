import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import StarIcon from 'mdi-react/StarIcon';
import DatePicker from 'react-datepicker';
import CalendarOutlineIcon from 'mdi-react/CalendarOutlineIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getUserById, getReviewsByUser, getPrivilegesByUser } from '../../../redux/actions/usersAction';
import { getDataCollaboratorDashboard } from '../../../redux/actions/dashboardActions';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

export default function index({ match }) {
  const {
    params: { userId },
  } = match;
  const dispatch = useDispatch();
  const isFetchingDataDashboardCollaborator = useSelector((state) => state.dashboard.isFetchingCollaborator);
  const dataCollaborator = useSelector((state) => state.dashboard.dataCollaborator);
  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const user = useSelector((state) => state.users.userById);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const [year, setYear] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date(`${new Date().getFullYear()}-01-01  00:00:00`));
  const [endDate, setEndDate] = useState(new Date());

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte');
  }, [location]);

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getReviewsByUser(userId));
    dispatch(getDataCollaboratorDashboard(moment().format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', userId));
    dispatch(getPrivilegesByUser(userId));
  }, []);

  useEffect(() => {
    dispatch(getDataCollaboratorDashboard(moment(year).format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', userId));
  }, [year]);

  const dataGraphics = (graphic) => {
    let data;

    data = dataCollaborator[graphic];

    const resultMapData = Months.map((month) => {
      const index = data.findIndex((monthData) => monthData.Mes === month);
      if (index < 0) return 0;
      else return data[index].Total;
    });

    return {
      labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
      datasets: [
        {
          data: resultMapData,
          backgroundColor: ['rgba(248, 211, 207)', 'rgba(208, 43, 31)', 'rgba(247, 191, 184)', 'rgba(242, 150, 139)', 'rgba(244, 170, 161)', 'rgba(248, 211, 207)', 'rgba(241, 127, 114)', 'rgba(237, 85, 71)', 'rgba(237, 85, 71)', 'rgb(209, 70, 67)', 'rgba(142, 46, 49)', 'rgba(40, 51, 80)'],
          borderWidth: 0,
        },
      ],
    };
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="selectYear">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <CalendarOutlineIcon onClick={props.onClick} style={{ height: '20px', marginLeft: '8px', marginTop: '-3px' }} />
      </div>
    );
  });

  return (
    <Container className="userProfile">
      <Row className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex">
          <AccountOutlineIcon className="iconTitle" />
          <h3 className="page-title" style={{ fontSize: '25px' }}>
            Usuarios
          </h3>
        </div>
        <div className="d-flex">
          <div style={{ marginRight: '15px' }}>
            <DatePicker selected={year} onChange={(date) => setYear(date)} showYearPicker dateFormat="yyyy" className="selectYear" maxDate={new Date()} customInput={<CustomInput />} />
          </div>
          <div className="buttons">
            <Link to={`/users/UserGeneralReport/${userId}`} className="link">
              Ver reporte
            </Link>
          </div>
        </div>
      </Row>
      <Row className="contentUser" style={{ paddingLeft: '20px' }}>
        <div className="leftColumn">
          <img src={`${user?.userImgUrl}`} alt="avatar" />
          <div className="separator"></div>
          <div>
            <div className="profileData">
              <h6 className="company">Corporación Favorita</h6>
              <h6 className="code">Código: {userId}</h6>
              <h6 className="name">
                {user?.firstName} {user?.firstLastName}
              </h6>
              <h6 className="company">
                Puntuación: {isFetchingReviews ? '' : userReviews?.average}
                <StarIcon style={{ height: '16px', marginTop: '-3px', marginLeft: '-3px' }} color="#FFCE31" />
              </h6>
            </div>
          </div>
        </div>
      </Row>
      <Row style={{ margin: '30px 0px 0px 20px', display: 'flex' }}>
        <Col md={8} xs={12}>
          {!isFetchingDataDashboardCollaborator && dataCollaborator ? (
            <>
              <Row>
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Total de <span className="text-danger">gasto</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalCost')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Km <span className="text-danger">promedio</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalKm')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="d-flex flex-column align-items-center justify-content-center">
                <Col md={6} xs={12}>
                  <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                    Número de viajes <span className="text-danger">Servicio</span>
                  </label>
                  <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                    <Bar
                      style={{ height: '200px' }}
                      data={dataGraphics('generalTravels')}
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Col>
        <Col md={4} xs={12} className="mb-4">
          <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
            Novedades del <span className="text-danger">Usuario</span>
          </label>
          <div style={{ width: '100%', backgroundColor: 'white', border: '1px solid #C4C4C4', borderRadius: '13px', padding: '10px', height: '450px' }}>
            {!isFetchingReviews &&
              userReviews?.reviews
                .filter((item) => item.comment !== '')
                .map((review) => (
                  <div className="mb-3">
                    <div className="d-flex align-items-center">
                      <div className="col-1">
                        <AccountCircleIcon color="#000000" style={{ marginRight: '10px' }} />
                      </div>
                      <div className="col-12">
                        <span className="font-weight-bold"> {`${review.sender.firstName} ${review.sender.firstLastName}`}:</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-1"></div>
                      <div className="col-12">
                        <h5 className="cell">{review.comment} </h5>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
