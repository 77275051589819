import React, { useEffect } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import PrivateRoute from '../../routers/PrivateRoute';
import PublicRoute from '../../routers/PublicRoute';
import { tokenRenovation, stopFetchLogin } from '../../redux/actions/authActions';
import './load.css';

import LogIn from '../Account/LogIn/index';

import Dashboard from '../Dashboard/index';
import JourneyAlerts from '../Dashboard/JourneyAlerts/index';
import JourneyAlertDetails from '../Dashboard/JourneyAlertDetails/index';
import GeneralReportGraphs from '../Dashboard/GeneralReportGraphs/index';
import GeneralReportTable from '../Dashboard/GeneralReportTable/index';
import OutHourJourneyAlerts from '../Dashboard/OutHourJourneyAlerts/index';
import HeatZoneMaps from '../Dashboard/HeatZoneMaps/index';
import TotalExpense from '../Dashboard/TotalExpense/index';

import Providers from '../Providers/index';
import ProvidersById from '../Providers/ById/index';
import ProviderGeneralReport from '../Providers/generalReport/index';
import ProviderPersonalData from '../Providers/providerPersonalData/index';

import Drivers from '../Drivers/index';

import VehicleProvider from '../Vehicle/VehiclesByProvider/index';
import VehicleProfile from '../Vehicle/VehicleProfile';

import Contracts from '../Contracts/index';

import Users from '../Users/index';
import UserRaces from '../Users/UserRaces/index';
import User from '../Users/User/index';
import UserReport from '../Users/UserReport/index';
import AuthorizationsHistorial from '../Users/AuthorizationsHistorial/index';
import UserGeneralReport from '../Users/UserGeneralReport/index';
import JourneyDetails from '../Users/JourneyAlertDetails/index';

import Authorizations from '../Authorizations/index';

import Coverage from '../Settings/Coverage/index';
import Guests from '../Settings/Guests/index';
import HelpApp from '../Settings/HelpApp/index';
import Faq from '../Settings/FAQ/index';
import Brand from '../Settings/Brand/index';
import HelpMenu from '../Settings/HelpMenu/index';
import Parameters from '../Settings/Parameters/index';
import Colors from '../Settings/Colors/index';
import Models from '../Settings/Model/index';
import Provinces from '../Settings/Provinces/index';
import Cities from '../Settings/Cities/index';
import Zones from '../Settings/Zones/index';
import Rates from '../Settings/Rates/index';
import AddRates from '../Settings/AddRates/index';
import DetailRates from '../Settings/DetailRates/index';
import AddDetailRates from '../Settings/AddDetailRates/index';
import Reports from '../Settings/Reports/index';
import Routes from '../Settings/Routes/index';
import Transfers from '../Settings/Transfers/index';

import ResetPassword from '../Account/ResetPassword/index';

//drivers general
import Vehicles from '../Drivers/Vehicles/index';
import FrequentDestinations from '../Drivers/FrequentDestinations/index';
import FrequentRivers from '../Drivers/FrequentRivers/index';
import AffiliateDrivers from '../Drivers/AffiliateDrivers/index';
import RegisteredCars from '../Drivers/RegisteredCars/index';
import FrequentPassengers from '../Drivers/FrequentPassengers/index';
import UserDrivers from '../Drivers/UserDrivers/index';
import VehiclesUser from '../Drivers/VehiclesUser/index';
import DriversFactsFigures from '../Drivers/DriversFactsFigures/index';
import DriverPersonalData from '../Drivers/DriverPersonalData/index';
import OwnerUser from '../Drivers/OwnerUser/index';
import DriverGeneralReport from '../Drivers/GeneralReport';

const Pages = () => (
  <Switch>
    <Route path="/authorizations" component={Authorizations} />
    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/dashboard/JourneyAlerts" component={JourneyAlerts} />
    <Route exact path="/dashboard/JourneyAlertDetails/:travelId" component={JourneyAlertDetails} />
    <Route exact path="/dashboard/GeneralReportGraphs" component={GeneralReportGraphs} />
    <Route exact path="/dashboard/GeneralReportTable" component={GeneralReportTable} />
    <Route exact path="/dashboard/HeatZoneMaps" component={HeatZoneMaps} />
    <Route exact path="/dashboard/OutHourJourneyAlerts" component={OutHourJourneyAlerts} />
    <Route exact path="/dashboard/TotalExpense" component={TotalExpense} />

    <Route path="/contracts" component={Contracts} />

    <Route exact path="/users" component={Users} />
    <Route exact path="/users/userRaces/:userId" component={UserRaces} />
    <Route exact path="/users/user/:userId" component={User} />
    <Route exact path="/users/userReport/:userId" component={UserReport} />
    <Route exact path="/users/AuthorizationsHistorial" component={AuthorizationsHistorial} />
    <Route exact path="/users/UserGeneralReport/:userId" component={UserGeneralReport} />
    <Route exact path="/users/UserGeneralReport/JourneyDetails/:travelId" component={JourneyDetails} />

    <Route exact path="/providers" component={Providers} />
    <Route exact path="/providers/:providerId" component={ProvidersById} />
    <Route exact path="/providerPersonalData/:providerId" component={ProviderPersonalData} />
    <Route exact path="/providers/generalReport/:providerId" component={ProviderGeneralReport} />

    <Route exact path="/drivers/registered-cars/:id" component={RegisteredCars} />
    <Route exact path="/drivers/affiliate/:id" component={AffiliateDrivers} />
    <Route exact path="/drivers/vehicle/provider/:id" component={VehicleProvider} />
    <Route exact path="/drivers/vehicle/:vehicleId" component={VehicleProfile} />
    <Route exact path="/drivers/driver/:driverId" component={UserDrivers} />
    <Route path="/drivers/driverProfile/:driverId/:idBoss" component={DriversFactsFigures} />
    <Route exact path="/drivers/driverGeneralReport/:driverId" component={DriverGeneralReport} />
    <Route path="/drivers/driverPersonalData/:driverId" component={DriverPersonalData} />
    <Route exact path="/drivers/:driverId" component={Drivers} />

    <Route path="/settings/coverage" component={Coverage} />
    <Route path="/settings/guests" component={Guests} />
    <Route path="/settings/helpApp" component={HelpApp} />
    <Route path="/settings/faq" component={Faq} />
    <Route path="/settings/brand" component={Brand} />
    <Route path="/settings/helpMenu" component={HelpMenu} />
    <Route path="/settings/parameters" component={Parameters} />
    <Route path="/settings/colors" component={Colors} />
    <Route path="/settings/models" component={Models} />
    <Route path="/settings/zones" exact component={Zones} />
    <Route path="/settings/zones/provinces/:id/:zone" component={Provinces} />
    <Route path="/settings/zones/cities/:id/:province" component={Cities} />
    <Route path="/settings/rates" exact component={Rates} />
    <Route path="/settings/rates/addRates" component={AddRates} />
    <Route path="/settings/rates/detailRates/:id/:rate" component={DetailRates} />
    <Route path="/settings/rates/addDetailRates/:idRate/:rate/:idDetailRate" component={AddDetailRates} />
    <Route path="/settings/reports" component={Reports} />
    <Route path="/settings/routes" component={Routes} />
    <Route path="/settings/transfers" component={Transfers} />

    {/* drivers general */}
    <Route path="/drivers1/providers" component={Providers} />
    <Route path="/drivers1/drivers" component={Drivers} />
    <Route path="/drivers1/vehicles" component={Vehicles} />
    <Route path="/drivers1/frequent-destinations" component={FrequentDestinations} />
    <Route path="/drivers1/frequent-rivers" component={FrequentRivers} />
    <Route path="/drivers1/affiliate-drivers" component={AffiliateDrivers} />
    <Route path="/drivers1/registered-cars" component={RegisteredCars} />
    <Route path="/drivers1/frequent-passengers" component={FrequentPassengers} />
    <Route path="/subroutes/user_drivers" component={UserDrivers} />
    <Route path="/subroutes/vehicles_user" component={VehiclesUser} />
    <Route path="/subroutes/driver_personal_data" component={DriverPersonalData} />
    <Route path="/subroutes/owner_user" component={OwnerUser} />
  </Switch>
);

const wrappedRoutes = () => {
  const breadcrums = useSelector((state) => state.breadcrums.paths);
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <div className="breadcrums">
          {breadcrums.map((path, idx) => (
            <>
              <NavLink
                //
                key={idx}
                to={path.path}
                className="item-breadcrum"
                activeClassName="current-link"
              >
                {path.name}
              </NavLink>
              <div className="breadcrumb__separator">/</div>
            </>
          ))}
        </div>
        <ToastContainer style={{ marginTop: '70px' }} />
        <Route path="/" component={Pages} />
      </div>
    </div>
  );
};

const Router = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const isFetchingToken = useSelector((state) => state.auth.isFetchingToken);

  useEffect(() => {
    if (localStorage.getItem('token')) dispatch(tokenRenovation(localStorage.getItem('token')));
    else dispatch(stopFetchLogin());
  }, [dispatch]);

  return (
    <MainWrapper>
      <main>
        {isFetchingToken ? (
          <div className="load" style={{ background: 'white' }}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#E8272C" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          </div>
        ) : (
          <Switch>
            <PublicRoute exact path="/" component={LogIn} isAuthenticated={isAuth} />
            <PublicRoute path="/password/recover" component={ResetPassword} isAuthenticated={isAuth} />
            <PrivateRoute path="/" component={wrappedRoutes} isAuthenticated={isAuth} />
          </Switch>
        )}
      </main>
    </MainWrapper>
  );
};

export default Router;
