import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row } from 'reactstrap';
import Select from "react-select";

import TableBase from './components/TableBase';
import { listUser } from "../../../redux/actions/usersAction";

const Users = () => {
  const dispatch = useDispatch();

  const columns = useMemo(() =>
    [
      {
        Header: 'Colaborador',
        accessor: 'Colaborador',
        disableFilters: true
      },
      {
        Header: 'Jefe Directo',
        accessor: 'Jefe',
        disableFilters: true
      },
      {
        Header: 'Área/local',
        accessor: 'Local',
        disableFilters: true
      },
      {
        Header: 'Uso promedio al mes',
        accessor: 'Uso',
        disableFilters: true
      },
      {
        Header: 'Ticket Promedio',
        accessor: 'Ticket',
        disableFilters: true
      },
      {
        Header: 'Valores',
        accessor: 'Valores',
        disableFilters: true
      },
      {
        Header: 'Tiempo en minutos',
        accessor: 'Tiempo',
        disableFilters: true
      },
      {
        Header: 'Número de usos',
        accessor: 'Numero',
        disableFilters: true
      }
    ],
    []
  );

  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Colaborador: ['Carlos Vaca', 'Pedro Araujo', 'Raul Andrade'][Math.floor((Math.random() * 3))],
        Jefe: ['Corporación Favorita'][Math.floor((Math.random() * 1))],
        Local: ['Tecnología'][Math.floor((Math.random() * 1))],
        Uso: ['10 - 15 traslados'][Math.floor((Math.random() * 1))],
        Ticket: ['$ 4.00'][Math.floor((Math.random() * 1))],
        Valores: ['10'][Math.floor((Math.random() * 1))],
        Tiempo: ['10'][Math.floor((Math.random() * 1))],
        Numero: ['10'][Math.floor((Math.random() * 1))]
      });
    }
    return data;
  }

  useEffect(() => {
    dispatch(listUser())
  }, [dispatch])


  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <h3 className="page-title">Pasajeros <span className="text-danger">Frecuentes</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
        </Container>
      </Row>
    </Container>
  )
};

export default Users;