import EditIcon from "mdi-react/EditIcon";
import TrashIcon from "mdi-react/TrashIcon";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  deleteRate,
  listDetailRateById,
  listRates,
  postRate,
} from "../../../redux/actions/ratesActions";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import TableBase from "./components/TableBase";
import { Form } from "./components/Form";
import { useBreadCrums } from "../../../hooks/useBreadCrums";

const validationSchema = yup.object({
  rate: yup.string("Ingrese la tarifa").required("La tarifa es requerida"),
});

const index = () => {
  const dispatch = useDispatch();
  const ratesList = useSelector((state) => state.rates.ratesData);
  const listDetailsRates = useSelector((state) => state.rates.ratesDetailData);
  const isFetchingList = useSelector((state) => state.rates.isFetching);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Agregar');
  }, [location]);

  const formSave = useFormik({
    initialValues: {
      rate: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        postRate({
          name: values.rate,
        })
      );
      formSave.resetForm();
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Tarifas",
        accessor: "rates",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.name}</span>,
        disableFilters: true,
      },
      {
        Header: " ",
        accessor: " ",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "admin",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellObj) => (
          <Link
            to={`/settings/rates/detailRates/${cellObj.cell.row.original.id}/${cellObj.cell.row.original.name}`}
          >
            <Button color="danger" style={{ marginBottom: "0px", padding: "5px 20px" }}>Administrar</Button>
          </Link>
        ),
      },

      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (cellObj) => (
          <>
            <button
              className="table__action__icon"
              onClick={() => handleDeleteRate(cellObj.cell.row.original.id)}
            >
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
      },
    ],
    [ratesList]
  );

  const handleDeleteRate = (id) => {
      dispatch(deleteRate(id));
  };

  useEffect(() => {
    dispatch(listRates());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col lg={7} md={12}>
          <TitleSettings title={`Agregar Tarifas`} />
          {!isFetchingList && ratesList ? (
            <TableBase
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: ratesList,
              }}
            />
          ) : (
            <Spinner
              className="table-fetch-spinner"
              style={{ marginLeft: "50%" }}
            />
          )}
        </Col>

        <Col lg={5} md={12}>
          <Row>
            <Col lg={12}>
              <Form
                title="Agregar"
                label="Tarifa"
                titleRed="Nueva Tarifa "
                titleButton="Agregar Tarifa"
                placeholderInput="Nombre de la tarifa"
                value={formSave.values.rate}
                handleChange={formSave.handleChange}
                touched={formSave.touched.rate}
                errors={formSave.errors.rate}
                handleSubmit={formSave.handleSubmit}
              ></Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default index;
