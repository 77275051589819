import { post } from "../base/index";

export default {
  sendNotification: (datos) => {
    const headers = {
      Authorization: "Basic MmRkOTcwMzQtZGI0Ny00YjAzLTk0OTAtNDY3ZjA5NTQ0MTEx",
    };
    return post( 'https://onesignal.com/api/v1/notifications', datos, { headers });
  },
};
