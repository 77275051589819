import { get, put, deleteRequest } from '../base/index';

export default {
  getUsers: (rolId, workAreaCode = 0) => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/byrole/${rolId}/${workAreaCode}`);
  },
  getRol: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/rol`);
  },
  getUserById: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/${id}`);
  },
  putUserById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/users/${id}`, body);
  },
  deletetUserById: (id) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/users/${id}`);
  },
  getDriversByProvider: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/byprovider/${id}`);
  },
  getVehicleByProvider: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/${id}`);
  },
  getReviewsByUser: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reviews/getReviewsByUser/${id}`);
  },

  getPrivilegesByUser: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/users/privilege/${id}`);
  },
  updatePrivilegesByUser: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/users/privilege/${id}`, body);
  },
  getUserRacesById: (idUser, idCollaborator, firstItem, lastItem) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/historyTravelUser/${idCollaborator}/${idUser}/completed/${firstItem}/${lastItem}`);
  },
  getUserRacesOtherById: (idUser, idCollaborator, firstItem, lastItem) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/historyOnlyCollaborator/${idCollaborator}/${idUser}/completed/${firstItem}/${lastItem}`);
  },
  getTravelsByIdCollaborator: (id, dateI, dateF, format) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/travelscollaborator/${dateI}/${dateF}/${id}/completed/${format}`);
  },
  getHistoryOnlyCollaboratoradmin: (id, dateI, dateF, format) => {
    return get(`${process.env.REACT_APP_URL_BASE}/reports/historyOnlyCollaboratoradmin/${format}/${id}/completed/${dateI}/${dateF}`);
  },
  closeSessionByIdUser: (id) => {
    return get(`${process.env.REACT_APP_URL_BASE}/auth/closeSessionByIdUser/${id}`);
  },
  deleteFilesUser: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/users/deleteFilesUser/${id}`, body);
  },
};
