import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row, Spinner } from 'reactstrap';
import Select from "react-select";
import Radio from '@material-ui/core/Radio';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';

import Switch from '@material-ui/core/Switch';

import TableBase from './components/TableBase';

const Frequent = () => {
  const dispatch = useDispatch();
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);


  const columns = useMemo(() =>
    [
      {
        Header: 'Conductor',
        accessor: 'Conductor',
        disableFilters: true
      },
      {
        Header: 'Tipo de Conductor',
        accessor: 'Tipo',
        disableFilters: true
      },
      {
        Header: 'Cabeza de transporte',
        accessor: 'Cabeza',
        disableFilters: true
      },
      {
        Header: 'Provincia',
        accessor: 'Provincia',
        disableFilters: true
      },
      {
        Header: 'Servicio Promedio',
        accessor: 'Servicio',
        disableFilters: true
      },
      {
        Header: 'Ticket Promedio',
        accessor: 'Ticket',
        disableFilters: true
      },
      {
        Header: "Habilitado",
        accessor: "[enabledButton]",
        Cell: (cellObj) => (
          <Switch
            checked={dataGenerator()[cellObj.row.index].status === "accepted"}
            onChange={() => handleDataEnabled(cellObj.row.index)}
          />
        ),
        disableFilters: true
      },
      {
        Header: "Acciones",
        accessor: "[editButton]",
        Cell: (cellObj) => (
          <>
            <button className="table__action__icon" onClick={() => handleDataCareers(cellObj.row.index)}>
              <CarIcon color="#00E0FF" />
            </button>
            <button className="table__action__icon" onClick={() => handleDataReport(cellObj.row.index)}>
              <ChartBarIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true
      },
    ],
    []
  );

  const filterOptions = (filterAttr) => {
    let arrayToReturn = [];
    let filterColumnData = [];
    dataGenerator().map(value => { if (value[filterAttr] !== null) filterColumnData.push(value[filterAttr]) })

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    filterColumnData.filter(onlyUnique).map((value) => arrayToReturn.push({ value, label: value }))

    return arrayToReturn;
  }

  const handleDataCareers = (index) => {
    alert(`Carreras del usuario ${dataGenerator()[index].Conductor}`)
  };

  const handleDataReport = (index) => {
    alert(`Reporte del usuario ${dataGenerator()[index].Conductor}`)
  };

  const handleDataEnabled = (index) => {
    alert(`${dataGenerator()[index].status === "accepted" ? 'DESHABILITAR' : 'HABILITAR'} usuario ${dataGenerator()[index].Conductor}`)
  }

  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Conductor: ['Carlos Vaca', 'Pedro Araujo', 'Raul Andrade'][Math.floor((Math.random() * 3))],
        Tipo: ['Dueño de flota'][Math.floor((Math.random() * 1))],
        Cabeza: ['Carlos Vaca', 'Pedro Araujo', 'Raul Andrade'][Math.floor((Math.random() * 3))],
        Provincia: ['Pichincha', 'Manabí', 'Guayas'][Math.floor((Math.random() * 3))],
        Servicio: ['10 - 15 traslados'][Math.floor((Math.random() * 1))],
        Ticket: ['$ 4.00'][Math.floor((Math.random() * 1))],
        status: ['accepted', 'declined'][Math.floor((Math.random() * 2))],
      });
    }
    return data;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <h3 className="page-title">Conductores <span className="text-danger">Frecuentes</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          {!isFetchingusersLists && dataGenerator()
            ? <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
            : <Spinner className="table-fetch-spinner" style={{ marginLeft: "50%" }} />
          }
        </Container>
      </Row>
    </Container>
  )
};

export default Frequent;