import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProps } from '../../../prop-types/ReducerProps';
import ReactTableDnDBody from './ReactTableDnDBody';
import ReactTableMultitableBody from './ReactTableMultitableBody'

const ReactTableDefaultBody = ({ page, getTableBodyProps, prepareRow }) => {
  return (
    <tbody className="table table--bordered" {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <td {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const ReactTableBody = ({
  page, getTableBodyProps, prepareRow, withDragAndDrop, updateDraggableData, theme, multiTable, multiTableRowSelected
}) => (
  <Fragment>
    {withDragAndDrop
      ? (
        <ReactTableDnDBody
          page={page}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          updateDraggableData={updateDraggableData}
          theme={theme}
        />
      ) : (
        multiTable
          ? <ReactTableMultitableBody
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            multiTableRowSelected={multiTableRowSelected}
          />
          : <ReactTableDefaultBody
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
          />
      )}
  </Fragment>
);

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  updateDraggableData: PropTypes.func.isRequired,
  withDragAndDrop: PropTypes.bool.isRequired,
  theme: ThemeProps.isRequired,
  multiTable: PropTypes.bool,
  multiTableRowSelected: PropTypes.number
};

export default connect((state) => ({
  theme: state.theme,
}))(ReactTableBody);
