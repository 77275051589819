import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Container, Row } from 'reactstrap';
import Select from "react-select";

import TableBase from './components/TableBase';
import { listUser } from "../../../redux/actions/usersAction";

const Users = () => {
  const dispatch = useDispatch();

  const columns = useMemo(() =>
    [
      {
        Header: 'Destino',
        accessor: 'Destino',
        disableFilters: true
      },
      {
        Header: 'Dirección',
        accessor: 'Dirección',
        disableFilters: true
      },
      {
        Header: 'Cordenadas',
        accessor: 'Cordenadas',
        disableFilters: true
      },
      {
        Header: 'Formato',
        accessor: 'Formato',
        disableFilters: true
      }
    ],
    []
  );

  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Destino: ['Megamaxi 6 de Diciembre'][Math.floor((Math.random() * 1))],
        Dirección: ['Av. 6 de diciembre y  Portugal'][Math.floor((Math.random() * 1))],
        Cordenadas: ['-0006477; -7812390432'][Math.floor((Math.random() * 1))],
        Formato: ['Supermaxi'][Math.floor((Math.random() * 1))]
      });
    }
    return data;
  }

  useEffect(() => {
    dispatch(listUser())
  }, [dispatch])


  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <h3 className="page-title">Destinos <span className="text-danger">Frecuentes</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px' }}>
          <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
        </Container>
      </Row>
    </Container>
  )
};

export default Users;