import { handleActions } from "redux-actions";
import {
  fetchUsersRequest,
  fetchUsersSucess,
  fetchUsersError,
  fetchUserByIdSucess,
  fetchUsersRolesSucess,
  fetchDriversByProviderSuccess,
  fetchVehicleByProviderSuccess,
  fetchReviewsByUserRequest,
  fetchReviewsByUserSucess,
  fetchPrivilegesByUserRequest,
  fetchPrivilegesByUserSucess,
  fetchRacesByUserRequest,
  fetchRacesByUserSucess,
  fetchTravelsByIdCollaboratorRequest,
  fetchTravelsByIdCollaboratorSucess,
  fetchGetCollaboratorsRequest,
  fetchGetCollaboratorsSucess,

} from "../actions/usersAction";

const defaultState = {
  data: null,
  userById: null,
  isFetching: false,
  isFetchingReviewsByUser: false,
  reviewsByUser: null,
  isFetchingPrivilegesByUser: false,
  privilegesByUser: null,
  isFetchingRacesByUser: false,
  racesByUser: null,
  racesAux: null,
  isFetchingTravelsByIdCollaborator: false,
  TravelsByIdCollaborator: null,
  collaborators: null,
  isFetchingCollaborators: false,
  error: null,
  roles: null,
};

export default handleActions(
  {
    [fetchUsersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchUsersSucess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchReviewsByUserRequest](state) {
      return {
        ...state,
        isFetchingReviewsByUser: true,
        error: null,
      };
    },
    [fetchReviewsByUserSucess](state, { payload }) {
      return {
        ...state,
        reviewsByUser: payload,
        isFetchingReviewsByUser: false,
        error: null,
      };
    },
    [fetchUsersRolesSucess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchUserByIdSucess](state, { payload }) {
      return {
        ...state,
        userById: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchUsersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchDriversByProviderSuccess](state, { payload }) {
      return {
        ...state,
        driverByProvider: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchVehicleByProviderSuccess](state, { payload }) {
      return {
        ...state,
        vehicleByProvider: payload,
        isFetching: false,
        error: null,
      };
    },

   

    [fetchPrivilegesByUserRequest](state, { payload }) {
      return {
        ...state,
        isFetchingPrivilegesByUser: true,
        error: null,
      };
    },
    [fetchPrivilegesByUserSucess](state, { payload }) {
      return {
        ...state,
        privilegesByUser: payload,
        isFetchingPrivilegesByUser: false,
        error: null,
      };
    },
    [fetchRacesByUserRequest](state, { payload }) {
      return {
        ...state,
        isFetchingRacesByUser: true,
        error: null,
      };
    },
    [fetchRacesByUserSucess](state, { payload }) {
      return {
        ...state,
        racesByUser: payload,
        isFetchingRacesByUser: false,
        error: null,
      };
    },
    [fetchTravelsByIdCollaboratorRequest](state) {
      return {
        ...state,
        isFetchingTravelsByIdCollaborator: true,
      };
    },
    [fetchTravelsByIdCollaboratorSucess](state, { payload }) {
      return {
        ...state,
        TravelsByIdCollaborator: payload,
        isFetchingTravelsByIdCollaborator: false,
        error: null,
      };
    },
    [fetchGetCollaboratorsRequest](state) {
      return {
        ...state,
        isFetchingCollaborators: true,
      };
    },
    [fetchGetCollaboratorsSucess](state, { payload }) {
      return {
        ...state,
        collaborators: payload,
        isFetchingCollaborators: false,
        error: null,
      };
    },
  },
  defaultState
);
