import api from '../../utils/api/apliClient';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { getReviewsByUser } from './usersAction';

export const fetchDashboardRequest = createAction('FETCH_DASHBOARD_REQUEST');
export const fetchDashboardSucess = createAction('FETCH_DASHBOARD_SUCCESS');
export const fetchDashboardCollaborator = createAction('FETCH_DASHBOARD_COLLABORATOR');
export const fetchDashboardCollaboratorSucess = createAction('FETCH_DASHBOARD_COLLABORATOR_SUCCESS');
export const fetchDashboardWorkAreaSucess = createAction('FETCH_DASHBOARD_WORKAREA_SUCCESS');
export const fetchDashboardFormatsSucess = createAction('FETCH_DASHBOARD_FORMATS_SUCCESS');
export const fetchTravelDetailsByIdSucess = createAction('FETCH_TRAVEL_DETAILS_BY_ID_SUCCESS');
export const fetchtravelsByStatusAndDatesSucess = createAction('FETCH_TRAVELS_BY_STATUS_AND_DATES_SUCCESS');
export const fetchDashboardJourneyAlerts = createAction('FETCH_DASHBOARD_JOURNEY_ALERTS_SUCCESS');
export const fetchAllTravelsSucess = createAction('FETCH_ALL_TRAVELS_SUCESS');
export const fetchPassengers = createAction('FETCH_PASSENGERS');
export const fetchPassengersSucess = createAction('FETCH_PASSENGERS_SUCCESS');
export const fetchDriver = createAction('FETCH_DRIVER');
export const fetchDriverSucess = createAction('FETCH_DRIVER_SUCCESS');
export const fetchDashboardError = createAction('FETCH_DASHBOARD_ERROR');
export const fetchTopFive = createAction('FETCH_TOP_FiVE');

export const getJourneyAlerts = () => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getJourneyAlerts();
    dispatch(fetchDashboardJourneyAlerts(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo los usuarios', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getDataDashboard = (year, status, adressStartDate, adressEndDate) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const [
      //
      generalCost,
      generalKm,
      generalTravels,
      addressList,
      admins,
      colaborators,
      superadmins,
      JourneyAlerts,
      topFive,
    ] = await Promise.all([
      //
      api.dashboard.getGeneralCostByYearAndStatus(year, adressStartDate, adressEndDate, status),
      api.dashboard.getGeneralKmByYearAndStatus(year, adressStartDate, adressEndDate, status),
      api.dashboard.getGeneralTravelsByYearAndStatus(year, adressStartDate, adressEndDate, status),
      api.dashboard.getAllAdressByStartEndDateAnStatus(adressStartDate, adressEndDate, status),
      api.dashboard.getUsersByRol(1),
      api.dashboard.getUsersByRol(3),
      api.dashboard.getUsersByRol(2),
      api.dashboard.getJourneyAlerts(),
      api.dashboard.getTopFive(),
    ]);

    dispatch(
      fetchDashboardSucess({
        generalCost: generalCost.data.generalCost,
        generalKm: generalKm.data.generalKm,
        generalTravels: generalTravels.data.generalTravels,
        addressList: addressList.data.addressList,
        users: [...admins.data, ...colaborators.data, ...superadmins.data],
        JourneyAlerts: JourneyAlerts.data.filter((item) => item.status === 'onway' && item.status === 'traveling'),
      })
    );
    dispatch(fetchTopFive(topFive.data.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la data del dashboard', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getDataDashboardWorArea = (year, status, adressStartDate, adressEndDate, workArea) => async (dispatch) => {
  dispatch(fetchDashboardRequest());
  try {
    const [
      //
      generalCost,
      generalKm,
      generalTravels,
      addressList,
      admins,
      colaborators,
      superadmins,
      JourneyAlerts,
      topFive,
    ] = await Promise.all([
      //
      api.dashboard.getGeneralCostByYearAndStatusWorkArea(year, adressStartDate, adressEndDate, status, workArea),
      api.dashboard.getGeneralKmByYearAndStatusWorkArea(year, adressStartDate, adressEndDate, status, workArea),
      api.dashboard.getGeneralTravelsByYearAndStatusWorkArea(year, adressStartDate, adressEndDate, status, workArea),
      api.dashboard.getAllAdressByStartEndDateAnStatusWorkArea(adressStartDate, adressEndDate, status, workArea),
      api.dashboard.getUsersByRol(1, workArea),
      api.dashboard.getUsersByRol(3, workArea),
      api.dashboard.getUsersByRol(2, workArea),
      api.dashboard.getJourneyAlerts(workArea),
      api.dashboard.getTopFiveWorkArea(adressStartDate, adressEndDate, workArea),
    ]);

    dispatch(
      fetchDashboardSucess({
        generalCost: generalCost.data.generalCostWorkArea,
        generalKm: generalKm.data.generalKm,
        generalTravels: generalTravels.data.generalTravels,
        addressList: addressList.data.addressList,
        users: [...admins.data, ...colaborators.data, ...superadmins.data],
        JourneyAlerts: JourneyAlerts.data.filter((item) => item.status === 'onway'),
      })
    );
    dispatch(fetchTopFive(topFive.data.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la data del dashboard', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getDataCollaboratorDashboard = (year, adressStartDate, adressEndDate, status, idCollaborator) => async (dispatch) => {
  try {
    dispatch(fetchDashboardCollaborator());
    const [generalCost, generalKm, generalTravels] = await Promise.all([
      //
      api.dashboard.getGeneralCostByYearCollaboratorAndStatus(year, adressStartDate, adressEndDate, status, idCollaborator),
      api.dashboard.getGeneralKmByYearCollaboratorAndStatus(year, adressStartDate, adressEndDate, status, idCollaborator),
      api.dashboard.getGeneralTravelsByYearCollaboratorAndStatus(year, adressStartDate, adressEndDate, status, idCollaborator),
    ]);
    dispatch(
      fetchDashboardCollaboratorSucess({
        generalCost: generalCost.data.collaboratorCost,
        generalKm: generalKm.data.collaboratorKm,
        generalTravels: generalTravels.data.collaboratorTravels,
      })
    );
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la data del colaborador', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getDataFormatsDashboard = (year, adressStartDate, adressEndDate, status, format) => async (dispatch) => {
  try {
    dispatch(fetchDashboardCollaborator());
    const [generalCost, generalKm, generalTravels] = await Promise.all([
      //
      api.dashboard.getGeneralCostByYearFormatsAndStatus(year, adressStartDate, adressEndDate, status, format),
      api.dashboard.getGeneralKmByYearFormatsAndStatus(year, adressStartDate, adressEndDate, status, format),
      api.dashboard.getGeneralTravelsByYearFormatsAndStatus(year, adressStartDate, adressEndDate, status, format),
    ]);
    dispatch(
      fetchDashboardFormatsSucess({
        generalCost: generalCost.data.formatsCost,
        generalKm: generalKm.data.formatskm,
        generalTravels: generalTravels.data.formatsTravels,
      })
    );
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la data del formato', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getAllTravels = () => async (dispatch) => {
  try {
    //dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getJourneyAlerts();
    dispatch(fetchDashboardJourneyAlerts(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo los viajes', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getReportsTopFive = () => async (dispatch) => {
  try {
    //dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTopFive();
    dispatch(fetchTopFive(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo los viajes', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const updateStatusTravel = (id, body) => async (dispatch) => {
  try {
    //dispatch(fetchDashboardRequest());
    if (body.status === 'reject') {
      await api.dashboard.deleteTravelById(id);
      api.notification.sendNotification({
        app_id: '2b32177d-bf8f-4ce3-9d81-b13e515428b1',
        headings: { en: 'Movilitix' },
        subtitle: { en: 'Movilitix' },
        data: { ruta: 'ruta', fecha: '100-210-250' },
        contents: { en: 'Su viaje ha sido rechazado' },
        channel_for_external_user_ids: 'push',
        include_external_user_ids: [body.email],
      });
    } else {
      await api.dashboard.putTravelStatus(id, body);
      if (body.status === 'accepted') {
        api.notification.sendNotification({
          app_id: '2b32177d-bf8f-4ce3-9d81-b13e515428b1',
          headings: { en: 'Movilitix' },
          subtitle: { en: 'Movilitix' },
          data: { ruta: 'ruta', fecha: '100-210-250' },
          contents: { en: 'Su viaje ha sido aceptado' },
          channel_for_external_user_ids: 'push',
          include_external_user_ids: [body.email],
        });
      }
    }

    toast.success('Viaje actualizado correctamente', {
      autoClose: 2500,
      theme: 'colored',
    });
    dispatch(getAllTravels());
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error actualizando el viaje', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getTravelDetailsById = (travelId) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTravelDetailsById(travelId);
    dispatch(getReviewsByUser(response.data.travel.idCollaborator));
    dispatch(getPassengers(response.data.travel.detailTravels));
    if (response.data.travel.status === 'traveling' || response.data.travel.status === 'onway') dispatch(getDriver(response.data.travel.idDriver));
    dispatch(fetchTravelDetailsByIdSucess(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la información del viaje', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getTravelDetailsByIdHistorial = (travelId) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTravelDetailsById(travelId);
    dispatch(fetchTravelDetailsByIdSucess(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la información del viaje', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getTravelDetailsByIdSimple = (travelId) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTravelDetailsById(travelId);
    dispatch(fetchTravelDetailsByIdSucess(response.data));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo la información del viaje', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getTravelsByStatus = (status) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTravelsByStatus(status);
    dispatch(fetchtravelsByStatusAndDatesSucess(response.data.travels));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error consultando los viajes por estado', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getTravelsByDates = (initialDate, endDate) => async (dispatch) => {
  try {
    dispatch(fetchDashboardRequest());
    const response = await api.dashboard.getTravelsByDates(initialDate, endDate);
    dispatch(fetchtravelsByStatusAndDatesSucess(response.data.travelsByDate));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error consultando los viajes por estado', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const updateStatusTravelAlert = (id, body) => async (dispatch) => {
  try {
    //dispatch(fetchDashboardRequest());
    if (body.status === 'reject') await api.dashboard.deleteTravelById(id);
    else await api.dashboard.putTravelStatus(id, body);
    toast.success('Viaje actualizado correctamente', {
      autoClose: 2500,
      theme: 'colored',
    });
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error actualizando el viaje', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getPassengers = (passengers) => async (dispatch) => {
  try {
    dispatch(fetchPassengersSucess(passengers));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo los pasajeros', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};

export const getDriver = (user) => async (dispatch) => {
  try {
    dispatch(fetchDriver());
    const response = await api.users.getUserById(user);
    dispatch(fetchDriverSucess(response.data.usuario));
  } catch (error) {
    dispatch(fetchDashboardError({ error }));
    toast.error('Error obteniendo el conductor', {
      autoClose: 2500,
      theme: 'colored',
    });
    throw error;
  }
};
