import { createAction } from 'redux-actions';
import api from "../../utils/api/apiProvider";
import { toast } from 'react-toastify';

export const fetchProvidersRequestInvoice = createAction('FETCH_PROVIDERS_INVOICE');
export const fetchProviderInvoice = createAction('FETCHING_PROVIDERS_INVOICE');

export const fetchProvidersRequest = createAction('FETCH_PROVIDERS_REQUEST');
export const fetchProvidersSucess = createAction('FETCH_PROVIDERS_SUCCESS');
export const fetchProvidersError = createAction('FETCH_PROVIDERS_ERROR');

export const fetchProviderById = createAction('FETCH_PROVIDER_BY_ID_REQUEST');
export const fetchProviderByIdSuccess = createAction('FETCH_PROVIDER_BY_ID_SUCCESS_REQUEST');

export const fetchDriverUserCostSuccess = createAction('FETCH_DRIVER_SUCCESS_COST_SUCCESS');
export const fetchDriverUserKmSuccess = createAction('FETCH_DRIVER_SUCCESS_KM_SUCCESS');
export const fetchDriverUserTravelsSuccess = createAction('FETCH_DRIVER_SUCCESS_TRAVEL_SUCCESS');

export const fetchProviderUserCostSuccess = createAction('FETCH_PROVIDER_SUCCESS_COST_SUCCESS');
export const fetchProviderUserKmSuccess = createAction('FETCH_PROVIDER_SUCCESS_KM_SUCCESS');
export const fetchProviderUserTravelsSuccess = createAction('FETCH_PROVIDER_SUCCESS_TRAVEL_SUCCESS');

export const fetchProviderTravelsByIdSuccess = createAction('FETCH_PROVIDER_TRAVEL_ID_SUCCESS');

export const fetchVehicleByUserId = createAction('FETCH_VEHICLE_BY_USERID');
export const fetchCurrentVehicleByUserId = createAction('FETCH_CURRENT_VEHICLE_BY_USERID');
export const fetchVehicleByUserIdSucess = createAction('FETCH_VEHICLE_BY_USERID_SUCCESS');
export const fetchCurrentVehicleByUserIdSuccess = createAction('FETCH_CURRENT_VEHICLE_BY_USERID_SUCCESS');

export const fetchSendEmail = createAction('FETCH_SEND_EMAIL');
export const fetchSendEmailSucess = createAction('FETCH_SEND_EMAIL_SUCCESS');
export const fetchSendEmailError = createAction('FETCH_SEND_EMAIL_ERROR');

export const fetchVehicleByIdSucess = createAction('FETCH_VEHICLE_BY_ID_SUCCESS');

export const vehicleProviders = (id) => async (dispatch) => {
    try {
        //dispatch(fetchProvidersRequest());
        const response = await api.vehicle.getvehicleProviders(id);
        dispatch(fetchProvidersSucess(response.data.VehicleDb));


    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error obteniendo los vehículos", { autoClose: 2500, theme: "colored" })
        throw error;
    }
}

export const getVehicleByUserId = (id) => async (dispatch) => {
    try {
        dispatch(fetchVehicleByUserId());
        const response = await api.vehicle.getVehicleByUserId(id);
        dispatch(fetchVehicleByUserIdSucess(response.data));

    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error obteniendo los vehículos", { autoClose: 2500, theme: "colored" })
        throw error;
    }
}

export const getProviderById = (id) => async (dispatch) => {
    try {
        dispatch(fetchProviderById());
        const response = await api.user.getUserById(id);
        dispatch(fetchProviderByIdSuccess(response.data.usuario));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error obteniendo el proveedor", { autoClose: 2500, theme: "colored" })
        throw error;
    }
}

export const getCurrentVehicleByUserId = (id) => async(dispatch) =>{
    try {
        dispatch(fetchCurrentVehicleByUserId());
        const response = await api.vehicle.getCurrentVehicleByIdDriver(id);
        if (response.data !== null) {
            dispatch(fetchCurrentVehicleByUserIdSuccess(response.data.vehicleProvider));
        }


    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error el vehículo actual", { autoClose: 2500, theme: "colored" })
        throw error;
    }
}



export const putVehicleStatusList = (id_data, body, id) => async (dispatch) => {
    try {
        //dispatch(fetchProvidersRequest());
        await api.vehicle.putVehicleStatus(id_data, body);
        toast.success("Dato actualizado correctamente", { autoClose: 2500, theme: "colored" });
        dispatch(vehicleProviders(id));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error actualizando el dato", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}


export const listDriverCost = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.driver.getDriverCost(id, year);
        dispatch(fetchDriverUserCostSuccess(response.data.driverCost));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los datos", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}
export const listDriverKm = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.driver.getDriverKm(id, year);
        dispatch(fetchDriverUserKmSuccess(response.data.driverKm));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los datos", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}
export const listDriverTravels = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.driver.getDriverTravels(id, year);
        dispatch(fetchDriverUserTravelsSuccess(response.data.driverTravels));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los datos", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}


export const listProviderCost = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.provider.getProviderCost(id, year);
        dispatch(fetchProviderUserCostSuccess(response.data.providerCost));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}
export const listProviderKm = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.provider.getProviderKm(id, year);
        dispatch(fetchProviderUserKmSuccess(response.data.providerKm));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}
export const listProviderTravels = (id, year) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.provider.getProviderTravels(id, year);
        dispatch(fetchProviderUserTravelsSuccess(response.data.providerTravels));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}


export const listsTravelsById = (id, yeari, yearf) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const { data } = await api.provider.getProviderTravelsById(id, yeari, yearf);
        let drivers = [];
        data.travels.map(travel => {
            const filter = drivers.findIndex(driver => (driver.id === travel.idDriver));
            if (filter === -1) {
                drivers.push({
                    id: travel.idDriver,
                    name: "",
                    driverTravels: [travel]
                })
            }
            else {
                drivers[filter].driverTravels.push(travel)
            }
        })
        const namesDrivers = await Promise.all(drivers.map(({ id }) => api.user.getUserById(id)))
        namesDrivers.map((driver, index) => { drivers[index].name = `${driver.data.usuario.firstName} ${driver.data.usuario.secondName ? driver.data.usuario.secondName : ""} ${driver.data.usuario.firstLastName} ${driver.data.usuario.secondLastName ? driver.data.usuario.secondLastName : ""}` })
        dispatch(fetchProviderTravelsByIdSuccess(drivers));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}

export const getInvoiceByProviderMonthAndYear = (id, month, year) => async (dispatch) => {
    try {
        dispatch(fetchProviderInvoice());
        const { data } = await api.provider.getIvoiceByProviderAndMonthAndYear(id, month, year);
        if(data) dispatch(fetchProvidersRequestInvoice(data.invoiceUrl));
        
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}

export const getRequestByDateByDriver = (id, yeari, yearf) => async (dispatch) => {
    try {
        dispatch(fetchProvidersRequest());
        const response = await api.provider.getRequestByDateByDriver(id, yeari, yearf);
        dispatch(fetchProviderUserTravelsSuccess(response.data.travels.filter(travel=>travel.status === 'completed')));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error listando los registros", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}

export const sendEmail = (body) => async (dispatch) => {
    try {
        dispatch(fetchSendEmail());
        await api.driver.sendEmail(body);
        dispatch(fetchSendEmailSucess());
        toast.success("Correo enviado correctamente", { autoClose: 2500, theme: "colored" });
    } catch (error) {
        dispatch(fetchSendEmailError({ error }));
        toast.error("Error enviando correo", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}

export const getVehicleById = (vehicleId) => async (dispatch) => {
    try {
        //dispatch(fetchProvidersRequest());
        const { data } = await api.vehicle.getVehicleById(vehicleId);
        dispatch(fetchVehicleByIdSucess(data));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error obteniendo el vehículo", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}

export const putehicleById = (vehicleId, body) => async (dispatch) => {
    try {
        //dispatch(fetchProvidersRequest());
        await api.vehicle.putVehicleStatus(vehicleId, body);
        toast.success("Vehículo actualizado correctamente", { autoClose: 2500, theme: "colored" });
        dispatch(getVehicleById(vehicleId));
    } catch (error) {
        dispatch(fetchProvidersError({ error }));
        toast.error("Error actualizando el vehículo", { autoClose: 2500, theme: "colored" });
        throw error;
    }
}