import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Spinner } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MapMarkerOutlineIcon from 'mdi-react/MapMarkerOutlineIcon';
import CurrencyUsdCircleOutlineIcon from 'mdi-react/CurrencyUsdCircleOutlineIcon';
import Switch from '@material-ui/core/Switch';
import StarIcon from 'mdi-react/StarIcon';
import MapMarkerDistanceIcon from 'mdi-react/MapMarkerDistanceIcon';
import { Link, useLocation } from 'react-router-dom';
import { getUserById, putUserById, getReviewsByUser, getPrivilegesByUser, updatePrivilegesByUser } from '../../../redux/actions/usersAction';
import { getDataCollaboratorDashboard } from '../../../redux/actions/dashboardActions';
import moment from 'moment';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
import DatePicker from 'react-datepicker';
import CalendarOutlineIcon from 'mdi-react/CalendarOutlineIcon';
import DateFnsUtils from '@date-io/date-fns';

import { Bar } from 'react-chartjs-2';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ButtonCustom } from '../../../shared/components/Button/Button';

export default function index({ match }) {
  const {
    params: { userId },
  } = match;
  const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.userById);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const userReviews = useSelector((state) => state.users.reviewsByUser);
  const isFetchingReviews = useSelector((state) => state.users.isFetchingReviewsByUser);
  const isFetchingDataDashboardCollaborator = useSelector((state) => state.dashboard.isFetchingCollaborator);
  const dataCollaborator = useSelector((state) => state.dashboard.dataCollaborator);
  const isFetchingPrivilegesByUser = useSelector((state) => state.users.isFetchingPrivilegesByUser);
  const privilegesByUser = useSelector((state) => state.users.privilegesByUser);
  const userAuth = useSelector((state) => state.auth.dataUser);
  const [startDate, setStartDate] = useState(new Date(`${new Date().getFullYear()}-01-01  00:00:00`));
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState(new Date());

  const [openModaleRequestTravel, setOpenModaleRequestTravel] = useState(false);
  const [dateFinish, setDateFinish] = useState(null);
  const [checked, setChecked] = useState(false);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    dispatch(getUserById(userId));
    dispatch(getReviewsByUser(userId));
    dispatch(getDataCollaboratorDashboard(moment().format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', userId));
    dispatch(getPrivilegesByUser(userId));
  }, []);

  useEffect(() => {
    dispatch(getDataCollaboratorDashboard(moment().format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', userId));
  }, []);

  useEffect(() => {
    dispatch(getDataCollaboratorDashboard(moment(year).format('YYYY'), `${moment(startDate).format('YYYY-MM-DD')}`, `${moment(endDate).format('YYYY-MM-DD')}`, 'completed', userId));
  }, [year]);

  useEffect(() => {
    setChecked(isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.travelAuthorized);
  }, [privilegesByUser]);

  const dataGraphics = (graphic) => {
    let data;

    data = dataCollaborator[graphic];

    const resultMapData = Months.map((month) => {
      const index = data.findIndex((monthData) => monthData.Mes === month);
      if (index < 0) return 0;
      else return data[index].Total;
    });

    return {
      labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
      datasets: [
        {
          data: resultMapData,
          backgroundColor: ['rgba(248, 211, 207)', 'rgba(208, 43, 31)', 'rgba(247, 191, 184)', 'rgba(242, 150, 139)', 'rgba(244, 170, 161)', 'rgba(248, 211, 207)', 'rgba(241, 127, 114)', 'rgba(237, 85, 71)', 'rgba(237, 85, 71)', 'rgb(209, 70, 67)', 'rgba(142, 46, 49)', 'rgba(40, 51, 80)'],
          borderWidth: 0,
        },
      ],
    };
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="selectYear">
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <CalendarOutlineIcon onClick={props.onClick} style={{ height: '20px', marginLeft: '8px', marginTop: '-3px' }} />
      </div>
    );
  });

  const handleUserEnabled = () => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserById(user.id, body));
  };

  const calculateNumbers = (param) => {
    let sum = 0;
    dataCollaborator?.[param].map((month) => (sum = sum + month.Total));
    if (param !== 'generalTravels') sum = sum.toFixed(2);
    return sum;
  };

  const handleOpenModal = (event) => {
    if (event.target.checked) {
      setOpenModaleRequestTravel(event.target.checked);
    } else {
      dispatch(
        updatePrivilegesByUser(userId, {
          travelAuthorized: !privilegesByUser.travelAuthorized,
          dateUntilTravelAutorized: null,
        })
      );
      setOpenModaleRequestTravel(false);
    }
  };

  const handleRequestTravel = () => {
    dispatch(
      updatePrivilegesByUser(userId, {
        travelAuthorized: !privilegesByUser.travelAuthorized,
        dateUntilTravelAutorized: dateFinish,
      })
    );
    setOpenModaleRequestTravel(false);
  };

  useEffect(() => {
    isFetchinguser === false && pushNewItemBreadCrums(`Usuario`);
  }, [isFetchinguser, location]);

  return (
    <Container className="userProfile">
      <Row>
        <Col md={12} className="title">
          <AccountOutlineIcon className="iconTitle" />
          <h3 className="page-title" style={{ fontSize: '25px' }}>
            Usuario
          </h3>
          <div style={{ marginRight: '15px' }}>
            <DatePicker selected={year} onChange={(date) => setYear(date)} showYearPicker dateFormat="yyyy" className="selectYear" maxDate={new Date()} customInput={<CustomInput />} />
          </div>
        </Col>
      </Row>
      {isFetchinguser ? (
        <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
      ) : (
        <>
          <Row className="contentUser" style={{ marginLeft: '0' }}>
            <div style={{ margin: '0px 0vw', width: '25%', maxWidth: '320px' }}>
              <Col md={4} xs={12}>
                <div className="leftColumn">
                  <img src={user?.userImgUrl} alt="avatar" />
                  <div className="separator"></div>
                  <div>
                    <div className="profileData">
                      <h6 className="company">Corporación Favorita</h6>
                      <h6 className="code">Código: {userId}</h6>
                      <h6 className="name">
                        {user?.firstName} {user?.firstLastName}
                      </h6>
                      <h6 className="company">
                        Puntuación: {isFetchingReviews ? '' : userReviews?.average}
                        <StarIcon style={{ height: '16px', marginTop: '-3px', marginLeft: '-3px' }} color="#FFCE31" />
                      </h6>
                    </div>
                    <div className="buttons" style={{ marginTop: '8px' }}>
                      {/* <Link to={`/users/userReport/${userId}`} className="link">
                    Ver reporte
                  </Link> */}
                      <Link to={`/users/UserGeneralReport/${userId}`} className="link">
                        Ver reporte
                      </Link>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: '40px', width: '280px', marginBottom: 40 }}>
                  <h5 style={{ fontSize: '15px', color: 'black', fontWeight: '600', marginBottom: '20px', textTransform: 'uppercase', textDecoration: 'underline' }}>Privilegios:</h5>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>
                      Solicitar viaje <span style={{ fontSize: 11 }}>{privilegesByUser?.travelAuthorized === true ? (privilegesByUser.dateUntilTravelAutorized ? `(Hasta: ${moment(privilegesByUser.dateUntilTravelAutorized).format('YYYY-MM-DD')})` : '(Hasta: Indefinido)') : ''}</span>
                    </h6>
                    <Switch disabled={isFetchingPrivilegesByUser} checked={checked} onChange={handleOpenModal} />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>Viaje fuera de la ciudad</h6>
                    <Switch
                      disabled={isFetchingPrivilegesByUser}
                      checked={isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.travelAllDay}
                      onChange={() =>
                        dispatch(
                          updatePrivilegesByUser(userId, {
                            travelAllDay: !privilegesByUser.travelAllDay,
                          })
                        )
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>Permitir Invitados</h6>
                    <Switch
                      disabled={isFetchingPrivilegesByUser}
                      checked={isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.invited}
                      onChange={() =>
                        dispatch(
                          updatePrivilegesByUser(userId, {
                            invited: !privilegesByUser.invited,
                          })
                        )
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>Mantenimiento</h6>
                    <Switch
                      disabled={isFetchingPrivilegesByUser || userAuth.roleId === 3 ? false : true}
                      checked={isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.maintenance}
                      onChange={() =>
                        dispatch(
                          updatePrivilegesByUser(userId, {
                            maintenance: !privilegesByUser.maintenance,
                          })
                        )
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>Corporativo</h6>
                    <Switch
                      disabled={isFetchingPrivilegesByUser || userAuth.roleId === 3 ? false : true}
                      checked={isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.corporate}
                      onChange={() =>
                        dispatch(
                          updatePrivilegesByUser(userId, {
                            corporate: !privilegesByUser.corporate,
                          })
                        )
                      }
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #C4C4C4', padding: '5px 0px' }}>
                    <h6 style={{ fontSize: '14px', color: '#4B545A', fontWeight: '600' }}>Seguridad</h6>
                    <Switch
                      disabled={isFetchingPrivilegesByUser || userAuth.roleId === 3 ? false : true}
                      checked={isFetchingPrivilegesByUser || privilegesByUser === null ? false : privilegesByUser?.security}
                      onChange={() =>
                        dispatch(
                          updatePrivilegesByUser(userId, {
                            security: !privilegesByUser.security,
                          })
                        )
                      }
                    />
                  </div>
                </div>
              </Col>
            </div>
            <div style={{ width: '5%', maxWidth: '50px' }}></div>
            <Col md={8} xs={12}>
              <div className="rightColumn">
                <div className="numbers">
                  <h3 style={{ fontSize: '14px', color: '#1A0C12', fontWeight: 600 }}>
                    {' '}
                    Cifras <span className="text-danger">{moment().format('YYYY')}</span>
                  </h3>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex' }}>
                      <div className="number" style={{ marginRight: '25px' }}>
                        <h6 className="numbersHeader">
                          <MapMarkerOutlineIcon className="iconHeader"></MapMarkerOutlineIcon> Traslados
                        </h6>
                        <h6>{isFetchingDataDashboardCollaborator ? '' : calculateNumbers('generalTravels')} Viajes</h6>
                      </div>
                      <div className="number" style={{ marginRight: '25px' }}>
                        <h6 className="numbersHeader">
                          <MapMarkerDistanceIcon className="iconHeader"></MapMarkerDistanceIcon> Kilometros
                        </h6>
                        <h6>{isFetchingDataDashboardCollaborator ? '' : calculateNumbers('generalKm')} km</h6>
                      </div>
                      <div className="number" style={{ marginRight: '25px' }}>
                        <h6 className="numbersHeader">
                          <CurrencyUsdCircleOutlineIcon className="iconHeader"></CurrencyUsdCircleOutlineIcon> Costos
                        </h6>
                        <h6>$ {isFetchingDataDashboardCollaborator ? '' : calculateNumbers('generalCost')}</h6>
                      </div>
                    </div>
                    <div className="number">
                      <h6 className="numbersHeader" style={{ marginBottom: '5px' }}>
                        <AccountOutlineIcon className="iconHeader"></AccountOutlineIcon>Habilitado
                      </h6>
                      <Switch disabled={userAuth.roleId === 3 ? false : true} checked={user?.status === 'accepted'} onChange={() => handleUserEnabled()} />
                    </div>
                  </div>
                </div>
                <div className="data">
                  <div className="header">
                    <h3 style={{ fontSize: '14px', color: '#1A0C12', fontWeight: 600 }}>
                      Datos del <span className="text-danger">Usuario</span>
                    </h3>
                    <Link to={`/users/userRaces/${userId}`} className="linkUser">
                      Ver historial de Viajes
                    </Link>
                  </div>
                  <div className="item">
                    <div className="cellHeader">
                      <div className="cell">
                        <h6>Puntuación Promedio: </h6>
                        <h6>
                          {isFetchingReviews ? '' : userReviews?.average} <StarIcon style={{ height: '16px', marginTop: '-3px', marginLeft: '-5px' }} color="#FFCE31" />
                        </h6>
                      </div>
                      <div className="cell">
                        <h6>Correo Electrónico: </h6>
                        <h6>{user?.email}</h6>
                      </div>
                      <div className="cell">
                        <h6>Teléfono: </h6>
                        <h6>{user?.phoneNumber}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="observations">
                  <h3 style={{ fontSize: '14px', color: '#1A0C12', fontWeight: 600, padding: '0 15px', marginBottom: '10px' }}>
                    Novedades del <span className="text-danger">Usuario</span>
                  </h3>
                  <div>
                    {!isFetchingReviews &&
                      userReviews?.reviews
                        .filter((review) => review.comment !== '')
                        .map((review) => (
                          <div className="cell">
                            <h6 className="col-4">
                              {/* <span><img src={review.sender.userImgUrl}/></span> */}
                              <AccountCircleIcon color="#000000" style={{ marginRight: '10px' }} />
                              <span className="font-weight-bold"> {`${review.sender.firstName} ${review.sender.firstLastName}`}:</span>
                            </h6>
                            <h5 className="col-8">{review.comment} </h5>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: '30px 0px 0px 20px', display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {!isFetchingDataDashboardCollaborator && dataCollaborator ? (
                <div className="row" style={{ width: '100%' }}>
                  <Col md={6} xs={12}>
                    <div style={{ marginRight: '3%' }}>
                      <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                        Total de <span className="text-danger">gasto</span>
                      </label>
                      <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                        <Bar
                          style={{ height: '200px' }}
                          data={dataGraphics('generalCost')}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <div style={{ marginRight: '3%' }}>
                      <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                        Km <span className="text-danger">promedio</span>
                      </label>
                      <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                        <Bar
                          style={{ height: '200px' }}
                          data={dataGraphics('generalKm')}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true,
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              x: {
                                grid: {
                                  display: false,
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <div className="row" style={{ width: '100%', marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                    <Col md={6} xs={12}>
                      <div style={{ marginRight: '3%' }}>
                        <label style={{ fontWeight: 600, fontSize: '16px' }} htmlFor="zona-calor" className="label-head-info">
                          Número de viajes <span className="text-danger">Servicio</span>
                        </label>
                        <div style={{ backgroundColor: 'white', padding: '10px 5px 5px 10px', marginLeft: '10px', marginBottom: '30px' }}>
                          <Bar
                            style={{ height: '200px' }}
                            data={dataGraphics('generalTravels')}
                            options={{
                              maintainAspectRatio: false,
                              responsive: true,
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                              scales: {
                                x: {
                                  grid: {
                                    display: false,
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              ) : (
                <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
              )}
            </div>
          </Row>
        </>
      )}
      <Dialog maxWidth="sx" open={openModaleRequestTravel} onClose={() => setOpenModaleRequestTravel(!openModaleRequestTravel)} aria-labelledby="max-width-dialog-title">
        <DialogContent style={{ padding: 40 }}>
          <DialogContentText style={{ width: 350 }}>Elije la fecha en la que finaliza la autorización de viaje</DialogContentText>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '100%' }}>
              <KeyboardDatePicker
                style={{ width: 350, margin: '30px 0px' }}
                disableToolbar
                minDate={new Date()}
                inputVariant="outlined"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker-inline"
                label="Fecha de finalización"
                value={dateFinish}
                autoOk
                onChange={(date) => {
                  setDateFinish(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <ButtonCustom onClick={handleRequestTravel} title="Habilitar" style={{ width: 350, margin: '0px 0px 20px 0px' }} />
            </div>
          </MuiPickersUtilsProvider>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
