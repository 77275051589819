import React, { useState, useEffect } from 'react';
import { Col, Container, Input, Spinner } from 'reactstrap';
import Select from "react-select";
import DownloadIcon from 'mdi-react/DownloadIcon';
import GoogleAssistantIcon from 'mdi-react/GoogleAssistantIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Link, useLocation } from 'react-router-dom';
import { getAllTravels, getTravelsByStatus, getTravelsByDates } from "../../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf"
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const DescriptionZone = ({ title, text, circleColor }) => (
    <div className="col-6">
        <div style={{ display: "flex", marginLeft: "-30px" }}>
            <div style={{ backgroundColor: circleColor, height: "20px", width: "20px", borderRadius: "50px" }}></div>
            <h5 style={{ color: "#000", fontWeight: 600, fontSize: "14px", marginBottom: "10px", marginLeft: "10px" }}>
                {title}
            </h5>
        </div>
        <h6 style={{ color: "#000", fontWeight: 300, fontSize: "14px" }}>
            {text}
        </h6>
    </div>
)


export default function index() {
    const dispatch = useDispatch();
    const dataMaps = useSelector((state) => state.dashboard.journeyAlerts);
    const isFetchingDataDashboard = useSelector((state) => state.dashboard.isFetching);
    const travelsByStatusAndDates = useSelector((state) => state.dashboard.travelsByStatusAndDates);
    const [initialDateSelected, setInitialDateSelected] = useState("");
    const [endDateSelected, setEndDateSelected] = useState("");
    const [statusSelected, setstatusSelected] = useState(null);
    const [searchDatesPressed, setSearchDatesPressed] = useState(false);
    const rowsPagePdf = 13;

    const location = useLocation();
    const { pushNewItemBreadCrums } = useBreadCrums();

    useEffect(() => {
        pushNewItemBreadCrums('Mapas: Solicitudes');
    }, [location]);

    useEffect(() => {
        dispatch(getAllTravels());
    }, [])

    useEffect(() => {
        if (statusSelected) dispatch(getTravelsByStatus(statusSelected.value))
    }, [statusSelected])

    const travelsByDates = () => {
        if (endDateSelected !== "" && initialDateSelected !== "" && !isFetchingDataDashboard) {
            setSearchDatesPressed(true);
            setstatusSelected(null);
            dispatch(getTravelsByDates(initialDateSelected, endDateSelected))
        }
    }

    const translateStatus = (status) => {
        switch (status) {
            case "pending":
                return "Pendiente";
            case "accepted":
                return "Aceptado";
            case "reject":
                return "Rechazado";
            case "completed":
                return "Completado";
            case "onway":
                return "En camino";
            case "traveling":
                return "Viajando";
            default:
                return status;
        }
    };

    const dataToExport = () => {
        let data;
        if ((endDateSelected !== "" && initialDateSelected !== "" && !isFetchingDataDashboard && searchDatesPressed) || statusSelected !== null) data = travelsByStatusAndDates
        else data = dataMaps;
        if (data === null) data = []
        return data;
    }

    const exportToExcel = () => {
        const dataTravels = dataToExport();
        const filterData = dataTravels.map(
            ({ id, date, hour, addresOrigin, addressDestiny, price, km, status }) => ({ id, date, hour, addresOrigin, addressDestiny, price, km, status: translateStatus(status) })
        );

        const Heading = [["Id", "Fecha", "Hora", "Origen", "Destino", "Precio", "Kilometros", "Estado"]];
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        const ws = XLSX.utils.json_to_sheet(filterData, { origin: "A2", skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, Heading);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Solicitudes.xlsx");
    };

    const generatePDF = () => {
        const pdf = new jsPDF('l', 'mm', [297, 210]);

        for (let i = 0; i < Math.ceil(dataMaps.length / rowsPagePdf); i++) {
            html2canvas(document.querySelector(`#tableToExport${i}`), {
                onclone: function (clonedDoc) {
                    clonedDoc.getElementById(`tableToExport${i}`).style.display = 'block';
                }
            }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 3, 3);

                if (i + 1 === Math.ceil(dataMaps.length / rowsPagePdf)) pdf.save("Solicitudes.pdf");
                else pdf.addPage();
            });
        }
    };

    return (
        <Container className="dashboard">
            <Col md={12}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h3 className="page-title" style={{ fontSize: "25px", marginTop: "10px" }}> <GoogleAssistantIcon style={{ color: "#D8394C", marginLeft: "-10px" }}></GoogleAssistantIcon> Mapas: <span className="text-danger">Solicitudes</span></h3>
                    <div className="row" style={{ justifyContent: "flex-end" }}>
                        <div style={{ marginRight: "10px" }}>
                            <Input
                                className="select__control"
                                id="selectorDate"
                                name="Seleccionar Fecha"
                                placeholder="Seleccione Fecha"
                                type="date"
                                style={{ width: "170px" }}
                                onChange={e => {
                                    setInitialDateSelected(e.target.value)
                                }}
                                value={initialDateSelected}
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                            <Input
                                className="select__control"
                                id="selectorDate"
                                name="Seleccionar Fecha"
                                placeholder="Seleccione Fecha"
                                type="date"
                                style={{ width: "170px" }}
                                onChange={e => {
                                    setEndDateSelected(e.target.value)
                                }}
                                value={endDateSelected}
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <button
                            style={{
                                border: "none",
                                backgroundColor: "#EC1C24",
                                height: "38px",
                                borderRadius: "9px",
                                marginRight: "20px"
                            }}
                            onClick={travelsByDates}
                        ><MagnifyIcon color='white' /></button>
                        <div style={{ marginRight: "30px" }}>
                            <Select
                                className="select__control"
                                classNamePrefix="select"
                                name="Estado"
                                isClearable={true}
                                isSearchable={true}
                                placeholder="Estado"
                                color
                                value={statusSelected}
                                onChange={e => {
                                    if (e == null) setstatusSelected(null);
                                    else {
                                        setInitialDateSelected("");
                                        setEndDateSelected("");
                                        setSearchDatesPressed(false);
                                        setstatusSelected({ value: e.value, label: e.label })
                                    }
                                }}
                                styles={{
                                    container: (provided, state) => ({
                                        ...provided,
                                        width: "170px"
                                    }),
                                }}
                                options={[{ value: 'pending', label: 'Pendiente' }, { value: 'accepted', label: 'Aceptado' }, { value: 'onway', label: 'En camino' }, { value: 'traveling', label: 'Viajando' }, { value: 'completed', label: 'Completado' }, { value: 'problem', label: 'Problema' }]}
                            />
                        </div>
                        <button className="dashboardButton" onClick={exportToExcel} style={{ backgroundColor: "#3DBE00", marginRight: "5px", fontSize: "12px", height: "42px" }}>XLSX <DownloadIcon style={{ width: "15px" }}></DownloadIcon></button>
                        <button className="dashboardButton" onClick={generatePDF} style={{ backgroundColor: "#EC1C24", fontSize: "12px", height: "42px" }}>PDF <DownloadIcon style={{ width: "15px" }}></DownloadIcon></button>
                    </div>
                </div>
                <div style={{ height: "48vh", width: "100%", marginTop: "10px", display: "block" }}>
                    {isFetchingDataDashboard && !dataMaps && < Spinner className="table-fetch-spinner" style={{ marginLeft: "50%" }} />}
                    {!isFetchingDataDashboard && dataMaps &&
                        <GoogleMap
                            id="map_heatZone"
                            center={{ lat: -0.225219, lng: -78.5248 }}
                            zoom={7}
                            mapContainerStyle={{ height: "100%", width: "100%" }}
                            options={{
                                streetViewControl: false,
                                zoomControl: false,
                                mapTypeControl: false,
                                minZoom: 2
                            }}
                        >
                            {(statusSelected !== null || (endDateSelected !== "" && initialDateSelected !== "" && searchDatesPressed)) && travelsByStatusAndDates
                                ? travelsByStatusAndDates.map(point =>
                                    <Marker
                                        position={{ lat: Number(point.latOrigin), lng: Number(point.lngOrigin) }}
                                        icon={{
                                            url: 'https://img.icons8.com/emoji/256/red-circle-emoji.png',
                                            scaledSize: new google.maps.Size(20, 20)
                                        }}
                                    />
                                )
                                : dataMaps.map(point =>
                                    <Marker
                                        position={{ lat: Number(point.latOrigin), lng: Number(point.lngOrigin) }}
                                        icon={{
                                            url: 'https://img.icons8.com/emoji/256/red-circle-emoji.png',
                                            scaledSize: new google.maps.Size(20, 20)
                                        }}
                                    />
                                )
                            }
                        </GoogleMap>}
                </div>
                <div style={{ marginTop: "3vh", position: "absolute" }}>
                    <DescriptionZone
                        title="Zonas de mayor frecuencia de uso"
                        text="Los puntos rojos iindican usuarios usando la plataforma en tiempo real. La acumulación de puntos indica alto indice de uso."
                        circleColor="#E8272C"
                    />
                </div>
                {
                    !isFetchingDataDashboard && dataMaps && dataMaps.map((item, index) => {
                        if (index < Math.ceil(dataMaps.length / rowsPagePdf)) return (
                            <table style={{ borderLeft: "1px solid #f2f2f2", borderRight: "none", borderBottom: "none", display: "none", width: "1100px", maxWidth: "1100px", minWidth: "1100px" }} id={`tableToExport${index}`}>
                                <tbody>
                                    <tr style={{ backgroundColor: "#303e47", borderBottom: "none" }}>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "50px" }}>Id</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>Fecha</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>Hora</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "250px" }}>Origen</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "250px" }}>Destino</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>Precio</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>Kilometros</th>
                                        <th style={{ fontWeight: "bold", color: "white", padding: "5px 15px 5px 5px", textAlign: "center", width: "150px" }}>Estado</th>
                                    </tr>
                                    {
                                        dataToExport().slice(index * rowsPagePdf, (index + 1) * rowsPagePdf).map((item, index) => (
                                            <tr key={`row-${index}`} style={{ borderRight: "1px solid #f2f2f2", borderBottom: "1px solid #f2f2f2" }}>
                                                <td style={{ padding: "5px 15px 5px 5px", width: "50px" }}>{item.id}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>{item.date}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>{item.hour}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "250px" }}>{item.addresOrigin}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "250px" }}>{item.addressDestiny}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>{item.price}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "100px" }}>{item.km}</td>
                                                <td style={{ padding: "5px 15px 5px 5px", textAlign: "center", width: "150px" }}>{translateStatus(item.status)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        )
                    })
                }
            </Col >
        </Container >
    )
};