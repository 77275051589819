import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { TitleSettings } from "../../../shared/components/TitleSettings";
import Dialog from "../../../shared/components/Dialog";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDetailRate,
  listDetailRateById,
} from "../../../redux/actions/ratesActions";
import TableBase from "./components/TableBase";
import { Button, Col, Row, Spinner, Container } from "reactstrap";
import TrashIcon from "mdi-react/TrashIcon";

const index = () => {
  const { id, rate } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idRate, setIdRate] = useState('');
  const [idDetailRate, setIdDetailRate] = useState()

  const listDetailsRates = useSelector((state) => state.rates.ratesDetailData);
  const isFetchingList = useSelector((state) => state.rates.isFetching);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        Cell: (cellObj) => <span>{cellObj.cell.row.index}</span>,
        disableFilters: true,
      },
      {
        Header: "Tarifa Base:",
        accessor: "base",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.baseRate}$</span>,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Valor por KM:",
        accessor: "km",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.valueKm}$</span>,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Valor por minuto:",
        accessor: "minute",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.valueMin}$</span>,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Aplica desde:",
        accessor: "since",
        Cell: (cellObj) => (
          <span>{cellObj.cell.row.original.applyFrom} km</span>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Rango:",
        accessor: "range",
        Cell: (cellObj) => (
          <span>
            {cellObj.cell.row.original.fromDate}km -
            {cellObj.cell.row.original.untilDate}km
          </span>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Extra:",
        accessor: "extra",
        Cell: (cellObj) => <span>{cellObj.cell.row.original.extra}$</span>,
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "",
        accessor: "edit",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellObj) => (
          <Link to={`/settings/rates/addDetailRates/${id}/${rate}/${cellObj.cell.row.original.id}`}>
            <Button color="danger" style={{ marginBottom: "0px", padding: "5px 20px" }}>Editar</Button>
          </Link>
        ),
      },

      {
        Header: "Acciones",
        accessor: "actions",
        Cell: (cellObj) => (
          <>
            <button
              className="table__action__icon"
              onClick={() =>
                handleDeleteDetailRate(cellObj.cell.row.original.id, cellObj.cell.row.index)
              }
            >
              <TrashIcon color="#00E0FF" />
            </button>
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },


    ],
    [listDetailsRates]
  );

  const handleDeleteDetailRate = (idDetail, index) => {
    setOpen(true);
    setIdRate(index)
    setIdDetailRate(idDetail)

    /* dispatch(deleteDetailRate(idDetail,id)) */
  };

  useEffect(() => {
    dispatch(listDetailRateById(id));
  }, []);

  return (
    <Container className="dashboard">
      <Dialog
        title={`¿Esta seguro de eliminar el detalle de la tarifa ${idRate} ?`}
        description="Si elimina este detalle de la tarifa puede producir daños en la aplicación movil "
        open={open}
        setOpen={setOpen}
        acceptClick={() => { dispatch(deleteDetailRate(idDetailRate, id)) }}
      />
      <Row>
        <Col
          md={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <TitleSettings title={`Detalle de ${rate} `} />
          <Link to={`/settings/rates/addDetailRates/${id}/${rate}/none`}>
            <Button
              style={{ marginRight: 25, width: 200 }}
              color="danger"
              type="submit"
            >
              Agregar Tarifa
            </Button>
          </Link>
        </Col>
      </Row>

      {!isFetchingList && listDetailsRates ? (
        <TableBase
          reactTableData={{
            tableHeaderData: columns,
            tableRowsData: listDetailsRates,
          }}
        />
      ) : (
        <Spinner
          className="table-fetch-spinner"
          style={{ marginLeft: "50%" }}
        />
      )}
    </Container>
  );
};

export default index;
