import React from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import TuneIcon from 'mdi-react/TuneIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import PencilPlusIcon from 'mdi-react/PencilPlusIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import Select from "react-select";

const data_time = [
  { image:'/img/casaoficina.png', text: 'Casa - Oficina', status: true },
  { image:'/img/oficinacasa.png', text: 'Oficina - Casa', status: false },
  { image:'/img/tramites.png', text: 'Tramites', status: true }
];

const Dashboard = () => {
  return (
    <Container className="dashboard">
      <Row className="mb-4">
        <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
          <TuneIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '10px', marginTop: '-5px' }} />
          <h3 className="page-title"> Ajuste: <span className="text-danger">Traslados</span></h3>
        </Col>
      </Row>
      <Row className="react-action-top-b-l">
        <div>
          <Button color="danger" style={{ backgroundColor: 'red' }} className="m-0 py-1">Guardar cambios</Button>
        </div>
      </Row>
      <Row className="mt-4 p-0 m-0">
        <Col lg={6} md={12}>
          <div className="space-line-div py-2 px-4"></div>
          <div className="space-line-div py-2 px-4">
            <Row>
              <Col lg={6} md={12} className="lh-1 bold">
                <ClockOutlineIcon style={{ height: '20px', width: '20px', color: "#4B545A", marginRight: '10px', marginTop: '-2px' }} />
                Tiempo <span className="text-danger">de espera</span>
              </Col>
              <Col lg={6} md={12}>
                <Select
                  className="basic-single-user"
                  name="Codigo_provedor"
                  isClearable={true}
                  isSearchable={true}
                  placeholder={"Tiempo de espera"}
                  color
                  options={[
                    { value: '1 minuto', label: '1 minuto' },
                    { value: '2 minutos', label: '2 minutos' },
                    { value: '3 minutos', label: '3 minutos' },
                    { value: '4 minutos', label: '4 minutos' },
                    { value: '5 minutos', label: '5 minutos' },
                    { value: '6 minutos', label: '6 minutos' },
                    { value: '7 minutos', label: '7 minutos' },
                    { value: '8 minutos', label: '8 minutos' },
                    { value: '9 minutos', label: '9 minutos' },
                    { value: '10 minutos', label: '10 minutos' },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={12}>
          <div className="py-2 px-4 bold mt-3">
            <Row>
              <Col sm={{ size: 'auto' }}>
                <MapMarkerIcon style={{ height: '20px', width: '20px', color: "#4B545A", marginRight: '10px', marginTop: '-2px' }} />
                Tiempo <span className="text-danger"> de viaje</span>
              </Col>
                { data_time.map(rq => (
                  <Col lg="2">
                    <div className="card-transfers text-center">
                      <PencilPlusIcon className="icon-card-transfers" />
                      <div className="image-tranfers">
                        <img src={ rq.image } alt="" />
                      </div>
                      <div className="text-tranfers mt-2 mx-2">
                        { rq.text } 
                      </div>
                      <div className="switch-tranfers mt-2">
                          <Switch checked={ rq.status }></Switch>
                      </div>
                    </div>
                  </Col>
                )) }
              <Col lg="2">
                  <div className="card-add-register text-center m-4 p-2">
                    <PlusIcon style={{ width: '35px', height: '35px' }} />
                    <div className="text-add-register" style={{ fontSize: '17px' }}>
                      Agregar Nuevo
                    </div>
                  </div>
              </Col>  
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

export default Dashboard;
