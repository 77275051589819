import { get, put, post, deleteRequest } from "../base/index";

export default {
  getColors: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/color/all`);
  },

  putColorById: (id, body) => {
    return put(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/color/${id}`,
      body
    );
  },

  postColor: (body) => {
    return post(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/color`,
      body
    );
  },

  deleteColorById: (id) => {
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/color/${id}`
    );
  },
};
