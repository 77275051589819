import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button, Input, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import SteeringIcon from 'mdi-react/SteeringIcon';
import Radio from '@material-ui/core/Radio';
import { toast } from 'react-toastify';
import EyeIcon from 'mdi-react/EyeIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import { templateEmail } from '../../../shared/templates/email';
import { sendEmail, getVehicleById, putehicleById } from '../../../redux/actions/ProvidersActions';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const VehicleProfile = ({ match }) => {
  const {
    params: { vehicleId },
  } = match;
  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.provider.isFetching);
  const vehicleById = useSelector((state) => state.provider.vehicleById);
  const isSendingEmail = useSelector((state) => state.provider.isSendingEmail);
  const isSendEmailError = useSelector((state) => state.provider.isSendEmailError);
  const [observations, setObservations] = useState('');

  useEffect(() => {
    dispatch(getVehicleById(vehicleId));
  }, []);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums(`Detalle vehículo`);
  }, [vehicleById, location]);

  const handleDataRow = (type) => {
    let body = {};
    switch (type) {
      case 'maintenance':
        if (vehicleById.maintenance === 'accepted') body = { maintenance: 'pending' };
        else body = { maintenance: 'accepted' };
        break;
      case 'corporate':
        if (vehicleById.corporate === 'accepted') body = { corporate: 'pending' };
        else body = { corporate: 'accepted' };
        break;
      case 'security':
        if (vehicleById.security === 'accepted') body = { security: 'pending' };
        else body = { security: 'accepted' };
        break;
      default:
        if (vehicleById.status !== 'accepted') {
          if (vehicleById.maintenance !== 'accepted' || vehicleById.corporate !== 'accepted' || vehicleById.security !== 'accepted') {
            toast.error('Primero se deben habilitar las aprobaciones', { autoClose: 2500, theme: 'colored' });
            return;
          } else body = { status: 'accepted' };
        } else body = { status: 'pending' };
        break;
    }
    dispatch(putehicleById(vehicleId, body));
  };

  const sendNotification = () => {
    dispatch(
      sendEmail({
        from: 'Movilitix',
        to: vehicleById?.user?.email,
        subject: 'Nuevas observaciones',
        text: observations,
        html: templateEmail('Nuevas observaciones', observations),
      })
    );
  };

  useEffect(() => {
    if (!isSendingEmail) {
      if (isSendEmailError === null) setObservations('');
    }
  }, [isSendingEmail]);

  return (
    <Container className="authorizations">
      {!isFetching && vehicleById ? (
        <Col md={12}>
          <Row className="mb-4">
            <Col md={12} style={{ display: 'flex', flexDirection: 'row' }}>
              <SteeringIcon style={{ height: '40px', width: '50px', color: '#4B545A', marginRight: '13px', marginTop: '-7px' }} />
              <h3 className="page-title">
                Propietario:{' '}
                <span className="text-danger">
                  {vehicleById.user.firstName} {vehicleById.user.firstLastName}
                </span>
              </h3>
            </Col>
          </Row>
          <Row className="mb-4" style={{ marginTop: '15px' }}>
            <Col md={6}>
              <h3 style={{ fontSize: '18px', marginBottom: '10px', fontWeight: 600, color: '#1A0C12' }}>
                Datos del <span className="text-danger"> Vehículo</span>
              </h3>
              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Input id="mark" style={{ marginRight: '14px' }} name="mark" placeholder="" type="text" disabled="true" value={vehicleById?.model.mark?.name} className="AuthorizationsInput" />
                <Input id="model" name="model" placeholder="" type="text" disabled="true" value={vehicleById.model.name} className="AuthorizationsInput" />
              </div>
              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Input id="color" style={{ marginRight: '14px' }} name="color" placeholder="" type="text" disabled="true" value={vehicleById.color.name} className="AuthorizationsInput" />
                <Input id="year" name="year" placeholder="" type="text" disabled="true" value={vehicleById.year} className="AuthorizationsInput" />
              </div>
              <div style={{ display: 'flex', marginBottom: '15px' }}>
                <Input id="carPlate" style={{ marginRight: '14px' }} name="carPlate" placeholder="" type="text" disabled="true" value={vehicleById.carPlate} className="AuthorizationsInput" />
                <Input id="safeCompany" name="safeCompany" placeholder="" type="text" disabled="true" value={vehicleById.safeCompany} className="AuthorizationsInput" />
              </div>
            </Col>
            <Col md={6}>
              <h3 style={{ fontSize: '15px', marginBottom: '0px', fontWeight: 600 }} className="text-danger">
                Aprobación
              </h3>
              <div className="d-flex flex-wrap">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 600, marginBottom: '0px' }} className="text-danger">
                    Mantenimiento
                  </h6>
                  <Radio className="radio-table" checked={vehicleById.maintenance === 'accepted'} style={{ paddingTop: '0', marginTop: '5px' }} onClick={() => handleDataRow('maintenance')} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 600, marginBottom: '0px', marginRight: '20px' }} className="text-danger">
                    Corporativo
                  </h6>
                  <Radio className="radio-table" checked={vehicleById.corporate === 'accepted'} style={{ paddingTop: '0', marginTop: '5px' }} onClick={() => handleDataRow('corporate')} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 600, marginBottom: '0px', marginRight: '20px' }} className="text-danger">
                    Seguridad
                  </h6>
                  <Radio className="radio-table" checked={vehicleById.security === 'accepted'} style={{ paddingTop: '0', marginTop: '5px' }} onClick={() => handleDataRow('security')} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h6 style={{ fontSize: '13px', fontWeight: 600, marginBottom: '7px' }} className="text-danger">
                    Habilitado
                  </h6>
                  <Switch checked={vehicleById.status === 'accepted'} onChange={() => handleDataRow('status')} />
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: '0px 0px 20px 0px', display: 'flex', alignItems: 'center' }}>
            <h5>Tiene seguro de vehículo:</h5>
            <Radio className="radio-table" checked={vehicleById.maintenance === 'accepted'} style={{ paddingTop: '0', marginTop: '10px' }} checkedIcon={<CheckCircleOutlineIcon color="#f50057" size={'35px'} />} />
          </Row>
          <Row style={{ margin: '0px', marginBottom: '5px' }}>
            <h3 style={{ fontSize: '18px', marginBottom: '0px', fontWeight: 600, color: '#1A0C12' }}>
              Validación del <span className="text-danger"> Vehículo</span>
            </h3>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col md={4}>
              <h3 style={{ fontSize: '15px', marginBottom: '0px', fontWeight: 600, marginLeft: '50px' }} className="text-danger">
                Documento
              </h3>
              <h6 style={{ marginBottom: '5px', fontSize: '14px', color: '#4B545A' }}>
                <a href={vehicleById?.platePhotoUrl} target="_blank">
                  <EyeIcon color="#00E0FF" className="mr-2" />
                </a>
                Matrícula{' '}
              </h6>
              <h6 style={{ fontSize: '14px', color: '#4B545A' }}>
                <a href={vehicleById?.safePolicyUrl} target="_blank">
                  <EyeIcon color="#00E0FF" className="mr-2" />
                </a>
                Póliza del seguro{' '}
              </h6>
            </Col>
            <Col md={6}>
              <h3 style={{ fontSize: '15px', marginBottom: '0px', fontWeight: 600 }} className="text-danger">
                Observaciones
              </h3>
              <Row>
                <Col lg={8}>
                  <Input type="textarea" style={{ height: '130px', fontSize: '14px', marginTop: '10px', backgroundColor: '#D6D8DB', color: '#5B5B5B' }} placeholder="Ingrese sus observaciones" id="Observations" value={observations} onChange={(e) => setObservations(e.target.value)} disabled={isSendingEmail} />
                </Col>
                <Col lg={3}>
                  <button
                    style={{
                      border: 'none',
                      backgroundColor: '#EC1C24',
                      borderRadius: '9px',
                      marginRight: '20px',
                      fontWeight: 600,
                      fontSize: '15px',
                      color: 'white',
                      padding: '10px 20px',
                      marginTop: '50px',
                    }}
                    disabled={observations === '' || isSendingEmail}
                    onClick={sendNotification}
                  >
                    {isSendingEmail ? <Spinner color="white" className="table-fetch-spinner" style={{ margin: '2px 30px' }} /> : 'ENVIAR Notificación'}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
      )}
    </Container>
  );
};

export default VehicleProfile;
