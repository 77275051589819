import React from 'react';
import PropTypes from 'prop-types';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from 'reactstrap';
import ArrowRightIcon from 'mdi-react/ArrowRightDropCircleOutlineIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftDropCircleOutlineIcon';

const ReactTablePagination = ({
  dataLength,
  page,
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageSize,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageSize,
  manualPageSize,
  pageCount
}) => {
  const arrayPageIndex = (pageIndex - 2) < 0
    ? pageOptions.slice(0, pageIndex + 3)
    : pageOptions.slice((pageIndex - 2), (pageIndex + 3));

  return (
    <Pagination className="pagination" dir="ltr">
      <div className="pagination">
        <PaginationItem className="pagination__item pagination-info">
          Pag. {(pageIndex < 9) ? `0${pageIndex + 1}` : (pageIndex + 1)} - {(pageCount < 10) ? `0${pageCount}` : (pageCount)}
        </PaginationItem>
        <PaginationLink
          className="pagination__link pagination__link--arrow"
          type="button"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <ArrowLeftIcon className="pagination__link-icon" />
        </PaginationLink>
        <PaginationItem className="pagination__item">
          <PaginationLink
            className="pagination__link pagination__link--arrow"
            type="button"
            onClick={nextPage}
            disabled={!canNextPage}
          >
            <ArrowRightIcon className="pagination__link-icon" />
          </PaginationLink>
        </PaginationItem>
      </div>
    </Pagination>
  );
};

ReactTablePagination.propTypes = {
  dataLength: PropTypes.number.isRequired,
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSize: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  manualPageSize: PropTypes.arrayOf(PropTypes.number),
};

ReactTablePagination.defaultProps = {
  manualPageSize: [10, 20, 30, 40],
};

export default ReactTablePagination;
