import { get, put, post, deleteRequest } from "../base/index";

export default {
  getCitiesByProvinceId: (provinceId) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/cities/getCityByProvinceId/${provinceId}`
    );
  },

  putCityById: (id, body) => {
    return put(`${process.env.REACT_APP_URL_BASE}/cities/${id}`, body);
  },

  postCity: (body) => {
    return post(`${process.env.REACT_APP_URL_BASE}/cities`, body);
  },

  deleteCity: (id) => {
    return deleteRequest(`${process.env.REACT_APP_URL_BASE}/cities/${id}`);
  },
};
