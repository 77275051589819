import { handleActions } from "redux-actions";

import {
  fetchProvincesRequest,
  fetchProvincesSuccess,
  fetchProvincesError,
} from "../actions/provincesActions";

const defaultState = [];

export default handleActions(
  {
    [fetchProvincesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchProvincesSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProvincesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
