import React from 'react';
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import Select from "react-select";
import PlayIcon from 'mdi-react/PlayIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import { Link } from 'react-router-dom';

const dataGraphics = {
  labels: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
  datasets: [
    {
      label: 'Data 2019',
      data: [100, 300, 120, 120, 120, 120, 150, 200, 220, 300, 400, 500],
      backgroundColor: ['rgba(255, 192, 192)', 'rgba(255, 43, 43)', 'rgba(255, 155, 155)', 'rgba(255, 135, 125)', 'rgba(255, 115, 115)', 'rgba(255, 95, 95)', 'rgba(255, 75, 75)', 'rgba(255, 55, 55)', 'rgba(255, 35, 35)', 'rgb(175, 20, 20)', 'rgba(90, 8, 8)', 'rgba(25, 25, 70)'],
      borderWidth: 0,
    },
    {
      label: 'Data 2020',
      data: [80, 150, 100, 90, 100, 90, 170, 180, 200, 270, 355, 400],
      backgroundColor: ['rgba(5, 192, 192)', 'rgba(5, 43, 43)', 'rgba(5, 155, 155)', 'rgba(5, 135, 125)', 'rgba(5, 115, 115)', 'rgba(5, 95, 95)', 'rgba(5, 75, 75)', 'rgba(5, 55, 55)', 'rgba(5, 35, 35)', 'rgb(5, 20, 20)', 'rgba(5, 8, 8)', 'rgba(5, 25, 70)'],
      borderWidth: 0,
    }
  ]
};
const dataTravelAlerts = [{ name: "Oscar Erazo", destiny: "Megamaxi & de Diciembre" }, { name: "Esteban Ortiz", destiny: "Megamaxi & de Diciembre" }, { name: "Pedro Jaramillo", destiny: "Megamaxi & de Diciembre" }, { name: "Esteban Ortiz", destiny: "Megamaxi & de Diciembre" }, { name: "Oscar Erazo", destiny: "Megamaxi & de Diciembre" }];
const dataFrequentHours = [{ date: "09:00 a 11:00", numberTravels: 11 }, { date: "13:00 a 15:00", numberTravels: 50 }, { date: "15:00 a 16:00", numberTravels: 30 }, { date: "09:00 a 11:00", numberTravels: 11 }, { date: "13:00 a 15:00", numberTravels: 50 }];
const dataTop5 = [{ name: "Oscar Erazo", distance: 20, minutes: 120, coste: 115 }, { name: "Esteban Ortiz", distance: 20, minutes: 120, coste: 115 }, { name: "Pedro Jaramillo", distance: 20, minutes: 120, coste: 115 }, { name: "Laura Yepez", distance: 20, minutes: 120, coste: 115 }, { name: "Jorge Egos", distance: 20, minutes: 120, coste: 115 }]

const Dashboard = () => {
  return (
    <Container className="dashboard">
      <Row className="mb-4">
        <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
          <h3 className="page-title">Vehiculo: <span className="text-danger">PHR-3245</span></h3>
        </Col>
      </Row>
      <Col md={12}>
        <Row style={{ justifyContent: "flex-end", marginTop: "-5.1em" }}>
            <Select
              className="basic-single-user rw-15 mr-2 h-35"
              isClearable={true}
              isSearchable={true}
              placeholder={"Código de provedor"}
              color
              options={[
                { value: 1, label: 1 },
              ]}
            />
            <Select
              className="basic-single-user rw-15 mr-2 h-35"
              isClearable={true}
              isSearchable={true}
              placeholder={"Gestor de transporte"}
              color
              options={[
                { value: 1, label: 1 },
              ]}
            />
            <Select
              className="basic-single-user rw-15 mr-2 h-35"
              isClearable={true}
              isSearchable={true}
              placeholder={"Conductor"}
              color
              options={[
                { value: 1, label: 1 },
              ]}
            />
            <Select
              className="basic-single-user rw-15 mr-2 h-35"
              isClearable={true}
              isSearchable={true}
              placeholder={"Provincia"}
              color
              options={[
                { value: 1, label: 1 },
              ]}
            />
          <Button className="py-1 px-3 mr-2 btn-xls">XLS <DownloadIcon style={{ color: "#fff" }}></DownloadIcon></Button>
          <Button className="py-1 px-3 btn-pdf">PDF <DownloadIcon style={{ color: "#fff" }}></DownloadIcon></Button>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col md={4}>
            <label htmlFor="zona-calor" className="label-head-info">Ganancia <span className="text-danger">Promedio</span></label>
            <div style={{ height: "250px" }}>
              <Bar
                data={dataGraphics}
                width={100}
                height={50}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: { beginAtZero: true }
                    }],
                    xAxes: {
                      grid: { offset: true }
                    }
                  }
                }}
              />
            </div>
            <label htmlFor="zona-calor" className="mt-4 label-head-info">Número de <span className="text-danger">Viajes</span></label>
            <div style={{ height: "250px" }}>
              <Bar
                data={dataGraphics}
                width={100}
                height={50}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: { beginAtZero: true }
                    }],
                    xAxes: {
                      grid: { offset: true }
                    }
                  }
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <label htmlFor="zona-calor" className="label-head-info">Km <span className="text-danger">promedio</span></label>
            <div style={{ height: "250px" }}>
              <Bar
                data={dataGraphics}
                width={100}
                height={50}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: { beginAtZero: true }
                    }],
                    xAxes: {
                      grid: { offset: true }
                    }
                  }
                }}
              />
            </div>
            <label htmlFor="zona-calor" className="mt-4 label-head-info">Tiempo de <span className="text-danger">Traslado</span></label>
            <div style={{ height: "250px" }}>
              <Bar
                data={dataGraphics}
                width={100}
                height={50}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: { beginAtZero: true }
                    }],
                    xAxes: {
                      grid: { offset: true }
                    }
                  }
                }}
              />
            </div>
          </Col>
          <Col md={4} className="p-0">
            <Accordion className="accordion">
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader">
                <PlayIcon className="playIcon" />
                <div className="numberAlerts">12</div>
                <div className="nameAccordion">
                  <h6 style={{ color: "#4B545A", fontWeight: "600", fontSize: "14px" }}>Alerta de viaje</h6>
                  <Link style={{ color: "#4B545A", fontWeight: "300", fontSize: "12px" }} to="/dashboard/travelAlerts">Ver Todas   <EyeOutlineIcon color="#646777"></EyeOutlineIcon></Link>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {dataTravelAlerts.map(alert => (
                  <div className="rowArowAccordionAlertBody">
                    <h6><AccountCircleIcon className="icon"></AccountCircleIcon>{alert.name}</h6>
                    <h6>{alert.destiny}</h6>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader">
                <PlayIcon className="playIcon" />
                <ClockOutlineIcon className="HeaderIcon"></ClockOutlineIcon>
                <div className="nameAccordion">
                  <h6 style={{ color: "#4B545A", fontWeight: "600", fontSize: "14px" }}>Horas de mayor uso</h6>
                  <div>
                    <h6>Ver todas  <EyeOutlineIcon></EyeOutlineIcon></h6>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {dataFrequentHours.map(alert => (
                  <div className="rowAccordionFrequentBody">
                    <h6>{alert.date}</h6>
                    <h6>{alert.numberTravels} viajes</h6>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion expanded className="accordion">
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="accordionHeader">
                <PlayIcon className="playIcon" />
                <AccountOutlineIcon className="HeaderIcon"></AccountOutlineIcon>
                <div className="nameAccordion">
                  <h6 style={{ color: "#4B545A", fontWeight: "600", fontSize: "14px" }}>TOP 5 Pasajeros</h6>
                  <div>
                    <h6>Ver todas  <EyeOutlineIcon></EyeOutlineIcon></h6>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordionBody">
                {dataTop5.map(top => (
                  <div className="rowArowAccordionTopBody">
                    <AccountCircleIcon className="icon"></AccountCircleIcon>
                    <div className="contentTop">
                      <h6>{top.name}</h6>
                      <div>
                        <h6>{top.distance} KM</h6>
                        <h6>{top.minutes} minutos</h6>
                        <h6>${top.coste}.00 Gasto</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </Col>
        </Row>
      </Col>
      <Row className="mt-4 p-0 m-0">
        <Col lg={8}>
            <Card>
              <CardBody>
                <label htmlFor="zona-calor" className="label-head-info">Observaciones del <span className="text-danger">Conductor</span> Asociado al <span className="text-danger">Vehiculo</span></label>
                <div className="space-line-div py-2 px-4">El tiempo de espera del usuario excedió los 15 minutos</div>
                <div className="space-line-div py-2 px-4">No indicó correctamente el punto departida</div>
                <div className="space-line-div py-2 px-4">Cambió el destino varias veces en el trayecto sin informar claramente el destino final</div>
              </CardBody>
            </Card>
        </Col>
      </Row>
    </Container>
  )
};

export default Dashboard;
