import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ClearBoxIcon from 'mdi-react/ClearCircleIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import TableBase from './components/TableBase';
import { closeSessionByIdUser, listUser, putUserByIdList } from '../../redux/actions/usersAction';
import { ListCities, listEstablisments, ListPrivicesByZone, ZonesFavorita } from '../../redux/actions/zoneAction';
import DataTable from 'react-data-table-component';
import { Button, Typography, IconButton, Tooltip, MenuItem } from '@material-ui/core';
import { CustomDataTable } from '../../shared/components/DataTable';
import { useBreadCrums } from '../../hooks/useBreadCrums';
import { DataTableCustom } from '../../shared/components/DataTableCustom';
import { Avatar } from 'primereact/avatar';
import { ButtonCustom } from '../../shared/components/Button/Button';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import { SelectOption } from '../../shared/components/SelectOptions';
import './styles.css';

export const columnsTable = [
  {
    name: 'Colaborador',
    selector: (row) => row.colaborador,
    width: '25%',
  },
  {
    name: 'Área/local',
    selector: (row) => row.workAreaCode,
    center: true,
    width: '20%',
  },
  {
    name: 'Habilitado',
    selector: (row) => row.habilitado,
    center: true,
    width: '15%',
  },
  {
    name: 'Acciones',
    selector: (row) => row.acciones,
    center: true,
    width: 'auto',
  },
  {
    name: 'Tipo de roles',
    selector: (row) => row.roleId,
    center: true,
    width: 'auto',
  },
];

const cols = [
  {
    field: 'first',
    header: '',
    style: { maxWidth: '60px' },
  },
  {
    field: 'colaborador',
    header: 'Colaborador',
    filter: true,
    sortable: true,
    style: { fontSize: '13px' },
  },
  {
    field: 'workAreaCode',
    header: 'Área/local',
    sortable: true,
    filter: true,
  },
  {
    field: 'habilitado',
    header: 'Habilitado',
  },
  {
    field: 'acciones',
    header: 'Acciones',
  },
  {
    field: 'roleId',
    header: 'Tipo de roles',
  },
  {
    field: 'session',
    header: 'Sesiones',
  },
];

const Users = () => {
  const dispatch = useDispatch();
  const usersLists = useSelector((state) => state.users.data);
  const userAuth = useSelector((state) => state.auth.dataUser);
  const usersRoles = useSelector((state) => state.users.roles);
  const zones = useSelector((state) => state.zonesFavorita.zones);
  const cities = useSelector((state) => state.zonesFavorita.cities);
  const establishments = useSelector((state) => state.zonesFavorita.establishments);
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);
  const [rol, setRol] = useState(2);
  const [establish, setEstablish] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [roles, setRoles] = useState([]);

  const [listUsers, setlistUsers] = useState([]);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Usuarios', 1);
  }, [location]);

  const mapperTable = () => {
    if (usersLists) {
      if (usersLists.length > 0) {
        var result = [];
        usersLists.map((item, key) => {
          result.push({
            first: (
              <Tooltip title="Mirar perfil" placement="top" arrow>
                <Link to={`/users/user/${item.id}`}>
                  <EyeOutlineIcon color="#000000" />
                </Link>
              </Tooltip>
            ),
            colaborador: `${item.firstName} ${item.firstLastName} ${item.secondLastName}`,
            workAreaCode: item.workAreaCode,
            habilitado: (
              <div>
                <Switch disabled={userAuth.roleId === 3 ? false : true} checked={item.status === 'accepted'} onChange={() => handleUserEnabled(item)} />
              </div>
            ),
            acciones: (
              <>
                <Tooltip title="Mirar carreras" placement="top" arrow>
                  <Link className="table__action__icon" to={`/users/userRaces/${item.id}`}>
                    <CarIcon color="#00E0FF" />
                  </Link>
                </Tooltip>
                <Tooltip title="Mirar estadísticas" placement="top" arrow>
                  <Link className="table__action__icon" to={`/users/userReport/${item.id}`}>
                    <ChartBarIcon color="#00E0FF" />
                  </Link>
                </Tooltip>
              </>
            ),
            roleId: (
              <Select
                disabled={userAuth.roleId === 3 ? false : true}
                className="basic-single"
                classNamePrefix="selectTable"
                name="Roles de usuario"
                placeholder={'Roles de usuario'}
                isDisabled={verifyRoleUser(item.id)}
                color
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 30,
                    minHeight: 30,
                  }),
                }}
                menuPosition={'fixed'}
                value={userRole()}
                onChange={(e) => updateUserRol(e.value, item.id)}
                options={userRolesOptions()}
              />
            ),
            session: <ButtonCustom disabled={!item.device} title="Cerrar sesión" onClick={() => dispatch(closeSessionByIdUser(item.id, rol))} />,
          });
        });
        setlistUsers(result);
      } else {
        setlistUsers([]);
      }
    }
  };

  const verifyRoleUser = (idUser) => {
    if (idUser === userAuth.id) return true;
    if (userAuth.roleId === 3) {
      return false;
    } else {
      return true;
    }
  };

  const filterOptions = (filterAttr) => {
    let arrayToReturn = [];
    let filterColumnData = [];
    usersLists.map((value) => {
      if (value[filterAttr] !== null) filterColumnData.push(value[filterAttr]);
    });

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    filterColumnData.filter(onlyUnique).map((value) => arrayToReturn.push({ value, label: value }));

    return arrayToReturn;
  };

  const translateRoles = () => {
    return (
      usersRoles &&
      usersRoles.map((rol) => {
        if (rol.nameRole === 'Colaborator') {
          return {
            ...rol,
            nameRole: 'Colaborador',
          };
        } else if (rol.nameRole === 'Super_Admin') {
          return {
            ...rol,
            nameRole: 'Super Administrador',
          };
        } else return rol;
      })
    );
  };

  const userRolesOptions = () => {
    const rolesUsers = translateRoles();
    const rolesTemp = rolesUsers && rolesUsers.map((rol) => ({ value: rol.id, label: rol.nameRole }));
    setRoles(rolesTemp);
    return rolesTemp;
  };

  const userRole = () => {
    const rolesUsers = translateRoles();
    return (
      rolesUsers &&
      rolesUsers.map((rolValue) => {
        if (rolValue.id === rol) return { value: rolValue.id, label: rolValue.nameRole };
      })
    );
  };

  const zonesOptions = () => {
    return zones.map((item) => ({ value: item.code, label: item.name, cities: item.cities }));
  };

  const selectZone = (e) => {
    dispatch(ListPrivicesByZone(e ? e.cities : []));
    dispatch(ListCities(e ? e.cities : []));
  };

  const citiesOptions = () => {
    return cities.map((item) => ({ value: item.code, label: item.name }));
  };

  const selectCity = (e) => {
    if (e) {
      const array = establishments.filter((item) => item.city.name === e.label);
      setEstablish([...array.map((item) => ({ value: item.workAreaCode, label: `${item.establishmentName} - ${item.workAreaCode} - ${item.workAreaName} ` }))]);
    }
  };

  const establishmentsOptions = () => {
    establishments.map((item) => ({ value: item.establishmentCode, label: item.establishmentAreaName }));
  };

  const selectEstablishment = (e) => {
    if (e) {
      dispatch(listUser(rol, e.value));
    } else {
      dispatch(listUser(rol));
    }
    mapperTable();
  };

  const updateUserRol = (newRol, userId) => {
    dispatch(putUserByIdList(userId, { roleId: newRol }, rol));
  };

  const handleUserEnabled = (user) => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserByIdList(user.id, body, rol));
  };

  useEffect(() => {
    dispatch(listUser(rol, userAuth.workAreaCode));
    dispatch(ZonesFavorita());
    dispatch(listEstablisments());
    mapperTable();
  }, [rol]);

  useEffect(() => {
    mapperTable();
    userRolesOptions();
  }, [usersLists]);

  const handleChange = (e) => {
    setRol(e.target.value);
    mapperTable();
  };

  return (
    <Container className="dashboard">
      <Row>
        <Container
          style={{
            paddingLeft: '20px',
            paddingBottom: '20px',
            paddingRight: '20px',
            // width: '70vw',
          }}
        >
          <>
            <Row>
              <div className="d-flex justify-content-between flex-wrap" style={{ width: '100%' }}>
                <h3 className="page-title">Usuarios</h3>
                <SelectOption inputLabel="Tipo de usuario" option={rol} options={roles} handleChange={handleChange} />
              </div>
            </Row>
            <Row className="mb-5">
              <div className="d-flex justify-content-between flex-wrap" style={{ width: '100%' }}>
                <Autocomplete
                  id="zones"
                  noOptionsText={'No se encuentran zonas'}
                  className="input-user my-2"
                  options={zonesOptions()}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => selectZone(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Zonas"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                <Autocomplete
                  id="cities"
                  noOptionsText={'No se encuentran ciudades'}
                  className="input-user my-2"
                  options={citiesOptions()}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => selectCity(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ciudades"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                <Autocomplete
                  id="establisment"
                  noOptionsText={'No se encuentran areas de trabajo'}
                  className="input-user my-2"
                  options={establish}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => selectEstablishment(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Area de trabajo"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            </Row>
            {!isFetchingusersLists && usersLists ? (
              <>
                <DataTableCustom
                  //
                  data={listUsers}
                  columns={cols}
                  filtered
                  scrollHeight="500px"
                />
              </>
            ) : (
              <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
            )}
          </>
        </Container>
      </Row>
    </Container>
  );
};

export default Users;
