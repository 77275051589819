import { get, put } from '../base/index';
import moment from 'moment';

export default {
    getProviderExample: (id) => {
        return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/${id}`)
    },

    getProviderCost: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/providercost/${moment(year).format("YYYY")}-01-01/${moment(year).format("YYYY")}-12-31/${id}/completed`);
    },
    getProviderKm: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/providerkm/${moment(year).format("YYYY")}-01-01/${moment(year).format("YYYY")}-12-31/${id}/completed`);
    },
    getProviderTravels: (id, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/providertravels/${moment(year).format("YYYY")}-01-01/${moment(year).format("YYYY")}-12-31/${id}/completed`);
    },
    getProviderTravelsById: (id, yeari, yearf) => {
        return get(`${process.env.REACT_APP_URL_BASE}/reports/travelsdriverbyprovider/${yeari}/${yearf}/${id}`);
    },
    getRequestByDateByDriver: (id, yeari, yearf) => {
        return get(`${process.env.REACT_APP_URL_BASE}/travels/getallrequestebydate/${yeari}/${yearf}/${id}`);
    },
    getIvoiceByProviderAndMonthAndYear: (id, month, year) => {
        return get(`${process.env.REACT_APP_URL_BASE}/invoices/byidandmonth/${id}/${month}/${year}`);
    },
}