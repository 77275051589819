import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row, Table, Input, Spinner } from 'reactstrap';
import SteeringIcon from 'mdi-react/SteeringIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import { getUserById, putUserById } from '../../../redux/actions/usersAction';
import { sendEmail } from '../../../redux/actions/ProvidersActions';
import { templateEmail } from '../../../shared/templates/email';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const index = () => {
  const { providerId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.users.userById === null
      ? null
      : {
          ...state.users.userById,
          city: state.users.userById.city !== null && state.users.userById.city.nameCity,
        };
  });

  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const isSendingEmail = useSelector((state) => state.provider.isSendingEmail);
  const isSendEmailError = useSelector((state) => state.provider.isSendEmailError);

  const [observations, setObservations] = useState('');

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  const handleUserEnabled = () => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserById(user.id, body));
  };

  const sendNotification = () => {
    dispatch(
      sendEmail({
        from: 'Movilitix',
        to: user?.email,
        subject: 'Nuevas observaciones',
        text: observations,
        html: templateEmail('Nuevas observaciones', observations),
      })
    );
  };

  useEffect(() => {
    dispatch(getUserById(providerId));
  }, []);

  // useEffect(() => {}, [user]);

  useEffect(() => {
    pushNewItemBreadCrums(`Proveedor`);
  }, [isFetchinguser, location]);

  return (
    <Container className="userProfile">
      <Row className="d-flex justify-content-between flex-wrap mb-4">
        <div className="d-flex">
          <SteeringIcon
            style={{
              height: '40px',
              width: '50px',
              color: '#4B545A',
              marginRight: '13px',
              marginTop: '-7px',
            }}
          />
          <h3 className="page-title">Proveedor</h3>
        </div>
        <div className="d-flex mx-4">
          <h5
            style={{
              marginRight: '10px',
              fontWeight: 600,
              color: '#4B545A',
              fontSize: '15px',
            }}
          >
            Habilitado
          </h5>{' '}
          <Switch className="switch-user-data mr-3" checked={user?.status === 'accepted'} onChange={() => handleUserEnabled()} />
        </div>
      </Row>

      {!isFetchinguser && user ? (
        <>
          <Row className="contentUser">
            <Col md={5} xs={12}>
              <div className="leftColumn">
                <img src={user?.userImgUrl} alt="avatar" style={{ minWidth: '100px' }} />
                <div className="separator"></div>
                <div>
                  <div className="profileData">
                    <h6 className="company">Corporación Favorita</h6>
                    <h6 className="code">Código: {providerId}</h6>
                    <h6 className="name">
                      {user?.firstName} {user?.firstLastName}
                    </h6>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={7} xs={12}>
              <div className="dataUser">
                <div className="header">
                  <h3
                    style={{
                      fontSize: '14px',
                      color: '#1A0C12',
                      fontWeight: 600,
                    }}
                  >
                    Datos del <span className="text-danger">Proveedor</span>
                  </h3>
                </div>
                <div className="item">
                  <div className="cellHeader">
                    <div className="cell">
                      <h6>Nombre: </h6>
                      <h6 style={{ paddingBottom: '2px' }}>
                        {user?.firstName} {user?.secondName}
                        {'  '}
                        {user?.firstLastName} {user?.secondLastName}
                      </h6>
                    </div>
                    <div className="cell">
                      <h6>Correo Electrónico: </h6>
                      <h6 style={{ paddingBottom: '2px' }}>{user?.email}</h6>
                    </div>
                    <div className="cell">
                      <h6>Teléfono: </h6>
                      <h6>{user?.phoneNumber}</h6>
                    </div>
                    <div className="cell">
                      <h6>Ruc o Nro de Cédula: </h6>
                      <h6>{user?.dni}</h6>
                    </div>
                    <div className="cell">
                      <h6>Ciudad: </h6>
                      <h6>{user.city}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Container
              style={{
                paddingLeft: '38px',
                paddingBottom: '20px',
                paddingRight: '49px',
              }}
            >
              <Row className="mb-5">
                <Col lg={6} md={12} xs={12}>
                  <div className="table react-table">
                    <h3
                      className="page-title"
                      style={{
                        fontSize: '18px',
                        marginTop: '40px',
                        marginBottom: '0px',
                      }}
                    >
                      Datos del <span className="text-danger">Conductor</span>
                    </h3>
                    <Table className="table-user title-red table-border-bottom">
                      <thead>
                        <tr>
                          <th>Documento</th>
                          <th>Fecha de subida</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="lh-1">
                            <a href={user.urlContract ? user.urlContract : `https://api.movilitix.nousproyect.com/uploads/contract/${user.dni}.pdf`} target="_blank">
                              <EyeIcon color="#00E0FF" className="mr-2" />
                            </a>
                            Contrato ({user.typeContrac === 'natural' ? 'Natural' : 'Jurídico'}){' '}
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            <a href={user?.dniImgUrl} target="_blank">
                              <EyeIcon color="#00E0FF" className="mr-2" />
                            </a>
                            Cédula
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <td className="lh-1">
                            <a href={user?.basicServiceUrl} target="_blank">
                              <EyeIcon color="#00E0FF" className="mr-2" />
                            </a>
                            Planilla de servicio básico
                          </td>
                          <td className="lh-1">{moment(user.createdAt).format('DD-MM-YYYY')}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col lg={6} md={12} xs={12}>
                  <h3
                    style={{
                      fontSize: '18px',
                      marginTop: '80px',
                      marginBottom: '0px',
                      fontWeight: 600,
                    }}
                    className="text-danger"
                  >
                    Observaciones
                  </h3>
                  <Row>
                    <Col lg={8}>
                      <Input
                        type="textarea"
                        style={{
                          height: '180px',
                          fontSize: '14px',
                          marginTop: '15px',
                          backgroundColor: '#D6D8DB',
                          color: '#5B5B5B',
                        }}
                        placeholder="Ingrese sus observaciones"
                        id="Observations"
                        value={observations}
                        onChange={(e) => setObservations(e.target.value)}
                        disabled={isSendingEmail}
                      />
                    </Col>
                    <Col lg={3}>
                      <button
                        style={{
                          border: 'none',
                          backgroundColor: '#EC1C24',
                          borderRadius: '9px',
                          marginRight: '20px',
                          fontWeight: 600,
                          fontSize: '15px',
                          color: 'white',
                          padding: '10px 20px',
                          marginTop: '70px',
                        }}
                        disabled={observations === '' || isSendingEmail}
                        onClick={sendNotification}
                      >
                        {isSendingEmail ? <Spinner color="white" className="table-fetch-spinner" style={{ margin: '2px 30px' }} /> : 'ENVIAR Notificación'}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Row>
        </>
      ) : (
        <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
      )}
    </Container>
  );
};

export default index;
