import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../../redux/actions/authActions';

const Person = `${process.env.PUBLIC_URL}/img/person.jpg`;

const TopbarProfile = () => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const user = useSelector((state) => state.auth.dataUser);


  const handleToggleCollapse = () => { setIsCollapsed(!isCollapsed) };
  const handleLogOut = () => {
    dispatch(logOut())
  }

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={setIsCollapsed}>
        <div className="topbar__avatar-perfil">
          <p className="topbar__avatar-name" style={{ fontWeight: '200', fontSize: '14px' }}>Colaborador Corporación Favorita</p>
          <p className="topbar__avatar-name" style={{ fontWeight: '400', fontSize: '18px', marginTop: '5px' }}>{user?.firstName?.slice(0, 1)}{user?.firstName?.slice(1).toLowerCase()} {user?.firstLastName?.slice(0, 1)}{user?.firstLastName?.slice(1).toLowerCase()} {user?.secondLastName?.slice(0, 1)}{user?.secondLastName?.slice(1).toLowerCase()} </p>
        </div>
        <img className="topbar__avatar-img" src={user?.userImgUrl} alt="avatar" />
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <button className="topbar__link" onClick={handleLogOut} style={{ backgroundColor: 'transparent', border: 'none', alignItems: 'center', paddingLeft: '15px' }}>
            <span className="topbar__link-icon lnr lnr-exit">
            </span><p className="topbar__link-title">Salir</p>
          </button>
        </div>
      </Collapse>
    </div>
  );
};


export default TopbarProfile;
