import React from 'react';
import { Col, Row, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from "react-select";
const ExampleCard = () => (
  <Col md={12}>
    <Row className="react-table_btn_action">
      <div>
        <Button color="danger" className="m-0 py-2">Guardar y Enviar</Button>
      </div>
    </Row>
    <Form>
      <label htmlFor="zona-calor" className="mt-4 label-head-info">Datos <span className="text-danger">Personales</span></label>
      <div className="row space-line-div mb-3">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="fecha" sm={4}>
              Fecha:
            </Label>
            <Col sm={8}>
              <Input id="fecha" name="fecha" placeholder="Seleccione una fecha" type="date"/>
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="vigencia_contrato" sm={4}>
              Vigencia contrato:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione Vigencia ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
      </div>
      <div className="row space-line-div mb-3">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="nombres" sm={4}>
              Nombres:
            </Label>
            <Col sm={8}>
              <Input id="nombres" name="nombres" placeholder="Ingrese sus dos nombres" type="text"/>
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="apellidos" sm={4}>
              Apellidos:
            </Label>
            <Col sm={8}>
              <Input id="apellidos" name="apellidos" placeholder="Ingrese sus dos apellidos" type="text"/>
            </Col>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="tipo_identificacion" sm={4}>
              Tipo de Identificación:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione Identificación ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="no_identificacion" sm={4}>
              No. de dentificación:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione Color ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
      </div>
      <label htmlFor="zona-calor" className="mt-4 label-head-info">Datos del <span className="text-danger">Vehículo</span></label>
      <div className="row space-line-div mb-3">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="marca" sm={4}>
              Marca:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"----Seleccione marca –––"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="year" sm={4}>
              Año:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione año ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
      </div>
      <div className="row space-line-div mb-3">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="modelo" sm={4}>
              Modelo:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"----Seleccione modelo –––"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="chasis" sm={4}>
              Chasis:
            </Label>
            <Col sm={8}>
              <Input id="chasis" name="chasis" placeholder="Ingrese chasis" type="text"/>
            </Col>
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="motor" sm={4}>
              Motor:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione motor ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
        <div className="col-lg-6 col-md-12">
          <FormGroup row>
            <Label for="color" sm={4}>
              Color:
            </Label>
            <Col sm={8}>
              <Select
                className="basic-single-user w-100"
                isClearable={true}
                isSearchable={true}
                placeholder={"--- Seleccione Color ---"}
                color
                options={[
                  { value: 1, label: 1 },
                ]}
              />
            </Col>
          </FormGroup>
        </div>
      </div>
    </Form>
  </Col>
);

export default ExampleCard;
