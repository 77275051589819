import React, { useState, useEffect } from 'react';
import { Col, Container, Input, Spinner } from 'reactstrap';
import DownloadIcon from 'mdi-react/DownloadIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { getRequestByDateByDriver } from '../../../redux/actions/ProvidersActions';
import { getUserById } from '../../../redux/actions/usersAction';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
import { translateStatus } from '../../../utils/util';

export default function index({ match }) {
  const {
    params: { driverId },
  } = match;
  const dispatch = useDispatch();
  const [initialDate, setInitialDate] = useState(`${moment().subtract(1, 'month').format('YYYY-MM-DD')}`);
  const [endDate, setEndDate] = useState(`${moment().format('YYYY-MM-DD')}`);
  const [searchButton, setSearchButton] = useState(false);
  const user = useSelector((state) => state.users.userById);
  const isFetchingTravels = useSelector((state) => state.provider.isFetching);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const travelsbyidClient = useSelector((state) => state.provider.providertravels);
  const rowsPagePdf = 20;

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte general');
  }, [location]);

  useEffect(() => {
    dispatch(getRequestByDateByDriver(driverId, initialDate, endDate));
    dispatch(getUserById(driverId));
  }, []);

  const getTotal = () => {
    let sum = 0;
    travelsbyidClient
      .filter((item) => item.status === 'completed')
      .map((travel) => {
        sum = sum + Number(travel.price);
      });
    return sum.toFixed(2);
  };

  const travelsByDates = () => {
    if (endDate !== '' && initialDate !== '' && !isFetchingTravels) dispatch(getRequestByDateByDriver(driverId, initialDate, endDate));
  };

  const exportToExcel = () => {
    if (!isFetchingTravels && travelsbyidClient) {
      const filterData = [];
      travelsbyidClient.map(({ collaborator, date, hour, typeTravel, addresOrigin, addressDestiny, price, status }) => {
        filterData.push({
          colaborator: `${collaborator.firstName} ${collaborator.secondName} ${collaborator.firstLastName} ${collaborator.secondLastName}`,
          date: `${date} ${hour}`,
          typeTravel: translateTypeRequest(typeTravel),
          addresOrigin,
          addressDestiny,
          state: translateStatus(status),
          price: price ? parseFloat(price) : price,
        });
      });

      const Heading = [['Colaborador', 'Fecha', 'Tipo de viaje', 'Origen', 'Destino', 'Estado', 'Valor']];
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const ws = XLSX.utils.json_to_sheet(filterData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.sheet_add_aoa(ws, Heading);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'Reporte de conductor.xlsx');
    }
  };

  const translateTypeRequest = (typeRequest) => {
    switch (typeRequest) {
      case 'home':
        return 'Domicilio';
      case 'steps':
        return 'Gestiones varias';
      case 'outoff':
        return 'Fuera de la ciudad';
      default:
        return typeRequest;
    }
  };

  const generatePDF = () => {
    if (!isFetchingTravels && travelsbyidClient) {
      const pdf = new jsPDF('l', 'mm', [297, 210]);
      for (let i = 0; i < Math.ceil(travelsbyidClient.length / rowsPagePdf); i++) {
        html2canvas(document.querySelector(`#tableToExport${i}`), {
          onclone: function (clonedDoc) {
            clonedDoc.getElementById(`tableToExport${i}`).style.display = 'block';
          },
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 3, 3);

          if (i + 1 === Math.ceil(travelsbyidClient.length / rowsPagePdf)) pdf.save('Reporte de conductor.pdf');
          else pdf.addPage();
        });
      }
    }
  };

  return (
    <Container className="dashboard">
      <div className="generalReportTable">
        <div className="d-flex justify-content-lg-between flex-wrap">
          <div className="d-flex justify-content-lg-between align-items-center flex-wrap">
            <h3 className="page-title" style={{ marginLeft: '-25px', marginRight: 5 }}>
              Reportes: <span className="text-danger">general</span>
            </h3>
            <div className="d-flex flex-wrap align-items-center mr-2">
              <div className="mr-2 my-2">
                <Input
                  className="select__control"
                  id="selectorDate"
                  name="Seleccionar Fecha"
                  placeholder="Seleccione Fecha"
                  type="date"
                  style={{ width: '170px' }}
                  onChange={(e) => {
                    setInitialDate(e.target.value);
                  }}
                  value={initialDate}
                  max={moment().format('YYYY-MM-DD')}
                />
              </div>
              <div className="mr-2 my-2">
                <Input
                  className="select__control"
                  id="selectorDate"
                  name="Seleccionar Fecha"
                  placeholder="Seleccione Fecha"
                  type="date"
                  style={{ width: '170px' }}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  value={endDate}
                  max={moment().format('YYYY-MM-DD')}
                />
              </div>
              <button
                style={{
                  border: 'none',
                  backgroundColor: '#EC1C24',
                  height: '38px',
                  borderRadius: '9px',
                }}
                onClick={travelsByDates}
              >
                <MagnifyIcon color="white" />
              </button>
            </div>
          </div>
          <div className="d-flex my-2">
            <button
              className="dashboardButton"
              onClick={exportToExcel}
              style={{
                backgroundColor: '#3DBE00',
                marginRight: '5px',
                fontSize: '12px',
                height: '42px',
              }}
            >
              XLSX <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
            <button
              className="dashboardButton"
              onClick={generatePDF}
              style={{
                backgroundColor: '#EC1C24',
                fontSize: '12px',
                height: '42px',
              }}
            >
              PDF <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
          </div>
        </div>
        <div className="contentReport">
          <div className="headerReport">
            <div className="cell">
              <h6>Nombre de Conductor: </h6>
              <h6>{!isFetchinguser && user ? `${user.firstName} ${user.secondName ? user.secondName : ''} ${user.firstLastName} ${user.secondLastName ? user.secondLastName : ''}` : ''}</h6>
            </div>
            <div className="cell">
              <h6>Rango de fecha: </h6>
              <h6>
                Del {moment(initialDate).format('DD MMMM YYYY')} al {moment(endDate).format('DD MMMM YYYY')}
              </h6>
            </div>
          </div>
          <div className="bodyReport">
            <div className="Header">
              <h6 style={{ width: '15%' }}>Colaborador</h6>
              <h6 style={{ width: '15%' }}>Tipo de traslado</h6>
              <h6 style={{ width: '35%' }}>Origen</h6>
              <h6 style={{ width: '35%' }}>Destino</h6>
              <h6 style={{ width: '20%' }}>Estado</h6>
              <h6 style={{ width: '15%' }}>Valor</h6>
            </div>
            {!isFetchingTravels && travelsbyidClient ? (
              travelsbyidClient.map((route, index) => (
                <div
                  key={`row${index}`}
                  className="cell"
                  style={{
                    backgroundColor: index % 2 === 0 ? 'transparent' : '#E5E5E5',
                  }}
                >
                  <h6 style={{ width: '15%', fontSize: 13 }}>{`${route.collaborator.firstName} ${route.collaborator.secondName} ${route.collaborator.firstLastName} ${route.collaborator.secondLastName}`}</h6>
                  <h6 style={{ width: '15%' }}>{translateTypeRequest(route.typeTravel)}</h6>
                  <h6 style={{ width: '35%' }}>{route.addresOrigin}</h6>
                  <h6 style={{ width: '35%' }}>{route.addressDestiny}</h6>
                  <h6 style={{ width: '20%' }}>{translateStatus(route.status)}</h6>
                  <h6 style={{ width: '15%' }}>$ {route.price}</h6>
                </div>
              ))
            ) : (
              <Spinner className="table-fetch-spinner" style={{ marginLeft: '45%', marginTop: '30px' }} />
            )}
            {!isFetchingTravels && travelsbyidClient && (
              <div className="total">
                <div className="itemValue">
                  <h6 style={{ width: '85%' }}>TOTAL: </h6>
                  <h6 style={{ width: '15%' }}>{getTotal()}</h6>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isFetchingTravels &&
          travelsbyidClient &&
          travelsbyidClient.map((item, index) => {
            if (index < Math.ceil(travelsbyidClient.length / rowsPagePdf))
              return (
                <table style={{ borderLeft: '1px solid #f2f2f2', borderRight: 'none', borderBottom: 'none', display: 'none', width: '1100px', maxWidth: '1100px', minWidth: '1100px' }} id={`tableToExport${index}`}>
                  <tbody>
                    <tr style={{ backgroundColor: '#303e47', borderBottom: 'none' }}>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>Tipo de viaje</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>Origen</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>Destino</th>
                      <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>Valor</th>
                    </tr>
                    {travelsbyidClient.slice(index * rowsPagePdf, (index + 1) * rowsPagePdf).map((item, index) => (
                      <tr key={`row-${index}`} style={{ borderRight: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}>
                        <td style={{ padding: '5px 15px 5px 5px', width: '110px' }}>{translateTypeRequest(item.typeTravel)}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>{item.addresOrigin}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '440px' }}>{item.addressDestiny}</td>
                        <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '110px' }}>{item.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
          })}
      </div>
    </Container>
  );
}
