import { get, put, post, deleteRequest } from "../base/index";

export default {
  getModelsByBrand: (idBrand) => {
    return get(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/model/all/${idBrand}`
    );
  },

  putModelById: (idModel, body) => {
    return put(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/model/${idModel}`,
      body
    );
  },

  postModel: (body) => {
    return post(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/model`,
      body
    );
  },

  deleteModelById: (idModel) => {
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/model/${idModel}`
    );
  },
};
