import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import TableBase from './components/TableBase';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import { useEffect } from 'react';
import { listProvincesandRates, putProvinceById } from '../../../redux/actions/ratesActions';
import { TitleSettings } from '../../../shared/components/TitleSettings';
import { Link, useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const Drivers = () => {
  const dispatch = useDispatch();
  const isFetchingProvincesAndRatesLists = useSelector((state) => state.rates.isFetching);
  const rateList = useSelector((state) => state.rates.ratesData);
  const provinceList = useSelector((state) => state.rates.data);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Tarifas', 1);
  }, [location]);

  const columns = useMemo(
    () => [
      {
        Header: 'Provincia',
        accessor: 'provincie',
        Cell: (cellObj) => <span>{cellObj.cell.row.original.nameProvince}</span>,
        disableFilters: true,
      },
      {
        Header: 'Tarifas',
        accessor: 'rate',
        Cell: (cellObj) => (
          <div style={{ width: 200 }}>
            <Select
              className="select__control"
              classNamePrefix="selectTable"
              name="Tarifa"
              placeholder={'Escoger Tarifa'}
              color
              onChange={(e) => {
                dispatch(
                  putProvinceById(cellObj.cell.row.original.id, {
                    idRegion: cellObj.cell.row.original.idRegion,
                    idRate: e.value,
                  })
                );
              }}
              styles={{
                control: (styles, { isDisabled }) => ({
                  ...styles,
                  height: 30,
                  minHeight: 30,
                  backgroundColor: isDisabled ? '#c7c7c7 !important' : '#D6D8DB',
                  color: isDisabled ? '#e9e9ea !important' : '#D6D8DB',
                  cursor: isDisabled ? 'not-allowed !important' : 'auto',
                }),
              }}
              menuPosition={'fixed'}
              value={listRatesSelect().filter((rate) => rate.value === cellObj.cell.row.original.idRate)}
              options={listRatesSelect()}
            />
          </div>
        ),
        disableFilters: true,
      },
      {
        Header: 'Habilitado',
        accessor: '[enabledButton]',
        Cell: (cellObj) => <Switch checked={cellObj.cell.row.original.status === 1} onChange={() => handleDataEnabled(cellObj.cell.row.original)} />,
        disableFilters: true,
      },
    ],
    [provinceList]
  );

  const listRatesSelect = () => {
    return rateList.map((rate) => {
      return {
        value: rate.id,
        label: rate.name,
      };
    });
  };

  const handleDataEnabled = (objectRow) => {
    dispatch(
      putProvinceById(objectRow.id, {
        status: !(objectRow.status === 1),
        idRegion: objectRow.idRegion,
        idRate: objectRow.idRate,
      })
    );
  };

  useEffect(() => {
    dispatch(listProvincesandRates());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleSettings title={`Tarifas`} />
          <Link to="/settings/rates/addRates">
            <Button style={{ marginRight: 20, width: 150 }} color="danger" type="submit">
              Ver Tarifas
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Container
          style={{
            paddingLeft: '38px',
            paddingBottom: '20px',
            paddingRight: '49px',
          }}
        >
          {!isFetchingProvincesAndRatesLists && provinceList && rateList ? (
            <TableBase
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: provinceList,
              }}
            />
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default Drivers;
