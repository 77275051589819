export const translateStatus = (status) => {
    switch (status) {
        case "pending":
            return "Pendiente";
        case "accepted":
            return "Aceptado";
        case "reject":
            return "Rechazado";
        case "completed":
            return "Completado";
        case "onway":
            return "En camino";
        case "traveling":
            return "Viajando";
        case "cancel":
            return "Cancelado";
        case "reserved":
            return "Reservado";
        case "now":
            return "Ahora";
        case "schedule":
            return "Agendado";
        default:
            return status;
    }
};