import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addBreadCrums, pushBreadcrums } from '../redux/actions/breadcrumsActions';

export const useBreadCrums = () => {
  const location = useLocation();
  const history = useHistory();
  const breadcrums = useSelector((state) => state.breadcrums.paths);
  const dispatch = useDispatch();

  const pushNewItemBreadCrums = (name, init) => {
    let tempArr = [...breadcrums];
    if (history.action === 'POP') {
      const bread = JSON.parse(localStorage.getItem('breadCrums'));
    }
    const itemSelected = breadcrums.find((item) => item.path === location.pathname);

    // checkLenghtBreadcrums(history);

    if (!itemSelected) {
      if (init) {
        dispatch(
          addBreadCrums([
            {
              path: location.pathname,
              name: name,
            },
          ])
        );
      } else {
        dispatch(
          pushBreadcrums({
            path: location.pathname,
            name: name,
          })
        );
      }
    } else {
      if (tempArr.length > 0) {
        const newBread = tempArr.splice(0, tempArr.map((item) => item.path).indexOf(itemSelected.path) + 1);
        newBread.length > 0 && dispatch(addBreadCrums(newBread));
      }
    }

    localStorage.setItem('breadCrums', JSON.stringify(breadcrums));
  };

  return { pushNewItemBreadCrums };
};
