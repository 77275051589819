import { get, put, post, deleteRequest } from "../base/index";

export default {
  getBrands: () => {
    return get(`${process.env.REACT_APP_URL_BASE}/vehicleProviders/mark/all`);
  },
  putBrandById: (id, body) => {
    
    return put(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/mark/${id}`,
      body
    );
  },
  postBrand: (body) => {
    return post(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/mark`,
      body
    );
  },
  deleteBrandById: (id) => {
    return deleteRequest(
      `${process.env.REACT_APP_URL_BASE}/vehicleProviders/mark/${id}`
    );
  },
};
