import React from "react";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import { Button, Col, Row, FormGroup } from "reactstrap";

export const Form = ({
  title,
  titleRed,
  titleButton,
  placeholderInput,
  value,
  valueId,
  handleChange,
  touched,
  errors,
  errorSelect,
  touchedSelect,
  handleSubmit,
  optionsSelect,
  changeSelect,
}) => {
  return (
    <Row className="mb-4">
      <Col md={12} style={{ display: "flex", flexDirection: "row" }}>
        <h3 className="page-title" style={{ fontSize: "22px" }}>
          {" "}
          {title}: <span className="text-danger">{titleRed} </span>
        </h3>
      </Col>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Col md={12} className="mt-1 mb-2">
          <FormGroup row style={{ alignItems: "center" }}>
            <Col>
              <Row>
                <Col className="mb-3">
                  <Select
                    className="basic-single"
                    classNamePrefix="selectDataUsers"
                    name="marcas"
                    placeholder={"Marca"}
                    options={optionsSelect}
                    value={
                      optionsSelect
                        ? optionsSelect.find(
                          (option) => option.value === valueId
                        )
                        : ""
                    }
                    onChange={(option) => changeSelect(option.value)}
                  />
                  {errorSelect && touchedSelect && (
                    <div
                      style={{ color: "red", marginTop: 10, marginLeft: 5 }}
                      className="input-feedback "
                    >
                      La marca es requerida
                    </div>
                  )}
                </Col>
                <Col
                  md={11}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h4 style={{ marginRight: 20 }}>Modelo:</h4>

                  <TextField
                    fullWidth
                    id="model"
                    name="model"
                    variant="outlined"
                    type="text"
                    value={value}
                    onChange={handleChange}
                    error={touched && Boolean(errors)}
                    helperText={touched && errors}
                    placeholder={placeholderInput}
                    className="inputFormOutCard"
                    inputProps={{ style: { fontSize: "14px" } }}
                  />
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Col>
        <Col md={12} style={{ display: "flex" }}>
          <Button color="danger" type="submit" className="mx-auto py-2">
            {titleButton}
          </Button>
        </Col>
      </form>
    </Row>
  );
};
