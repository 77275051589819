import { handleActions } from "redux-actions";
import {
  fetchProblemsRequest,
  fetchProblemsSuccess,
  fetchProblemsError,
  fetchProblemsRolesSucess,
} from "../actions/helpAppActions";
const defaultState = [];

export default handleActions(
  {
    [fetchProblemsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchProblemsSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProblemsRolesSucess](state, { payload }) {
      return {
        ...state,
        roles: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchProblemsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
