import React, { useState, useEffect } from 'react';
import { Col, Container, Input, Spinner, Row } from 'reactstrap';
import DownloadIcon from 'mdi-react/DownloadIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work
import { fetchProvidersRequestInvoice, getInvoiceByProviderMonthAndYear, listsTravelsById } from '../../../redux/actions/ProvidersActions';
import { getUserById } from '../../../redux/actions/usersAction';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';
import { useBreadCrums } from '../../../hooks/useBreadCrums';
import { translateStatus } from '../../../utils/util';

export default function index({ match }) {
  const {
    params: { providerId },
  } = match;
  const dispatch = useDispatch();
  const [initialDate, setInitialDate] = useState(`${moment().subtract(1, 'month').format('YYYY-MM-DD')}`);
  const [endDate, setEndDate] = useState(`${moment().format('YYYY-MM-DD')}`);
  const [dateInvoice, setDateInvoice] = useState(`${moment().format('YYYY-MM-DD')}`);
  const [searchButton, setSearchButton] = useState(false);
  const user = useSelector((state) => state.users.userById);
  const isFetchingTravels = useSelector((state) => state.provider.isFetching);
  const isFetchinguser = useSelector((state) => state.users.isFetching);
  const travelsbyid = useSelector((state) => state.provider.providertravelsid);
  const imgInvoice = useSelector((state) => state.provider.imgInvoiceUrl);
  const rowsPagePdf = 20;

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Reporte general');
  }, [location]);

  useEffect(() => {
    dispatch(listsTravelsById(providerId, initialDate, endDate));
    return () => {
      dispatch(fetchProvidersRequestInvoice(null));
    };
  }, []);

  useEffect(() => {
    dispatch(getUserById(providerId));
  }, []);

  const getTotalDriver = (index) => {
    let sum = 0;
    if (!isFetchingTravels && travelsbyid) {
      travelsbyid[index].driverTravels
        .filter((item) => item.status === 'completed')
        .map((travel) => {
          sum = sum + Number(travel.price);
        });
    }
    return parseFloat(sum.toFixed(2));
  };

  const getTotalReport = () => {
    let sum = 0;
    if (!isFetchingTravels && travelsbyid) {
      travelsbyid.map((driver, index) => {
        sum = sum + Number(getTotalDriver(index));
      });
    }
    return parseFloat(sum.toFixed(2));
  };

  const travelsByDates = () => {
    if (endDate !== '' && initialDate !== '' && !isFetchingTravels) dispatch(listsTravelsById(providerId, initialDate, endDate));
  };

  const getInvoice = () => {
    const month = moment(dateInvoice).locale('en').format('MMMM');
    const year = moment(dateInvoice).format('yy');
    dispatch(getInvoiceByProviderMonthAndYear(providerId, month, year));
  };

  const exportToExcel = () => {
    let filterData = [];
    if (!isFetchingTravels && travelsbyid) {
      travelsbyid.map((driver, idx) => {
        driver.driverTravels.map((route) => {
          filterData.push({
            colaborator: `${route.firstName} ${route.secondName} ${route.firstLastName} ${route.secondLastName}`,
            Fecha: route.date + ' ' + route.hour,
            'Ruc Proveedor': user ? user.userCF : '-',
            'Nombre o razon social': user ? `${user.firstName} ${user.secondName ? user.secondName : ''} ${user.firstLastName} ${user.secondLastName ? user.secondLastName : ''}` : '',
            Conductor: driver.name,
            km: route.km,
            tipo: translateStatus(route.typeRequest),
            Origen: route.addresOrigin,
            Destino: route.addressDestiny,
            estado: translateStatus(route.status),
            Valor: route.price ? parseFloat(parseFloat(route.price).toFixed(2)) : route.price,
          });
        });

        filterData.push({
          Fecha: '',
          'Ruc Proveedor': '',
          'Nombre o razon social': '',
          Conductor: '',
          km: '',
          tipo: '',
          Origen: '',
          Destino: '',
          estado: 'Subtotal por conductor',
          Valor: getTotalDriver(idx),
        });
        filterData.push({
          Fecha: '',
          'Ruc Proveedor': '',
          'Nombre o razon social': '',
          Conductor: '',
          km: '',
          tipo: '',
          Origen: '',
          Destino: '',
          estado: '',
          Valor: '',
        });
      });

      filterData.push({
        Fecha: '',
        'Ruc Proveedor': '',
        'Nombre o razon social': '',
        Conductor: '',
        km: '',
        tipo: '',
        Origen: '',
        Destino: '',
        estado: 'Total proveedor',
        Valor: getTotalReport(),
      });

      const Heading = [['Colaborator', 'Fecha', 'Ruc Proveedor', 'Nombre o razon social', 'Conductor', 'KM', 'Tipo', 'Origen', 'Destino', 'Estado', 'Valor']];
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const ws = XLSX.utils.json_to_sheet(filterData, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(ws, Heading);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, 'Reporte de proveedor.xlsx');
    }
  };

  const generateDataToExport = () => {
    let data = [];
    travelsbyid.map(({ name, driverTravels }) => driverTravels.map((driver) => data.push({ ...driver, nameDriver: name })));
    return data;
  };

  const generatePDF = () => {
    if (!isFetchingTravels && travelsbyid) {
      const pdf = new jsPDF('l', 'mm', [297, 210]);
      for (let i = 0; i < Math.ceil(generateDataToExport().length / rowsPagePdf); i++) {
        html2canvas(document.querySelector(`#tableToExport${i}`), {
          onclone: function (clonedDoc) {
            clonedDoc.getElementById(`tableToExport${i}`).style.display = 'block';
          },
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          pdf.addImage(imgData, 'PNG', 3, 3);

          if (i + 1 === Math.ceil(generateDataToExport().length / rowsPagePdf)) pdf.save('Reporte de proveedor.pdf');
          else pdf.addPage();
        });
      }
    }
  };

  return (
    <Container className="dashboard">
      <Row>
        <Col md={8} className="generalReportTable" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
            <h3 className="page-title" style={{ marginLeft: '10px' }}>
              Reporte: <span className="text-danger">general</span>
            </h3>
            <div className="row" style={{ justifyContent: 'flex-end' }}>
              <div style={{ marginRight: '10px' }}>
                <Input
                  className="select__control"
                  id="selectorDate"
                  name="Seleccionar Fecha"
                  placeholder="Seleccione Fecha"
                  type="date"
                  style={{ width: '170px' }}
                  onChange={(e) => {
                    setInitialDate(e.target.value);
                  }}
                  value={initialDate}
                  max={moment().format('YYYY-MM-DD')}
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <Input
                  className="select__control"
                  id="selectorDate"
                  name="Seleccionar Fecha"
                  placeholder="Seleccione Fecha"
                  type="date"
                  style={{ width: '170px' }}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  value={endDate}
                  max={moment().format('YYYY-MM-DD')}
                />
              </div>
              <button
                style={{
                  border: 'none',
                  backgroundColor: '#EC1C24',
                  height: '38px',
                  borderRadius: '9px',
                  marginRight: '20px',
                }}
                onClick={travelsByDates}
              >
                <MagnifyIcon color="white" />
              </button>
            </div>
          </div>

          <div className="contentReport">
            <div className="headerReport">
              <div className="cell">
                <h6>Nombre de Gestor: </h6>
                <h6>{!isFetchinguser && user ? `${user.firstName} ${user.secondName ? user.secondName : ''} ${user.firstLastName} ${user.secondLastName ? user.secondLastName : ''}` : ''}</h6>
              </div>
              <div className="cell">
                <h6>Empresa:: </h6>
                <h6>Corporación Favorita</h6>
              </div>
              <div className="cell">
                <h6>R.U.C.: </h6>
                <h6>{!isFetchinguser && user ? user.userCF : ''}</h6>
              </div>
              <div className="cell">
                <h6>Número de Proveedor: </h6>
                <h6>{providerId}</h6>
              </div>
              <div className="cell">
                <h6>Rango de fecha: </h6>
                <h6>
                  Del {moment(initialDate).format('DD MMMM YYYY')} al {moment(endDate).format('DD MMMM YYYY')}
                </h6>
              </div>
            </div>
            <div className="bodyReport">
              <div className="Header">
                <h6 style={{ width: '20%' }}>Conductor</h6>
                <h6 style={{ width: '10%' }}>KM</h6>
                <h6 style={{ width: '30%' }}>Origen</h6>
                <h6 style={{ width: '30%' }}>Destino</h6>
                <h6 style={{ width: '20%' }}>Estado</h6>
                <h6 style={{ width: '10%' }}>Valor</h6>
              </div>
              {!isFetchingTravels && travelsbyid ? (
                travelsbyid.map((driver, idx) => (
                  <div key={`row${idx}`}>
                    <div className="driverCell">
                      <h6>{driver.name}</h6>
                    </div>
                    {driver.driverTravels.map((route, index) => (
                      <div key={`row${index}`} className="cell" style={{ borderBottom: '1px solid #969696' }}>
                        <h6 style={{ width: '20%' }}></h6>
                        <h6 style={{ width: '10%' }}>{route.km}</h6>
                        <h6 style={{ width: '30%' }}>{route.addresOrigin}</h6>
                        <h6 style={{ width: '30%' }}>{route.addressDestiny}</h6>
                        <h6 style={{ width: '20%' }}>{translateStatus(route.status)}</h6>
                        <h6 style={{ width: '10%' }}>$ {route.price}</h6>
                      </div>
                    ))}
                    <div className="cellTotal">
                      <h6>TOTAL: </h6>
                      <h6>${getTotalDriver(idx)}</h6>
                    </div>
                  </div>
                ))
              ) : (
                <Spinner className="table-fetch-spinner" style={{ marginLeft: '45%', marginTop: '30px' }} />
              )}
              {!isFetchingTravels && travelsbyid && (
                <div className="total">
                  <div className="itemValue">
                    <h6>Subtotal: </h6>
                    <h6>${getTotalReport()}</h6>
                  </div>
                  <div className="itemValue">
                    <h6>TOTAL: </h6>
                    <h6>${getTotalReport()}</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!isFetchingTravels && travelsbyid && (
            <table style={{ borderLeft: '1px solid #f2f2f2', borderRight: 'none', borderBottom: 'none', display: 'none' }} id="tableToExport">
              <tbody>
                <tr style={{ backgroundColor: '#303e47', borderBottom: 'none' }}>
                  <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Conductor</th>
                  <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>km</th>
                  <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Origen</th>
                  <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Destino</th>
                  <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center' }}>Valor</th>
                </tr>
                {travelsbyid.map((driver) =>
                  driver.driverTravels.map((route, index) => (
                    <tr key={`row-${index}`} style={{ borderRight: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}>
                      <td style={{ padding: '5px 15px 5px 5px' }}>{driver.name}</td>
                      <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{route.km}</td>
                      <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{route.addresOrigin}</td>
                      <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{route.addressDestiny}</td>
                      <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center' }}>{route.price}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
          {!isFetchingTravels &&
            travelsbyid &&
            generateDataToExport().map((item, index) => {
              if (index < Math.ceil(generateDataToExport().length / rowsPagePdf))
                return (
                  <table style={{ borderLeft: '1px solid #f2f2f2', borderRight: 'none', borderBottom: 'none', display: 'none', width: '1100px', maxWidth: '1100px', minWidth: '1100px' }} id={`tableToExport${index}`}>
                    <tbody>
                      <tr style={{ backgroundColor: '#303e47', borderBottom: 'none' }}>
                        <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '200px' }}>Conductor</th>
                        <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '100px' }}>km</th>
                        <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '350px' }}>Origen</th>
                        <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '350px' }}>Destino</th>
                        <th style={{ fontWeight: 'bold', color: 'white', padding: '5px 15px 5px 5px', textAlign: 'center', width: '100px' }}>Valor</th>
                      </tr>
                      {generateDataToExport()
                        .slice(index * rowsPagePdf, (index + 1) * rowsPagePdf)
                        .map((route, index) => (
                          <tr key={`row-${index}`} style={{ borderRight: '1px solid #f2f2f2', borderBottom: '1px solid #f2f2f2' }}>
                            <td style={{ padding: '5px 15px 5px 5px', width: '200px' }}>{route.nameDriver}</td>
                            <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '100px' }}>{route.km}</td>
                            <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '350px' }}>{route.addresOrigin}</td>
                            <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '350px' }}>{route.addressDestiny}</td>
                            <td style={{ padding: '5px 15px 5px 5px', textAlign: 'center', width: '100px' }}>{route.price}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                );
            })}
        </Col>
        <Col md={4}>
          <div style={{ width: '100%' }}>
            <button className="dashboardButton" onClick={exportToExcel} style={{ backgroundColor: '#3DBE00', marginRight: '5px', fontSize: '12px', height: '42px' }}>
              XLSX <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
            <button className="dashboardButton" onClick={generatePDF} style={{ backgroundColor: '#EC1C24', fontSize: '12px', height: '42px' }}>
              PDF <DownloadIcon style={{ width: '15px' }}></DownloadIcon>
            </button>
            <div style={{ marginRight: '10px', margin: '20px 0px' }}>
              <h3 style={{ margin: '20px 0px' }}>
                Reporte <span className="text-danger">Comprobantes</span>
              </h3>
              <Input
                className="select__control"
                id="selectorDate"
                name="Seleccionar Fecha"
                placeholder="Seleccione Fecha"
                type="date"
                style={{ width: '170px' }}
                onChange={(e) => {
                  setDateInvoice(e.target.value);
                }}
                value={dateInvoice}
                max={moment().format('YYYY-MM-DD')}
              />
              <button
                style={{
                  border: 'none',
                  backgroundColor: '#EC1C24',
                  height: '38px',
                  width: '170px',
                  borderRadius: '9px',
                  marginRight: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  margin: '20px 0px',
                }}
                onClick={getInvoice}
              >
                Revisar
              </button>
            </div>
            <div
              style={{
                width: '359px',
                height: '465px',
                background: '#C4C4C4',
                borderRadius: 22,
              }}
            >
              {imgInvoice && <img alt="Invoice" src={imgInvoice} width="359px" height="465px" />}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
