import React, { useEffect, useMemo } from 'react';

import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import Select from "react-select";
import { Link } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import CarIcon from 'mdi-react/CarIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon'

import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale("es");
var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months: meses, weekdays: semanas });

import TableBase from './components/TableBase';

export default function index() {

  const columns = useMemo(() => [
    {
      Header: 'Colaborador',
      accessor: 'colaborador',
      Cell: (cellObj) => (
        <h6 style={{ color: "#646777", marginTop: "2.5px", fontSize: "13px" }}>
          <AccountCircleIcon color="#000000" style={{ marginRight: "10px" }} />
          {cellObj.value}
        </h6>
      ),
      Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="Colaborador"
          isClearable={true}
          isSearchable={true}
          placeholder={"Colaborador"}
          styles={{
            option: (styles, state) => ({
              ...styles,
              backgroundColor: state.isSelected ? "rgb(0,117,255)" : ""
            })
          }}
          onChange={e => {
            if (e === null) setFilter(undefined)
            else setFilter(e.value)
          }}
          options={[
            { value: "Laura Yepez", label: "Laura Yepez" },
            { value: "Sara Ruiz", label: "Sara Ruiz" },
            { value: "Oscar Erazo", label: "Oscar Erazo" }
          ]}
        />
      )
    },
    {
      Header: 'Área / Local',
      accessor: 'area',
      Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
        <Input
          id="selectorDate"
          name="date"
          placeholder="Seleccione Fecha"
          type="date"
          onChange={e => {
            if (e.target.value === '') setFilter(undefined)
            else setFilter(moment(e.target.value, "YYYY-MM-DD").format('DD MMMM, YYYY'))
          }}
        />
      )
    },
    {
      Header: "Acciones",
      accessor: "[editButton]",
      Cell: (cellObj) => (
        <>
          <Link className="table__action__icon" >
            <ChartBarIcon color="#00E0FF" />
          </Link>
          <Link className="table__action__icon" >
            <CarIcon color="#00E0FF" />
          </Link>
        </>
      ),
      disableFilters: true,
      disableSortBy: true
    }
  ],
    []
  );

  const dataGenerator = () => {
    let data = [];
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        colaborador: ['Oscar Erazo', 'Laura Yepez', 'Sara Ruiz'][Math.floor((Math.random() * 3))],
        area: ['Megamaxi 6 de Diciembre', 'Supermaxi Multicentro', 'Supermaxi 12 de Octubre', 'Marketing', 'Bienes', 'Financiera'][Math.floor((Math.random() * 6))],
      });
    }
    return data;
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
          <AccountOutlineIcon style={{ height: '40px', width: '50px', color: "#4B545A", marginRight: '13px', marginTop: '-7px' }} />
          <h3 className="page-title">Historial de <span className="text-danger">Autorizaciones</span></h3>
        </Col>
      </Row>
      <Row>
        <Container style={{ paddingBottom: '20px', paddingRight: '49px', marginLeft: "0px", width: "70vw" }}>
          <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
        </Container>
      </Row>
    </Container>
  )
}