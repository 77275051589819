import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import Switch from '@material-ui/core/Switch';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import TrashCanIcon from 'mdi-react/TrashCanIcon';

import EyeIcon from 'mdi-react/EyeIcon';
import TableBase from './components/TableBase';
import CarIcon from 'mdi-react/CarIcon';
import UserBadgeIcon from 'mdi-react/UserCardDetailsOutlineIcon';
import ClearBoxIcon from 'mdi-react/ClearCircleIcon';

import { closeSessionByIdUser, deleteUserByIdList, listUser, putUserByIdList } from '../../redux/actions/usersAction';
import { listProvinces } from '../../redux/actions/locationAction';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '../../shared/components/Dialog';
import { CustomDataTable } from '../../shared/components/DataTable';
import { TextField, Tooltip } from '@material-ui/core';
import { useBreadCrums } from '../../hooks/useBreadCrums';
import { DataTableCustom } from '../../shared/components/DataTableCustom';
import { ButtonCustom } from '../../shared/components/Button/Button';

export const columnsTable = [
  {
    name: 'nro.',
    selector: (row) => row.cod,
    sortable: true,
    width: '98px',
  },
  {
    name: 'RUC',
    selector: (row) => row.dni,
    width: '200px',
  },
  {
    name: 'Tipo contrato',
    selector: (row) => row.razon,
    width: '200px',
  },
  {
    name: 'Nombre',
    selector: (row) => row.name,
    width: '300px',
  },
  {
    name: 'Celular',
    selector: (row) => row.phoneNumber,
    width: '200px',
  },
  {
    name: 'Zona',
    selector: (row) => row.zona,
    width: '200px',
  },
  {
    name: 'Conductores Afiliados',
    selector: (row) => row.conductores,
    center: true,
    width: '200px',
  },
  {
    name: 'Autos inscritos',
    selector: (row) => row.autos,
    center: true,
    width: '200px',
  },
  {
    name: 'Habilitado',
    selector: (row) => row.habilitado,
    center: true,
    width: '200px',
  },
  {
    name: 'Acciones',
    selector: (row) => row.acciones,
    center: true,
    width: '200px',
  },
  {
    name: 'Documentos',
    selector: (row) => row.documentos,
    center: true,
    width: '200px',
  },
];

const cols = [
  {
    field: 'cod',
    header: 'Nro.',
    style: { maxWidth: '80px' },
    filter: true,
    sortable: true,
  },
  {
    field: 'dni',
    header: 'RUC',
    filter: true,
    sortable: true,
    style: { minWidth: '150px' },
  },
  {
    field: 'razon',
    header: 'Tipo contrato',
    sortable: true,
    filter: true,
  },
  {
    field: 'name',
    header: 'Nombre',
    sortable: true,
    filter: true,
    style: { minWidth: '300px' },
  },
  {
    field: 'phoneNumber',
    header: 'Celular',
    sortable: true,
    filter: true,
    style: { minWidth: '150px' },
  },
  {
    field: 'zona',
    header: 'Zona',
    sortable: true,
    filter: true,
    style: { minWidth: '150px' },
  },
  {
    field: 'conductores',
    header: 'Conductores Afiliados',
    style: { minWidth: '150px' },
  },
  {
    field: 'autos',
    header: 'Autos inscritos',
    style: { minWidth: '150px' },
  },
  {
    field: 'habilitado',
    header: 'Habilitado',
    style: { minWidth: '150px' },
  },
  {
    field: 'acciones',
    header: 'Acciones',
    style: { minWidth: '150px' },
  },
  {
    field: 'documentos',
    header: 'Documentos',
    style: { minWidth: '150px' },
  },
  {
    field: 'session',
    header: 'Sesiones',
    style: { minWidth: '150px' },
  },
];

const Providers = () => {
  const dispatch = useDispatch();
  const isFetchingusersLists = useSelector((state) => state.users.isFetching);
  const usersLists = useSelector((state) => state.users.data);
  const provincesLists = useSelector((state) => state.location.data);
  const [rol, setRol] = useState(4);
  const userAuth = useSelector((state) => state.auth.dataUser);
  const [open, setOpen] = useState(false);
  const [selectedProv, setSelectedProv] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [listUsers, setlistUsers] = useState([]);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Proveedores', 1);
  }, [location]);

  const deleteProveedor = () => {
    // console.log(selectedProv);
    dispatch(deleteUserByIdList(selectedProv, rol));
  };

  const selectedProveedor = (id) => {
    setOpen(true);
    setSelectedProv(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'nro.',
        Cell: (cellObj) => cellObj.row.index + 1,
      },
      {
        Header: 'RUC',
        accessor: 'dni',
        disableFilters: true,
      },
      {
        Header: 'Razon social',
        accessor: 'razon',
        Cell: (cellObj) => <>{razonSocial(usersLists[cellObj.row.index].typeContrac)}</>,
        disableFilters: true,
      },
      {
        Header: 'Nombre',
        accessor: (data) => `${data.firstName} ${data.firstLastName} ${data.secondLastName}`,
        Cell: (cellObj) => (
          <>
            <span style={{ textTransform: 'uppercase' }}>{cellObj.cell.row.original.firstName}</span> <span style={{ textTransform: 'uppercase' }}>{cellObj.cell.row.original.firstLastName}</span> <span style={{ textTransform: 'uppercase' }}>{cellObj.cell.row.original.secondLastName}</span>
          </>
        ),
      },
      {
        Header: 'Celular',
        accessor: 'phoneNumber',
        disableFilters: true,
      },

      // {
      //   Header: 'Provincia',
      //   accessor: 'Provincia',
      //   Cell: (cellObj) => <>{printProvince(usersLists[cellObj.row.index].cityId)}</>,
      //   disableFilters: true,
      // },
      {
        Header: 'Conductores Afiliados',
        accessor: 'Conductores',
        Cell: (cellObj) => (
          <>
            <Tooltip title="Mirar conductores afiliados" placement="top" arrow>
              <Link className="table__action__icon" to={`/drivers/${usersLists[cellObj.row.index].id}`}>
                <UserBadgeIcon color="#00E0FF" className="ml-2" />
              </Link>
            </Tooltip>
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Autos inscritos',
        accessor: 'Autos',
        Cell: (cellObj) => (
          <>
            <Tooltip title="Mirar autos inscritos" placement="top" arrow>
              <Link className="table__action__icon" to={`/drivers/vehicle/provider/${usersLists[cellObj.row.index].id}`}>
                <CarIcon color="#00E0FF" className="ml-2" />
              </Link>
            </Tooltip>
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: () => <div style={{ marginLeft: '50px' }}>Habilitado</div>,
        accessor: '[enabledButton]',
        Cell: (cellObj) => (
          <div style={{ textAlign: 'center' }}>
            <Switch disabled={userAuth.roleId !== 3} checked={cellObj.row.original.status === 'accepted'} onChange={() => handleUserEnabled(cellObj.row.original)} />
          </div>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Acciones',
        accessor: '[editButton]',
        Cell: (cellObj) => (
          <>
            <Tooltip title="Mirar estadísticas" placement="top" arrow>
              <Link className="table__action__icon" to={`/providers/${usersLists[cellObj.row.index].id}`}>
                <ChartBarIcon color="#00E0FF" />
              </Link>
            </Tooltip>
            {/* <IconButton title="Eliminar proveedor" aria-label="" onClick={() => console.log(usersLists[cellObj.row.index].id)}> */}
            <Tooltip title="Eliminar proveedor" placement="top" arrow>
              <a onClick={() => selectedProveedor(usersLists[cellObj.row.index].id)} style={{ cursor: 'pointer' }}>
                <TrashCanIcon color="red" />
              </a>
            </Tooltip>
            {/* // </IconButton> */}
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Documentos',
        accessor: 'edit',
        Cell: (cellObj) => (
          <>
            <Tooltip title="Mirar documento" placement="top" arrow>
              <Link title="Documentos" className="table__action__icon" to={`/providerPersonalData/${usersLists[cellObj.row.index].id}`}>
                <FileDocumentIcon color="#00E0FF" />
              </Link>
            </Tooltip>
          </>
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [usersLists]
  );

  const razonSocial = (razon) => {
    switch (razon) {
      case 'solidary':
        return 'Solidario';
      case 'juridic':
        return 'Jurídico';
      case 'natural':
        return 'Natural';

      default:
        return 'no tiene';
    }
  };

  const mapperTable = () => {
    if (usersLists) {
      if (usersLists.length > 0) {
        var result = [];
        usersLists.map((item, key) => {
          result.push({
            cod: key + 1,
            name: `${item.firstName} ${item.firstLastName} ${item.secondLastName}`,
            dni: item.dni,
            razon: razonSocial(item.typeContrac),
            phoneNumber: item.phoneNumber,
            zona: item.city ? item.city.province.nameProvince : '-',
            conductores: (
              <>
                <Tooltip title="Mirar conductores afiliados" placement="top" arrow>
                  <Link className="table__action__icon" to={`/drivers/${item.id}`}>
                    <UserBadgeIcon color="#00E0FF" className="ml-2" />
                  </Link>
                </Tooltip>
              </>
            ),
            autos: (
              <>
                <Tooltip title="Mirar autos inscritos" placement="top" arrow>
                  <Link className="table__action__icon" to={`/drivers/vehicle/provider/${item.id}`}>
                    <CarIcon color="#00E0FF" className="ml-2" />
                  </Link>
                </Tooltip>
              </>
            ),
            habilitado: (
              <div style={{ textAlign: 'center' }}>
                <Switch disabled={userAuth.roleId !== 3} checked={item.status === 'accepted'} onChange={() => handleUserEnabled(item)} />
              </div>
            ),
            acciones: (
              <>
                <Tooltip title="Mirar estadísticas" placement="top" arrow>
                  <Link className="table__action__icon" to={`/providers/${item.id}`}>
                    <ChartBarIcon color="#00E0FF" />
                  </Link>
                </Tooltip>
                <Tooltip title="Eliminar proveedor" placement="top" arrow>
                  <a onClick={() => selectedProveedor(item.id)} style={{ cursor: 'pointer' }}>
                    <TrashCanIcon color="red" />
                  </a>
                </Tooltip>
              </>
            ),
            documentos: (
              <>
                <Tooltip title="Mirar documento" placement="top" arrow>
                  <Link className="table__action__icon" to={`/providerPersonalData/${item.id}`}>
                    <FileDocumentIcon color="#00E0FF" />
                  </Link>
                </Tooltip>
              </>
            ),
            session: <ButtonCustom disabled={!item.device} title="Cerrar sesión" onClick={() => dispatch(closeSessionByIdUser(item.id, rol)) } />,
          });
        });
        setlistUsers(result);
      } else {
        setlistUsers([]);
      }
    }
  };

  const handleUserEnabled = (user) => {
    let body = {};
    if (user.status === 'accepted') body = { status: 'pending' };
    else body = { status: 'accepted' };
    dispatch(putUserByIdList(user.id, body, rol));
  };

  const printProvince = (id) => {
    if (id && provincesLists) {
      const province = provincesLists.find((el) => el.id === id);
      if (province) {
        return province.nameProvince;
      } else {
        return 'Sin provincia añadida';
      }
    }
    return 'Sin Ubicación';
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        mapperTable();
      }
    };

    return (
      <>
        <TextField
          // placeholder="Filtrar por nombre"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
            if (e.target.value == '') {
              mapperTable();
            }
            setlistUsers((prew) => prew.filter((item) => item.nameTmp && item.nameTmp.toLowerCase().includes(e.target.value.toLowerCase())));
          }}
          label="Filtar por nombre"
          size="small"
        />
        <IconButton aria-label="clerar" onClick={handleClear}>
          <ClearBoxIcon />
        </IconButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    dispatch(listUser(rol));
    mapperTable();
  }, [rol]);

  useEffect(() => {
    mapperTable();
  }, [usersLists]);

  useEffect(() => {
    if (provincesLists === null) dispatch(listProvinces());
  }, []);

  return (
    <Container className="dashboard" style={{ overflow: 'hidden' }}>
      <Row>
        <Col md={12} className="ml-3" style={{ display: 'flex', flexDirection: 'row', paddingTop: '21px' }}>
          <h3 className="page-title">Proveedor</h3>
        </Col>
      </Row>
      <Row>
        <Container
          style={{
            paddingLeft: '20px',
            paddingBottom: '20px',
            paddingRight: '20px',
          }}
          className="tableminsize"
        >
          {!isFetchingusersLists && usersLists ? (
            <>
              <DataTableCustom
                //
                data={listUsers}
                columns={cols}
                filtered={true}
                scrollHeight="500px"
              />
            </>
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Container>
      </Row>
      <Dialog title="Eliminar proveedor" description="¿Está seguro de eliminar al proveedor?" open={open} setOpen={setOpen} acceptClick={deleteProveedor} />
    </Container>
  );
};

export default Providers;
