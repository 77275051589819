import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleByUserId } from "../../../../redux/actions/ProvidersActions"
import { Spinner } from 'reactstrap';

export default function ReactTableMultitableBody({ page, getTableBodyProps, prepareRow, multiTableRowSelected }) {
    const dispatch = useDispatch();
    const usersLists = useSelector((state) => state.users.data);
    const isFetchingVehicleByUserId = useSelector((state) => state.provider.isFetchingVehicleByUserId);
    const vehicleByUserId = useSelector((state) => state.provider.vehicleByUserId);

    useEffect(() => {
        if (multiTableRowSelected) dispatch(getVehicleByUserId(usersLists[multiTableRowSelected].id))
    }, [multiTableRowSelected])

    return (
        <tbody className="table table--bordered" {...getTableBodyProps()}>
            {page.map((row) => {
                prepareRow(row);
                return (
                    <>
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                        {
                            row.id === `${multiTableRowSelected}` && <>
                                <tr {...row.getRowProps()} style={{ paddingLeft: "15px" }}>
                                    <td {...row.cells[0].getCellProps()} style={{ color: "#D8394C", paddingLeft: "60px" }}>
                                        {isFetchingVehicleByUserId
                                            ? <Spinner className="table-fetch-spinner" style={{ height: "20px", width: "20px" }} />
                                            : "Vehículo"}
                                    </td>
                                    <td {...row.cells[0].getCellProps()}>
                                    </td>
                                    <td {...row.cells[0].getCellProps()}>
                                    </td>
                                    <td {...row.cells[0].getCellProps()}>
                                    </td>
                                    <td {...row.cells[0].getCellProps()}>
                                    </td>
                                    <td {...row.cells[0].getCellProps()}>
                                    </td>
                                </tr>
                                {
                                    !isFetchingVehicleByUserId && vehicleByUserId &&
                                    vehicleByUserId.map(vehicle => (
                                        <tr {...row.getRowProps()}>
                                            <td {...row.cells[0].getCellProps()} style={{ paddingLeft: "60px" }}>
                                                {vehicle.vehicleProvider.carPlate}
                                            </td>
                                            <td {...row.cells[0].getCellProps()}>
                                            </td>
                                            <td {...row.cells[0].getCellProps()}>
                                            </td>
                                            <td {...row.cells[0].getCellProps()}>
                                            </td>
                                            <td {...row.cells[0].getCellProps()}>
                                            </td>
                                            <td {...row.cells[0].getCellProps()}>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </>
                        }
                    </>
                );
            })}
        </tbody>
    )
}