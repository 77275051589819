import React, { useEffect, useMemo } from 'react';

import { Col, Container, Row, Spinner, Input } from 'reactstrap';
import Select from "react-select";
import { Link } from 'react-router-dom';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';

import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale("es");
var meses = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_');
var semanas = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_');
moment.updateLocale('es', { months: meses, weekdays: semanas });

import TableBase from './components/TableBase';

export default function index() {

    
    const location = useLocation();
    const { pushNewItemBreadCrums } = useBreadCrums();

    useEffect(() => {
        pushNewItemBreadCrums('Total gasto');
    }, [location]);

    const columns = useMemo(() => [
        {
            Header: 'Usuario',
            accessor: 'Usuario',
            disableFilters: true,
            Cell: (cellObj) => (
                <h6 style={{ color: "#646777", marginTop: "2.5px", fontSize: "13px" }}>
                    <AccountCircleIcon color="#000000" style={{ marginRight: "10px" }} />
                    {cellObj.value}
                </h6>
            )
        },
        {
            Header: 'Tipo de viaje',
            accessor: 'TipoDeViaje',
            Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
                <Input
                    id="selectorDate"
                    name="date"
                    placeholder="Seleccione Fecha"
                    type="date"
                    onChange={e => {
                        if (e.target.value === '') setFilter(undefined)
                        else setFilter(moment(e.target.value, "YYYY-MM-DD").format('DD MMMM, YYYY'))
                    }}
                />
            )
        },
        {
            Header: 'Área / Local',
            accessor: 'Area',
            Filter: ({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) => (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="Area"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={"Área / Local"}
                    styles={{
                        option: (styles, state) => ({
                            ...styles,
                            backgroundColor: state.isSelected ? "rgb(0,117,255)" : ""
                        })
                    }}
                    onChange={e => {
                        if (e === null) setFilter(undefined)
                        else setFilter(e.value)
                    }}
                    options={[
                        { value: "Megamaxi 6 de Diciembr", label: "Megamaxi 6 de Diciembr" },
                        { value: "Supermaxi Multicentro", label: "Supermaxi Multicentro" },
                        { value: "Supermaxi 12 de Octubre", label: "Supermaxi 12 de Octubre" },
                        { value: "Marketing", label: "Marketing" },
                        { value: "Bienes", label: "Bienes" },
                        { value: "Financiera", label: "Financiera" }
                    ]}
                />
            )
        },
        {
            Header: 'Provincia',
            accessor: 'Provincia',
            disableFilters: true
        },
        {
            Header: 'Gasto',
            accessor: 'Gasto',
            disableFilters: true
        }
    ],
        []
    );

    const dataGenerator = () => {
        let data = [];
        for (let i = 1; i < 36; i += 1) {
            data.push({
                id: i,
                Usuario: ['Oscar Erazo', 'Laura Yepez', 'Sara Ruiz'][Math.floor((Math.random() * 3))],
                TipoDeViaje: ['Liviano', 'Abierto'][Math.floor((Math.random() * 2))],
                Provincia: ['Pinchincha', 'Guayas', 'Esmeraldas', 'Imbabura'][Math.floor((Math.random() * 4))],
                Area: ['Megamaxi 6 de Diciembre', 'Supermaxi Multicentro', 'Supermaxi 12 de Octubre', 'Marketing', 'Bienes', 'Financiera'][Math.floor((Math.random() * 6))],
                Gasto: ['$ 4.00']
            });
        }
        return data;
    }

    return (
        <Container className="dashboard">
            <Row>
                <Col md={12} style={{ display: "flex", flexDirection: "row", paddingTop: '21px' }}>
                    <h3 className="page-title">Total de Gasto: <span className="text-danger">Enero</span></h3>
                </Col>
            </Row>
            <Row>
                <Container style={{ paddingLeft: '38px', paddingBottom: '20px', paddingRight: '49px', marginLeft: "0px", width: "70vw" }}>
                    <TableBase reactTableData={{ tableHeaderData: columns, tableRowsData: dataGenerator() }} />
                </Container>
            </Row>
        </Container>
    )
}
