import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'reactstrap';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import TableBase from './components/TableBase';
import { vehicleProviders, putVehicleStatusList } from '../../../redux/actions/ProvidersActions';
import { useBreadCrums } from '../../../hooks/useBreadCrums';

const VehiclesByProvider = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isFetchingusersLists = useSelector((state) => state.provider.isFetching);
  const vehicleLists = useSelector((state) => state.provider.vehicle);
  const userAuth = useSelector((state) => state.auth.dataUser);
  const privileges = useSelector((state) => state.auth.privileges);

  const location = useLocation();
  const { pushNewItemBreadCrums } = useBreadCrums();

  useEffect(() => {
    pushNewItemBreadCrums('Autos incritos');
  }, [location]);

  const columns = useMemo(
    () => [
      {
        Header: 'Placa',
        Cell: (cellObj) => (
          <Link style={{ color: '#646777', marginTop: '2.5px', fontSize: '13px' }} to={`/drivers/vehicle/${cellObj.cell.row.original.id}`}>
            <span style={{ textTransform: 'uppercase', textDecoration: 'underline', textDecorationColor: 'blue', color: 'blue' }}>{cellObj.cell.row.original.carPlate}</span>
          </Link>
        ),
        disableFilters: true,
      },
      {
        Header: 'Marca',
        accessor: (obj) => (obj.model ? obj.model.mark.name : ''),
        disableFilters: true,
      },
      {
        Header: 'Modelo',
        accessor: (obj) => (obj.model ? obj.model.name : ''),
        disableFilters: true,
      },
      {
        Header: 'Mantenimiento',
        accessor: '[mantenimentButton]',
        Cell: (cellObj) => <Radio disabled={userAuth.roleId === 3 && privileges && privileges.maintenance ? false : true} className="radio-table" checked={cellObj.row.original.maintenance === 'accepted'} onClick={() => handleDataRow(cellObj.cell.row.original.id, cellObj.cell.row.original.maintenance, 'maintenance', cellObj.cell.row.original)} />,
        disableFilters: true,
      },
      {
        Header: 'Corporativo',
        accessor: '[corpButton]',
        Cell: (cellObj) => <Radio disabled={userAuth.roleId === 3 && privileges && privileges.corporate ? false : true} className="radio-table" checked={cellObj.row.original.corporate === 'accepted'} onClick={() => handleDataRow(cellObj.cell.row.original.id, cellObj.cell.row.original.corporate, 'corporate', cellObj.cell.row.original)} />,
        disableFilters: true,
      },
      {
        Header: 'Seguridad',
        accessor: '[secButton]',
        Cell: (cellObj) => <Radio disabled={userAuth.roleId === 3 && privileges && privileges.security ? false : true} className="radio-table" checked={cellObj.row.original.security === 'accepted'} onClick={() => handleDataRow(cellObj.cell.row.original.id, cellObj.cell.row.original.security, 'security', cellObj.cell.row.original)} />,
        disableFilters: true,
      },
      {
        Header: 'Habilitado',
        accessor: '[enabledButton]',
        Cell: (cellObj) => <Switch disabled={userAuth.roleId !== 3} checked={cellObj.row.original.status === 'accepted'} onChange={() => handleDataRow(cellObj.cell.row.original.id, cellObj.cell.row.original.status, 'status', cellObj.cell.row.original)} />,
        disableFilters: true,
      },
    ],
    [vehicleLists]
  );

  const filterOptions = (filterAttr) => {
    let arrayToReturn = [];
    let filterColumnData = [];
    vehicleLists.map((value) => {
      if (value[filterAttr] !== null) filterColumnData.push(value[filterAttr]);
    });

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    filterColumnData.filter(onlyUnique).map((value) => arrayToReturn.push({ value, label: value }));

    return arrayToReturn;
  };

  const handleDataRow = (id_data, status, type, data = null) => {
    let body = {};
    switch (type) {
      case 'maintenance':
        body = { maintenance: 'accepted' };
        if (status === 'accepted') {
          body = { maintenance: 'pending' };
          data.maintenance = 'pending';
        }

        break;
      case 'corporate':
        body = { corporate: 'accepted' };
        if (status === 'accepted') {
          body = { corporate: 'pending' };
          data.corporate = 'pending';
        }
        break;
      case 'security':
        body = { security: 'accepted' };
        if (status === 'accepted') {
          body = { security: 'pending' };
          data.security = 'pending';
        }
        break;
      default:
        if (status !== 'accepted') {
          if (data.maintenance !== 'accepted' || data.corporate !== 'accepted' || data.security !== 'accepted') {
            toast.error('Primero se deben habilitar los permisos', {
              autoClose: 2500,
              theme: 'colored',
            });
            return;
          }
        } else {
        }
        body = { status: 'accepted' };
        if (status === 'accepted') body = { status: 'pending' };
        break;
    }

    if (data.maintenance === 'pending' || data.corporate === 'pending' || data.security === 'pending') {
      body = {
        ...body,
        status: 'pending',
      };
    }
    dispatch(putVehicleStatusList(id_data, body, id));
  };

  useEffect(() => {
    dispatch(vehicleProviders(id));
  }, [id]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} style={{ display: 'flex', flexDirection: 'row', paddingTop: '21px' }}>
          <h3 className="page-title">
            Autos <span className="text-danger">Inscritos</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Container
          style={{
            paddingLeft: '38px',
            paddingBottom: '20px',
            paddingRight: '49px',
          }}
        >
          {!isFetchingusersLists && vehicleLists ? (
            <TableBase
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: vehicleLists,
              }}
            />
          ) : (
            <Spinner className="table-fetch-spinner" style={{ marginLeft: '50%' }} />
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default VehiclesByProvider;
